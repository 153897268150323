const Referral = [{
    path: '/referral-list',
    name: 'referral-list',
    component: () =>
        import (
            /* webpackChunkName: "page-referral-list" */
            /* webpackPrefetch: true */
            /* webpackMode: "eager" */
            '../views/ReferralList.vue'
        ),
    meta: {
        requiresAuth: true,
        permission: 'GET:employee.default.employee-management-menu'
    }
},{
    path: '/referral-agreement',
    name: 'referral-agreement',
    component: () =>
        import (
            /* webpackChunkName: "page-referral-agreement" */
            /* webpackPrefetch: true */
            /* webpackMode: "eager" */
            '../views/ReferralAgreement.vue'
        ),
    meta: {
        requiresAuth: true,
        permission: 'GET:employee.default.employee-management-menu'
    }
}]
export default Referral
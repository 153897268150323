export default {
    id: 0,
    token: '-',
    authorization_code: '-',
    expires_at: '-',
    user_id: 0,
    refresh_token: '-',
    created_by_user_id: 0,
    updated_by_user_id: 0,
    created_date_user_id: '-',
    updated_date_user_id: 0,
    created_by_client_id: 0,
    updated_by_client_id: 0,
    created_date_client_id: 0,
    updated_date_client_id: 0,
    created_via: '-',
    user: {
        id: 0,
        username: '-',
        password: '-',
        active: '0',
        last_login: '-',
        last_login_ip: '-',
        created_by_user_id: null,
        updated_by_user_id: null,
        created_date_user_id: null,
        updated_date_user_id: null,
        created_by_client_id: null,
        updated_by_client_id: null,
        created_date_client_id: null,
        updated_date_client_id: null,
        created_via: null,
        roles: [
            {
                id: 0,
                user_id: 0,
                role_id: 0,
                role: {
                    id: 0,
                    name: '-',
                    permissions: []
                }
            }
        ],
        employee: {
            id: 0,
            firstname: '-',
            lastname: '-',
            dob: '-',
            pob: '-',
            employee_no: '-',
            user_id: 0,
            phone: '-',
            image: null,
            hire_date: '-',
            resign_date: null,
            meal_allowance: '-',
            department_id: 0,
            occupation_id: 0,
            job_id: null,
            type_id: null,
            branch_id: 0,
            employee_group_id: null,
            leave_type_id: null,
            bank_id: 0,
            bank_account_no: null,
            bank_type_id: null,
            bank_account_name: null,
            bpjs_kesehatan: null,
            bpjs_ketenagakerjaan: null,
            npwp: '-',
            created_date_user_id: null,
            created_by_user_id: null,
            updated_by_user_id: null,
            updated_date_user_id: null,
            created_date_client_id: null,
            created_by_client_id: null,
            updated_by_client_id: null,
            updated_date_client_id: null,
            created_via: null,
            gender_id: 0,
            email: '0',
            identity_type_id: null,
            identity_number: null,
            company_name: null,
            company_field: null,
            job_desc: null,
            bank_branch: null,
            mobile_number: '-',
            email_signature: null,
            personal_email: '-',
            alias_code: null,
            upline_id: null,
            biological_mother_name: null,
            department: {
                id: 0,
                name: '-',
                code: null,
                manager_id: 0,
                parent_id: 0,
                created_date_user_id: null,
                created_by_user_id: null,
                updated_by_user_id: null,
                updated_date_user_id: null,
                created_date_client_id: null,
                created_by_client_id: null,
                updated_by_client_id: null,
                updated_date_client_id: null,
                is_sales: 0
            },
            occupation: {
                id: 0,
                name: '-',
                parent_id: null,
                department_id: 0,
                created_date_user_id: null,
                created_by_user_id: null,
                updated_by_user_id: null,
                updated_date_user_id: null,
                created_date_client_id: null,
                created_by_client_id: null,
                updated_by_client_id: null,
                updated_date_client_id: null
            }
        }
    }
}

<template>
  <div>
    <Header>
      <template v-slot:nav-item-left>
        <li class="nav-item">
          <router-link class="navbar-nav-link" to="/"
            >Verify</router-link
          >
        </li>
      </template>
    </Header>
    <div class="page-content">
      <div class="content-wrapper">
        <div class="content">
            <div class="row justify-content-center">
                <div class="col-md-5">
                    <div class="card bg-white text-center">
                        <div class="card-header bg-white">
                            <h2 class="text-success">Kode Google Authenticator</h2>
                        </div>
                        <div class="card-body">
                            <img src="https://backoffice-frontend.test/template/images/Capture.png?20210709050315" alt="">
                            <p>Masukan 6 digit code</p>
                            <div v-if="error" class="alert alert-danger">
                                <p>Kode Salah</p>
                            </div>
                        <div class="row mt-4">
                            <div class="col-md-2 col-sm-2 col-xs-2">                                
                                <div class="form-group">
                                    <input id="code0" type="text" autofocus required class="form-control text-center" style="font-size: 30px;" maxlength="1" v-model="code0">
                                </div>
                            </div>
                            <div class="col-md-2 col-sm-2 col-xs-2">                                
                                <div class="form-group">
                                    <input id="code1" type="text" required class="form-control text-center" style="font-size: 30px;" maxlength="1" v-model="code1">
                                </div>
                            </div>
                            <div class="col-md-2 col-sm-2 col-xs-2">                                
                                <div class="form-group">
                                    <input id="code2" type="text" required class="form-control text-center" style="font-size: 30px;" maxlength="1" v-model="code2">
                                </div>
                            </div>
                            <div class="col-md-2 col-sm-2 col-xs-2">                                
                                <div class="form-group">
                                    <input id="code3" type="text" required class="form-control text-center" style="font-size: 30px;" maxlength="1" v-model="code3">
                                </div>
                            </div>
                            <div class="col-md-2 col-sm-2 col-xs-2">                                
                                <div class="form-group">
                                    <input id="code4" type="text" required class="form-control text-center" style="font-size: 30px;" maxlength="1" v-model="code4">
                                </div>
                            </div>
                            <div class="col-md-2 col-sm-2 col-xs-2">                                
                                <div class="form-group">
                                    <input id="code5" type="text" required class="form-control text-center" style="font-size: 30px;" maxlength="1" v-model="code5">
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-6">
                                <button @click="cancleButton" class="btn btn-lg btn-warning mt-5 mb-5">Batalkan</button>
                            </div>
                            <div class="col-md-6">
                                <button @click="submitForm" class="btn btn-lg btn-success mt-5 mb-5">Lanjutkan</button>
                            </div>
                        </div><br>
                            <a href="#" @click="confirmRequest" class="text-success">Request 16 digit kode setup baru</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import $ from "jquery";
import Authtentication from "../apis/Authtentication"
import router from '../routes'
import TokenObject from '../models/roleByAccessTokenObject'
import Log from '../apis/Log'
import axios from 'axios'
import HelperApi from '../apis/Helper'
export default {
  data() {
    return {
        user: {
            username: '',
            password: '',
            ip: '127.0.0.1',
            loc: 'hrms-default',
            via: 'hrms-frontend',
            ua: 'hrms-frontend',
        },
        code0:'',
        code1:'',
        code2:'',
        code3:'',
        code4:'',
        code5:'',
        error:false,
        ip_address: '',
        loading: false,
        errorMessage: '',
        isError: false,
        TokenObject: TokenObject
    }
  },
  components: {
    Header: () =>
      import(
        /* webpackChunkName: "component-header" */
        /* webpackPrefetch: true */
        /* webpackMode: "eager" */
        '../components/Header'
      ),
    Sidebar: () =>
      import(
        /* webpackChunkName: "component-sidebar" */
        /* webpackPrefetch: true */

        /* webpackMode: "eager" */
        '../components/Sidebar'
      )
  },
  methods: {
      confirmRequest(){
          if(confirm('Apakah anda yakin?')){
              Authtentication
              .validGoogleAuthResend(JSON.parse(localStorage.getItem('hr_token')).user_id)
              .then((result) => {
                  if(result.data.status =='1'){
                      alert('16 digit kode setup Google Authenticator Anda sudah dikirimkan melalui SMS ke nomor HP Anda.')
                  }else{                      
                        alert('Terlalu cepat, silahkan tunggu 3 menit lagi.')
                  }
              }).catch((err) => {
                        alert('Terlalu cepat, silahkan tunggu 3 menit lagi.')
              });
          }
      },
      cancleButton(){
        localStorage.clear();
		router.push('/login')
      },
      submitForm(){
          let code = this.code0+this.code1+this.code2+this.code3+this.code4+this.code5
          Authtentication
          .validGoogleAuth(code,JSON.parse(localStorage.getItem('hr_token')).user_id)
          .then((result) => {
           console.log(result) 
           console.log(result.data) 
           if(result.data.status == '1'){
               Authtentication.accesstoken(JSON.parse(localStorage.getItem('hr_token')).code)
               .then(res=>{
                   let hr_token = JSON.parse(localStorage.getItem('hr_token'))
                   hr_token = {...hr_token,...res.data.data}
                   localStorage.setItem('hr_token', JSON.stringify(hr_token))
                    console.log(localStorage.getItem('hr_token'))
                    
                    let payload = {
                        user_id: hr_token.user_id,
                        user_type: 'user',
                        ip_address: hr_token.user_ip,
                        original_source: 'ERP - HRMS',
                        log_type: 'login',
                        log_data: JSON.stringify(hr_token)
                    }
                    Log.LoginLogoutLogs(payload)
                    .then(res =>{
                        console.log(res)
                    })
                    .catch(err=>{
                        console.log(err)
                    })
                    HelperApi.RoleByAccessToken()
                    .then(res =>{
                        console.log(res)
                        this.TokenObject = res.data.data
                        this.TokenObject.user.password = ''
                        console.log('this.TokenObject ', this.TokenObject)
                        localStorage.setItem(
                            'hr_token_object',
                            JSON.stringify(this.TokenObject)
                        )
                        localStorage.setItem('is_verify',true)
                        localStorage.setItem('is_login',true)
                        router.push('/')
                    })
                    .catch(err=>{
                        localStorage.clear()
                        router.push('/login')
                    })
               })
               .catch(err=>{
                    localStorage.clear()
                    router.push('/login')
               })
           }else{
               this.error = true
               this.code0 = ''
               this.code1 = ''
               this.code2 = ''
               this.code3 = ''
               this.code4 = ''
               this.code5 = ''
           }
          }).catch((err) => {
            console.log(err)  
               this.code0 = ''
               this.code1 = ''
               this.code2 = ''
               this.code3 = ''
               this.code4 = ''
               this.code5 = ''
          });
      }
  },
  mounted() {
            document.title = 'Verify Your Login'
            let $inputs = $(".form-control");
            let intRegex = /^\d+$/;
            $inputs.on("input.fromManual", function () {
                if (!intRegex.test($(this).val())) {
                    $(this).val("");
                }
            });
            $inputs.on("paste", function () {
            $inputs.attr("maxlength", 6);
            let $this = $(this);
            let originalValue = $this.val();
            $this.val("");
            $this.one("input.fromPaste", function () {
                let values = $(this).val().match(/.{1,1}/g);
                $("#code0").val("");
                $("#code1").val("");
                $("#code2").val("");
                $("#code3").val("");
                $("#code4").val("");
                $("#code5").val("");
                $inputs.attr("maxlength", 6);
                for (let i = 0; i < values.length; i++) {
                    if ($("#code0").val().length < 1) {
                        this.value = this.value.toString() + values[i];
                    }
                    else if ($("#code1").val().length < 1) {
                        $("#code1").val( $("#code1").val().toString() + values[i]);
                    }
                    else if ($("#code2").val().length < 1){
                        $("#code2").val($("#code2").val().toString() + values[i]);
                    }
                    else if ($("#code3").val().length < 1){
                        $("#code3").val($("#code3").val().toString() + values[i]);
                    }
                    else if ($("#code4").val().length < 1){
                        $("#code4").val($("#code4").val().toString() + values[i]);
                    }
                    else if ($("#code5").val().length < 1){
                        $("#code5").val($("#code5").val().toString() + values[i]);
                    }
                }
                    $inputs.attr("maxlength", 1);
                });

            
        });
  
        $('#code0').keyup(function() {
            if(this.value.length >= $(this).attr('maxlength'))
                {
                    $('#code1').focus();
                }
            });
        $('#code1').keyup(function() {
        if(this.value.length >= $(this).attr('maxlength'))
            {
                $('#code2').focus();
            }
        });
        $('#code2').keyup(function() {
        if(this.value.length >= $(this).attr('maxlength'))
            {
                $('#code3').focus();
            }
        });
        $('#code3').keyup(function() {
        if(this.value.length >= $(this).attr('maxlength'))
            {
                $('#code4').focus();
            }
        });
        $('#code4').keyup(function() {
            if(this.value.length >= $(this).attr('maxlength'))
                {
                    $('#code5').focus();
                }
        });
        let is_login = localStorage.getItem('is_login') 
        if(!is_login){
            localStorage.clear()
            router.push('/login')
        }

        if( typeof is_login == 'string' ){
            if( is_login == 'true' ){
                router.push('/')
            }
        }
    }
}
</script>

const lotEarningsReport = [{
    path: '/lot-earnings-report',
    name: 'lot-earnings-report-view',
    component: () =>
        import (
            /* webpackChunkName: "page-lot-earnings-report-view" */
            /* webpackPrefetch: true */
            /* webpackMode: "eager" */
            '../views/LotEarningsReport.vue'
        ),
    meta: {
        requiresAuth: true,
        permission: 'GET:employee.default.lot-earnings-report-view'
    }
}, {
    path: '/lot-earnings-report-detail/:user_id-:year-:month-:type',
    name: 'lot-earnings-report-detail-view',
    component: () =>
        import (
            /* webpackChunkName: "lot-earnings-report-detail-view" */
            /* webpackPrefetch: true */
            /* webpackMode: "eager" */
            '../views/LotEarningsReportDetail.vue'
        ),
    meta: {
        requiresAuth: true,
        permission: 'GET:employee.default.lot-earnings-report-view'
    }
}, {
    path: '/lot-earnings-report-client',
    name: 'lot-earnings-report-client-view',
    component: () =>
        import (
            /* webpackChunkName: "page-lot-earnings-report-client-view" */
            /* webpackPrefetch: true */
            /* webpackMode: "eager" */
            '../views/LotEarningsReportClient.vue'
        ),
    meta: {
        requiresAuth: true,
        permission: 'GET:employee.default.lot-earnings-report-view'
    }
}, {
    path: '/lot-earnings-report-client-detail/:user_id-:year-:month-:type',
    name: 'lot-earnings-report-client-detail-view',
    component: () =>
        import (
            /* webpackChunkName: "lot-earnings-report-client-detail-view" */
            /* webpackPrefetch: true */
            /* webpackMode: "eager" */
            '../views/LotEarningsReportClientDetail.vue'
        ),
    meta: {
        requiresAuth: true,
        permission: 'GET:employee.default.lot-earnings-report-view'
    }
}]
export default lotEarningsReport
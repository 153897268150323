const employee = [
  {
    path: '/employees',
    name: 'employee-list',
    component: () =>
      import(
        /* webpackChunkName: "page-employee-list" */
        /* webpackPrefetch: true */
        /* webpackMode: "eager" */
        '../views/EmployeeList.vue'
      ),
    meta: {
      requiresAuth: true,
      permission: 'GET:employee.default.index'
    }
  },
  {
    path: '/employees-disabled',
    name: 'employee-list-disabled',
    component: () =>
      import(
        /* webpackChunkName: "page-employee-list-disabled" */
        /* webpackPrefetch: true */
        /* webpackMode: "eager" */
        '../views/EmployeeListDisabled.vue'
      ),
    meta: {
      requiresAuth: true,
      permission: 'GET:employee.default.index'
    }
  },
  {
    path: '/employees/create',
    name: 'employee-create',
    component: () =>
      import(
        /* webpackChunkName: "page-employee-create" */
        /* webpackPrefetch: true */
        /* webpackMode: "eager" */
        '../views/EmployeeCreate.vue'
      ),
    meta: {
      requiresAuth: true,
      permission: 'GET:employee.default.create'
    }
  },
  {
    path: '/employees/:id',
    name: 'employee-view',
    component: () =>
      import(
        /* webpackChunkName: "page-employee-view" */
        /* webpackPrefetch: true */
        /* webpackMode: "eager" */
        '../views/EmployeeView.vue'
      ),
    meta: {
      requiresAuth: true,
      permission: 'GET:employee.default.view'
    }
  },
  {
    path: '/employees/:id/tasks',
    name: 'employee-task-list-view',
    component: () =>
      import(
        /* webpackChunkName: "employee-task-list-view" */
        /* webpackPrefetch: true */
        /* webpackMode: "eager" */
        '../views/EmployeeTaskList.vue'
      ),
    meta: {
      requiresAuth: true,
      permission: 'GET:employee.default.employee-task-list-view'
    }
  },
  {
    path: '/employees/:id/request-tasks',
    name: 'employee-request-task-list-view',
    component: () =>
      import(
        /* webpackChunkName: "employee-request-task-list-view" */
        /* webpackPrefetch: true */
        /* webpackMode: "eager" */
        '../views/EmployeeTaskRequestList.vue'
      ),
    meta: {
      requiresAuth: true,
      permission: 'GET:employee.default.employee-request-task-list-view'
    }
  },
  {
    path: '/employees/:id/approval-tasks',
    name: 'employee-approval-task-list-view',
    component: () =>
      import(
        /* webpackChunkName: "employee-approval-task-list-view" */
        /* webpackPrefetch: true */
        /* webpackMode: "eager" */
        '../views/EmployeeTaskApprovalList.vue'
      ),
    meta: {
      requiresAuth: true,
      permission: 'GET:employee.default.employee-approval-task-list-view'
    }
  },
  {
    path: '/employees/:id/history-tasks',
    name: 'employee-history-task-list-view',
    component: () =>
      import(
        /* webpackChunkName: "employee-history-task-list-view" */
        /* webpackPrefetch: true */
        /* webpackMode: "eager" */
        '../views/EmployeeTaskList.vue'
      ),
    meta: {
      requiresAuth: true,
      permission: 'GET:employee.default.employee-history-task-list-view'
    }
  },
  {
    path: '/employees/:id/task/:tid',
    name: 'employee-task-detail-view',
    component: () =>
      import(
        /* webpackChunkName: "employee-task-detail-view" */
        /* webpackPrefetch: true */
        /* webpackMode: "eager" */
        '../views/EmployeeTaskDetail.vue'
      ),
    meta: {
      requiresAuth: true,
      permission: 'GET:employee.default.employee-task-detail-view'
    }
  },
  {
    path: '/employees/:id/edit',
    name: 'employee-edit',
    component: () =>
      import(
        /* webpackChunkName: "page-employee-edit" */
        /* webpackPrefetch: true */
        /* webpackMode: "eager" */
        '../views/EmployeeEdit'
      ),
    meta: {
      requiresAuth: true,
      permission: 'GET:employee.default.view'
    }
  },
  {
    path: '/employees/:id/reset-password',
    name: 'employee-reset-password',
    component: () =>
      import(
        /* webpackChunkName: "page-employee-reset-password" */
        /* webpackPrefetch: true */
        /* webpackMode: "eager" */
        '../views/EmployeeResetPassword'
      ),
    meta: {
      requiresAuth: true,
      permission: 'GET:employee.default.view'
    }
  }
]
export default employee

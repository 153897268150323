<template>
  <div>
    <Header>
      <template v-slot:nav-item-left>
        <li class="nav-item">
          <router-link class="navbar-nav-link" to="/"
            >Employee Edit</router-link
          >
        </li>
      </template>
      <template v-slot:nav-item-right>
        <form
          @submit.prevent="validateForm"
          action="javascript:void(0)"
          class="form-inline"
        >
          <button class="btn btn-success my-2 my-sm-0" type="submit">
            Update
          </button>
        </form>
      </template>
    </Header>
    <div class="page-content">
      <Sidebar />
      <div class="content-wrapper">
        <div class="content">
          <div class="row">
            <div class="col-md-6">
              <div class="card">
                <div class="card-header bg-white">
                  Personal Data
                </div>
                <div class="card-body">
                  <div class="row">
                    <div class="col-md-6">
                      <div class="form-group">
                        <label for="firstname">Firstname</label>
                        <input
                          class="form-control"
                          id="firstname"
                          type="text"
                          v-model="employee.firstname"
                        />
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="form-group">
                        <label for="lastname">Lastname</label>
                        <input
                          class="form-control"
                          id="lastname"
                          type="text"
                          v-model="employee.lastname"
                        />
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="form-group">
                        <label for="gender_id">Gender</label>
                        <model-select
                          :options="genders"
                          id="gender_id"
                          v-model="employee.gender_id"
                        ></model-select>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="form-group">
                        <label>Date Of Birth</label>
                        <datepicker
                          :input-class="'form-control'"
                          id="dob"
                          placeholder="Pilih Tanggal lahir"
                          v-model="employee.dob"
                        />
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="form-group">
                        <label for="pob">Place Of Birth</label>
                        <input
                          class="form-control"
                          id="pob"
                          type="text"
                          v-model="employee.pob"
                        />
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="form-group">
                        <label for="personal_email">Personal Email</label>
                        <input
                          class="form-control"
                          id="personal_email"
                          type="email"
                          v-model="employee.personal_email"
                          @input="searchFC"
                        />
                        <div
                          class="text-success d-block mt-1"
                          v-if="is_fc_notif"
                        >
                          <template v-if="is_fc">
                            <p>email sudah terdaftar sebagai FC</p>
                            <p></p>
                          </template>
                          <template v-else
                            >email belum terdaftar sebagai FC</template
                          >
                        </div>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="form-group">
                        <label for="phone">Phone Number</label>
                        <input
                          class="form-control"
                          id="phone"
                          type="text"
                          v-model="employee.phone"
                        />
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="form-group">
                        <label for="mobile_number">Mobile Number</label>
                        <input
                          class="form-control"
                          id="mobile_number"
                          type="text"
                          v-model="employee.mobile_number"
                        />
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="form-group">
                        <label for="identity_type_id">Identity Type</label>
                        <model-select
                          :options="identities"
                          id="identity_type_id"
                          v-model="employee.identity_type_id"
                        ></model-select>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="form-group">
                        <label for="identity_number">Identity Number</label>
                        <input
                          class="form-control"
                          id="identity_number"
                          type="text"
                          v-model="employee.identity_number"
                        />
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="form-group">
                        <label for="biological_mother_name"
                          >Biological Mother Name</label
                        >
                        <input
                          class="form-control"
                          id="biological_mother_name"
                          type="text"
                          v-model="employee.biological_mother_name"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="card">
                <div class="card-header bg-white">
                  Bank Data
                </div>
                <div class="card-body">
                  <div class="row">
                    <div class="col-md-6">
                      <div class="form-group">
                        <label for="bank_id">Bank</label>
                        <model-select
                          :options="banks"
                          id="bank_id"
                          v-model="employee.bank_id"
                        ></model-select>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="form-group">
                        <label for="bank_account_name">Bank Account Name</label>
                        <input
                          class="form-control"
                          id="bank_account_name"
                          type="text"
                          v-model="employee.bank_account_name"
                        />
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="form-group">
                        <label for="bank_account_no">Bank Account Number</label>
                        <input
                          class="form-control"
                          id="bank_account_no"
                          type="text"
                          v-model="employee.bank_account_no"
                        />
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="form-group">
                        <label for="bank_branch">Bank Branch</label>
                        <input
                          class="form-control"
                          id="bank_branch"
                          type="text"
                          v-model="employee.bank_branch"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="card">
                <div class="card-header bg-white">
                  Login Data
                </div>
                <div class="card-body">
                  <div class="row">
                    <div class="col-md-6">
                      <div class="form-group">
                        <label for="username">Username</label>
                        <input
                          class="form-control"
                          id="username"
                          type="text"
                          v-model="employee.user.username"
                        />
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="form-group">
                        <label for="password">Password</label>
                        <input
                          class="form-control"
                          id="password"
                          type="text"
                          v-model="employee.user.password"
                        />
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="form-group">
                        <label for="password_confirmation"
                          >Password Confirmation</label
                        >
                        <input
                          class="form-control"
                          id="password_confirmation"
                          type="text"
                          v-model="employee.user.password"
                        />
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="form-group">
                        <label for="is_active">Is Active</label>
                        <input
                          class="form-control"
                          id="is_active"
                          type="text"
                          v-model="employee.user.active"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="card">
                <div class="card-header bg-white">
                  HR Data
                </div>
                <div class="card-body">
                  <div class="row">
                    <div class="col-md-6">
                      <div class="form-group">
                        <label for="employee_no">Employee No</label>
                        <input
                          class="form-control"
                          id="employee_no"
                          type="text"
                          v-model="employee.employee_no"
                        />
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="form-group">
                        <label for="department_id">Department</label>
                        <model-select
                          :options="departments"
                          id="department_id"
                          v-model="employee.department_id"
                        ></model-select>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="form-group">
                        <label for="occupation_id">Occupation</label>
                        <model-select
                          :options="occupations"
                          id="occupation_id"
                          v-model="employee.occupation_id"
                        />
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="form-group">
                        <label for="branch_id">Branch</label>
                        <model-select
                          :options="branches"
                          id="branch_id"
                          v-model="employee.branch_id"
                        ></model-select>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="form-group">
                        <label for="upline_id">Upline</label>
                        <input
                          class="form-control"
                          id="upline_id"
                          type="text"
                          v-model="employee.upline_id"
                        />
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="form-group">
                        <label>Hire Date</label>
                        <datepicker
                          :input-class="'form-control'"
                          id="hire_date"
                          placeholder="Pilih Tanggal lahir"
                          v-model="employee.hire_date"
                        />
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="form-group">
                        <label>Resign Date</label>
                        <datepicker
                          :input-class="'form-control'"
                          id="resign_date"
                          placeholder="Pilih Tanggal lahir"
                          v-model="employee.resign_date"
                        />
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="form-group">
                        <label for="email">Corporate Email</label>
                        <input
                          class="form-control"
                          id="email"
                          type="text"
                          v-model="employee.email"
                        />
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="form-group">
                        <label for="npwp">NPWP Number</label>
                        <input
                          class="form-control"
                          id="npwp"
                          type="text"
                          v-model="employee.npwp"
                        />
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="form-group">
                        <label for="bpjs_kesehatan">BPJS Kesehatan</label>
                        <input
                          class="form-control"
                          id="bpjs_kesehatan"
                          type="text"
                          v-model="employee.bpjs_kesehatan"
                        />
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="form-group">
                        <label for="bpjs_ketenagakerjaan"
                          >BPJS Ketenagakerjaan</label
                        >
                        <input
                          class="form-control"
                          id="bpjs_ketenagakerjaan"
                          type="text"
                          v-model="employee.bpjs_ketenagakerjaan"
                        />
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="form-group">
                        <label for="meal_allowance">Meal Allowance</label>
                        <input
                          class="form-control"
                          id="meal_allowance"
                          type="text"
                          v-model="employee.meal_allowance"
                        />
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="form-group">
                        <label for="alias_code">Alias Code</label>
                        <input
                          class="form-control"
                          id="alias_code"
                          type="text"
                          v-model="employee.alias_code"
                        />
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="form-group">
                        <label for="company_name">Company Name</label>
                        <input
                          class="form-control"
                          id="company_name"
                          type="text"
                          v-model="employee.company_name"
                        />
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="form-group">
                        <label for="company_field">Company Field</label>
                        <input
                          class="form-control"
                          id="company_field"
                          type="text"
                          v-model="employee.company_field"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="card">
                <div class="card-header bg-white">Signature And Intro</div>
                <div class="card-body">
                  <div class="row">
                    <div class="col-md-12">
                      <div class="form-group">
                        <label for="email_signature">Email Signature</label>
                        <textarea
                          class="form-control"
                          id="email_signature"
                        ></textarea>
                      </div>
                    </div>
                    <div class="col-md-12">
                      <div class="form-group">
                        <label for="email_intro">Email Intro</label>
                        <textarea
                          class="form-control"
                          id="email_intro"
                        ></textarea>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import BackofficeApi from '../apis/Backoffice'
import EmployeeApi from '../apis/Employee'
import DepartmentApi from '../apis/Department'
import OccupationApi from '../apis/Occupation'
import BranchApi from '../apis/Branch'
import EnumDetail from '../apis/EnumDetail'
import BankApi from '../apis/Bank'
import { ModelListSelect, ModelSelect } from 'vue-search-select'
import Switches from 'vue-switches'
import Datepicker from 'vuejs-datepicker'

export default {
  data() {
    return {
      employee: {
        id: null,
        firstname: null,
        lastname: null,
        dob: null,
        pob: null,
        employee_no: null,
        user_id: null,
        phone: null,
        image: null,
        hire_date: null,
        resign_date: null,
        meal_allowance: null,
        department_id: null,
        occupation_id: null,
        job_id: null,
        type_id: null,
        branch_id: null,
        employee_group_id: null,
        leave_type_id: null,
        bank_id: null,
        bank_account_no: null,
        bank_type_id: null,
        bank_account_name: null,
        bpjs_kesehatan: null,
        bpjs_ketenagakerjaan: null,
        npwp: null,
        created_date_user_id: null,
        created_by_user_id: null,
        updated_by_user_id: null,
        updated_date_user_id: null,
        created_date_client_id: null,
        created_by_client_id: null,
        updated_by_client_id: null,
        updated_date_client_id: null,
        created_via: null,
        gender_id: null,
        email: null,
        identity_type_id: null,
        identity_number: null,
        company_name: null,
        company_field: null,
        job_desc: null,
        bank_branch: null,
        mobile_number: null,
        email_signature: null,
        personal_email: null,
        alias_code: null,
        upline_id: null,
        biological_mother_name: null,
        email_intro: null,
        occupation: {
          id: null,
          name: null,
          parent_id: null,
          department_id: null
        },
        department: {
          id: null,
          name: null,
          code: null,
          manager_id: null,
          parent_id: null,
          is_sales: null
        },
        user: {
          id: null,
          username: null,
          password: null,
          active: null,
          last_login: null,
          last_login_ip: null,
          created_by_user_id: null,
          updated_by_user_id: null,
          created_date_user_id: null,
          updated_date_user_id: null,
          created_by_client_id: null,
          updated_by_client_id: null,
          created_date_client_id: null,
          updated_date_client_id: null,
          created_via: null
        },
        branch: {
          id: null,
          code: null,
          name: null,
          address: null,
          country_id: null,
          province_id: null,
          kabupaten_id: null,
          kecamatan_id: null,
          kelurahan_id: null,
          zip_code: null,
          phone: null,
          fax: null,
          representative_email: null,
          digit_account_partial: null,
          digit_account_regular: null,
          meta_group_query: null,
          active: null,
          group_meta_code: null,
          created_date: null,
          updated_date: null,
          group_demo_default: null,
          is_headquarter: null,
          is_partner: null
        },
        bank: {
          id: null,
          bank_name: null,
          bank_full_name: null,
          active: null,
          is_show_mtp: null,
          image_file_host: null,
          image_file_path: null,
          image_file_name: null,
          bank_url: null,
          created_date: null,
          created_by: null,
          updated_date: null,
          updated_by: null
        },
        upline: {
          id: null,
          firstname: null,
          lastname: null,
          dob: null,
          pob: null,
          employee_no: null,
          user_id: null,
          phone: null,
          image: null,
          hire_date: null,
          resign_date: null,
          meal_allowance: null,
          department_id: null,
          occupation_id: null,
          job_id: null,
          type_id: null,
          branch_id: null,
          employee_group_id: null,
          leave_type_id: null,
          bank_id: null,
          bank_account_no: null,
          bank_type_id: null,
          bank_account_name: null,
          bpjs_kesehatan: null,
          bpjs_ketenagakerjaan: null,
          npwp: null,
          created_date_user_id: null,
          created_by_user_id: null,
          updated_by_user_id: null,
          updated_date_user_id: null,
          created_date_client_id: null,
          created_by_client_id: null,
          updated_by_client_id: null,
          updated_date_client_id: null,
          created_via: null,
          gender_id: null,
          email: null,
          identity_type_id: null,
          identity_number: null,
          company_name: null,
          company_field: null,
          job_desc: null,
          bank_branch: null,
          mobile_number: null,
          email_signature: null,
          personal_email: null,
          alias_code: null,
          upline_id: null,
          biological_mother_name: null,
          email_intro: null
        }
      },
      branches: [],
      banks: [],
      occupations: [],
      departments: [],
      genders: [],
      identities: [],
      is_fc_notif: false,
      is_fc: false
    }
  },
  components: {
    Header: () =>
      import(
        /* webpackChunkName: "component-header" */
        /* webpackPrefetch: true */
        /* webpackMode: "eager" */
        '../components/Header'
      ),
    Sidebar: () =>
      import(
        /* webpackChunkName: "component-sidebar" */
        /* webpackPrefetch: true */

        /* webpackMode: "eager" */
        '../components/Sidebar'
      ),
    ModelSelect,
    ModelListSelect,
    Switches,
    Datepicker
  },
  methods: {
    employeeHired() {
      let payload = {
        nik: this.employee.employee_no,
        personal_email: this.employee.personal_email,
        bank_id: this.employee.bank_id,
        bank_account_no: this.employee.bank_account_no,
        bank_account_name: this.employee.bank_account_name,
        bank_branch: this.employee.bank_branch
      }
      console.log(payload)
      if (this.is_fc) {
        BackofficeApi.employeeHired(payload)
          .then(res => {
            console.log(res)
            this.init();
          })
          .catch(res => {
            console.log(res)
          })
      } else {
        console.log('bukan fc skiped')
      }
    },
    searchFC() {
      console.log(this.employee.personal_email)
      let personal_email = this.employee.personal_email
      let filter = /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/
      if (filter.test(personal_email)) {
        console.log('email')
        //document.getElementById("personal_email").disabled = true;
        this.clientSearchAjaxByEmailName({
          q: personal_email,
          functional: 'REFERRAL'
        })
      } else {
        console.log('bukan email')
        this.is_fc_notif = false
      }
    },
    clientSearchAjaxByEmailName(query) {
      BackofficeApi.clientSearchAjaxByEmailName(query)
        .then(res => {
          console.log(res)
          if (res.data.data.length > 0) {
            let personal_email = this.employee.personal_email
            let data = res.data.data
            data.forEach((v, i) => {
              console.log(v.email)
              console.log(personal_email)
              if (v.email == personal_email) {
                this.is_fc_notif = true
                this.is_fc = true
                console.log('ini reperal')
              }
            })
          } else {
            console.log('ini bukan reperal')
            this.is_fc = false
          }
        })
        .catch(err => {
          console.log(err)
        })
    },
    init(condition) {
      this.listDepartment({ limit: -1 })
      if (condition) {
        this.employeeView(condition.params.id)
      } else {
        this.employeeView(this.$route.params.id)
      }
      this.listGender()
      this.listIdType()
      this.listBank()
      this.listIdType()
      this.listBranch()
    },
    validateForm() {
      let payload = this.employee
      EmployeeApi.update(this.$route.params.id, payload)
        .then(res => {
          this.employeeHired()
          this.$swal('Hooray!', 'Profile Updated', 'success')
        })
        .catch(error => {
          let that = this
          this.$swal(
            'Ooops!',
            error.response.data.data[0].message,
            'error'
          ).then(() => {
            that.employeeView(that.$route.params.id)
          })
        })
    },
    employeeView(id) {
      EmployeeApi.view(id)
        .then(res => {
          this.employee = res.data.data
          this.employee.user.password = ''
          if (this.employee.department_id) {
            this.listOccupation({ department_id: this.employee.department_id })
          }
          this.searchFC()
        })
        .catch(error => {
          console.log(error.response.data.errors)
        })
    },
    listGender() {
      EnumDetail.findByRuleName('GENDER_TYPE')
        .then(result => {
          this.genders = result.data.data.map(x => {
            return {
              value: x.rule_value,
              text: x.rule_description
            }
          })
        })
        .catch(err => {})
    },
    listBranch() {
      BranchApi.list()
        .then(result => {
          this.branches = result.data.data.map(x => {
            return {
              value: x.id,
              text: x.name
            }
          })
        })
        .catch(err => {
          this.$swal(
            'Ooops!',
            'A network error occurred. ' + 'It is not possible for us to know.',
            'error'
          )
        })
    },
    listDepartment(params) {
      DepartmentApi.search(params)
        .then(result => {
          this.departments = result.data.data.map(x => {
            return {
              value: x.id,
              text: x.name
            }
          })
        })
        .catch(err => {
          this.$swal(
            'Ooops!',
            'A network error occurred. ' + 'It is not possible for us to know.',
            'error'
          )
        })
    },
    listIdType() {
      EnumDetail.findByRuleName('PROFILE_IDENTITY_TYPE')
        .then(result => {
          this.identities = result.data.data.map(x => {
            return {
              value: x.rule_value,
              text: x.rule_description
            }
          })
        })
        .catch(err => {})
    },
    listBank() {
      BankApi.list()
        .then(result => {
          this.banks = result.data.data.map(x => {
            return {
              value: x.id,
              text: x.bank_name + ' (' + x.bank_full_name + ')'
            }
          })
        })
        .catch(err => {
          this.$swal(
            'Ooops!',
            'A network error occurred. ' + 'It is not possible for us to know.',
            'error'
          )
        })
    },
    listOccupation(params) {
      OccupationApi.search(params)
        .then(result => {
          this.occupations = result.data.data.map(x => {
            return {
              value: x.id,
              text: x.name
            }
          })
        })
        .catch(err => {
          this.$swal(
            'Ooops!',
            'A network error occurred. ' + 'It is not possible for us to know.',
            'error'
          )
        })
    }
  },
  mounted() {
    document.title = 'Employee Dashboard'
    this.init()
  },
  beforeRouteUpdate(to, from, next) {
    this.loading = true
    this.init(to)
    next()
  }
}
</script>

import axios from '../services/logs'

const resource = '/logs'
export default {
	LoginLogoutLogs(payload) {
		let token = JSON.parse(localStorage.getItem('hr_token')).access_token
		return axios.post(`${ resource }/login-logout-logs`, payload, {
			headers: {
				'x-access-token': `${ token }`
			}
		})
	}
}

const wp = [{
    path: '/wakil-pialang',
    name: 'wakil-pialang',
    component: () =>
        import (
            /* webpackChunkName: "page-wakil-pialang-list" */
            /* webpackPrefetch: true */
            /* webpackMode: "eager" */
            '../views/WakilPialangList.vue'
        ),
    meta: {
        requiresAuth: true,
        permission: 'GET:employee.default.wakil-pialang'
    }
}]
export default wp
import axios from '../services/backoffice'

const resource = '/backoffice'
export default {
  SalesCreate(payload) {
    let token = JSON.parse(localStorage.getItem('hr_token')).access_token
    return axios.post(`${resource}/sales-create`, payload, {
      headers: {
        'x-access-token': `${token}`
      }
    })
  },
  MyRequest(user_id) {
    let token = JSON.parse(localStorage.getItem('hr_token')).access_token
    return axios.get(`${resource}/task/my-request?user_id=${user_id}`, {
      headers: {
        'x-access-token': `${token}`
      }
    })
  },
  MyTask(user_id) {
    let token = JSON.parse(localStorage.getItem('hr_token')).access_token
    return axios.get(`${resource}/task/my-task?user_id=${user_id}`, {
      headers: {
        'x-access-token': `${token}`
      }
    })
  },
  MyApproval(user_id) {
    let token = JSON.parse(localStorage.getItem('hr_token')).access_token
    return axios.get(`${resource}/task/my-approval?user_id=${user_id}`, {
      headers: {
        'x-access-token': `${token}`
      }
    })
  },
  TaskView(id) {
    let token = JSON.parse(localStorage.getItem('hr_token')).access_token
    return axios.get(`${resource}/task/task-view?id=${id}`, {
      headers: {
        'x-access-token': `${token}`
      }
    })
  },
  TaskViewApproval(id) {
    let token = JSON.parse(localStorage.getItem('hr_token')).access_token
    return axios.get(`${resource}/task/task-view-approval?task_id=${id}`, {
      headers: {
        'x-access-token': `${token}`
      }
    })
  },
  TaskViewAttachment(id) {
    let token = JSON.parse(localStorage.getItem('hr_token')).access_token
    return axios.get(`${resource}/task/task-view-attachment?task_id=${id}`, {
      headers: {
        'x-access-token': `${token}`
      }
    })
  },
  TaskApprovalView(id) {
    let token = JSON.parse(localStorage.getItem('hr_token')).access_token
    return axios.get(`${resource}/task/task-approval-view?id=${id}`, {
      headers: {
        'x-access-token': `${token}`
      }
    })
  },
  TaskApprovalHistory(id) {
    let token = JSON.parse(localStorage.getItem('hr_token')).access_token
    return axios.get(`${resource}/task/task-approval-history?task_id=${id}`, {
      headers: {
        'x-access-token': `${token}`
      }
    })
  },
  commissionEarningsReportByUser(start_date, end_date, user_type, page = 1) {
    let token = JSON.parse(localStorage.getItem('hr_token')).access_token
    return axios.get(
      `${resource}/commission/commission-earnings-report-by-user?start_date=${start_date}&end_date=${end_date}&user_type=${user_type}&page=${page}`,
      {
        headers: {
          'x-access-token': `${token}`
        }
      }
    )
  },
  commissionEarningsReportByUserDetail(
    start_date,
    end_date,
    user_type,
    user_id,
    page = 1
  ) {
    let token = JSON.parse(localStorage.getItem('hr_token')).access_token
    return axios.get(
      `${resource}/commission/commission-earnings-report-by-user-detail?start_date=${start_date}&end_date=${end_date}&user_type=${user_type}&user_id=${user_id}&page=${page}`,
      {
        headers: {
          'x-access-token': `${token}`
        }
      }
    )
  },
  employeeResign(payload) {
    let token = JSON.parse(localStorage.getItem('hr_token')).access_token
    return axios.post(`${resource}/support/employee-resign`, payload, {
      headers: {
        'x-access-token': `${token}`
      }
    })
  },
  referralList(query) {
    let token = JSON.parse(localStorage.getItem('hr_token')).access_token
    const params = new URLSearchParams(query)
    return axios.get(`${resource}/referral/referral-list?${params}`, {
      headers: {
        'x-access-token': `${token}`
      }
    })
  },
  referralListNewAgreement(query) {
    let token = JSON.parse(localStorage.getItem('hr_token')).access_token
    const params = new URLSearchParams(query)
    return axios.get(
      `${resource}/referral/referral-list-new-agreement?${params}`,
      {
        headers: {
          'x-access-token': `${token}`
        }
      }
    )
  },
  clientSearchAjaxByEmailName(query) {
    let token = JSON.parse(localStorage.getItem('hr_token')).access_token
    const params = new URLSearchParams(query)
    return axios.get(
      `${resource}/general/client-search-ajax-by-email-name?${params}`,
      {
        headers: {
          'x-access-token': `${token}`
        }
      }
    )
  },
  employeeHired(payload) {
    let token = JSON.parse(localStorage.getItem('hr_token')).access_token
    return axios.post(`${resource}/support/employee-hired`, payload, {
      headers: {
        'x-access-token': `${token}`
      }
    })
  },
  leagueTableListByOccupation(query) {
    let token = JSON.parse(localStorage.getItem('hr_token')).access_token
    const params = new URLSearchParams(query)
    return axios.get(
      `${resource}/report/league-table-list-by-occupation?${params}`,
      {
        headers: {
          'x-access-token': `${token}`
        }
      }
    )
  },
  clientSearchAjaxByEmailName(query) {
    let token = JSON.parse(localStorage.getItem('hr_token')).access_token
    const params = new URLSearchParams(query)
    return axios.get(
      `${resource}/general/client-search-ajax-by-email-name?${params}`,
      {
        headers: {
          'x-access-token': `${token}`
        }
      }
    )
  },
  employeeHired(payload) {
    let token = JSON.parse(localStorage.getItem('hr_token')).access_token
    return axios.post(`${resource}/support/employee-hired`, payload, {
      headers: {
        'x-access-token': `${token}`
      }
    })
  },
  myBudgetRequest(query) {
    let token = JSON.parse(localStorage.getItem('hr_token')).access_token
    const params = new URLSearchParams(query)
    return axios.get(`${resource}/budget/my-budget-request?${params}`, {
      headers: {
        'x-access-token': `${token}`
      }
    })
  },
  myApproval(query) {
    let token = JSON.parse(localStorage.getItem('hr_token')).access_token
    const params = new URLSearchParams(query)
    return axios.get(`${resource}/budget/my-approval?${params}`, {
      headers: {
        'x-access-token': `${token}`
      }
    })
  },
  allBudgetRequest(query) {
    let token = JSON.parse(localStorage.getItem('hr_token')).access_token
    const params = new URLSearchParams(query)
    return axios.get(`${resource}/budget/all-budget-request?${params}`, {
      headers: {
        'x-access-token': `${token}`
      }
    })
  },
  searchEmployeeAjax(fullname, functional) {
    let url = '/general/employee-search-ajax-by-name'
    let token = JSON.parse(localStorage.getItem('hr_token')).access_token
    return axios.get(
      `${resource}${url}?fullname=${fullname}&functional=${functional}`,
      {
        headers: {
          'x-access-token': `${token}`
        }
      }
    )
  },
  budgetCreate(payload) {
    let token = JSON.parse(localStorage.getItem('hr_token')).access_token
    return axios.post(`${resource}/budget/budget-create`, payload, {
      headers: {
        'x-access-token': `${token}`,
        'Content-Type': 'multipart/form-data',
        Accept: '*/*'
      }
    })
  },
  budgetUpdate(id, payload) {
    let token = JSON.parse(localStorage.getItem('hr_token')).access_token
    return axios.post(`${resource}/budget/budget-update?id=${id}`, payload, {
      headers: {
        'x-access-token': `${token}`,
        'Content-Type': 'multipart/form-data',
        Accept: '*/*'
      }
    })
  },
  budgetView(query) {
    let token = JSON.parse(localStorage.getItem('hr_token')).access_token
    const params = new URLSearchParams(query)
    return axios.get(`${resource}/budget/budget-view?${params}`, {
      headers: {
        'x-access-token': `${token}`
      }
    })
  },
  budgetRequestApprovalHistory(query) {
    let token = JSON.parse(localStorage.getItem('hr_token')).access_token
    const params = new URLSearchParams(query)
    return axios.get(
      `${resource}/budget/budget-request-approval-history?${params}`,
      {
        headers: {
          'x-access-token': `${token}`
        }
      }
    )
  },
  budgetApprovalView(query) {
    let token = JSON.parse(localStorage.getItem('hr_token')).access_token
    const params = new URLSearchParams(query)
    return axios.get(`${resource}/budget/budget-approval-view?${params}`, {
      headers: {
        'x-access-token': `${token}`
      }
    })
  },
  budgetViewApproval(query) {
    let token = JSON.parse(localStorage.getItem('hr_token')).access_token
    const params = new URLSearchParams(query)
    return axios.get(`${resource}/budget/budget-view-approval?${params}`, {
      headers: {
        'x-access-token': `${token}`
      }
    })
  },
  budgetRequestApprove(payload) {
    let token = JSON.parse(localStorage.getItem('hr_token')).access_token
    return axios.post(`${resource}/budget/budget-request-approve`, payload, {
      headers: {
        'x-access-token': `${token}`
      }
    })
  },
  budgetRequestReject(payload) {
    let token = JSON.parse(localStorage.getItem('hr_token')).access_token
    return axios.post(`${resource}/budget/budget-request-reject`, payload, {
      headers: {
        'x-access-token': `${token}`
      }
    })
  },
  referralListWithNikHistory(query) {
    let token = JSON.parse(localStorage.getItem('hr_token')).access_token
    const params = new URLSearchParams(query)
    return axios.get(`${resource}/referral/referral-list-with-nik-history?${params}`, {
      headers: {
        'x-access-token': `${token}`
      }
    })
  },
  employeeChangeUplineAttributes(query) {
    let token = JSON.parse(localStorage.getItem('hr_token')).access_token
    const params = new URLSearchParams(query)
    return axios.get(`${resource}/support/employee-change-upline-attributes?${params}`, {
      headers: {
        'x-access-token': `${token}`
      }
    })
  },
  employeeChangeUpline(payload) {
    let token = JSON.parse(localStorage.getItem('hr_token')).access_token
    return axios.post(`${resource}/support/employee-change-upline`, payload, {
      headers: {
        'x-access-token': `${token}`
      }
    })
  },
}

import axios from '../services/backoffice'
import report from '../services/report'
const resource = '/backoffice'
export default {
    commissionOnHoldSetAsPaid(payload) {
        let token = JSON.parse(localStorage.getItem('hr_token')).access_token
        return axios.post(`${ resource }/commission/commission-on-hold-set-as-paid`, payload, {
            headers: {
                'x-access-token': `${ token }`
            }
        })
    },
    reportCommissionHrdByDateRange(payload) {
        let token = JSON.parse(localStorage.getItem('hr_token')).access_token
        return report.post(`/report/commission/report-commission-hrd-by-date-range`, payload, {
            headers: {
                'x-access-token': `${ token }`
            }
        })
    }
}

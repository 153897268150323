export default {
    id: null,
    firstname: null,
    lastname: null,
    dob: null,
    pob: null,
    employee_no: null,
    user_id: null,
    phone: null,
    image: null,
    hire_date: null,
    resign_date: null,
    meal_allowance: null,
    department_id: null,
    occupation_id: null,
    job_id: null,
    type_id: null,
    branch_id: null,
    employee_group_id: null,
    leave_type_id: null,
    bank_id: null,
    bank_account_no: null,
    bank_type_id: null,
    bank_account_name: null,
    bpjs_kesehatan: null,
    bpjs_ketenagakerjaan: null,
    npwp: null,
    created_date_user_id: null,
    created_by_user_id: null,
    updated_by_user_id: null,
    updated_date_user_id: null,
    created_date_client_id: null,
    created_by_client_id: null,
    updated_by_client_id: null,
    updated_date_client_id: null,
    created_via: null,
    gender_id: null,
    email: null,
    identity_type_id: null,
    identity_number: null,
    company_name: null,
    company_field: null,
    job_desc: null,
    bank_branch: null,
    mobile_number: null,
    email_signature: null,
    personal_email: null,
    alias_code: null,
    upline_id: null,
    biological_mother_name: null,
    user: {
        id: null,
        username: null,
        password: null,
        active: null,
        last_login: null,
        last_login_ip: null,
        created_by_user_id: null,
        updated_by_user_id: null,
        created_date_user_id: null,
        updated_date_user_id: null,
        created_by_client_id: null,
        updated_by_client_id: null,
        created_date_client_id: null,
        updated_date_client_id: null,
        created_via: null
    },
    homeAddress: null,
    photo: {
        id: null,
        employee_id: null,
        attachment_type_id: null,
        file_host: null,
        file_path: null,
        file_name: null,
        file_mime: null,
        file_size: null,
        file_extension: null,
        created_date_user_id: null,
        created_by_user_id: null,
        updated_by_user_id: null,
        updated_date_user_id: null,
        created_date_client_id: null,
        created_by_client_id: null,
        updated_by_client_id: null,
        updated_date_client_id: null,
        created_via: null
    },
    department: {
        id: null,
        name: null,
        code: null,
        manager_id: null,
        parent_id: null,
        created_date_user_id: null,
        created_by_user_id: null,
        updated_by_user_id: null,
        updated_date_user_id: null,
        created_date_client_id: null,
        created_by_client_id: null,
        updated_by_client_id: null,
        updated_date_client_id: null,
        is_sales: null
    },
    occupation: {
        id: null,
        name: null,
        parent_id: null,
        department_id: null,
        created_date_user_id: null,
        created_by_user_id: null,
        updated_by_user_id: null,
        updated_date_user_id: null,
        created_date_client_id: null,
        created_by_client_id: null,
        updated_by_client_id: null,
        updated_date_client_id: null
    }
}

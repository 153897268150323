<template>
  <div>
    <Header>
      <template v-slot:nav-item-left>
        <li class="nav-item">
          <router-link class="navbar-nav-link" to="/"
            >Agreement Referral (Commission)</router-link
          >
        </li>
      </template>
    </Header>
    <div class="page-content">
      <Sidebar />
      <div class="content-wrapper">
        <div class="content">
          <div class="row mb-3">
            <div class="col-md-12">
              <div class="card">
                <div class="card-body">
                  <div class="row">
                    <div class="col-md-2">
                      <div class="form-group">
                        <label for="referral_name">Referral Name</label>
                        <input
                          type="text"
                          class="form-control"
                          id="referral_name"
                          v-model="
                            referralFilter.search.filter_referral_first_name
                          "
                        />
                      </div>
                    </div>
                    <div class="col-md-2">
                      <div class="form-group">
                        <label for="referral_email">Referral Email</label>
                        <input
                          type="text"
                          class="form-control"
                          id="referral_email"
                          v-model="referralFilter.search.filter_referral_email"
                        />
                      </div>
                    </div>
                    <div class="col-md-2">
                      <div class="form-group">
                        <label for="filter_new_agreement_status"
                          >Agreement Status</label
                        >
                        <select
                          name="filter_new_agreement_status"
                          id="filter_new_agreement_status"
                          class="form-control"
                          v-model="
                            referralFilter.search.filter_new_agreement_status
                          "
                        >
                          <option value="all">All</option>
                          <option value="agreed">Agreed</option>
                          <option value="unagree">Waiting</option>
                        </select>
                      </div>
                    </div>
                    <div class="col-md-2">
                      <div class="form-group">
                        <label for="filter_referral_status"
                          >Referral Status</label
                        >
                        <select
                          name="filter_referral_status"
                          id="filter_referral_status"
                          class="form-control"
                          v-model="
                            referralFilter.search.filter_referral_status
                          "
                        >
                          <option value="all">All</option>
                          <option value="1">Active</option>
                          <option value="0">Non Active</option>
                        </select>
                      </div>
                    </div>
                    <div class="col-md-2">
                      <div class="form-group">
                        <label for="filter_commission_status"
                          >Commission Status</label
                        >
                        <select
                          name="filter_commission_status"
                          id="filter_commission_status"
                          class="form-control"
                          v-model="
                            referralFilter.search.filter_commission_status
                          "
                        >
                          <option value="all">All</option>
                          <option value="paid">Paid</option>
                          <option value="unpaid">Unpaid</option>
                        </select>
                      </div>
                    </div>
                    <div class="col-md-2">
                      <div class="form-group">
                        <label for="branch_name">Branch</label>
                        <model-select
                          :options="branches"
                          id="branch_name"
                          placeholder="select item"
                          v-model="referralFilter.search.filter_branch_name"
                        ></model-select>
                      </div>
                    </div>
                    <div class="col-md-3">
                      <div class="form-group">
                        <label for="filter_agreement_submit_date_from"
                          >Agreed Date From</label
                        >

                        <datepicker
                          :input-class="'form-control'"
                          id="filter_agreement_submit_date_from"
                          v-model="
                            referralFilter.filter_agreement_submit_date_from
                          "
                          :format="ymd"
                        />
                      </div>
                    </div>
                    <div class="col-md-3">
                      <div class="form-group">
                        <label for="filter_agreement_submit_date_to">To</label>

                        <datepicker
                          :input-class="'form-control'"
                          id="filter_agreement_submit_date_to"
                          v-model="
                            referralFilter.filter_agreement_submit_date_to
                          "
                          :format="ymd"
                        />
                      </div>
                    </div>
                    <div class="col-md-3">
                      <div class="form-group">
                        <label for="filter_unpaid_commission_date_from"
                          >Unpaid commission Date From</label
                        >

                        <datepicker
                          :input-class="'form-control'"
                          id="filter_unpaid_commission_date_from"
                          v-model="
                            referralFilter.filter_unpaid_commission_date_from
                          "
                          :format="ym"
                          :minimumView="'month'"
                          :maximumView="'month'"
                        />
                      </div>
                    </div>
                    <div class="col-md-3">
                      <div class="form-group">
                        <label for="filter_unpaid_commission_date_to">To</label>

                        <datepicker
                          :input-class="'form-control'"
                          id="filter_unpaid_commission_date_to"
                          v-model="
                            referralFilter.filter_unpaid_commission_date_to
                          "
                          :format="ym"
                          :minimumView="'month'"
                          :maximumView="'month'"
                        />
                      </div>
                    </div>
                  </div>
                  <div class="row justify-content-end">
                    <div class="col-md-2 mb-1">
                      <button
                        @click="downloadCsv"
                        class="btn bg-info-600 btn-block rounded-round btn-labeled btn-labeled-left"
                        type="button"
                      >
                        <b>
                          <i class="fa fa-file"></i>
                        </b>
                        Export CSV
                      </button>
                    </div>
                    <div class="col-md-2">
                      <button
                        @click="resetFilter"
                        class="btn bg-warning-600 btn-block rounded-round btn-labeled btn-labeled-left"
                        type="button"
                      >
                        <b>
                          <i class="icon-reset"></i>
                        </b>
                        Reset
                      </button>
                    </div>
                    <div class="col-md-2">
                      <button
                        @click="submitFilter"
                        class="btn bg-green-700 btn-block rounded-round btn-labeled btn-labeled-left"
                        type="button"
                      >
                        <b>
                          <i class="icon-check"></i>
                        </b>
                        Apply
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row mb-3">
            <div class="col-md-6 mb-1">
              Displaying
              <span class="font-weight-bold text-green-700">{{
                pagination.displaying
              }}</span>
              of
              <span class="font-weight-bold text-green-700">{{
                pagination.totalData
              }}</span>
              Result
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <div class="card">
                <div class="card-header">
                  Referral List
                </div>
                <div class="table-responsive">
                  <table class="table table-bordered table-striped table-hover">
                    <thead>
                      <tr>
                        <th>
                          <a
                            href="#nogo"
                            @click="sortOrder('nik')"
                          >
                            NIK
                          </a>
                        </th>
                        <th>
                          <a
                            href="#nogo"
                            @click="sortOrder('referral_first_name')"
                          >
                            Referral Name
                          </a>
                        </th>
                        <th>
                          <a href="#nogo" @click="sortOrder('referral_email')">
                            Referral Email
                          </a>
                        </th>
                        <th>
                          <a
                            href="#nogo"
                            @click="sortOrder('sales_first_name')"
                          >
                            Sales Name
                          </a>
                        </th>
                        <th>
                          <a href="#nogo" @click="sortOrder('branch_name')">
                            Branch
                          </a>
                        </th>
                        <th>New Agreement Status</th>
                        <th>
                          <a
                            href="#nogo"
                            @click="sortOrder('agreement_submit_date')"
                          >
                            Agreed Date
                          </a>
                        </th>
                        <th>
                          <a
                            href="#nogo"
                            @click="sortOrder('total_unpaid_commission')"
                          >
                            Total Unpaid Commission
                          </a>
                        </th>
                        <th>Commission Status</th>
                        <th>Referral Status</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      <template v-if="loading">
                        <tr :key="n" v-for="n in referralFilter.limit">
                          <td :key="m" v-for="m in 10">
                            <content-placeholders>
                              <content-placeholders-heading :img="true" />
                            </content-placeholders>
                          </td>
                        </tr>
                      </template>
                      <tr
                        v-for="referral in referralLists"
                        :key="referral.referral_id"
                        v-else
                      >
                        <td>{{ referral.nik }}</td>
                        <td>{{ referral.referral_first_name }}</td>
                        <td>{{ referral.referral_email }}</td>
                        <td>{{ referral.sales_first_name }}</td>
                        <td>{{ referral.branch_name }}</td>
                        <td>
                          <template
                            v-if="referral.new_agreement_status == 'Agreed'"
                          >
                            <span class="badge badge-success">Agreed</span>
                          </template>
                          <template v-else>
                            <span class="badge badge-warning">Waiting</span>
                          </template>
                        </td>
                        <td>{{ referral.agreement_submit_date }}</td>
                        <td>Rp. {{ referral.total_unpaid_commission | formatNum }}</td>
                        <td>
                          <template v-if="referral.total_unpaid_commission > 0">
                            <span class="text-danger">Unpaid</span>
                          </template>
                          <template v-else>
                            <span class="text-success">Paid</span>
                          </template>
                        </td>
                        <td>
                          <template v-if="referral.active">
                            <i class="fa fa-check-circle text-success"></i>
                          </template>
                          <template v-else>
                            <i class="fa fa-times-circle text-danger"></i>
                          </template>
                        </td>
                        <td>
                          <div class="list-icons">
                            <div class="list-icons-item dropdown">
                              <a
                                class="list-icons-item dropdown-toggle caret-0"
                                data-toggle="dropdown"
                                href="javascript:void(0)"
                              >
                                <i
                                  class="fas fa-ellipsis-v text-green-700 fa-2x"
                                ></i>
                              </a>
                              <div class="dropdown-menu dropdown-menu-right">
                                <template
                                  v-if="referral.total_unpaid_commission > 0"
                                >
                                  <a
                                    class="dropdown-item text-success"
                                    href="javascript:void(0)"
                                    @click="setAsPaid(referral)"
                                  >
                                    <i class="fa fa-check"></i>
                                    Set as Paid
                                  </a>
                                </template>
                              </div>
                            </div>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>

                <paginate
                  :click-handler="clickCallback"
                  :container-class="
                    'pagination pagination-separated pagination-rounded align-self-center mt-3 mb-3'
                  "
                  :margin-pages="5"
                  :next-class="'page-item'"
                  :next-link-class="'page-link'"
                  :next-text="'Next'"
                  :page-class="'page-item'"
                  :page-count="pagination.totalPage"
                  :page-link-class="'page-link'"
                  :page-range="5"
                  :prev-class="'page-item'"
                  :prev-link-class="'page-link'"
                  :prev-text="'Prev'"
                  v-if="!$root.mobile"
                ></paginate>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <b-modal
      :hide-footer="true"
      :no-close-on-backdrop="true"
      :no-close-on-esc="true"
      header-class="bg-white"
      id="modalSetAsPaid"
      ref="modalSetAsPaid"
      title="Update Sales Data"
    >
      <form @submit.prevent="submitSetAsPaid">
        <div class="row">
          <div class="col-md-12">
            <div class="form-group">
              <label for="">Paid Date</label>
              <datepicker
                :input-class="'form-control'"
                id="paid_date"
                placeholder="Pilih tanggal bayar"
                v-model="model.paid_date"
              />
            </div>
          </div>
        </div>
        <button class="btn bg-green-700 btn-block mt-3" type="submit">
          Submit
        </button>
      </form>
    </b-modal>
  </div>
</template>
<script>
import Paginate from '../components/Paginate'
import referralListsApi from '../apis/Backoffice'
import Datepicker from 'vuejs-datepicker'
import moment from 'moment'
import CommissionApi from '../apis/Commission'
import BranchApi from '../apis/Branch'
import { ModelSelect } from 'vue-search-select'
export default {
  data() {
    return {
      json_data: [],
      branches: [],
      loading: true,
      referralLists: [],
      pagination: {
        totalPage: 0,
        currentPage: 1,
        totalData: 0,
        displaying: 0
      },
      referralFilter: {
        limit: 10,
        order: 'referral_email asc',
        search: {
          filter_referral_first_name: null,
          filter_referral_email: null,
          filter_new_agreement_status: 'all',
          filter_commission_status: 'all',
          filter_branch_name: 'all',
          filter_referral_status: 'all'
        },
        filter_agreement_submit_date_from: null,
        filter_agreement_submit_date_to: null,
        filter_unpaid_commission_date_from: null,
        filter_unpaid_commission_date_to: null
      },
      model: {
        user_id: null,
        user_type: 'client',
        paid_date: ''
      }
    }
  },
  components: {
    Header: () =>
      import(
        /* webpackChunkName: "component-header" */
        /* webpackPrefetch: true */
        /* webpackMode: "eager" */
        '../components/Header'
      ),
    DownloadExcel: () =>
      import(
        /* webpackChunkName: "component-download-excel" */
        /* webpackPrefetch: true */
        /* webpackMode: "eager" */
        '../components/JsonExcel'
      ),
    Sidebar: () =>
      import(
        /* webpackChunkName: "component-sidebar" */
        /* webpackPrefetch: true */

        /* webpackMode: "eager" */
        '../components/Sidebar'
      ),
    Paginate,
    Datepicker,
    ModelSelect
  },
  methods: {
    downloadCsv() {
      this.loading = true
      let search = this.setSearchFields()
      search = search + 'limit=-1'
      referralListsApi
        .referralListNewAgreement(search)
        .then(res => {
          let rows = res.data.data
          let filename = 'Agreement Referral (Commission).csv'
          let csvFile =
            'No;NIK;Referral Name;Referral Email;Sales Name;Branch;New Agreement Status;Agreed Date;Total Unpaid Commission;Commission Status;Referral Status' +
            '\n'
          for (let i = 0; i < rows.length; i++) {
            let row = rows[i]
            console.log(row)
            let nik = row.nik
            let referral_first_name = row.referral_first_name
            let referral_email = row.referral_email
            let sales_first_name = row.sales_first_name
            let branch_name = row.branch_name
            let new_agreement_status =
              row.new_agreement_status == 'Agreed'
                ? row.new_agreement_status
                : 'Waiting'
            let referral_status = row.active == '1' ? 'Active' : 'Non Active'
            let agreement_submit_date = row.agreement_submit_date
            let total_unpaid_commission = row.total_unpaid_commission
            let commission_status =
              row.total_unpaid_commission > 0 ? 'Unpaid' : 'Paid'
            let index = parseInt(i) + 1
            csvFile +=
              index +
              ';' +
              nik +
              ';' +
              referral_first_name +
              ';' +
              referral_email +
              ';' +
              sales_first_name +
              ';' +
              branch_name +
              ';' +
              new_agreement_status +
              ';' +
              agreement_submit_date +
              ';' +
              total_unpaid_commission +
              ';' +
              commission_status +
              ';' +
              referral_status +
              '\n'
          }
          let blob = new Blob([csvFile], { type: 'text/csv;charset=utf-8;' })
          if (navigator.msSaveBlob) {
            // IE 10+
            navigator.msSaveBlob(blob, filename)
          } else {
            let link = document.createElement('a')
            if (link.download !== undefined) {
              // feature detection
              // Browsers that support HTML5 download attribute
              let url = URL.createObjectURL(blob)
              link.setAttribute('href', url)
              link.setAttribute('download', filename)
              link.style.visibility = 'hidden'
              document.body.appendChild(link)
              link.click()
              document.body.removeChild(link)
            }
          }
          this.loading = false
        })
        .catch(err => {
          console.log(err)
        })
    },
    listBranch() {
      BranchApi.list()
        .then(result => {
          this.branches = result.data.data.map(x => {
            return {
              value: x.name,
              text: x.name
            }
          })
        })
        .catch(err => {
          console.log(err)
          this.$swal(
            'Ooops!',
            'A network error occurred. ' +
              'It is not possible for us to know. [CE-BRANCH-002]',
            'error'
          )
        })
    },
    resetFilter() {
      this.loading = true
      this.referralFilter.search.filter_referral_first_name = null
      this.referralFilter.search.filter_referral_email = null
      this.referralFilter.search.filter_new_agreement_status = 'all'
      this.referralFilter.search.filter_commission_status = 'all'
      this.referralFilter.search.filter_branch_name = 'all'
      this.referralFilter.search.filter_referral_status = 'all'
      this.referralFilter.filter_agreement_submit_date_from = null
      this.referralFilter.filter_agreement_submit_date_to = null
      this.referralFilter.filter_unpaid_commission_date_from = null
      this.referralFilter.filter_unpaid_commission_date_to = null
      let search = this.setSearchFields()
      search = search + 'page=1'
      this.referralListSearch(search)
    },
    submitFilter() {
      this.loading = true
      let search = this.setSearchFields()
      search = search + 'page=1'
      this.referralListSearch(search)
    },
    referralListSearch(query) {
      referralListsApi
        .referralListNewAgreement(query)
        .then(res => {
          this.referralLists = res.data.data
          this.pagination.totalData = parseInt(res.data.totalCount)
          this.pagination.currentPage = parseInt(res.data.page)
          this.pagination.totalPage = Math.ceil(
            this.pagination.totalData / parseInt(this.referralFilter.limit)
          )
          let display = this.referralFilter.limit * this.pagination.currentPage
          if (display >= this.pagination.totalData) {
            this.pagination.displaying = this.pagination.totalData
          } else {
            this.pagination.displaying = display
          }
          this.loading = false
        })
        .catch(err => {
          console.log(err)
        })
    },
    clickCallback(e) {
      this.loading = true
      this.referralFilter.page = e
      let search = this.setSearchFields()
      search = search + 'page=' + e
      this.referralListSearch(search)
    },
    sortOrder(e) {
      let sort = this.referralFilter.order
      let string = sort.split(' ')
      let field = string[0]
      let dir = string[1]
      if (dir == 'asc') {
        dir = 'desc'
      } else {
        dir = 'asc'
      }
      this.referralFilter.order = e + ' ' + dir
      this.loading = true
      this.referralFilter.page = 1
      let search = this.setSearchFields()
      search = search + 'page=1&order=' + this.referralFilter.order
      this.referralListSearch(search)
    },
    setAsPaid(referral) {
      console.log(referral)
      this.model.user_id = referral.referral_id
      this.$refs['modalSetAsPaid'].show()
    },
    submitSetAsPaid() {
      this.$refs['modalSetAsPaid'].hide()
      this.loading = true
      this.referralFilter.page = 1
      this.model.paid_date = this.dateToDatabaseFormat(this.model.paid_date)
      console.log(this.model)
      let search = this.setSearchFields()
      search = search + 'page=1'
      CommissionApi.commissionOnHoldSetAsPaid(this.model)
        .then(res => {
          this.$swal('Success', 'Data berhasil di update', 'success').then(
            data => {
              this.referralListSearch(search)
            }
          )
        })
        .catch(err => {
          this.referralListSearch(search)
        })
    },
    dateToDatabaseFormat(value) {
      return moment(String(value)).format('YYYY-MM-DD')
    },

    ymd(date) {
      return moment(date).format('YYYY-MM-DD')
    },
    ym(date) {
      return moment(date).format('YYYY-MM')
    },
    setSearchFields() {
      let filter = this.referralFilter
      let search = ''

      if (filter.search.filter_referral_first_name) {
        let referral_name = filter.search.filter_referral_first_name
        search =
          search +
          'search[filter_referral_first_name]=' +
          referral_name.trim() +
          '&'
      }

      if (filter.search.filter_referral_email) {
        let referral_email = filter.search.filter_referral_email
        search =
          search +
          'search[filter_referral_email]=' +
          referral_email.trim() +
          '&'
      }
      if (filter.search.filter_sales_first_name) {
        let filter_sales_first_name = filter.search.filter_sales_first_name
        search =
          search +
          'search[filter_sales_first_name]=' +
          filter_sales_first_name.trim() +
          '&'
      }

      if (
        filter.search.filter_new_agreement_status &&
        filter.search.filter_new_agreement_status != 'all'
      ) {
        let filter_new_agreement_status =
          filter.search.filter_new_agreement_status
        search =
          search +
          'search[filter_new_agreement_status]=' +
          filter_new_agreement_status.trim() +
          '&'
      }



      if (
        filter.search.filter_referral_status &&
        filter.search.filter_referral_status != 'all'
      ) {
        let filter_referral_status =
          filter.search.filter_referral_status
        search =
          search +
          'search[filter_active]=' +
          filter_referral_status.trim() +
          '&'
      }

      if (
        filter.search.filter_branch_name &&
        filter.search.filter_branch_name != 'all'
      ) {
        let filter_branch_name = filter.search.filter_branch_name
        search =
          search +
          'search[filter_branch_name]=' +
          filter_branch_name.trim() +
          '&'
      }

      if (
        filter.search.filter_commission_status &&
        filter.search.filter_commission_status != 'all'
      ) {
        let filter_commission_status = filter.search.filter_commission_status
        search =
          search +
          'search[filter_commission_status]=' +
          filter_commission_status.trim() +
          '&'
      }

      if (filter.filter_agreement_submit_date_to) {
        let filter_agreement_submit_date_to =
          filter.filter_agreement_submit_date_to
        search =
          search +
          'filter_agreement_submit_date_to=' +
          this.dateToDatabaseFormat(filter_agreement_submit_date_to) +
          '&'
      }

      if (filter.filter_agreement_submit_date_from) {
        let filter_agreement_submit_date_from =
          filter.filter_agreement_submit_date_from
        search =
          search +
          'filter_agreement_submit_date_from=' +
          this.dateToDatabaseFormat(filter_agreement_submit_date_from) +
          '&'
      }

      if (filter.filter_unpaid_commission_date_from) {
        let filter_unpaid_commission_date_from =
          filter.filter_unpaid_commission_date_from
        search =
          search +
          'filter_unpaid_commission_date_from=' +
          this.dateToDatabaseFormat(filter_unpaid_commission_date_from) +
          '&'
      }

      if (filter.filter_unpaid_commission_date_to) {
        let filter_unpaid_commission_date_to =
          filter.filter_unpaid_commission_date_to
        search =
          search +
          'filter_unpaid_commission_date_to=' +
          this.dateToDatabaseFormat(filter_unpaid_commission_date_to) +
          '&'
      }

      console.log(search)
      return search
    }
  },
  mounted() {
    document.title = 'Agreement Referral (Commission)'
    let search = this.setSearchFields()
    search = search + 'page=1'
    this.referralListSearch(search)
    this.listBranch()
  },
        filters: {
            formatNum(value) {
                return new Intl.NumberFormat('id-ID').format(value)
            }
        }
}
</script>

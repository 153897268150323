<template>
    <div>
        <Header>
            <template v-slot:nav-item-left>
                <li class="nav-item">
                    <router-link class="navbar-nav-link" to="/role-permission">
                        <i class="fas fa-chevron-left text-green-700"></i>
                        Role And Permission
                    </router-link>
                </li>
            </template>
        </Header>
        <div class="page-content">
            <Sidebar/>
            <div class="content-wrapper">
                <div class="content">
                    <div class="row">
                        <div class="col-md-12">
                            <div class="card">
                                <div class="card-header bg-white header-elements-inline">
                                    <h6 class="card-title">Role List</h6>
                                    <div class="header-elements">
                                        <button
                                                class="btn bg-green-700 btn-labeled btn-labeled-left"
                                                type="button"
                                                v-b-modal="'new-role-modal'"
                                        >
                                            <b>
                                                <i class="fa fa-plus"></i>
                                            </b>
                                            Add New Role
                                        </button>
                                    </div>
                                </div>
                                <table class="table">
                                    <thead>
                                    <tr>
                                        <th>Role Name</th>
                                        <th width="50"></th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <tr :key="role.id" v-for="role in roles">
                                        <td scope="row">{{role.name}}</td>
                                        <td>
                                            <router-link
                                                    :to="`/role-permission/${role.id}`"
                                                    class="btn btn-sm bg-green"
                                            >
                                                <i class="fa fa-eye"></i>
                                            </router-link>
                                        </td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <b-modal
                :hide-footer="true"
                :no-close-on-backdrop="true"
                :no-close-on-esc="true"
                header-class="bg-white"
                id="new-role-modal"
                ref="new-role-modal"
                title="Create New Role"
        >
            <form @submit.prevent="submitForm">
                <div class="form-group">
                    <label for="name">Role Name</label>
                    <input class="form-control" type="text" v-model="role.name"/>
                </div>
                <button class="btn bg-green-700 btn-block mt-3" type="submit">Submit</button>
            </form>
        </b-modal>
    </div>
</template>
<script>
    import RoleAndPermissionApi from '../apis/RoleAndPermission'
    import Hrms from '../services/hrms'

    export default {
        data() {
            return {
                roles: [],
                role: {
                    name: ''
                }
            }
        },
        components: {
            Header: () =>
                import(
                    /* webpackChunkName: "component-header" */
                    /* webpackPrefetch: true */
                    /* webpackMode: "eager" */
                    '../components/Header'
                    ),
            Sidebar: () =>
                import(
                    /* webpackChunkName: "component-sidebar" */
                    /* webpackPrefetch: true */

                    /* webpackMode: "eager" */
                    '../components/Sidebar'
                    )
        },
        methods: {
            submitForm(e) {
                this.RoleStore({name: this.role.name})
            },
            RoleStore(payload) {
                Hrms.interceptors.response.use(
                    response => response,
                    error => {
                        if (typeof error.response === 'undefined') {
                            this.$swal(
                                'Ooops!',
                                'A network error occurred. ' +
                                'This could be a CORS issue or a dropped internet connection. ' +
                                'It is not possible for us to know. [CE-ROLESTORE-001]',
                                'error'
                            )
                        }
                        return Promise.reject(error)
                    }
                )
                RoleAndPermissionApi.RoleStore(payload)
                    .then(result => {
                        this.RoleList({})
                        this.$refs['new-role-modal'].hide()
                    })
                    .catch(err => {
                        this.$swal(
                            'Ooops!',
                            'A network error occurred. ' +
                            'It is not possible for us to know. [CE-ROLESTORE-002]',
                            'error'
                        )
                    })
            },
            RoleList(query) {
                Hrms.interceptors.response.use(
                    response => response,
                    error => {
                        if (typeof error.response === 'undefined') {
                            this.$swal(
                                'Ooops!',
                                'A network error occurred. ' +
                                'This could be a CORS issue or a dropped internet connection. ' +
                                'It is not possible for us to know. [CE-ROLE-001]',
                                'error'
                            )
                        }
                        return Promise.reject(error)
                    }
                )
                RoleAndPermissionApi.RoleList(query)
                    .then(result => {
                        this.roles = result.data.data
                    })
                    .catch(err => {
                        this.$swal(
                            'Ooops!',
                            'A network error occurred. ' +
                            'It is not possible for us to know. [CE-ROLE-002]',
                            'error'
                        )
                    })
            }
        },
        mounted() {
            ;(document.title = 'Role And Permission'), this.RoleList({})
        }
    }
</script>

const kpiScoreByBranch = [{
    path: '/kpi-score-by-branch',
    name: 'kpi-score-by-branch-view',
    component: () =>
        import (
            /* webpackChunkName: "kpi-score-by-branch-view" */
            /* webpackPrefetch: true */
            /* webpackMode: "eager" */
            '../views/KpiScoreBranch'
        ),
    meta: {
        requiresAuth: true,
        permission: 'GET:kpi.kpi-employee.search'
    }
}, {
    path: '/kpi-score-by-branch/:year/:month',
    name: 'kpi-score-by-branch-detail-view',
    component: () =>
        import (
            /* webpackChunkName: "kpi-score-by-branch-detail-view" */
            /* webpackPrefetch: true */
            /* webpackMode: "eager" */
            '../views/KpiScoreBranchDetail.vue'
        ),
    meta: {
        requiresAuth: true,
        permission: 'GET:kpi.kpi-employee.search'
    }
}]
export default kpiScoreByBranch
<template>
    <div>
        <Header>
            <template v-slot:nav-item-left>
                <li class="nav-item">
                    <router-link
                            :class="{ active: $route.name === 'role-permission-role-view' }"
                            :to="`/role-permission/${$route.params.id}`"
                            class="navbar-nav-link"
                    >
                        <i class="fas fa-chevron-left text-green-700"></i>
                        Permissions
                    </router-link>
                </li>
                <li class="nav-item">
                    <router-link
                            :class="{
              active: $route.name === 'role-permission-role-users-view'
            }"
                            :to="`/role-permission/${$route.params.id}/users`"
                            class="navbar-nav-link"
                    >Users
                    </router-link>
                </li>
            </template>
        </Header>
        <div class="page-content">
            <Sidebar/>
            <div class="content-wrapper">
                <div class="content">
                    <div class="row">
                        <div class="col-md-12">
                            <div class="card">
                                <div class="card-header bg-white">{{ role.name.toUpperCase() }}</div>
                                <template v-if="!loading">
                                    <div class="card-body">
                                        <div class="row">
                                            <template v-for="employee in employees">
                                                <div :key="employee.id" class="col-md-6 mb-2">
                                                    <p-check
                                                            :checked="checked(employee.user_id)"
                                                            @change="assignOrRevoke($event, employee)"
                                                            class="p-default p-fill"
                                                            color="primary"
                                                            name="check[]"
                                                    >
                                                        {{
                                                        employee.firstname + ' ' + employee.lastname
                                                        }}
                                                        ({{ employee.email }})
                                                    </p-check>
                                                </div>
                                            </template>
                                        </div>
                                    </div>
                                </template>
                                <template v-else>
                                    <div class="card-body">
                                        <content-placeholders>
                                            <content-placeholders-heading :img="true"/>
                                            <content-placeholders-text :lines="3"/>
                                        </content-placeholders>
                                    </div>
                                </template>
                            </div>
                            <paginate
                                    :click-handler="clickCallback"
                                    :container-class="
                  'pagination pagination-separated pagination-rounded align-self-center'
                "
                                    :margin-pages="5"
                                    :next-class="'page-item'"
                                    :next-link-class="'page-link'"
                                    :next-text="'Next'"
                                    :page-class="'page-item'"
                                    :page-count="pagination.totalPage"
                                    :page-link-class="'page-link'"
                                    :page-range="5"
                                    :prev-class="'page-item'"
                                    :prev-link-class="'page-link'"
                                    :prev-text="'Prev'"
                                    v-if="!$root.mobile"
                            ></paginate>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import RoleAndPermissionApi from '../apis/RoleAndPermission'
    import EmployeeApi from '../apis/Employee'
    import Hrms from '../services/hrms'
    import Paginate from '../components/Paginate'

    export default {
        data() {
            return {
                loading: true,
                employees: [],
                userIds: [],
                role: {
                    id: 0,
                    name: '-',
                    permissions: [],
                    users: []
                },
                pagination: {
                    totalPage: 0,
                    currentPage: 1,
                    totalData: 0,
                    displaying: 0
                }
            }
        },
        components: {
            Header: () =>
                import(
                    /* webpackChunkName: "component-header" */
                    /* webpackPrefetch: true */
                    /* webpackMode: "eager" */
                    '../components/Header'
                    ),
            Sidebar: () =>
                import(
                    /* webpackChunkName: "component-sidebar" */
                    /* webpackPrefetch: true */

                    /* webpackMode: "eager" */
                    '../components/Sidebar'
                    ),
            Paginate
        },
        methods: {
            clickCallback(e) {
                this.loading = true
                this.EmployeeSearch({page: e})
                this.RoleView(this.$route.params.id)
            },
            assignOrRevoke(e, employee) {
                let payload = {
                    user_id: employee.user_id,
                    role_id: this.$route.params.id
                }
                if (e) {
                    this.RoleAssignUser(payload)
                } else {
                    this.RoleRevokeUser(payload)
                }
            },
            RoleAssignUser(payload) {
                Hrms.interceptors.response.use(
                    response => response,
                    error => {
                        if (typeof error.response === 'undefined') {
                            this.$swal(
                                'Ooops!',
                                'A network error occurred. ' +
                                'This could be a CORS issue or a dropped internet connection. ' +
                                'It is not possible for us to know. [CE-ROLEASSIGNUSER-001]',
                                'error'
                            )
                        }
                        return Promise.reject(error)
                    }
                )
                RoleAndPermissionApi.RoleAssignUser(payload)
                    .then(result => {
                        console.log(result)
                    })
                    .catch(err => {
                        console.log(err)
                        this.$swal(
                            'Ooops!',
                            'A network error occurred. ' +
                            'It is not possible for us to know. [CE-ROLEASSIGNUSER-002]',
                            'error'
                        )
                    })
            },
            RoleRevokeUser(payload) {
                Hrms.interceptors.response.use(
                    response => response,
                    error => {
                        if (typeof error.response === 'undefined') {
                            this.$swal(
                                'Ooops!',
                                'A network error occurred. ' +
                                'This could be a CORS issue or a dropped internet connection. ' +
                                'It is not possible for us to know. [CE-ROLEASSIGNUSER-001]',
                                'error'
                            )
                        }
                        return Promise.reject(error)
                    }
                )
                RoleAndPermissionApi.RoleRevokeUser(payload)
                    .then(result => {
                        console.log(result)
                    })
                    .catch(err => {
                        console.log(err)
                        this.$swal(
                            'Ooops!',
                            'A network error occurred. ' +
                            'It is not possible for us to know. [CE-ROLEASSIGNUSER-002]',
                            'error'
                        )
                    })
            },
            RoleView(query) {
                Hrms.interceptors.response.use(
                    response => response,
                    error => {
                        if (typeof error.response === 'undefined') {
                            this.$swal(
                                'Ooops!',
                                'A network error occurred. ' +
                                'This could be a CORS issue or a dropped internet connection. ' +
                                'It is not possible for us to know. [CE-ROLE-001]',
                                'error'
                            )
                        }
                        return Promise.reject(error)
                    }
                )
                RoleAndPermissionApi.RoleView(query)
                    .then(result => {
                        const that = this
                        this.role = result.data.data
                        if (
                            typeof result.data.data.users !== 'undefined' &&
                            result.data.data.users
                        ) {
                            result.data.data.users.map(x => {
                                return (that.userIds[x.user_id] = x.user_id)
                            })
                        }
                        this.userIds = this.userIds.filter(Number)
                        console.log(this.userIds)
                        this.loading = false
                    })
                    .catch(err => {
                        console.log(err)
                        this.$swal(
                            'Ooops!',
                            'A network error occurred. ' +
                            'It is not possible for us to know. [CE-ROLE-002]',
                            'error'
                        )
                    })
            },
            EmployeeSearch(params) {
                Hrms.interceptors.response.use(
                    response => response,
                    error => {
                        if (typeof error.response === 'undefined') {
                            this.$swal(
                                'Ooops!',
                                'A network error occurred. ' +
                                'This could be a CORS issue or a dropped internet connection. ' +
                                'It is not possible for us to know. [CE-EMPLOYEESEARCH-001]',
                                'error'
                            )
                        }
                        return Promise.reject(error)
                    }
                )
                EmployeeApi.search(params)
                    .then(result => {
                        const that = this
                        this.employees = result.data.data
                        this.pagination.totalPage = parseInt(
                            result.headers['x-pagination-page-count']
                        )
                        this.pagination.currentPage = parseInt(
                            result.headers['x-pagination-current-page']
                        )
                        this.pagination.totalData = parseInt(
                            result.headers['x-pagination-total-count']
                        )
                        let perPage = parseInt(result.headers['x-pagination-per-page'])
                        let display = perPage * this.pagination.currentPage
                        if (display >= this.pagination.totalData) {
                            this.pagination.displaying = this.pagination.totalData
                        } else {
                            this.pagination.displaying = display
                        }
                    })
                    .catch(err => {
                        console.log(err)
                        this.$swal(
                            'Ooops!',
                            'A network error occurred. ' +
                            'It is not possible for us to know. [CE-EMPLOYEESEARCH-002]',
                            'error'
                        )
                    })
            },
            checked(id) {
                return this.userIds.find(x => x === id)
            }
        },
        mounted() {
            document.title = 'Employee Dashboard'
            this.EmployeeSearch()
            this.RoleView(this.$route.params.id)
            console.log(this.userIds)
        }
    }
</script>

import axios from '../services/master'

const resource = '/branch'
export default {
	list() {
		let token = JSON.parse(localStorage.getItem('hr_token')).access_token
		return axios.get(`${ resource }?limit=-1`, {
			headers: {
				'x-access-token': `${ token }`
			}
		})
	},
	search(query) {
		let token = JSON.parse(localStorage.getItem('hr_token')).access_token
		const params = new URLSearchParams(query)
		return axios.get(`${ resource }?${ params }`, {
			headers: {
				'x-access-token': `${ token }`
			}
		})
	},
	view(id) {
		let token = JSON.parse(localStorage.getItem('hr_token')).access_token
		return axios.get(`${ resource }/view?id=${ id }`, {
			headers: {
				'x-access-token': `${ token }`
			}
		})
	}
}
<template>
  <div>
    <Header>
      <template v-slot:nav-item-left>
        <li class="nav-item">
          <router-link class="navbar-nav-link" to="/achievement-bonus">
            <i class="fas fa-chevron-left text-green-700"></i> Achievement Bonus
            Detail
          </router-link>
        </li>
      </template>
    </Header>
    <div class="page-content">
      <Sidebar />
      <div class="content-wrapper">
        <div class="content">
          <breadcrumb-component
            :links="[
              { name: 'Achievement Bonus' },
              { name: 'Achievement Bonus Detail' }
            ]"
          ></breadcrumb-component>
          <div class="row mb-2 justify-content-end">
            <div class="col-md-6 mb-1 col-6">
              <div class="row">
                <div class="col-md-3">
                  <div class="form-group selectdiv">
                    <select class="form-control" v-model="model.bulan">
                      <option value="1">Januari</option>
                      <option value="2">Februari</option>
                      <option value="3">Maret</option>
                      <option value="4">April</option>
                      <option value="5">Mei</option>
                      <option value="6">Juni</option>
                      <option value="7">Juli</option>
                      <option value="8">Agustus</option>
                      <option value="9">September</option>
                      <option value="10">Oktober</option>
                      <option value="11">November</option>
                      <option value="12">Desember</option>
                    </select>
                  </div>
                </div>
                <div class="col-md-3">
                  <div class="form-group selectdiv">
                    <select class="form-control" v-model="model.year">
                      <option :value="year" v-for="year in years">{{
                        year
                      }}</option>
                    </select>
                  </div>
                </div>
                <div class="col-md-3">
                  <button
                    @click="filter"
                    class="btn bg-white btn-block text-secondary rounded-round btn-labeled btn-labeled-left"
                  >
                    <b>
                      <i class="icon-filter3"></i>
                    </b>
                    Filter
                  </button>
                </div>
                <div class="col-md-3">
                  <button
                    class="btn bg-green-600 btn-block rounded-round btn-labeled btn-labeled-left"
                  >
                    <b>
                      <i class="icon-filter3"></i>
                    </b>
                    Export
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div class="row mb-2">
            <div class="col-md-12">
              <div class="card">
                <div class="row">
                  <div class="col">
                    <dl class="p-2">
                      <dt>Branch</dt>
                      <dd class="font-weight-bold">
                        {{ achievementHeader.branch_name }}
                      </dd>
                    </dl>
                  </div>
                  <div class="col border-left">
                    <dl class="p-2">
                      <dt>Total Lot 10K & 12K</dt>
                      <dd class="font-weight-bold">
                        {{ achievementHeader.total_lot_10_12 }}
                      </dd>
                    </dl>
                  </div>
                  <div class="col border-left">
                    <dl class="p-2">
                      <dt>Total Lot 14K & USD</dt>
                      <dd class="font-weight-bold">
                        {{ achievementHeader.total_lot_14_usd }}
                      </dd>
                    </dl>
                  </div>
                  <div class="col border-left">
                    <dl class="p-2">
                      <dt>Total Achievement Bonus</dt>
                      <dd class="font-weight-bold">
                        {{ achievementHeader.total_achievement_bonus }}
                      </dd>
                    </dl>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12 mb-3">
              <div class="card">
                <table class="table">
                  <thead>
                    <tr>
                      <th class="text-muted font-weight-bold">Branch</th>
                      <th class="text-muted font-weight-bold">Position</th>
                      <th class="text-muted font-weight-bold">Name</th>
                      <th class="text-muted font-weight-bold">Score</th>
                      <th class="text-muted font-weight-bold">Lot 10K & 12K</th>
                      <th class="text-muted font-weight-bold">Lot 14K & USD</th>
                      <th class="text-muted font-weight-bold">Total</th>
                    </tr>
                  </thead>
                  <tbody>
                    <template v-if="loading">
                      <tr :key="a" v-for="a in 10">
                        <td :key="b" v-for="b in 7">
                          <content-placeholders :rounded="true">
                            <content-placeholders-text :lines="1" />
                          </content-placeholders>
                        </td>
                      </tr>
                    </template>
                    <template v-else>
                      <template v-if="achievements.length == 0">
                        <tr>
                          <td colspan="7">No Data</td>
                        </tr>
                      </template>
                      <template v-else>
                        <tr
                          :key="index"
                          v-for="(achievement, index) in achievements"
                        >
                          <td scope="row">{{ achievement.branch_name }}</td>
                          <td>{{ achievement.occupation_name }}</td>
                          <td>
                            {{
                              achievement.employee_first_name +
                                ' ' +
                                achievement.employee_last_name
                            }}
                          </td>
                          <td>{{ achievement.score }}</td>
                          <td>{{ achievement.total_lot_10_12 }}</td>
                          <td>{{ achievement.total_lot_14_usd }}</td>
                          <td>{{ achievement.total_lot }}</td>
                        </tr>
                      </template>
                    </template>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Paginate from '../components/Paginate'
import Achievement from '../apis/Achievement'
import moment from 'moment'
import BranchApi from '../apis/Branch'

export default {
  data() {
    return {
      loading: true,
      pagination: {
        totalPage: 10,
        currentPage: 1,
        totalData: 0,
        displaying: 0
      },
      achievements: [],
      model: {
        year: new Date().getFullYear(),
        bulan: new Date().getMonth() + 1
      },
      achievementHeader: {
        branch_name: '-',
        total_lot_10_12: 0,
        total_lot_14_usd: 0,
        total_achievement_bonus: 0
      }
    }
  },
  computed: {
    years() {
      const year = new Date().getFullYear()
      return Array.from({ length: year - 2010 }, (value, index) => 2011 + index)
    }
  },
  components: {
    Header: () =>
      import(
        /* webpackChunkName: "component-header" */
        /* webpackPrefetch: true */
        /* webpackMode: "eager" */
        '../components/Header'
      ),
    Sidebar: () =>
      import(
        /* webpackChunkName: "component-sidebar" */
        /* webpackPrefetch: true */

        /* webpackMode: "eager" */
        '../components/Sidebar'
      ),
    Paginate
  },
  methods: {
    filter() {
      let last = this.formatDate(
        new Date(this.model.year, this.model.bulan, 0, 23, 59, 59),
        'day'
      )
      let from_date = this.model.year + '-' + this.model.bulan + '-1'
      let to_date = this.model.year + '-' + this.model.bulan + '-' + last
      localStorage.setItem('achievement_bonus', {
        from_date: from_date,
        to_date: to_date
      })
      this.loading = true
      this.achievementBranchBonusDetailByDateRange(
        this.$route.params.id,
        from_date,
        to_date
      )
    },
    branchView(id) {
      BranchApi.view(id)
        .then(res => {
          this.achievementHeader.branch_name = res.data.data.name
          console.log(res.data.data.name)
        })
        .catch()
    },
    achievementBranchBonusDetailByDateRange(id, from_date, to_date) {
      Achievement.achievementBranchBonusDetailByDateRange(
        id,
        from_date,
        to_date
      )
        .then(res => {
          console.log(res)
          this.achievements = res.data.data
          let branch_name = ''
          let total_lot_10_12 = parseFloat(0)
          let total_lot_14_usd = parseFloat(0)
          let total_achievement_bonus = parseFloat(0)
          let i
          for (i = 0; i < this.achievements.length; i++) {
            total_lot_10_12 += parseFloat(this.achievements[i].total_lot_10_12)
            total_lot_14_usd += parseFloat(
              this.achievements[i].total_lot_14_usd
            )
            total_achievement_bonus += parseFloat(
              this.achievements[i].total_lot
            )
          }
          this.achievementHeader.total_lot_10_12 = total_lot_10_12.toFixed(2)
          this.achievementHeader.total_lot_14_usd = total_lot_14_usd.toFixed(2)
          this.achievementHeader.total_achievement_bonus = total_achievement_bonus.toFixed(
            2
          )
          this.loading = false
        })
        .catch(err => {
          this.loading = false
          console.log(err)
        })
    },
    beginningOfMonth(myDate) {
      let date = new Date(myDate)
      date.setDate(1)
      date.setHours(0)
      date.setMinutes(0)
      date.setSeconds(0)
      return date
    },
    endOfMonth(myDate) {
      let date = new Date(myDate)
      date.setMonth(date.getMonth() + 1)
      date.setDate(0)
      date.setHours(23)
      date.setMinutes(59)
      date.setSeconds(59)
      return date
    },
    formatDate(date, type) {
      if (type === 'day') {
        return moment(String(date)).format('DD')
      }
      if (type === 'bulan') {
        return moment(String(date)).format('MM')
      }
      if (type === 'year') {
        return moment(String(date)).format('YYYY')
      }
      if (type === 'full') {
        moment(String(value)).format('YYYY-MM-DD')
      }
    }
  },
  mounted() {
    document.title = 'Achievement Bonus'
    let last = this.formatDate(
      new Date(this.model.year, this.model.bulan, 0, 23, 59, 59),
      'day'
    )
    let from_date = this.model.year + '-' + this.model.bulan + '-1'
    let to_date = this.model.year + '-' + this.model.bulan + '-' + last
    this.branchView(this.$route.params.id)
    this.achievementBranchBonusDetailByDateRange(
      this.$route.params.id,
      from_date,
      to_date
    )
  }
}
</script>

const home = [{
    path: '/',
    name: 'home',
    component: () =>
        import (
            /* webpackChunkName: "page-home" */
            /* webpackPrefetch: true */
            /* webpackMode: "eager" */
            '../views/Home.vue'
        ),
    meta: {
        requiresAuth: true,
        permission: 'GET:employee.default.dashboard'
    }
},
{
    path: '/verify',
    name: 'verify',
    component: () =>
        import (
            /* webpackChunkName: "page-verify" */
            /* webpackPrefetch: true */
            /* webpackMode: "eager" */
            '../views/verify.vue'
        ),
    meta: {
        requiresAuth: false,
        permission: 'GET:employee.default.dashboard'
    }
}
]
export default home
import axios from '../services/hrms'

const resource = '/kpi'
export default {
	leagueTable(query) {
		let token = JSON.parse(localStorage.getItem('hr_token')).access_token
		const params = new URLSearchParams(query)
		return axios.get(`${ resource }/league-table?${ params }`, {
			headers: {
				'x-access-token': `${ token }`
			}
		})
	},
	search(query) {
		let token = JSON.parse(localStorage.getItem('hr_token')).access_token
		const params = new URLSearchParams(query)
		return axios.get(`${ resource }/search?${ params }`, {
			headers: {
				'x-access-token': `${ token }`
			}
		})
	},
	view(id) {
		let token = JSON.parse(localStorage.getItem('hr_token')).access_token
		return axios.get(
			`${ resource }/${ id }`, {
				headers: {
					'x-access-token': `${ token }`
				}
			}
		)
	},
	store(payload) {
		let token = JSON.parse(localStorage.getItem('hr_token')).access_token
		return axios.post(`${ resource }/store`, payload, {
			headers: {
				'x-access-token': `${ token }`
			}
		})
	},
	delete(id, payload) {
		let token = JSON.parse(localStorage.getItem('hr_token')).access_token
		return axios.post(`${ resource }/${ id }/delete`, payload, {
			headers: {
				'x-access-token': `${ token }`
			}
		})
	},
	cloneKpiByMonthYear(from_year, from_month, to_year, to_month) {
		let token = JSON.parse(localStorage.getItem('hr_token')).access_token
		return axios.get(
			`${ resource }/clone-kpi-by-month-year?from_year=${ from_year }&from_month=${ from_month }&to_year=${ to_year }&to_month=${ to_month }`, {
				headers: {
					'x-access-token': `${ token }`
				}
			}
		)
	},
	groupByMonthYearAction(year) {
		let token = JSON.parse(localStorage.getItem('hr_token')).access_token
		return axios.get(
			`${ resource }/group-by-month-year?year=${ year }`, {
				headers: {
					'x-access-token': `${ token }`
				}
			}
		)
	},
	groupByMonthYearBranchAction(year, month, branch_id) {
		let token = JSON.parse(localStorage.getItem('hr_token')).access_token
		return axios.get(
			`${ resource }/group-kpi-by-month-year-branch?year=${ year }&month=${ month }&branch_id=${ branch_id }`, {
				headers: {
					'x-access-token': `${ token }`
				}
			}
		)
	},
	groupByMonthYearEmployeeAction(year, month, employee_id) {
		let token = JSON.parse(localStorage.getItem('hr_token')).access_token
		return axios.get(
			`${ resource }/group-kpi-by-month-year-employee?year=${ year }&month=${ month }&employee_id=${ employee_id }`, {
				headers: {
					'x-access-token': `${ token }`
				}
			}
		)
	}
	,
	cloneScoreKpiByMonthYear(from_year, from_month, to_year, to_month) {
		let token = JSON.parse(localStorage.getItem('hr_token')).access_token
		return axios.get(
			`${ resource }/clone-score-kpi-by-month-year?from_year=${ from_year }&from_month=${ from_month }&to_year=${ to_year }&to_month=${ to_month }`, {
				headers: {
					'x-access-token': `${ token }`
				}
			}
		)
	},
	groupScoreByMonthYearAction(year) {
		let token = JSON.parse(localStorage.getItem('hr_token')).access_token
		return axios.get(
			`${ resource }/group-score-by-month-year?year=${ year }`, {
				headers: {
					'x-access-token': `${ token }`
				}
			}
		)
	},
	groupScoreByMonthYearBranchAction(year, month, branch_id) {
		let token = JSON.parse(localStorage.getItem('hr_token')).access_token
		return axios.get(
			`${ resource }/group-score-kpi-by-month-year-branch?year=${ year }&month=${ month }&branch_id=${ branch_id }`, {
				headers: {
					'x-access-token': `${ token }`
				}
			}
		)
	},
	groupScoreByMonthYearEmployeeAction(year, month, employee_id) {
		let token = JSON.parse(localStorage.getItem('hr_token')).access_token
		return axios.get(
			`${ resource }/group-score-kpi-by-month-year-employee?year=${ year }&month=${ month }&employee_id=${ employee_id }`, {
				headers: {
					'x-access-token': `${ token }`
				}
			}
		)
	},
	kpiDetail(query) {
		let token = JSON.parse(localStorage.getItem('hr_token')).access_token
		return axios.get(
			`${ resource }/kpi-employee-detail?${ query }`, {
				headers: {
					'x-access-token': `${ token }`
				}
			}
		)
	},
	kpiScoreDetail(query) {
		let token = JSON.parse(localStorage.getItem('hr_token')).access_token
		return axios.get(
			`${ resource }/kpi-score-employee-detail?${ query }`, {
				headers: {
					'x-access-token': `${ token }`
				}
			}
		)
	}
}
<template>
    <div class="breadcrumb-line breadcrumb-line-light bg-white breadcrumb-line-component header-elements-md-inline mb-4">
        <div class="d-flex">
            <div class="breadcrumb">
                <router-link to="/" class="breadcrumb-item">
                    <i class="icon-home2 mr-2"></i>
                    Home
                </router-link>
                <template v-for="(link, index) in links">
                    <span class="breadcrumb-item active" :key="index">{{link.name}}</span>
                </template>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        props:{
          links:{
              type: Array,
          }
        },
        name: "Breadcrumb"
    }
</script>

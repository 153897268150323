const login = [{
    path: '/departments',
    name: 'departments-view',
    component: () =>
        import (
            /* webpackChunkName: "departments-view" */
            /* webpackPrefetch: true */
            /* webpackMode: "eager" */
            '../views/DepartmentList.vue'
        ),
    meta: {
        requiresAuth: true,
        permission: 'GET:employee.default.departments-view'
    }
}, {
    path: '/departments/:id',
    name: 'departments-detail-view',
    component: () =>
        import (
            /* webpackChunkName: "departments-detail-view" */
            /* webpackPrefetch: true */
            /* webpackMode: "eager" */
            '../views/DepartmentDetail.vue'
        ),
    meta: {
        requiresAuth: true,
        permission: 'GET:employee.default.departments-detail-view'
    }
}]
export default login
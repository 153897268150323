import axios from '../services/hrms'

const resource = '/employee'
export default {
  search(query) {
    let token = JSON.parse(localStorage.getItem('hr_token')).access_token
    const params = new URLSearchParams(query)
    return axios.get(`${resource}/search?${params}`, {
      headers: {
        'x-access-token': `${token}`
      }
    })
  },
  view(id) {
    let token = JSON.parse(localStorage.getItem('hr_token')).access_token
    return axios.get(
      `${resource}/${id}?expand=families,educations,branch,occupation,department,user`,
      {
        headers: {
          'x-access-token': `${token}`
        }
      }
    )
  },
  ViewByUserId(id) {
    let token = JSON.parse(localStorage.getItem('hr_token')).access_token
    return axios.get(
      `${resource}/view-by-user-id/${id}?expand=families,educations,branch,occupation,department`,
      {
        headers: {
          'x-access-token': `${token}`
        }
      }
    )
  },
  store(payload) {
    let token = JSON.parse(localStorage.getItem('hr_token')).access_token
    return axios.post(`${resource}/store`, payload, {
      headers: {
        'x-access-token': `${token}`
      }
    })
  },
  delete(id, payload) {
    let token = JSON.parse(localStorage.getItem('hr_token')).access_token
    return axios.post(`${resource}/${id}/delete`, payload, {
      headers: {
        'x-access-token': `${token}`
      }
    })
  },
  update(id, payload) {
    let token = JSON.parse(localStorage.getItem('hr_token')).access_token
    return axios.post(`${resource}/${id}/update`, payload, {
      headers: {
        'x-access-token': `${token}`
      }
    })
  },
  userCount() {
    let token = JSON.parse(localStorage.getItem('hr_token')).access_token
    return axios.get(`users/check-user`, {
      headers: {
        'x-access-token': `${token}`
      }
    })
  },
  changePassword(payload) {
    let token = JSON.parse(localStorage.getItem('hr_token')).access_token
    return axios.post(`/users/change-password-hr`, payload, {
      headers: {
        'x-access-token': `${token}`
      }
    })
  },
  resign(payload) {
    let token = JSON.parse(localStorage.getItem('hr_token')).access_token
    return axios.post(`${resource}/resign`, payload, {
      headers: {
        'x-access-token': `${token}`
      }
    })
  },
  historyStore(payload) {
    let token = JSON.parse(localStorage.getItem('hr_token')).access_token
    return axios.post(`${resource}/history/store`, payload, {
      headers: {
        'x-access-token': `${token}`
      }
    })
  },
  history(id, query) {
    let token = JSON.parse(localStorage.getItem('hr_token')).access_token
    const params = new URLSearchParams(query)
    return axios.get(`${resource}/${id}/history?${params}`, {
      headers: {
        'x-access-token': `${token}`
      }
    })
  },
  searchDisable(query) {
    let token = JSON.parse(localStorage.getItem('hr_token')).access_token
    const params = new URLSearchParams(query)
    return axios.get(`${resource}/disabled?${params}`, {
      headers: {
        'x-access-token': `${token}`
      }
    })
  }
}

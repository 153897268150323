import axios from '../services/master'

const resource = '/data-enum-details'
export default {
	findByRuleName(name) {
		let token = JSON.parse(localStorage.getItem('hr_token')).access_token
		return axios.get(`${ resource }/find-by-rule-name?name=${ name }`, {
			headers: {
				'x-access-token': `${ token }`
			}
		})
	},
	findByRuleNameValue(name, value) {
		let token = JSON.parse(localStorage.getItem('hr_token')).access_token
		return axios.get(
			`${ resource }/find-by-rule-name-value?name=${ name }&value=${ value }`,
			{
				headers: {
					'x-access-token': `${ token }`
				}
			}
		)
	}
}

<template>
    <div>
        <Header>
            <template v-slot:nav-item-left>
                <li class="nav-item">
                    <router-link class="navbar-nav-link" to="/">Kpi Channel</router-link>
                </li>
            </template>
        </Header>
        <div class="page-content">
            <Sidebar/>
            <div class="content-wrapper">
                <div class="content">
                    <breadcrumb-component
                            :links="[{ name: 'KPI' }, { name: 'Score Channel' }]"
                    ></breadcrumb-component>
                    <div class="row mb-1">
                        <div class="col-md-6 mb-1"></div>
                        <div class="col-md-6 mb-1 col-6">
                            <div class="row">
                                <div class="col-md-6"></div>
                                <div class="col-md-6">
                                    <div class="form-group selectdiv">
                                        <select
                                                @change="filterDropdown"
                                                class="form-control"
                                                v-model="filter.tahun"
                                        >
                                            <template v-for="year in filter.years">
                                                <option :key="year.id">{{ year.tahun }}</option>
                                            </template>
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12">
                            <div class="card">
                                <table class="table table-striped">
                                    <thead>
                                    <tr>
                                        <th>Month</th>
                                        <th>Year</th>
                                        <th>Kpi Set</th>
                                        <th></th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <tr :key="mnth.month" v-for="mnth in months">
                                        <td>{{ mnth.month_name }}</td>
                                        <td>{{ mnth.year }}</td>
                                        <td>{{ mnth.kpi_set }}</td>
                                        <td class="text-right">
                                            <div class="list-icons">
                                                <div class="list-icons-item dropdown">
                                                    <a
                                                            class="list-icons-item dropdown-toggle caret-0"
                                                            data-toggle="dropdown"
                                                            href="javascript:void(0)"
                                                    >
                                                        <i
                                                                class="fas fa-ellipsis-v text-green-700 fa-2x"
                                                        ></i>
                                                    </a>
                                                    <div class="dropdown-menu dropdown-menu-right">
                                                        <router-link
                                                                :to="`/kpi-score-channel/${mnth.month}/${mnth.year}`"
                                                                class="dropdown-item"
                                                        >
                                                            <i class="icon-file-stats"></i>
                                                            Edit KPI
                                                        </router-link>
                                                        <a
                                                                @click="cloneKPI(filter.tahun, mnth.month)"
                                                                class="dropdown-item"
                                                                href="javascript:void(0)"
                                                        >
                                                            <i class="far fa-copy"></i>
                                                            Duplicate KPI
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <b-modal
                :hide-footer="true"
                :no-close-on-backdrop="true"
                :no-close-on-esc="true"
                header-class="bg-white"
                id="cloneKPI"
                ref="cloneKPI"
                title="Duplicate KPI"
        >
            <form @submit.prevent="submitCloneForm">
                <div class="row">
                    <div class="col-md-12 mb-2">
                        <label for="year">From</label>
                        <div class="row">
                            <div class="col-md-6">
                                <div class="form-group selectdiv">
                                    <select
                                            class="form-control"
                                            disabled
                                            v-model="cloneModel.from_month"
                                    >
                                        <option value="1">Januari</option>
                                        <option value="2">Februari</option>
                                        <option value="3">Maret</option>
                                        <option value="4">April</option>
                                        <option value="5">Mei</option>
                                        <option value="6">Juni</option>
                                        <option value="7">Juli</option>
                                        <option value="8">Agustus</option>
                                        <option value="9">September</option>
                                        <option value="10">Oktober</option>
                                        <option value="11">November</option>
                                        <option value="12">Desember</option>
                                    </select>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <input
                                        class="form-control"
                                        disabled
                                        id="year"
                                        type="text"
                                        v-model="cloneModel.from_year"
                                />
                            </div>
                        </div>
                    </div>
                    <div class="col-md-12 mb-2">
                        <label for="year">To</label>
                        <div class="row">
                            <div class="col-md-6">
                                <div class="form-group selectdiv">
                                    <select class="form-control" v-model="cloneModel.to_month">
                                        <option value="1">Januari</option>
                                        <option value="2">Februari</option>
                                        <option value="3">Maret</option>
                                        <option value="4">April</option>
                                        <option value="5">Mei</option>
                                        <option value="6">Juni</option>
                                        <option value="7">Juli</option>
                                        <option value="8">Agustus</option>
                                        <option value="9">September</option>
                                        <option value="10">Oktober</option>
                                        <option value="11">November</option>
                                        <option value="12">Desember</option>
                                    </select>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="form-group selectdiv">
                                    <select class="form-control" v-model="cloneModel.to_year">
                                        <template v-for="year in filter.years">
                                            <option :key="year.id">{{ year.tahun }}</option>
                                        </template>
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="form-group"></div>
                <button class="btn bg-green-700 btn-block mt-3" type="submit">
                    Submit
                </button>
            </form>
        </b-modal>
    </div>
</template>
<script>
    import KpiScoreChannel from '../apis/KpiScoreChannel'
    import KpiApi from '../apis/Kpi'

    export default {
        data() {
            return {
                months: [],
                filter: {
                    years: [],
                    tahun: new Date().getFullYear()
                },
                loading: true,
                cloneModel: {
                    from_year: '',
                    from_month: '',
                    to_year: '',
                    to_month: 1
                }
            }
        },
        components: {
            Header: () =>
                import(
                    /* webpackChunkName: "component-header" */
                    /* webpackPrefetch: true */
                    /* webpackMode: "eager" */
                    '../components/Header'
                    ),
            Sidebar: () =>
                import(
                    /* webpackChunkName: "component-sidebar" */
                    /* webpackPrefetch: true */

                    /* webpackMode: "eager" */
                    '../components/Sidebar'
                    )
        },
        methods: {
            groupKpiChannelByMonthYear(query) {
                KpiScoreChannel.groupKpiScoreChannelByMonthYear(query)
                    .then(res => {
                        console.log(res)
                        this.months = res.data.data
                    })
                    .catch(res => {
                        console.log(res)
                    })
            },
            kpiSearch(param) {
                KpiApi.search(param)
                    .then(res => {
                        this.filter.years = res.data.data
                    })
                    .catch(error => {
                        this.$swal(
                            'Ooops!',
                            'A network error occurred. ' +
                            'It is not possible for us to know. [CE-KPI-002]',
                            'error'
                        )
                    })
            },

            submitCloneForm() {
                console.log(this.cloneModel)
                this.loading = true
                this.cloneKpiChannelByMonthYear(
                    this.cloneModel.from_year,
                    this.cloneModel.from_month,
                    this.cloneModel.to_year,
                    this.cloneModel.to_month
                )
                this.loading = true
                this.groupKpiChannelByMonthYear({year: this.filter.tahun})
                this.$refs['cloneKPI'].hide()
            },
            cloneKPI(tahun, bulan) {
                this.cloneModel.from_year = tahun
                this.cloneModel.from_month = bulan
                this.cloneModel.to_year = tahun
                this.$refs['cloneKPI'].show()
            },
            cloneKpiChannelByMonthYear(from_year, from_month, to_year, to_month) {
                KpiScoreChannel.cloneKpiScoreChannelByMonthYear(
                    from_year,
                    from_month,
                    to_year,
                    to_month
                )
                    .then(res => {
                        console.log(res)
                        this.loading = false
                    })
                    .catch(res => {
                        console.log(res)
                    })
            },
            filterDropdown(e) {
                this.groupKpiChannelByMonthYear({year: this.filter.tahun})
            }
        },
        mounted() {
            document.title = 'Kpi Channel'
            this.kpiSearch({limit: -1, sort: 3, order: 'tahun'})
            this.groupKpiChannelByMonthYear({year: this.filter.tahun})
        }
    }
</script>

<template>
    <div>
        <Header>
            <template v-slot:nav-item-left>
                <li class="nav-item">
                    <router-link :class="{
                active:
                  $route.name === 'lot-earnings-report-view'
              }" class="navbar-nav-link"
                                 to="/lot-earnings-report">
                        Employee
                    </router-link>
                </li>
                <li class="nav-item">
                    <router-link :class="{
                active:
                  $route.name === 'lot-earnings-report-client-view'
              }" class="navbar-nav-link"
                                 to="/lot-earnings-report-client">
                        Referral
                    </router-link>
                </li>
            </template>
        </Header>
        <div class="page-content">
            <Sidebar/>
            <div class="content-wrapper">
                <div class="content">
                    <breadcrumb-component
                            :links="[{name:'Lot Earing Report'},{name:'Client'}]"></breadcrumb-component>
                    <div class="row mb-2">
                        <div class="col-md-6 mb-1">
                            Displaying
                            <span class="font-weight-bold text-green-700">{{ pagination.displaying }}</span>
                            of
                            <span
                                    class="font-weight-bold text-green-700"
                            >{{ pagination.totalData }}</span>
                            Result
                        </div>
                        <div class="col-md-6 mb-1 col-6">
                            <div class="row">
                                <div class="col-md-3">
                                    <div class="form-group selectdiv">
                                        <select @change="filterDropdown" class="form-control"
                                                v-model="model.bulan">
                                            <option value="1">Januari</option>
                                            <option value="2">Februari</option>
                                            <option value="3">Maret</option>
                                            <option value="4">April</option>
                                            <option value="5">Mei</option>
                                            <option value="6">Juni</option>
                                            <option value="7">Juli</option>
                                            <option value="8">Agustus</option>
                                            <option value="9">September</option>
                                            <option value="10">Oktober</option>
                                            <option value="11">November</option>
                                            <option value="12">Desember</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="col-md-3">
                                    <div class="form-group selectdiv">
                                        <select @change="filterDropdown" class="form-control"
                                                v-model="model.year">
                                            <option :value="year" v-for="year in years">{{ year }}</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="col-md-3">
                                    <button
                                            class="btn bg-white btn-block text-secondary rounded-round btn-labeled btn-labeled-left"
                                    >
                                        <b>
                                            <i class="icon-filter3"></i>
                                        </b>
                                        Filter
                                    </button>
                                </div>
                                <div class="col-md-3">
                                    <button
                                            class="btn bg-green-600 btn-block rounded-round btn-labeled btn-labeled-left"
                                    >
                                        <b>
                                            <i class="icon-filter3"></i>
                                        </b>
                                        Export
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12">
                            <div class="card">
                                <table class="table">
                                    <thead>
                                    <tr>
                                        <th class="text-muted font-weight-bold">Referral Name</th>
                                        <th class="text-muted font-weight-bold">Referral Email</th>
                                        <th class="text-muted font-weight-bold">Branch</th>
                                        <th class="text-muted font-weight-bold">Position</th>
                                        <th class="text-muted font-weight-bold">OR</th>
                                        <th class="text-muted font-weight-bold">Recom</th>
                                        <th class="text-muted font-weight-bold">Subsidi</th>
                                        <th class="text-muted font-weight-bold">Reduksi</th>
                                        <th class="text-muted font-weight-bold">Earnings</th>
                                        <th class="text-muted font-weight-bold"></th>
                                    </tr>
                                    </thead>
                                    <tbody>

                                    <template v-if="loading">
                                        <tr :key="a" v-for="a in 10">
                                            <td :key="b" v-for="b in 10">
                                                <content-placeholders :rounded="true">
                                                    <content-placeholders-text :lines="1"/>
                                                </content-placeholders>
                                            </td>
                                        </tr>
                                    </template>

                                    <template v-if="!loading && datas.length === 0">
                                        <tr>
                                            <td colspan="10">
                                                No Data
                                            </td>
                                        </tr>
                                    </template>
                                    <tr :key="index" v-for="(data,index) in datas"
                                        v-if="!loading && datas.length > 0">
                                        <td>{{data.first_name}}</td>
                                        <td>{{data.email}}</td>
                                        <td>{{data.branch_name}}</td>
                                        <td>{{data.occupation_name}}</td>
                                        <td>{{data.or_commission_amount | formatNum}}</td>
                                        <td>{{data.recom_commission_amount | formatNum}}</td>
                                        <td>{{data.subsidi_commission_amount | formatNum}}</td>
                                        <td>{{data.reduksi_commission_amount | formatNum}}</td>
                                        <td>{{data.earnings_commission_amount | formatNum}}</td>
                                        <td class="text-right">
                                            <div class="list-icons">
                                                <div class="list-icons-item dropdown">
                                                    <a
                                                            class="list-icons-item dropdown-toggle caret-0"
                                                            data-toggle="dropdown"
                                                            href="javascript:void(0)"
                                                    >
                                                        <i class="fas fa-ellipsis-v text-green-700 fa-2x"></i>
                                                    </a>
                                                    <div class="dropdown-menu dropdown-menu-right">
                                                        <router-link
                                                                :to="`/lot-earnings-report-client-detail/${data.user_id}-${model.year}-${model.bulan}-${data.user_type}`"
                                                                class="dropdown-item"
                                                        >
                                                            <i class="icon-file-stats"></i>
                                                            View Detail
                                                        </router-link>
                                                    </div>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                            <paginate
                                    :click-handler="clickCallback"
                                    :container-class="
									'pagination pagination-separated pagination-rounded align-self-center'
								"
                                    :margin-pages="5"
                                    :next-class="'page-item'"
                                    :next-link-class="'page-link'"
                                    :next-text="'Next'"
                                    :page-class="'page-item'"
                                    :page-count="pagination.totalPage"
                                    :page-link-class="'page-link'"
                                    :page-range="5"
                                    :prev-class="'page-item'"
                                    :prev-link-class="'page-link'"
                                    :prev-text="'Prev'"
                            ></paginate>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import BackofficeApi from '../apis/Backoffice'
    import moment from "moment";
    import Paginate from '../components/Paginate'

    export default {
        data() {
            return {
                loading: true,
                datas: [],
                pagination: {
                    totalPage: 1,
                    currentPage: 1,
                    totalData: 0,
                    displaying: 0
                },
                model: {
                    year: new Date().getFullYear(),
                    bulan: new Date().getMonth() + 1
                }
            }
        },
        components: {
            Header: () =>
                import(
                    /* webpackChunkName: "component-header" */
                    /* webpackPrefetch: true */
                    /* webpackMode: "eager" */
                    '../components/Header'
                    ),
            Sidebar: () =>
                import(
                    /* webpackChunkName: "component-sidebar" */
                    /* webpackPrefetch: true */

                    /* webpackMode: "eager" */
                    '../components/Sidebar'
                    ),
            Paginate
        },
        methods: {
            commissionEarningsReportByUser(start_date, end_date, user_type, page = 1) {
                BackofficeApi
                    .commissionEarningsReportByUser(start_date, end_date, user_type, page)
                    .then(res => {
                        this.datas = res.data.data
                        this.pagination.totalData = res.data.totalCount
                        this.pagination.currentPage = res.data.page
                        this.pagination.totalPage = _.ceil(res.data.totalCount / res.data.size)
                        this.loading = false
                    })
                    .catch(err => {
                        this.loading = false
                    })
            },
            formatDate(date, type) {
                if (type === 'day') {
                    return moment(String(date)).format('DD')
                }
                if (type === 'bulan') {
                    return moment(String(date)).format('MM')
                }
                if (type === 'year') {
                    return moment(String(date)).format('YYYY')
                }
                if (type === 'full') {
                    moment(String(value)).format('YYYY-MM-DD')
                }
            },
            filterDropdown() {
                this.pagination.currentPage = 1
                this.loading = true
                let last = this.formatDate(new Date(this.model.year, this.model.bulan, 0, 23, 59, 59), 'day')
                let from_date = this.model.year + '-' + this.model.bulan + '-1'
                let to_date = this.model.year + '-' + this.model.bulan + '-' + last
                this.commissionEarningsReportByUser(from_date, to_date, 'client')
            },
            clickCallback(e) {
                this.loading = true
                let last = this.formatDate(new Date(this.model.year, this.model.bulan, 0, 23, 59, 59), 'day')
                let from_date = this.model.year + '-' + this.model.bulan + '-1'
                let to_date = this.model.year + '-' + this.model.bulan + '-' + last
                this.commissionEarningsReportByUser(from_date, to_date, 'client', e)
            }
        },
        mounted() {
            document.title = 'Lot Earnings Report'
            let last = this.formatDate(new Date(this.model.year, this.model.bulan, 0, 23, 59, 59), 'day')
            let from_date = this.model.year + '-' + this.model.bulan + '-1'
            let to_date = this.model.year + '-' + this.model.bulan + '-' + last
            this.commissionEarningsReportByUser(from_date, to_date, 'client')
        },
        computed: {
            years() {
                const year = new Date().getFullYear()
                return Array.from({length: year - 2018}, (value, index) => 2019 + index)
            }
        },
        filters: {
            formatNum(value) {
                return new Intl.NumberFormat('id-ID').format(value)
            }
        }
    }
</script>
<style scoped>
    .table td {
        padding: 0.5rem 1rem;
    }
</style>
const rolePermission = [{
    path: '/role-permission',
    name: 'role-permission-view',
    component: () =>
        import (
            /* webpackChunkName: "page-role-permission-view" */
            /* webpackPrefetch: true */
            /* webpackMode: "eager" */
            '../views/RoleAndPermission.vue'
        ),
    meta: {
        requiresAuth: true,
        permission: 'GET:employee.default.role-permission-view'
    }
}, {
    path: '/role-permission/:id',
    name: 'role-permission-role-view',
    component: () =>
        import (
            /* webpackChunkName: "page-role-permission-role-view" */
            /* webpackPrefetch: true */
            /* webpackMode: "eager" */
            '../views/RoleAndPermissionRoleView.vue'
        ),
    meta: {
        requiresAuth: true,
        permission: 'GET:employee.default.role-permission-role-view'
    }
}, {
    path: '/role-permission/:id/users',
    name: 'role-permission-role-users-view',
    component: () =>
        import (
            /* webpackChunkName: "page-role-permission-role-view" */
            /* webpackPrefetch: true */
            /* webpackMode: "eager" */
            '../views/RoleAndPermissionRoleUsersView.vue'
        ),
    meta: {
        requiresAuth: true,
        permission: 'GET:employee.default.role-permission-role-users-view'
    }
}]
export default rolePermission
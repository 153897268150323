<template>
  <div>
    <Header>
      <template v-slot:nav-item-left>
        <li class="nav-item">
          <router-link class="navbar-nav-link" to="/employees">
            <i class="fas fa-chevron-left text-green-700"></i>
            Employee Details
          </router-link>
        </li>
      </template>
    </Header>
    <div class="page-content">
      <Sidebar />
      <div class="content-wrapper">
        <div
          class="content"
          v-if="
            isHavePermission('GET:employee.default.can-see-other-profile') ||
              localEmployee.id === $route.params.id
          "
        >
          <breadcrumb-component
            :links="[
              { name: 'Employee Management' },
              { name: employee.firstname + ' ' + employee.lastname }
            ]"
          ></breadcrumb-component>
          <div class="row">
            <div class="col-md-4">
              <div class="card card-body">
                <div class="media">
                  <div class="mr-3">
                    <a href="#">
                      <i class="icon-vcard icon-3x"></i>
                    </a>
                  </div>
                  <div class="media-body">
                    <span class="text-muted mb-0">Employee ID</span>
                    <h6 class="mb-0 mt-1">
                      <template v-if="loading">
                        <content-placeholders :rounded="true">
                          <content-placeholders-text :lines="1" />
                        </content-placeholders>
                      </template>
                      <template v-else>{{ employee.employee_no }}</template>
                    </h6>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-4">
              <div class="card card-body">
                <div class="media">
                  <div class="mr-3">
                    <a href="#">
                      <i class="icon-envelop4 icon-3x"></i>
                    </a>
                  </div>
                  <div class="media-body">
                    <span class="text-muted mb-0">Email</span>
                    <h6 class="mb-0 mt-1">
                      <template v-if="loading">
                        <content-placeholders :rounded="true">
                          <content-placeholders-text :lines="1" />
                        </content-placeholders>
                      </template>
                      <template v-else>{{ employee.email }}</template>
                    </h6>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-md-12">
              <div>
                <b-tabs content-class="mt-3" v-model="tabIndex">
                  <b-tab active title="Contact Data">
                    <h4>Personal Contact</h4>
                    <div class="card card-body">
                      <div class="row">
                        <div class="col-md-3">
                          <dl>
                            <dt class="font-weight-light text-muted">
                              Firstname
                            </dt>
                            <template v-if="loading">
                              <content-placeholders :rounded="true">
                                <content-placeholders-text :lines="1" />
                              </content-placeholders>
                            </template>
                            <BaseDd :value="employee.firstname" v-else />
                            <dt class="font-weight-light text-muted">
                              Lastname
                            </dt>
                            <template v-if="loading">
                              <content-placeholders :rounded="true">
                                <content-placeholders-text :lines="1" />
                              </content-placeholders>
                            </template>
                            <BaseDd :value="employee.lastname" v-else />
                            <dt class="font-weight-light text-muted">
                              Mobile Number
                            </dt>
                            <template v-if="loading">
                              <content-placeholders :rounded="true">
                                <content-placeholders-text :lines="1" />
                              </content-placeholders>
                            </template>
                            <BaseDd :value="employee.mobile_number" v-else />
                            <dt class="font-weight-light text-muted">
                              NPWP Number
                            </dt>
                            <template v-if="loading">
                              <content-placeholders :rounded="true">
                                <content-placeholders-text :lines="1" />
                              </content-placeholders>
                            </template>
                            <BaseDd :value="employee.npwp" v-else />
                          </dl>
                        </div>
                        <div class="col-md-3">
                          <dl>
                            <dt class="font-weight-light text-muted">
                              Place Of Birth
                            </dt>
                            <template v-if="loading">
                              <content-placeholders :rounded="true">
                                <content-placeholders-text :lines="1" />
                              </content-placeholders>
                            </template>
                            <BaseDd :value="employee.pob" v-else />
                            <dt class="font-weight-light text-muted">
                              Date Of Birth
                            </dt>
                            <template v-if="loading">
                              <content-placeholders :rounded="true">
                                <content-placeholders-text :lines="1" />
                              </content-placeholders>
                            </template>
                            <BaseDd :value="employee.dob | formatDate" v-else />
                            <dt class="font-weight-light text-muted">
                              Mother Maiden Name
                            </dt>
                            <template v-if="loading">
                              <content-placeholders :rounded="true">
                                <content-placeholders-text :lines="1" />
                              </content-placeholders>
                            </template>
                            <BaseDd
                              :value="employee.biological_mother_name"
                              v-else
                            />
                            <dt class="font-weight-light text-muted">
                              Personal Email Address
                            </dt>
                            <template v-if="loading">
                              <content-placeholders :rounded="true">
                                <content-placeholders-text :lines="1" />
                              </content-placeholders>
                            </template>
                            <BaseDd :value="employee.personal_email" v-else />
                          </dl>
                        </div>
                      </div>
                    </div>
                    <h4>Employee History</h4>
                    <div class="row justify-content-end mb-5">
                      <div class="col-md-4">
                        <model-select
                          :options="types"
                          id="history_type"
                          placeholder="select item"
                          v-model="historyModel.type"
                          @input="reloadHistory"
                        ></model-select>
                      </div>
                    </div>
                    <div class="card">
                      <table class="table">
                        <thead>
                          <tr>
                            <th>Date</th>
                            <th>Updated Field</th>
                            <th>Update From</th>
                            <th>Update To</th>
                            <th>Note</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr v-for="history in histories" :key="history.id">
                            <td scope="row">
                              <div class="d-flex align-items-center">
                                <div>
                                  <span
                                    class="text-default font-weight-semibold"
                                  >
                                    <i class="icon-calendar mr-1"></i>
                                    {{ history.created_date | formatDate }}
                                  </span>
                                  <div class="text-muted font-size-sm">
                                    <i class="icon-alarm mr-1"></i>
                                    {{
                                      history.created_date
                                        | formatGetTimeFromDate
                                    }}
                                  </div>
                                </div>
                              </div>
                            </td>
                            <td>
                              {{ history.column_name }}
                            </td>
                            <td>
                              <template v-if="historyModel.type == 'upline'">
                                {{ history.uplineBefore.firstname }} {{ history.uplineBefore.lastname }}
                              </template>
                              <template v-else-if="historyModel.type == 'occupation'">
                                {{ history.occupationBefore.name }}
                              </template>
                              <template v-else>
                                <template v-if="history.column_name == 'bank_id'">
                                  <template v-for="bank in banks">                                    
                                    <template v-if="history.old_value == bank.id">
                                      {{bank.bank_name}} ( {{bank.bank_full_name}} )
                                    </template>
                                  </template>
                                </template>
                                <template v-else-if="history.column_name == 'bank_type_id'">
                                  Tabungan
                                </template>
                                <template v-else>
                                  {{ history.old_value }}
                                </template>
                              </template>
                            </td>
                            <td>
                              <template v-if="historyModel.type == 'upline'">
                                {{ history.uplineAfter.firstname }} {{ history.uplineAfter.lastname }}
                              </template>
                              <template v-else-if="historyModel.type == 'occupation'">
                                {{ history.occupationAfter.name }}
                              </template>
                              <template v-else>
                                <template v-if="history.column_name == 'bank_id'">
                                  <template v-for="bank in banks">                                    
                                    <template v-if="history.new_value == bank.id">
                                      {{bank.bank_name}} ( {{bank.bank_full_name}} )
                                    </template>
                                  </template>
                                </template>
                                <template v-else-if="history.column_name == 'bank_type_id'">
                                  Tabungan
                                </template>
                                <template v-else>
                                  {{ history.new_value }}
                                </template>
                              </template>
                            </td>
                            <td>
                              {{ history.note }}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </b-tab>
                  <b-tab lazy title="HR Data">
                    <div class="card card-body">
                      <div class="row">
                        <div class="col-md-6">
                          <div class="row">
                            <div class="col-md-6">
                              <h4 class="border-bottom">Personal Data</h4>
                              <dl>
                                <dt>First Name</dt>
                                <template v-if="loading">
                                  <content-placeholders :rounded="true">
                                    <content-placeholders-text :lines="1" />
                                  </content-placeholders>
                                </template>
                                <BaseDd :value="employee.firstname" v-else />
                                <dt>Last Name</dt>
                                <template v-if="loading">
                                  <content-placeholders :rounded="true">
                                    <content-placeholders-text :lines="1" />
                                  </content-placeholders>
                                </template>
                                <BaseDd :value="employee.lastname" v-else />
                                <dt>Gender</dt>
                                <template v-if="loading">
                                  <content-placeholders :rounded="true">
                                    <content-placeholders-text :lines="1" />
                                  </content-placeholders>
                                </template>
                                <BaseDd :value="gender" v-else />
                                <dt>Mobile Number</dt>
                                <template v-if="loading">
                                  <content-placeholders :rounded="true">
                                    <content-placeholders-text :lines="1" />
                                  </content-placeholders>
                                </template>
                                <BaseDd
                                  :value="employee.mobile_number"
                                  v-else
                                />
                                <dt>Address</dt>
                                <template v-if="loading">
                                  <content-placeholders :rounded="true">
                                    <content-placeholders-text :lines="1" />
                                  </content-placeholders>
                                </template>
                                <BaseDd :value="employee.address" v-else />
                                <dt>MIFX Email</dt>
                                <template v-if="loading">
                                  <content-placeholders :rounded="true">
                                    <content-placeholders-text :lines="1" />
                                  </content-placeholders>
                                </template>
                                <BaseDd :value="employee.email" v-else />
                                <dt>Personal Email</dt>
                                <template v-if="loading">
                                  <content-placeholders :rounded="true">
                                    <content-placeholders-text :lines="1" />
                                  </content-placeholders>
                                </template>
                                <BaseDd
                                  :value="employee.personal_email"
                                  v-else
                                />
                                <dt>City of Birth</dt>
                                <template v-if="loading">
                                  <content-placeholders :rounded="true">
                                    <content-placeholders-text :lines="1" />
                                  </content-placeholders>
                                </template>
                                <BaseDd :value="employee.pob" v-else />
                                <dt>Date of Birth</dt>
                                <template v-if="loading">
                                  <content-placeholders :rounded="true">
                                    <content-placeholders-text :lines="1" />
                                  </content-placeholders>
                                </template>
                                <BaseDd
                                  :value="employee.dob | formatDate"
                                  v-else
                                />
                                <dt>ID Type</dt>
                                <template v-if="loading">
                                  <content-placeholders :rounded="true">
                                    <content-placeholders-text :lines="1" />
                                  </content-placeholders>
                                </template>
                                <BaseDd :value="idType" v-else />
                                <dt>ID Number</dt>
                                <template v-if="loading">
                                  <content-placeholders :rounded="true">
                                    <content-placeholders-text :lines="1" />
                                  </content-placeholders>
                                </template>
                                <BaseDd
                                  :value="employee.identity_number"
                                  v-else
                                />
                                <dt>BPJS Kesehatan</dt>
                                <template v-if="loading">
                                  <content-placeholders :rounded="true">
                                    <content-placeholders-text :lines="1" />
                                  </content-placeholders>
                                </template>
                                <BaseDd
                                  :value="employee.bpjs_kesehatan"
                                  v-else
                                />
                                <dt>BPJS Ketenagakerjaan</dt>
                                <template v-if="loading">
                                  <content-placeholders :rounded="true">
                                    <content-placeholders-text :lines="1" />
                                  </content-placeholders>
                                </template>
                                <BaseDd
                                  :value="employee.bpjs_ketenagakerjaan"
                                  v-else
                                />
                                <dt>NPWP Number</dt>
                                <template v-if="loading">
                                  <content-placeholders :rounded="true">
                                    <content-placeholders-text :lines="1" />
                                  </content-placeholders>
                                </template>
                                <BaseDd :value="employee.npwp" v-else />
                              </dl>
                            </div>
                            <div class="col-md-6">
                              <h4 class="border-bottom">Company Data</h4>
                              <dl>
                                <dt>NIK</dt>
                                <template v-if="loading">
                                  <content-placeholders :rounded="true">
                                    <content-placeholders-text :lines="1" />
                                  </content-placeholders>
                                </template>
                                <BaseDd :value="employee.employee_no" v-else />
                                <dt>Joined Date</dt>
                                <template v-if="loading">
                                  <content-placeholders :rounded="true">
                                    <content-placeholders-text :lines="1" />
                                  </content-placeholders>
                                </template>
                                <BaseDd
                                  :value="employee.hire_date | formatDate"
                                  v-else
                                />
                                <dt>Branch</dt>
                                <template v-if="loading">
                                  <content-placeholders :rounded="true">
                                    <content-placeholders-text :lines="1" />
                                  </content-placeholders>
                                </template>
                                <BaseDd
                                  :value="
                                    employee.branch
                                      ? employee.branch.name
                                      : null
                                  "
                                  v-else
                                />
                                <dt>Division</dt>
                                <template v-if="loading">
                                  <content-placeholders :rounded="true">
                                    <content-placeholders-text :lines="1" />
                                  </content-placeholders>
                                </template>
                                <BaseDd
                                  :value="
                                    employee.department
                                      ? employee.department.name
                                      : null
                                  "
                                  v-else
                                />
                                <dt>Occupation</dt>
                                <template v-if="loading">
                                  <content-placeholders :rounded="true">
                                    <content-placeholders-text :lines="1" />
                                  </content-placeholders>
                                </template>
                                <BaseDd
                                  :value="
                                    employee.occupation
                                      ? employee.occupation.name
                                      : null
                                  "
                                  v-else
                                />
                                <dt>Upline</dt>
                                <template v-if="loading">
                                  <content-placeholders :rounded="true">
                                    <content-placeholders-text :lines="1" />
                                  </content-placeholders>
                                </template>
                                <BaseDd :value="upline" v-else />
                                <dt>Alias</dt>
                                <template v-if="loading">
                                  <content-placeholders :rounded="true">
                                    <content-placeholders-text :lines="1" />
                                  </content-placeholders>
                                </template>
                                <BaseDd :value="employee.alias_code" v-else />
                                <dt>Commission Scheme</dt>
                                <template v-if="loading">
                                  <content-placeholders :rounded="true">
                                    <content-placeholders-text :lines="1" />
                                  </content-placeholders>
                                </template>
                                <BaseDd
                                  :value="employee.schema_commision"
                                  v-else
                                />
                              </dl>
                              <h4 class="mt-2 border-bottom">Bank Data</h4>
                              <dl>
                                <dt>Bank Name</dt>
                                <template v-if="loading">
                                  <content-placeholders :rounded="true">
                                    <content-placeholders-text :lines="1" />
                                  </content-placeholders>
                                </template>
                                <BaseDd :value="bank" v-else />
                                <dt>Bank Account Owner</dt>
                                <template v-if="loading">
                                  <content-placeholders :rounded="true">
                                    <content-placeholders-text :lines="1" />
                                  </content-placeholders>
                                </template>
                                <BaseDd
                                  :value="employee.bank_account_name"
                                  v-else
                                />
                                <dt>Account Number</dt>
                                <template v-if="loading">
                                  <content-placeholders :rounded="true">
                                    <content-placeholders-text :lines="1" />
                                  </content-placeholders>
                                </template>
                                <BaseDd
                                  :value="employee.bank_account_no"
                                  v-else
                                />
                                <dt>Bank Branch</dt>
                                <template v-if="loading">
                                  <content-placeholders :rounded="true">
                                    <content-placeholders-text :lines="1" />
                                  </content-placeholders>
                                </template>
                                <BaseDd :value="employee.bank_branch" v-else />
                              </dl>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </b-tab>
                  <b-tab lazy title="Task">
                    <h4>
                      My Request
                      <router-link
                        :to="`/employees/${employee.id}/request-tasks`"
                        class="float-right mr-2 text-green-700 font-weight-bold"
                        >View All &raquo;
                      </router-link>
                    </h4>
                    <task-table
                      :route="$route"
                      :type="'employee-request-task-list-view'"
                    />
                    <h4>
                      My Task
                      <router-link
                        :to="`/employees/${employee.id}/tasks`"
                        class="float-right mr-2 text-green-700 font-weight-bold"
                        >View All &raquo;
                      </router-link>
                    </h4>
                    <task-table
                      :route="$route"
                      :type="'employee-task-list-view'"
                    />
                    <h4>
                      My Approvals
                      <router-link
                        :to="`/employees/${employee.id}/approval-tasks`"
                        class="float-right mr-2 text-green-700 font-weight-bold"
                        >View All &raquo;
                      </router-link>
                    </h4>
                    <task-table
                      :route="$route"
                      :type="'employee-approval-task-list-view'"
                    />
                  </b-tab>
                  <b-tab
                    lazy
                    title="Structure"
                    v-if="
                      typeof employee.department !== 'undefined' &&
                        employee.department &&
                        employee.department.is_sales
                    "
                  >
                    <div class="card">
                      <div class="card-body" v-if="loading">
                        <content-placeholders>
                          <content-placeholders-heading :img="true" />
                          <content-placeholders-text :lines="3" />
                        </content-placeholders>
                      </div>
                    </div>
                  </b-tab>
                  <template v-if="false">
                    <b-tab lazy title="Referral">
                      <div class="card card-body">
                        <content-placeholders>
                          <content-placeholders-heading :img="true" />
                          <content-placeholders-text :lines="3" />
                        </content-placeholders>
                      </div>
                    </b-tab>
                    <b-tab lazy title="Live List">
                      <div class="card ">
                        <table class="table table-striped">
                          <thead>
                            <tr>
                              <th class="text-muted font-weight-bold">
                                Live ID
                              </th>
                              <th class="text-muted font-weight-bold">
                                Account
                              </th>
                              <th class="text-muted font-weight-bold">
                                Client Name
                              </th>
                              <th class="text-muted font-weight-bold">
                                Client Email
                              </th>
                              <th class="text-muted font-weight-bold">
                                Total Lots
                              </th>
                              <th class="text-muted font-weight-bold"></th>
                            </tr>
                          </thead>
                          <tbody>
                            <template v-if="liveLoading">
                              <tr :key="a" v-for="a in 5">
                                <td :key="b" v-for="b in 6">
                                  <content-placeholders :rounded="true">
                                    <content-placeholders-text :lines="1" />
                                  </content-placeholders>
                                </td>
                              </tr>
                            </template>
                            <tr v-if="!liveLoading && lives.length == 0">
                              <td colspan="6">No Data</td>
                            </tr>
                            <template v-if="!liveLoading && lives.length > 0">
                              <tr :key="live.id" v-for="live in lives">
                                <td>{{ live.id }}</td>
                                <td>{{ live.account }}</td>
                                <td>
                                  {{
                                    live.client_first_name +
                                      ' ' +
                                      live.client_last_name
                                  }}
                                </td>
                                <td>{{ live.client_email }}</td>
                                <td>{{ live.total_lot }}</td>
                                <td></td>
                              </tr>
                            </template>
                          </tbody>
                        </table>
                      </div>
                    </b-tab>
                    <b-tab lazy title="Sales Area">
                      <div class="card card-body">
                        <dl>
                          <template v-for="(link, index) in salesLink">
                            <dt :key="index">{{ link.label }}</dt>
                            <template v-if="saleLoading">
                              <content-placeholders
                                :rounded="true"
                                :key="index"
                              >
                                <content-placeholders-text :lines="1" />
                              </content-placeholders>
                            </template>
                            <BaseDd :value="link.url" v-else :key="index" />
                          </template>
                        </dl>
                      </div>
                    </b-tab>
                  </template>
                </b-tabs>
              </div>
            </div>
          </div>
        </div>
        <div
          class="content d-flex justify-content-center align-items-center"
          v-else
        >
          <div class="flex-fill">
            <!-- Error title -->
            <div class="text-center mb-3">
              <h1 class="error-title">401</h1>
              <h5>Oops, You do not have enough power to access this page</h5>
            </div>
            <!-- /error title -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import EmployeeApi from '../apis/Employee'
import DataEnumDetail from '../apis/EnumDetail'
import BackofficeSalesApi from '../apis/BackofficeSales'
import BankApi from '../apis/Bank'
import router from '../routes'
import Hrms from '../services/hrms'
import Master from '../services/master'
import PermissionMixin from '../mixins/PermissionMixin'
import { ModelSelect } from 'vue-search-select'

export default {
  components: {
    TaskTable: () =>
      import(
        /* webpackChunkName: "component-task-table" */
        /* webpackPrefetch: true */
        /* webpackMode: "eager" */
        '../components/TableTask'
      ),
    Header: () =>
      import(
        /* webpackChunkName: "component-header" */
        /* webpackPrefetch: true */
        /* webpackMode: "eager" */
        '../components/Header'
      ),
    Sidebar: () =>
      import(
        /* webpackChunkName: "component-sidebar" */
        /* webpackPrefetch: true */

        /* webpackMode: "eager" */
        '../components/Sidebar'
      ),
    BaseDd: () =>
      import(
        /* webpackChunkName: "component-base-defines-description" */
        /* webpackPrefetch: true */
        /* webpackMode: "eager" */
        '../components/defines-description'
      ),
    ModelSelect
  },
  mixins: [PermissionMixin],
  data() {
    return {
      employee: [],
      loading: true,
      liveLoading: true,
      salesLoading: true,
      saleLoading: true,
      gender: '-',
      upline: '',
      idType: '-',
      bank: '-',
      tabIndex: 0,
      localEmployee: JSON.parse(localStorage.getItem('hr_employee')),
      lives: [],
      salesLink: [],
      sales: [],
      histories: [],
      banks: [],
      types:[
        {
          value:'bank',
          text:'Bank'
        },
        {
          value:'nik',
          text:'NIK'
        },
        {
          value:'upline',
          text:'Upline'
        },
        {
          value:'phone',
          text:'Phone'
        },
        {
          value:'occupation',
          text:'Occupation'
        },
        {
          value:'name',
          text:'Name'
        }
      ],
      historyModel:{
        type:'bank'
      }
    }
  },
  methods: {
    salesLiveAccountList(params) {
      BackofficeSalesApi.SalesLiveAccountList(params)
        .then(res => {
          this.lives = res.data.data
          this.liveLoading = false
        })
        .catch()
    },
    salesView(params) {
      BackofficeSalesApi.salesView(params)
        .then(res => {
          this.sales = res.data.data
          this.saleLoading = false
        })
        .catch()
    },
    salesAliasLink(params) {
      BackofficeSalesApi.salesAliasLink(params)
        .then(res => {
          this.salesLink = res.data.data
          this.salesLoading = false
        })
        .catch()
    },
    bankList(){
      Master.interceptors.response.use(
        response => response,
        error => {
          if (typeof error.response === 'undefined') {
            this.$swal(
              'Ooops!',
              'A network error occurred. ' +
                'This could be a CORS issue or a dropped internet connection. ' +
                'It is not possible for us to know.',
              'error'
            )
          }
          return Promise.reject(error)
        }
      )
      BankApi.list()
        .then(res => {
          this.banks = res.data.data
        })
        .catch()
    },
    bankView(id) {
      Master.interceptors.response.use(
        response => response,
        error => {
          if (typeof error.response === 'undefined') {
            this.$swal(
              'Ooops!',
              'A network error occurred. ' +
                'This could be a CORS issue or a dropped internet connection. ' +
                'It is not possible for us to know.',
              'error'
            )
          }
          return Promise.reject(error)
        }
      )
      BankApi.view(id)
        .then(res => {
          this.bank =
            res.data.data.bank_name + ' (' + res.data.data.bank_full_name + ') '
        })
        .catch()
    },
    genderView(id) {
      Master.interceptors.response.use(
        response => response,
        error => {
          if (typeof error.response === 'undefined') {
            this.$swal(
              'Ooops!',
              'A network error occurred. ' +
                'This could be a CORS issue or a dropped internet connection. ' +
                'It is not possible for us to know.',
              'error'
            )
          }
          return Promise.reject(error)
        }
      )
      if (typeof id !== 'undefined' && id) {
        DataEnumDetail.findByRuleNameValue('GENDER_TYPE', id)
          .then(res => {
            this.gender = res.data.data.rule_description
          })
          .catch()
      } else {
        this.gender = '-'
      }
    },
    idTyView(id) {
      Master.interceptors.response.use(
        response => response,
        error => {
          if (typeof error.response === 'undefined') {
            this.$swal(
              'Ooops!',
              'A network error occurred. ' +
                'This could be a CORS issue or a dropped internet connection. ' +
                'It is not possible for us to know.',
              'error'
            )
          }
          return Promise.reject(error)
        }
      )
      DataEnumDetail.findByRuleNameValue('PROFILE_IDENTITY_TYPE', id)
        .then(res => {
          this.idType = res.data.data.rule_description
        })
        .catch()
    },
    viewEmployee(id) {
      Hrms.interceptors.response.use(
        response => response,
        error => {
          if (typeof error.response === 'undefined') {
            this.$swal(
              'Ooops!',
              'A network error occurred. ' +
                'This could be a CORS issue or a dropped internet connection. ' +
                'It is not possible for us to know.',
              'error'
            )
          }
          return Promise.reject(error)
        }
      )
      EmployeeApi.view(id)
        .then(res => {
          this.employee = res.data.data
          if (res.data.data.occupation == null) {
            console.log('null cok')
            this.employee.occupation = {
              name: '-'
            }
          }
          if (
            typeof res.data.data.identity_type_id !== 'undefined' &&
            res.data.data.identity_type_id
          ) {
            this.idTyView(res.data.data.identity_type_id)
          }
          this.genderView(res.data.data.gender_id)
          if (
            typeof res.data.data.upline_id !== 'undefined' &&
            res.data.data.upline_id
          ) {
            this.viewUpline(res.data.data.upline_id)
          }

          if (
            typeof res.data.data.bank_id !== 'undefined' &&
            res.data.data.bank_id
          ) {
            this.bankView(res.data.data.bank_id)
          }
          document.title =
            res.data.data.firstname + ' ' + res.data.data.lastname
          this.salesLiveAccountList({ sales_id: res.data.data.user_id })
          this.salesAliasLink({ sales_id: res.data.data.user_id })
          this.salesView({ id: res.data.data.user_id })
          this.loading = false
        })
        .catch(error => {
          console.log(error.response)
          this.$swal('Ooops!', error.response.data.message, 'error').then(a => {
            router.go(-1)
          })
        })
    },
    viewUpline(id) {
      EmployeeApi.view(id)
        .then(res => {
          this.upline = res.data.data.firstname + ' ' + res.data.data.lastname
        })
        .catch()
    },
    history(id, query) {
      Hrms.interceptors.response.use(
        response => response,
        error => {
          if (typeof error.response === 'undefined') {
            this.$swal(
              'Ooops!',
              'A network error occurred. ' +
                'This could be a CORS issue or a dropped internet connection. ' +
                'It is not possible for us to know.',
              'error'
            )
          }
          return Promise.reject(error)
        }
      )

      EmployeeApi.history(id, query)
        .then(res => {
          this.histories = res.data.data
          console.log(res)
        })
        .catch()
    },
    reloadHistory(){
      this.history(this.$route.params.id,{type:this.historyModel.type})
    }
  },
  mounted() {
    this.bankList()
    this.viewEmployee(this.$route.params.id)
    this.history(this.$route.params.id)
    if (this.localEmployee.id !== this.$route.params.id) {
      if (
        !this.isHavePermission('GET:employee.default.can-see-other-profile')
      ) {
        router.push('/forbiden')
      }
    }
  },
  beforeRouteUpdate(to, from, next) {
    this.loading = true
    this.liveLoading = true
    this.viewEmployee(to.params.id)
    this.history(to.params.id)
    this.tabIndex = 0
    next()
  }
}
</script>

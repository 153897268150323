<template>
  <div>
    <Header>
      <template v-slot:nav-item-left>
        <li class="nav-item">
          <router-link
            class="navbar-nav-link"
            to="/budget-request"
            :class="{
              active: $route.name === 'budget-request'
            }"
          >
            My Request
          </router-link>
        </li>
        <li class="nav-item">
          <router-link
            class="navbar-nav-link"
            to="/budget-request-my-approval"
            :class="{
              active: $route.name === 'budget-request-my-approval'
            }"
          >
            My Approval
          </router-link>
        </li>
        <li class="nav-item">
          <router-link
            class="navbar-nav-link"
            to="/budget-request-all"
            :class="{
              active: $route.name === 'budget-request-all'
            }"
          >
            All Request
          </router-link>
        </li>
      </template>
    </Header>
    <div class="page-content">
      <Sidebar />
      <div class="content-wrapper">
        <div class="content">
          <div class="row">
            <div class="col-md-12">
              <div class="row justify-content-end mb-3">
                <div class="col-md-2">
                  <router-link
                    class="btn bg-green-600 btn-block rounded-round btn-labeled btn-labeled-left"
                    to="/budget-request-create"
                  >
                    <b>
                      <i class="icon-user-plus"></i>
                    </b>
                    Request Budget
                  </router-link>
                </div>
              </div>
              <div class="card">
                <div class="card-body">
                  <div class="row">
                    <div class="col-md-2">
                      <div class="form-group">
                        <label for="filter_request_by">Request By</label>
                        <input
                          id="filter_request_by"
                          type="text"
                          class="form-control"
                          v-model="filter['search[filter_request_by]']"
                          name="filter_request_by"
                        />
                      </div>
                    </div>
                    <div class="col-md-2">
                      <div class="form-group">
                        <label for="filter_budget_name">Budget Title</label>
                        <input
                          id="filter_budget_name"
                          type="text"
                          class="form-control"
                          v-model="filter['search[filter_budget_name]']"
                          name="filter_budget_name"
                        />
                      </div>
                    </div>
                    <div class="col-md-2">
                      <div class="form-group">
                        <label for="filter_transaction_type_id">Status</label>
                        <select
                          id="filter_transaction_type_id"
                          class="form-control"
                          v-model="filter['search[filter_transaction_type_id]']"
                          name="filter_transaction_type_id"
                        >
                          <option value="all">All</option>
                          <option
                            :value="status.rule_value"
                            v-for="status in budget_status"
                            :key="status.id"
                            >{{ status.rule_description }}</option
                          >
                        </select>
                      </div>
                    </div>
                    <div class="col-md-2">
                      <div class="form-group">
                        <label for="filter_status_paid_id">Paid Status</label>
                        <select
                          id="filter_status_paid_id"
                          class="form-control"
                          v-model="filter['search[filter_status_paid_id]']"
                          name="filter_status_paid_id"
                        >
                          <option value="all">All</option>
                          <option
                            :value="stat.rule_value"
                            v-for="stat in budget_status_paid"
                            :key="stat.id"
                            >{{ stat.rule_description }}</option
                          >
                        </select>
                      </div>
                    </div>
                    <div class="col-md-2">
                      <div class="form-group">
                        <label for="filter_budget_type">Amount Type</label>
                        <select
                          class="form-control"
                          v-model="filter['search[filter_budget_type]']"
                          name="filter_budget_type"
                          id="filter_budget_type"
                        >
                          <option value="all">All</option>
                          <option value="<"><</option>
                          <option value="=">=</option>
                          <option value=">">></option>
                        </select>
                      </div>
                    </div>
                    <div class="col-md-2">
                      <div class="form-group">
                        <label for="filter_budget_amount">Amount</label>
                        <input
                          id="filter_budget_amount"
                          type="text"
                          class="form-control"
                          v-model="filter['search[filter_budget_amount]']"
                          name="filter_budget_amount"
                        />
                      </div>
                    </div>
                  </div>
                  <div class="row justify-content-end">
                    <div class="col-md-2">
                      <button
                        class="btn bg-green-600 btn-block rounded-round btn-labeled btn-labeled-left"
                        @click="filterResults"
                      >
                        <b>
                          <i class="icon-filter3"></i>
                        </b>
                        Filter Result
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-12">
              <div class="card">
                <div class="table-responsive">
                  <table class="table table-bordered">
                    <thead>
                      <tr>
                        <th>Request Date</th>
                        <th>Request By</th>
                        <th>Budget Title</th>
                        <th>Amount</th>
                        <th>Due Date</th>
                        <th>Status</th>
                        <th>Paid Status</th>
                        <th>Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      <template v-if="loading">
                        <tr :key="a" v-for="a in 12">
                          <td :key="b" v-for="b in 8">
                            <content-placeholders :rounded="true">
                              <content-placeholders-text :lines="1" />
                            </content-placeholders>
                          </td>
                        </tr>
                      </template>

                      <template v-else>
                        <template v-if="datas.length === 0">
                          <tr>
                            <td colspan="8">No Data</td>
                          </tr>
                        </template>
                        <template v-else>
                          <tr :key="data.id" v-for="data in datas">
                            <td scope="row">
                              <span class="d-block">
                                <i class="fa fa-calendar mr-2"></i>
                                {{ data.created_date | formatDate }}
                              </span>
                              <span>
                                <i class="fa fa-clock mr-2"></i>
                                {{ data.created_date | formatGetTimeFromDate }}
                              </span>
                            </td>
                            <td>
                              {{ data.request_by_first_name }}
                            </td>
                            <td>
                              {{ data.name }}
                            </td>
                            <td>
                              {{
                                new Intl.NumberFormat('id-ID', {}).format(
                                  data.amount
                                )
                              }}
                            </td>
                            <td>
                              <span class="d-block">
                                <i class="fa fa-calendar mr-2"></i>
                                {{ data.due_date | formatDate }}
                              </span>
                            </td>
                            <td>
                              {{ data.status_name }}
                            </td>
                            <td>
                              {{ data.paid_status_name }}
                            </td>

                            <td>
                              <div class="list-icons">
                                <div class="list-icons-item dropdown">
                                  <a
                                    class="list-icons-item dropdown-toggle caret-0"
                                    data-toggle="dropdown"
                                    href="javascript:void(0)"
                                  >
                                    <i
                                      class="fas fa-ellipsis-v text-green-700 fa-2x"
                                    ></i>
                                  </a>
                                  <div
                                    class="dropdown-menu dropdown-menu-right"
                                  >
                                    <router-link
                                      :to="`/budget-request-detail/${data.id}`"
                                      class="dropdown-item"
                                    >
                                      <i class="icon-file-stats"></i>
                                      See Details
                                    </router-link>
                                  </div>
                                </div>
                              </div>
                            </td>
                          </tr>
                        </template>
                      </template>
                    </tbody>
                  </table>
                </div>
              </div>

              <paginate
                :click-handler="clickCallback"
                :container-class="
                  'pagination pagination-separated pagination-rounded align-self-center'
                "
                :margin-pages="5"
                :next-class="'page-item'"
                :next-link-class="'page-link'"
                :next-text="'Next'"
                :page-class="'page-item'"
                :page-count="pagination.totalPage"
                :page-link-class="'page-link'"
                :page-range="5"
                :prev-class="'page-item'"
                :prev-link-class="'page-link'"
                :prev-text="'Prev'"
              ></paginate>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import BackofficeApi from '../apis/Backoffice'
import DataEnumDetail from '../apis/EnumDetail'
import moment from 'moment'
import Paginate from '../components/Paginate'
export default {
  data() {
    return {
      loading: true,
      datas: [],
      pagination: {
        totalPage: 0,
        currentPage: 1,
        totalData: 0,
        displaying: 0
      },
      budget_status_paid: [],
      budget_status: [],
      filter: {
        'search[filter_request_by]': null,
        'search[filter_budget_name]': null,
        'search[filter_transaction_type_id]': 'all',
        'search[filter_status_paid_id]': 'all',
        'search[filter_budget_type]': 'all',
        'search[filter_budget_amount]': null
      }
    }
  },
  components: {
    Header: () =>
      import(
        /* webpackChunkName: "component-header" */
        /* webpackPrefetch: true */
        /* webpackMode: "eager" */
        '../components/Header'
      ),
    Sidebar: () =>
      import(
        /* webpackChunkName: "component-sidebar" */
        /* webpackPrefetch: true */

        /* webpackMode: "eager" */
        '../components/Sidebar'
      ),
    Paginate
  },
  methods: {
    clickCallback(e) {
      this.loading = true
      let search = ''
      if (this.filter['search[filter_request_by]']) {
        search =
          search +
          'search[filter_request_by]=' +
          this.filter['search[filter_request_by]']
      }
      if (this.filter['search[filter_budget_name]']) {
        search =
          search +
          '&search[filter_budget_name]=' +
          this.filter['search[filter_budget_name]']
      }
      if (
        this.filter['search[filter_transaction_type_id]'] &&
        this.filter['search[filter_transaction_type_id]'] != 'all'
      ) {
        search =
          search +
          '&search[filter_transaction_type_id]=' +
          this.filter['search[filter_transaction_type_id]']
      }
      if (
        this.filter['search[filter_status_paid_id]'] &&
        this.filter['search[filter_status_paid_id]'] != 'all'
      ) {
        search =
          search +
          '&search[filter_status_paid_id]=' +
          this.filter['search[filter_status_paid_id]']
      }
      if (
        this.filter['search[filter_budget_type]'] &&
        this.filter['search[filter_budget_type]'] != 'all'
      ) {
        search =
          search +
          '&search[filter_budget_type]=' +
          this.filter['search[filter_budget_type]']
      }
      if (this.filter['search[filter_budget_amount]']) {
        search =
          search +
          '&search[filter_budget_amount]=' +
          this.filter['search[filter_budget_amount]']
      }
      search = search + '&page=' + e
      this.allBudgetRequest(search)
    },
    enum(name, target) {
      DataEnumDetail.findByRuleName(name)
        .then(res => {
          this[target] = res.data.data
          console.log('target', this[target])
        })
        .catch()
    },
    filterResults() {
      this.loading = true
      console.log(this.filter)
      let search = ''
      if (this.filter['search[filter_request_by]']) {
        search =
          search +
          'search[filter_request_by]=' +
          this.filter['search[filter_request_by]']
      }
      if (this.filter['search[filter_budget_name]']) {
        search =
          search +
          '&search[filter_budget_name]=' +
          this.filter['search[filter_budget_name]']
      }
      if (
        this.filter['search[filter_transaction_type_id]'] &&
        this.filter['search[filter_transaction_type_id]'] != 'all'
      ) {
        search =
          search +
          '&search[filter_transaction_type_id]=' +
          this.filter['search[filter_transaction_type_id]']
      }
      if (
        this.filter['search[filter_status_paid_id]'] &&
        this.filter['search[filter_status_paid_id]'] != 'all'
      ) {
        search =
          search +
          '&search[filter_status_paid_id]=' +
          this.filter['search[filter_status_paid_id]']
      }
      if (
        this.filter['search[filter_budget_type]'] &&
        this.filter['search[filter_budget_type]'] != 'all'
      ) {
        search =
          search +
          '&search[filter_budget_type]=' +
          this.filter['search[filter_budget_type]']
      }
      if (this.filter['search[filter_budget_amount]']) {
        search =
          search +
          '&search[filter_budget_amount]=' +
          this.filter['search[filter_budget_amount]']
      }
      search = search + '&page=1'
      this.allBudgetRequest(search)
    },
    filterRequest() {
      let search = ''
      if (this.filter['search[filter_request_by]']) {
        search =
          search +
          'search[filter_request_by]=' +
          this.filter['search[filter_request_by]']
      }
      if (this.filter['search[filter_budget_name]']) {
        search =
          search +
          '&search[filter_budget_name]=' +
          this.filter['search[filter_budget_name]']
      }
      if (
        this.filter['search[filter_transaction_type_id]'] &&
        this.filter['search[filter_transaction_type_id]'] != 'all'
      ) {
        search =
          search +
          '&search[filter_transaction_type_id]=' +
          this.filter['search[filter_transaction_type_id]']
      }
      if (
        this.filter['search[filter_status_paid_id]'] &&
        this.filter['search[filter_status_paid_id]'] != 'all'
      ) {
        search =
          search +
          '&search[filter_status_paid_id]=' +
          this.filter['search[filter_status_paid_id]']
      }
      if (
        this.filter['search[filter_budget_type]'] &&
        this.filter['search[filter_budget_type]'] != 'all'
      ) {
        search =
          search +
          '&search[filter_budget_type]=' +
          this.filter['search[filter_budget_type]']
      }
      if (this.filter['search[filter_budget_amount]']) {
        search =
          search +
          '&search[filter_budget_amount]=' +
          this.filter['search[filter_budget_amount]']
      }
      search = search + '&page=1'
      console.log('filter', this.filter)
      return search
    },
    allBudgetRequest(query) {
      BackofficeApi.allBudgetRequest(query)
        .then(res => {
          console.log('query', query)
          console.log('data', res.data)
          this.pagination.totalPage = Math.ceil(
            parseInt(res.data.totalCount) / parseInt(res.data.size)
          )
          this.pagination.currentPage = res.data.page
          this.pagination.totalData = res.data.totalCount
          this.datas = res.data.data
          this.loading = false
        })
        .catch()
    }
  },
  mounted() {
    document.title = 'All Budget Request'
    this.allBudgetRequest({})
    this.enum('BUDGET_STATUS', 'budget_status')
    this.enum('BUDGET_PAID_STATUS', 'budget_status_paid')
  }
}
</script>

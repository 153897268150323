import axios from '../services/hrms'

const resource = '/roleandpermission'
export default {
	RoleList(query) {
		let token = JSON.parse(localStorage.getItem('hr_token')).access_token
		const params = new URLSearchParams(query)
		return axios.get(`${ resource }/role?${ params }`, {
			headers: {
				'x-access-token': `${ token }`
			}
		})
	},
	PermissionList(query) {
		let token = JSON.parse(localStorage.getItem('hr_token')).access_token
		const params = new URLSearchParams(query)
		return axios.get(`${ resource }/permission/search?${ params }`, {
			headers: {
				'x-access-token': `${ token }`
			}
		})
	},
	RoleView(id) {
		let token = JSON.parse(localStorage.getItem('hr_token')).access_token
		return axios.get(`${ resource }/role/${ id }?expand=users,permissions`, {
			headers: {
				'x-access-token': `${ token }`
			}
		})
	},
	RoleStore(payload) {
		let token = JSON.parse(localStorage.getItem('hr_token')).access_token
		return axios.post(`${ resource }/role/store`, payload, {
			headers: {
				'x-access-token': `${ token }`
			}
		})
	},
	PermissionStore(payload) {
		let token = JSON.parse(localStorage.getItem('hr_token')).access_token
		return axios.post(`${ resource }/permission/store`, payload, {
			headers: {
				'x-access-token': `${ token }`
			}
		})
	},
	RoleAssignUser(payload) {
		let token = JSON.parse(localStorage.getItem('hr_token')).access_token
		return axios.post(`${ resource }/role/assign-user`, payload, {
			headers: {
				'x-access-token': `${ token }`
			}
		})
	},
	RoleRevokeUser(payload) {
		let token = JSON.parse(localStorage.getItem('hr_token')).access_token
		return axios.post(`${ resource }/role/revoke-user`, payload, {
			headers: {
				'x-access-token': `${ token }`
			}
		})
	},
	PermissionAssignRole(payload) {
		let token = JSON.parse(localStorage.getItem('hr_token')).access_token
		return axios.post(`${ resource }/permission/assign-role`, payload, {
			headers: {
				'x-access-token': `${ token }`
			}
		})
	},
	PermissionRevokeRole(payload) {
		let token = JSON.parse(localStorage.getItem('hr_token')).access_token
		return axios.post(`${ resource }/permission/revoke-role`, payload, {
			headers: {
				'x-access-token': `${ token }`
			}
		})
	}
}

const reward = [{
    path: '/reward/best-fm',
    name: 'page-best-fm',
    component: () =>
        import (
            /* webpackChunkName: "page-best-fm" */
            /* webpackPrefetch: true */
            /* webpackMode: "eager" */
            '../views/Reward.vue'
        ),
    meta: {
        requiresAuth: true,
        permission: 'GET:employee.reward.best-fm'
    }
},{
    path: '/reward/best-rm',
    name: 'page-best-rm',
    component: () =>
        import (
            /* webpackChunkName: "page-best-rm" */
            /* webpackPrefetch: true */
            /* webpackMode: "eager" */
            '../views/RewardRM.vue'
            ),
    meta: {
        requiresAuth: true,
        permission: 'GET:employee.reward.best-fm'
    }
},{
    path: '/reward/best-cfm',
    name: 'page-best-cfm',
    component: () =>
        import (
            /* webpackChunkName: "page-best-cfm" */
            /* webpackPrefetch: true */
            /* webpackMode: "eager" */
            '../views/RewardCFM.vue'
            ),
    meta: {
        requiresAuth: true,
        permission: 'GET:employee.reward.best-fm'
    }
},{
    path: '/reward/best-crm',
    name: 'page-best-crm',
    component: () =>
        import (
            /* webpackChunkName: "page-best-crm" */
            /* webpackPrefetch: true */
            /* webpackMode: "eager" */
            '../views/RewardCRM.vue'
            ),
    meta: {
        requiresAuth: true,
        permission: 'GET:employee.reward.best-fm'
    }
},{
    path: '/reward/best-growth',
    name: 'page-best-growth',
    component: () =>
        import (
            /* webpackChunkName: "page-best-growth" */
            /* webpackPrefetch: true */
            /* webpackMode: "eager" */
            '../views/RewardGrowth.vue'
            ),
    meta: {
        requiresAuth: true,
        permission: 'GET:employee.reward.best-fm'
    }
},{
    path: '/reward/best-kpi',
    name: 'page-best-kpi',
    component: () =>
        import (
            /* webpackChunkName: "page-best-kpi" */
            /* webpackPrefetch: true */
            /* webpackMode: "eager" */
            '../views/RewardKPI.vue'
            ),
    meta: {
        requiresAuth: true,
        permission: 'GET:employee.reward.best-fm'
    }
},{
    path: '/reward/best-total-performance',
    name: 'page-best-best-total-performance',
    component: () =>
        import (
            /* webpackChunkName: "page-best-best-total-performance" */
            /* webpackPrefetch: true */
            /* webpackMode: "eager" */
            '../views/RewardBestTotalPerformance.vue'
            ),
    meta: {
        requiresAuth: true,
        permission: 'GET:employee.reward.best-fm'
    }
}]
export default reward
import axios from '../services/report'

const resource = '/report/achievement'
export default {
	achievementBranchBonusByDateRange(from_date, to_date) {
		let token = JSON.parse(localStorage.getItem('hr_token')).access_token
		return axios.get(`${ resource }/achievement-branch-bonus-by-date-range?from_date=${ from_date }&to_date=${ to_date }`, {
			headers: {
				'x-access-token': `${ token }`
			}
		})
	},
	achievementBranchBonusDetailByDateRange(branch_id, from_date, to_date) {
		let token = JSON.parse(localStorage.getItem('hr_token')).access_token
		return axios.get(`${ resource }/achievement-branch-bonus-detail-by-date-range?branch_id=${ branch_id }&from_date=${ from_date }&to_date=${ to_date }`, {
			headers: {
				'x-access-token': `${ token }`
			}
		})
	},
	achievementBranchByDateRange(branch_id, from_date, to_date) {
		let token = JSON.parse(localStorage.getItem('hr_token')).access_token
		return axios.get(`${ resource }/achievement-branch-by-date-range?branch_id=${ branch_id }&from_date=${ from_date }&to_date=${ to_date }`, {
			headers: {
				'x-access-token': `${ token }`
			}
		})
	},
	achievementBranchDetailByDateRange(branch_id, from_date, to_date) {
		let token = JSON.parse(localStorage.getItem('hr_token')).access_token
		return axios.get(`${ resource }/achievement-branch-detail-by-date-range?branch_id=${ branch_id }&from_date=${ from_date }&to_date=${ to_date }`, {
			headers: {
				'x-access-token': `${ token }`
			}
		})
	}
}
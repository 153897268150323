const backofficeAccess = [{
    path: '/backoffice-access',
    name: 'backoffice-access-view',
    component: () =>
        import (
            /* webpackChunkName: "backoffice-access-view" */
            /* webpackPrefetch: true */
            /* webpackMode: "eager" */
            '../views/BackofficeAccess.vue'
        ),
    meta: {
        requiresAuth: true,
        permission: 'GET:employee.default.backoffice-access-view'
    }
}, {
    path: '/backoffice-access/:id',
    name: 'backoffice-access-detail-view',
    component: () =>
        import (
            /* webpackChunkName: "backoffice-access-view" */
            /* webpackPrefetch: true */
            /* webpackMode: "eager" */
            '../views/BackofficeAccessDetail.vue'
        ),
    meta: {
        requiresAuth: true,
        permission: 'GET:employee.default.backoffice-access-detail-view'
    }
}]
export default backofficeAccess
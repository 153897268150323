const referralBankAccount = [{
    path: '/referral-bank-account',
    name: 'referral-bank-account',
    component: () =>
        import (
            /* webpackChunkName: "referral-bank-account" */
            /* webpackPrefetch: true */
            /* webpackMode: "eager" */
            '../views/ReferralBankAccount'
            ),
    meta: {
        requiresAuth: true,
        permission: 'GET:employee.default.employee-management-menu'
    }
},{
    path: '/sales-bank-account',
    name: 'sales-bank-account',
    component: () =>
        import (
            /* webpackChunkName: "sales-bank-account" */
            /* webpackPrefetch: true */
            /* webpackMode: "eager" */
            '../views/SalesBankAccount'
            ),
    meta: {
        requiresAuth: true,
        permission: 'GET:employee.default.employee-management-menu'
    }
}]
export default referralBankAccount
<template>
  <div>
    <Header>
      <template v-slot:nav-item-left>
        <li class="nav-item">
          <router-link
            class="navbar-nav-link"
            to="/budget-request"
            :class="{
              active: $route.name === 'budget-request'
            }"
          >
            My Request
          </router-link>
        </li>
        <li class="nav-item">
          <router-link
            class="navbar-nav-link"
            to="/budget-request-my-approval"
            :class="{
              active: $route.name === 'budget-request-my-approval'
            }"
          >
            My Approval
          </router-link>
        </li>
        <li class="nav-item">
          <router-link
            class="navbar-nav-link"
            to="/budget-request-all"
            :class="{
              active: $route.name === 'budget-request-all'
            }"
          >
            All Request
          </router-link>
        </li>
      </template>
    </Header>
    <div class="page-content">
      <Sidebar />
      <div class="content-wrapper">
        <div class="content">
          <div
            class="row justify-content-end mb-3"
            v-if="details.approval_user_id == user_id"
          >
            <div class="col-md-3">
              <div class="row">
                <div class="col-md-6">
                  <button
                    @click="modalReject"
                    type="button"
                    class="btn bg-danger-400 btn-labeled btn-labeled-left rounded-round btn-block"
                  >
                    <b><i class="fa fa-times"></i></b> Reject
                  </button>
                </div>
                <div class="col-md-6">
                  <button
                    @click="modalApprove"
                    type="button"
                    class="btn bg-success-400 btn-labeled btn-labeled-left rounded-round btn-block"
                  >
                    <b><i class="fa fa-check"></i></b> Approve
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-2">
              <div class="card card-body">
                <div class="media">
                  <div class="mr-3">
                    <a href="#"><i class="fa fa-handshake fa-3x"></i></a>
                  </div>
                  <div class="media-body">
                    <span class="text-muted mb-0">Status</span>
                    <h6
                      :class="
                        `${
                          text[details.status_name.toLowerCase()]
                        } font-weight-bold mb-0 mt-1`
                      "
                    >
                      {{ details.status_name }}
                    </h6>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-2">
              <div class="card card-body">
                <div class="media">
                  <div class="mr-3">
                    <a href="#"
                      ><i class="fa fa-file-invoice-dollar fa-3x"></i
                    ></a>
                  </div>
                  <div class="media-body">
                    <span class="text-muted mb-0">Paid Status</span>
                    <h6 class="mb-0 mt-1">{{ details.paid_status_name }}</h6>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-2">
              <div class="card card-body">
                <div class="media">
                  <div class="mr-3">
                    <a href="#"><i class="fa fa-user-circle fa-3x"></i></a>
                  </div>
                  <div class="media-body">
                    <span class="text-muted mb-0">Request By</span>
                    <h6 class="mb-0 mt-1">
                      {{ details.request_by_first_name }}
                    </h6>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-2">
              <div class="card card-body">
                <div class="media">
                  <div class="mr-3">
                    <a href="#"><i class="fa fa-calendar-alt fa-3x"></i></a>
                  </div>
                  <div class="media-body">
                    <span class="text-muted mb-0">Request Date</span>
                    <h6 class="mb-0 mt-1">{{ details.date | formatDate }}</h6>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-2">
              <div class="card card-body">
                <div class="media">
                  <div class="mr-3">
                    <a href="#"
                      ><i class="fa fa-calendar-times fa-3x text-danger"></i
                    ></a>
                  </div>
                  <div class="media-body">
                    <span class="text-muted mb-0">Budget Due Date</span>
                    <h6 class="mb-0 mt-1">
                      {{ details.due_date | formatDate }}
                    </h6>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-2">
              <div class="card card-body">
                <div class="media">
                  <div class="mr-3">
                    <a href="#"
                      ><i class="fa fa-money-bill-wave fa-3x text-success"></i
                    ></a>
                  </div>
                  <div class="media-body">
                    <span class="text-muted mb-0">Budget Amount</span>
                    <h6 class="mb-0 mt-1">
                      {{
                        new Intl.NumberFormat('id-ID', {}).format(
                          details.amount
                        )
                      }}
                    </h6>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <h4><strong>Approvals</strong></h4>
            </div>
          </div>
          <div class="row">
            <div
              class="col-2 card-approval"
              v-for="approval in approvals"
              :key="approval.id"
            >
              <CardApproval
                :employee="`${approval.first_name}`"
                :status="`${approval.status_name}`"
              />
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <div class="card">
                <div class="card-header bg-white">
                  {{ details.name }}
                </div>
                <div class="card-body" v-html="details.description"></div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6">
              <div class="card">
                <div class="card-header bg-transparent header-elements-inline">
                  <h6 class="card-title font-weight-semibold">
                    <i class="icon-folder6 mr-2"></i>
                    Attachments
                  </h6>

                  <div class="header-elements">
                    <span class="text-muted"
                      >({{
                        details.attachments ? details.attachments.length : '0'
                      }})</span
                    >
                  </div>
                </div>

                <div class="list-group list-group-flush">
                  <template v-for="file in details.attachments">
                    <a
                      :href="
                        `${file.file_host}${file.file_path}${file.file_name}`
                      "
                      target="_blank"
                      class="list-group-item list-group-item-action"
                      :key="file.id"
                    >
                      <i class="fa fa-file mr-3"></i>
                      {{ file.file_name }}
                    </a>
                  </template>
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="card">
                <div class="card-header">
                  Comments
                </div>
                <div class="card-body">
                  <ul class="media-list media-chat media-chat-scrollable mb-3">
                    <li
                      class="media"
                      v-for="comment in comments"
                      :key="comment.id"
                    >
                      <div class="mr-3">
                        <a href="#">
                          <img
                            src="../scss/global_assets/images/placeholders/placeholder.jpg"
                            class="rounded-circle"
                            width="40"
                            height="40"
                            alt=""
                          />
                        </a>
                      </div>

                      <div class="media-body">
                        <div class="font-size-sm text-muted mb-2">
                          <strong>{{ comment.first_name }}</strong> |
                          {{ comment.status_name }}
                        </div>
                        <div class="media-chat-item d-block">
                          {{ comment.comment }}
                        </div>
                        <div class="font-size-sm text-muted mt-2">
                          {{ comment.created_date | formatDate }}
                          {{ comment.created_date | formatGetTimeFromDate }}
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <b-modal
      :hide-footer="true"
      :no-close-on-backdrop="true"
      :no-close-on-esc="true"
      header-class="bg-white"
      id="submitApprove"
      ref="submitApprove"
      title="Approve Budget Request"
    >
      <form @submit.prevent="submitApprove">
        <div class="form-group">
          <label for="">Note</label>
          <textarea
            v-model="model.comment"
            cols="30"
            rows="10"
            class="form-control"
          ></textarea>
        </div>
        <button class="btn bg-green-700 btn-block mt-3" type="submit">
          Submit
        </button>
      </form>
    </b-modal>

    <b-modal
      :hide-footer="true"
      :no-close-on-backdrop="true"
      :no-close-on-esc="true"
      header-class="bg-white"
      id="submitReject"
      ref="submitReject"
      title="Reject Budget Request"
    >
      <form @submit.prevent="submitReject">
        <div class="form-group">
          <label for="">Note</label>
          <textarea
            v-model="model.comment"
            cols="30"
            rows="10"
            class="form-control"
          ></textarea>
        </div>
        <button class="btn bg-green-700 btn-block mt-3" type="submit">
          Submit
        </button>
      </form>
    </b-modal>
  </div>
</template>
<script>
import BackofficeApi from '../apis/Backoffice'
import DataEnumDetail from '../apis/EnumDetail'
import moment from 'moment'
import Paginate from '../components/Paginate'
export default {
  data() {
    return {
      details: {
        status_name: 'Pending'
      },
      user_id: JSON.parse(localStorage.getItem('hr_employee')).user_id,
      model: {
        budget_id: '',
        id: '',
        comment: '',
        user_id: ''
      },
      comments: [],
      approvals: [],
      currentApproval: {},
      icons: {
        pending: 'icon-minus-circle2 ',
        rejected: 'icon-cancel-circle2 ',
        approved: 'icon-checkmark-circle ',
        partial: 'icon-question4'
      },
      text: {
        pending: 'text-warning-400',
        rejected: 'text-danger',
        approved: 'text-green-700',
        partial: 'text-orange-700'
      },
      background: {
        pending: 'bg-warning-400',
        rejected: 'bg-danger',
        approved: 'bg-green-700',
        partial: 'bg-orange-700'
      }
    }
  },
  components: {
    Header: () =>
      import(
        /* webpackChunkName: "component-header" */
        /* webpackPrefetch: true */
        /* webpackMode: "eager" */
        '../components/Header'
      ),
    Sidebar: () =>
      import(
        /* webpackChunkName: "component-sidebar" */
        /* webpackPrefetch: true */

        /* webpackMode: "eager" */
        '../components/Sidebar'
      ),
    Paginate,
    CardApproval: () =>
      import(
        /* webpackChunkName: "component-card-approval" */
        /* webpackPrefetch: true */
        /* webpackMode: "eager" */
        '../components/CardApproval'
      )
  },
  methods: {
    modalApprove() {
      this.$refs['submitApprove'].show()
      console.log(this.model)
    },
    submitApprove() {
      console.log(this.model)
      let payload = this.model
      BackofficeApi.budgetRequestApprove(payload)
        .then(res => {
          this.$refs['submitApprove'].hide()
          this.budgetView({ id: this.$route.params.id })
        })
        .catch(res => {
          this.$refs['submitApprove'].hide()
          this.budgetView({ id: this.$route.params.id })
        })
    },
    modalReject() {
      this.$refs['submitReject'].show()
      console.log(this.model)
    },
    submitReject() {
      console.log(this.model)
      let payload = this.model
      BackofficeApi.budgetRequestReject(payload)
        .then(res => {
          this.$refs['submitReject'].hide()
          this.budgetView({ id: this.$route.params.id })
        })
        .catch(res => {
          this.$refs['submitReject'].hide()
          this.budgetView({ id: this.$route.params.id })
        })
    },
    history(query) {
      BackofficeApi.budgetRequestApprovalHistory(query)
        .then(res => {
          console.log('comments', res)
          this.comments = res.data.data
        })
        .catch(err => {
          console.log(err.message)
        })
    },
    budgetView(query) {
      BackofficeApi.budgetView(query)
        .then(res => {
          console.log('details', res)
          this.details = res.data.data
          this.history({ budget_id: this.details.id, limit: '-1' })
          this.approval({ id: this.details.id, budget_id: this.details.id })
          this.currentApproval = this.details.approvals.map(x => {
            if (
              x.user_id ==
              JSON.parse(localStorage.getItem('hr_employee')).user_id
            ) {
              return x
            }
          })
          this.currentApproval = this.currentApproval.filter(function(el) {
            return el != null
          })
          if (this.currentApproval.length > 0) {
            this.model = {
              budget_id: this.details.id,
              id: this.currentApproval[0].id,
              comment: '',
              user_id: this.user_id
            }
            console.log(this.model)
          }
        })
        .catch(err => {
          console.log(err.message)
        })
    },
    approval(query) {
      BackofficeApi.budgetViewApproval(query)
        .then(res => {
          console.log('approvals', res)
          this.approvals = res.data.data
        })
        .catch(err => {
          console.log(err.message)
        })
    }
  },
  mounted() {
    document.title = 'Budget Detail'
    this.budgetView({ id: this.$route.params.id })
  }
}
</script>

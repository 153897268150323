import axios from '../services/client'

const resource = '/backoffice-referral'
export default {
    referralBankAccountCommissionProcess(payload) {
        let token = JSON.parse(localStorage.getItem('hr_token')).access_token
        return axios.post(`${ resource }/referral-bank-account-commission-process`, payload, {
            headers: {
                'x-access-token': `${ token }`
            }
        })
    }
}
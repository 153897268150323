const reportCommission = [{
    path: '/report-commission-hrd-by-date-range',
    name: 'report-commission-hrd-by-date-range',
    component: () =>
        import (
            /* webpackChunkName: "page-report-commission-hrd-by-date-range" */
            /* webpackPrefetch: true */
            /* webpackMode: "eager" */
            '../views/ReportCommissionHrdByDateRange.vue'
        ),
    meta: {
        isGuest: true
    }
}]
export default reportCommission

<template>
    <div>
        <Header>
            <template v-slot:nav-item-left>
                <li class="nav-item">
                    <router-link :to="`/employees/${employee_id}/tasks`" class="navbar-nav-link">
                        <i class="fas fa-chevron-left text-green-700"></i>
                        Task Detail
                    </router-link>
                </li>
            </template>
            <template v-slot:nav-item-right>
                <li class="nav-item">
                    <button
                            class="btn bg-danger mt-1 mr-2 rounded-round w100px btn-sm"
                            type="button"
                            v-b-modal.modalReject
                    >Reject
                    </button>
                </li>
                <li class="nav-item">
                    <button
                            class="btn bg-green-700 mt-1 rounded-round mr-2 w100px btn-sm"
                            type="button"
                            v-b-modal.modalApproval
                    >Approve
                    </button>
                </li>
            </template>
        </Header>
        <div class="page-content">
            <Sidebar/>
            <div class="content-wrapper">
                <div class="content">
                    <div class="row">
                        <div class="col">
                            <div
                                    :class="{
                  'bg-grey-300': task.status_name === 'Pending',
                  'bg-danger': task.status_name === 'Rejected',
                  'bg-orange-400': task.status_name === 'Partial',
                  'bg-green-400': task.status_name === 'Approved'
                }"
                                    class="card"
                            >
                                <dl class="p-3">
                                    <dt>Status</dt>
                                    <dd class="font-weight-bold">
                                        <template v-if="loading">
                                            <content-placeholders :rounded="true">
                                                <content-placeholders-text :lines="1"/>
                                            </content-placeholders>
                                        </template>
                                        <template v-else>{{ task.status_name }}</template>
                                    </dd>
                                </dl>
                            </div>
                        </div>
                        <div class="col">
                            <div class="card">
                                <dl class="p-3">
                                    <dt>Assing To</dt>
                                    <dd class="font-weight-bold">
                                        <template v-if="loading">
                                            <content-placeholders :rounded="true">
                                                <content-placeholders-text :lines="1"/>
                                            </content-placeholders>
                                        </template>
                                        <template v-else>
                                            {{
                                            task.assigned_to_first_name +
                                            ' ' +
                                            task.assigned_to_last_name | str_limit(13)
                                            }}
                                        </template>
                                    </dd>
                                </dl>
                            </div>
                        </div>
                        <div class="col">
                            <div class="card">
                                <dl class="p-3">
                                    <dt>Request Date</dt>
                                    <dd class="font-weight-bold">
                                        <template v-if="loading">
                                            <content-placeholders :rounded="true">
                                                <content-placeholders-text :lines="1"/>
                                            </content-placeholders>
                                        </template>
                                        <template v-else>
                                            {{ task.created_date | formatDate }} |
                                            {{ task.created_date | formatGetTimeFromDate }}
                                        </template>
                                    </dd>
                                </dl>
                            </div>
                        </div>
                        <div class="col">
                            <div class="card">
                                <dl class="p-3">
                                    <dt>Due Date</dt>
                                    <dd class="text-danger-700">
                                        <template v-if="loading">
                                            <content-placeholders :rounded="true">
                                                <content-placeholders-text :lines="1"/>
                                            </content-placeholders>
                                        </template>
                                        <template v-else>{{ task.due_date | formatDate }}</template>
                                    </dd>
                                </dl>
                            </div>
                        </div>
                        <div class="col">
                            <div class="card">
                                <dl class="p-3">
                                    <dt>Request By</dt>
                                    <dd class="font-weight-bold">
                                        <template v-if="loading">
                                            <content-placeholders :rounded="true">
                                                <content-placeholders-text :lines="1"/>
                                            </content-placeholders>
                                        </template>
                                        <template v-else>
                                            {{
                                            task.request_by_first_name +
                                            ' ' +
                                            task.request_by_last_name | str_limit(13)
                                            }}
                                        </template>
                                    </dd>
                                </dl>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12">
                            <h4>Approval</h4>
                        </div>
                    </div>
                    <div class="row">
                        <template v-if="loadingApprovals">
                            <div :key="n" class="col-3" v-for="n in 4">
                                <div class="card card-body">
                                    <content-placeholders>
                                        <content-placeholders-heading :img="true"/>
                                    </content-placeholders>
                                </div>
                            </div>
                        </template>
                        <template v-else>
                            <div class="col-3 card-approval" v-for="approval in approvals">
                                <CardApproval
                                        :employee="
                    `${approval.user_first_name +
                      ' ' +
                      approval.user_last_name}`
                  "
                                        :key="approval.id"
                                        :status="`${approval.status_name}`"
                                />
                            </div>
                        </template>
                    </div>
                    <div class="row">
                        <div class="col-md-12">
                            <h4>Brief</h4>
                            <div class="card card-body">
                                <dl>
                                    <dt>Title</dt>
                                    <dd class="font-weight-bold">
                                        <template v-if="loading">
                                            <content-placeholders :rounded="true">
                                                <content-placeholders-text :lines="1"/>
                                            </content-placeholders>
                                        </template>
                                        <template v-else>{{ task.title }}</template>
                                    </dd>
                                    <dt>Description</dt>
                                    <dd class="font-weight-bold">
                                        <template v-if="loading">
                                            <content-placeholders :rounded="true">
                                                <content-placeholders-text :lines="1"/>
                                            </content-placeholders>
                                        </template>
                                        <template v-else>{{ task.description }}</template>
                                    </dd>
                                </dl>
                            </div>
                            <div class="card">
                                <div class="card-header bg-white">
                                    <h4 class="card-title">Attachment</h4>
                                </div>
                                <div class="card-body">
                                    <div class="d-flex flex-wrap align-items-center">
                                        <template v-for="attachment in attachments">
                                            <template v-if="loadingAttachment">
                                                <content-placeholders :rounded="true">
                                                    <content-placeholders-text :lines="1"/>
                                                </content-placeholders>
                                            </template>
                                            <template v-else>
                                                <a :key="attachment.id" class="mr-3" href="javascript:void(0)">
                                                    <img
                                                            alt
                                                            class="rounded-round"
                                                            height="36"
                                                            src="../scss/global_assets/images/placeholders/placeholder.jpg"
                                                            width="36"
                                                    />
                                                    {{ attachment.file_name }}
                                                </a>
                                            </template>
                                        </template>
                                    </div>
                                </div>
                            </div>
                            <h4>Comments</h4>
                            <div class="card card-body">
                                <ul class="media-list media-chat media-chat-scrollable mb-3">
                                    <template v-if="loadingApprovalHistory">
                                        <content-placeholders>
                                            <content-placeholders-heading :img="true"/>
                                            <content-placeholders-text :lines="3"/>
                                        </content-placeholders>
                                    </template>
                                    <template v-else>
                                        <template v-if="histories.length === 0">No Data</template>
                                        <template v-for="history in histories">
                                            <MediaChatItem :history="history"/>
                                        </template>
                                    </template>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <b-modal
                            :hide-footer="true"
                            :no-close-on-backdrop="true"
                            :no-close-on-esc="true"
                            header-class="bg-white text-danger-700 font-weight-bold"
                            id="modalReject"
                            ref="modalReject"
                            title="Reject"
                    >
                        <form action>
                            <div class="form-group">
                                <label for="comment">Comment</label>
                                <textarea class="form-control" cols="30" id="comment" rows="10"></textarea>
                            </div>
                            <div class="row">
                                <div class="col-md-6">
                                    <button
                                            @click="hideModal($event, 'modalReject')"
                                            class="btn btn-outline-success btn-block rounded-round"
                                            type="button"
                                    >Cancle
                                    </button>
                                </div>
                                <div class="col-md-6">
                                    <button class="btn btn-block btn-success rounded-round" type="button">Submit
                                    </button>
                                </div>
                            </div>
                        </form>
                    </b-modal>

                    <b-modal
                            :hide-footer="true"
                            :no-close-on-backdrop="true"
                            :no-close-on-esc="true"
                            header-class="bg-white text-green-700 font-weight-bold"
                            id="modalApproval"
                            ref="modalApproval"
                            title="Approve"
                    >
                        <form action>
                            <div class="form-group">
                                <label for="comment">Comment</label>
                                <textarea class="form-control" cols="30" id="comment" rows="10"></textarea>
                            </div>
                            <div class="row">
                                <div class="col-md-6">
                                    <button
                                            @click="hideModal($event, 'modalApproval')"
                                            class="btn btn-outline-success btn-block rounded-round"
                                            type="button"
                                    >Cancle
                                    </button>
                                </div>
                                <div class="col-md-6">
                                    <button class="btn btn-block btn-success rounded-round" type="button">Submit
                                    </button>
                                </div>
                            </div>
                        </form>
                    </b-modal>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import BackofficeApi from '../apis/Backoffice'
    import SalesService from '../services/sales'
    import Paginate from '../components/Paginate'
    import Switches from 'vue-switches'
    import {ModelListSelect, ModelSelect} from 'vue-search-select'

    export default {
        data() {
            return {
                loading: true,
                loadingAttachment: true,
                loadingApprovals: true,
                loadingApproval: true,
                loadingApprovalHistory: true,
                task: {
                    id: 0,
                    title: '-',
                    request_by_first_name: '-',
                    request_by_last_name: '-',
                    date: '0000-00-00',
                    description: '-',
                    due_date: '0000-00-00 00:00:00',
                    created_date: '0000-00-00 12:57:57',
                    created_by_user_id: 0,
                    assigned_to_user_id: 0,
                    assigned_to_first_name: '-',
                    assigned_to_last_name: '-',
                    status_id: 0,
                    status_name: '-',
                    approval_user_id: 0,
                    approval_user_first_name: '-',
                    approval_user_last_name: '-'
                },
                approvals: [],
                histories: [],
                approval: {},
                attachments: [],
                employee_id: this.$route.params.id,
                task_id: this.$route.params.tid
            }
        },
        components: {
            Paginate,
            Switches,
            ModelSelect,
            ModelListSelect,
            MediaChatItem: () =>
                import(
                    /* webpackChunkName: "media-chat-item" */
                    /* webpackPrefetch: true */
                    /* webpackMode: "eager" */
                    '../components/MediaChatItem'
                    ),

            Header: () =>
                import(
                    /* webpackChunkName: "component-header" */
                    /* webpackPrefetch: true */
                    /* webpackMode: "eager" */
                    '../components/Header'
                    ),
            Sidebar: () =>
                import(
                    /* webpackChunkName: "component-sidebar" */
                    /* webpackPrefetch: true */

                    /* webpackMode: "eager" */
                    '../components/Sidebar'
                    ),
            CardApproval: () =>
                import(
                    /* webpackChunkName: "component-card-approval" */
                    /* webpackPrefetch: true */
                    /* webpackMode: "eager" */
                    '../components/CardApproval'
                    )
        },
        methods: {
            hideModal(e, ref) {
                this.$refs[ref].hide()
            },
            TaskView(id) {
                SalesService.interceptors.response.use(
                    response => response,
                    error => {
                        if (typeof error.response === 'undefined') {
                            this.$swal(
                                'Ooops!',
                                'A network error occurred. ' +
                                'This could be a CORS issue or a dropped internet connection. ' +
                                'It is not possible for us to know.',
                                'error'
                            )
                        }
                        return Promise.reject(error)
                    }
                )
                BackofficeApi.TaskView(id)
                    .then(result => {
                        this.task = result.data.data
                        this.TaskViewApproval(this.$route.params.tid)
                        this.TaskViewAttachment(this.$route.params.tid)
                        this.TaskApprovalView(this.$route.params.tid)
                        this.TaskApprovalHistory(this.$route.params.tid)
                        this.loading = false
                    })
                    .catch(err => {
                        console.log(err.response)
                        if (err.response.status === 400) {
                            this.$swal(
                                'Ooops!',
                                err.response.data.errors.message,
                                'error'
                            ).then(a => {
                                this.$router.go(-1)
                            })
                        } else {
                            this.$swal(
                                'Ooops!',
                                'A network error occurred. ' +
                                'This could be a CORS issue or a dropped internet connection. ' +
                                'It is not possible for us to know.',
                                'error'
                            )
                        }
                    })
            },
            TaskViewApproval(id) {
                SalesService.interceptors.response.use(
                    response => response,
                    error => {
                        if (typeof error.response === 'undefined') {
                            this.$swal(
                                'Ooops!',
                                'A network error occurred. ' +
                                'This could be a CORS issue or a dropped internet connection. ' +
                                'It is not possible for us to know.',
                                'error'
                            )
                        }
                        return Promise.reject(error)
                    }
                )
                BackofficeApi.TaskViewApproval(id)
                    .then(result => {
                        this.approvals = result.data.data
                        this.loadingApprovals = false
                    })
                    .catch(err => {
                        console.log(err)
                        this.$swal(
                            'Ooops!',
                            'A network error occurred. ' +
                            'This could be a CORS issue or a dropped internet connection. ' +
                            'It is not possible for us to know.',
                            'error'
                        )
                    })
            },
            TaskViewAttachment(id) {
                SalesService.interceptors.response.use(
                    response => response,
                    error => {
                        if (typeof error.response === 'undefined') {
                            this.$swal(
                                'Ooops!',
                                'A network error occurred. ' +
                                'This could be a CORS issue or a dropped internet connection. ' +
                                'It is not possible for us to know.',
                                'error'
                            )
                        }
                        return Promise.reject(error)
                    }
                )
                BackofficeApi.TaskViewAttachment(id)
                    .then(result => {
                        this.attachments = result.data.data
                        this.loadingAttachment = false
                    })
                    .catch(err => {
                        console.log(err)
                        this.$swal(
                            'Ooops!',
                            'A network error occurred. ' +
                            'This could be a CORS issue or a dropped internet connection. ' +
                            'It is not possible for us to know.',
                            'error'
                        )
                    })
            },
            TaskApprovalView(id) {
                SalesService.interceptors.response.use(
                    response => response,
                    error => {
                        if (typeof error.response === 'undefined') {
                            this.$swal(
                                'Ooops!',
                                'A network error occurred. ' +
                                'This could be a CORS issue or a dropped internet connection. ' +
                                'It is not possible for us to know.',
                                'error'
                            )
                        }
                        return Promise.reject(error)
                    }
                )
                BackofficeApi.TaskApprovalView(id)
                    .then(result => {
                        this.approval = result.data.data
                        this.loadingApproval = false
                    })
                    .catch(err => {
                        console.log(err)
                        this.$swal(
                            'Ooops!',
                            'A network error occurred. ' +
                            'This could be a CORS issue or a dropped internet connection. ' +
                            'It is not possible for us to know.',
                            'error'
                        )
                    })
            },
            TaskApprovalHistory(id) {
                SalesService.interceptors.response.use(
                    response => response,
                    error => {
                        if (typeof error.response === 'undefined') {
                            this.$swal(
                                'Ooops!',
                                'A network error occurred. ' +
                                'This could be a CORS issue or a dropped internet connection. ' +
                                'It is not possible for us to know.',
                                'error'
                            )
                        }
                        return Promise.reject(error)
                    }
                )
                BackofficeApi.TaskApprovalHistory(id)
                    .then(result => {
                        this.histories = result.data.data
                        this.loadingApprovalHistory = false
                    })
                    .catch(err => {
                        console.log(err)
                        this.$swal(
                            'Ooops!',
                            'A network error occurred. ' +
                            'This could be a CORS issue or a dropped internet connection. ' +
                            'It is not possible for us to know.',
                            'error'
                        )
                    })
            }
        },
        mounted() {
            document.title = 'Task Detail'
            this.TaskView(this.task_id)
        }
    }
</script>

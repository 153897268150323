<template>
  <div>
    <Header></Header>
    <div class="page-content">
      <Sidebar />
      <div class="content-wrapper">
        <div class="content">
          <breadcrumb-component
            :links="[{ name: 'Achievement Bonus' }]"
          ></breadcrumb-component>
          <div class="row mb-2">
            <div class="col-md-6 mb-1">
              Displaying
              <span class="font-weight-bold text-green-700">{{
                pagination.displaying
              }}</span>
              of
              <span class="font-weight-bold text-green-700">{{
                pagination.totalData
              }}</span>
              Result
            </div>
            <div class="col-md-6 mb-1 col-6">
              <div class="row">
                <div class="col-md-3">
                  <div class="form-group selectdiv">
                    <select class="form-control" v-model="model.bulan">
                      <option value="1">Januari</option>
                      <option value="2">Februari</option>
                      <option value="3">Maret</option>
                      <option value="4">April</option>
                      <option value="5">Mei</option>
                      <option value="6">Juni</option>
                      <option value="7">Juli</option>
                      <option value="8">Agustus</option>
                      <option value="9">September</option>
                      <option value="10">Oktober</option>
                      <option value="11">November</option>
                      <option value="12">Desember</option>
                    </select>
                  </div>
                </div>
                <div class="col-md-3">
                  <div class="form-group selectdiv">
                    <select class="form-control" v-model="model.year">
                      <option :value="year" v-for="year in years">{{
                        year
                      }}</option>
                    </select>
                  </div>
                </div>
                <div class="col-md-3">
                  <button
                    @click="filter"
                    class="btn bg-white btn-block text-secondary rounded-round btn-labeled btn-labeled-left"
                  >
                    <b>
                      <i class="icon-filter3"></i>
                    </b>
                    Filter
                  </button>
                </div>
                <div class="col-md-3">
                  <button
                    class="btn bg-green-600 btn-block rounded-round btn-labeled btn-labeled-left"
                  >
                    <b>
                      <i class="icon-filter3"></i>
                    </b>
                    Export
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <div class="card">
                <table class="table">
                  <thead>
                    <tr>
                      <th class="text-muted font-weight-bold">Branch</th>
                      <th class="text-muted font-weight-bold">Lot 10K & 12K</th>
                      <th class="text-muted font-weight-bold">Lot 14K & USD</th>
                      <th class="text-muted font-weight-bold">
                        Total Achievement Bonus
                      </th>
                      <th class="text-muted font-weight-bold"></th>
                    </tr>
                  </thead>
                  <tbody>
                    <template v-if="loading">
                      <tr :key="a" v-for="a in 10">
                        <td :key="b" v-for="b in 5">
                          <content-placeholders :rounded="true">
                            <content-placeholders-text :lines="1" />
                          </content-placeholders>
                        </td>
                      </tr>
                    </template>
                    <template v-else>
                      <template v-if="achievements.length === 0">
                        <tr>
                          <td colspan="5">No Data</td>
                        </tr>
                      </template>
                      <template v-else>
                        <tr
                          :key="index"
                          v-for="(achievement, index) in achievements"
                        >
                          <td scope="row">{{ achievement.branch_name }}</td>
                          <td>{{ achievement.total_lot_10_12 | formatNum }}</td>
                          <td>
                            {{ achievement.total_lot_14_usd | formatNum }}
                          </td>
                          <td>
                            {{
                              achievement.total_achievement_bonus | formatNum
                            }}
                          </td>
                          <td>
                            <div class="list-icons-item dropdown">
                              <a
                                class="list-icons-item dropdown-toggle caret-0"
                                data-toggle="dropdown"
                                href="javascript:void(0)"
                              >
                                <i
                                  class="fas fa-ellipsis-v text-green-700 fa-2x"
                                ></i>
                              </a>
                              <div class="dropdown-menu dropdown-menu-right">
                                <router-link
                                  :to="
                                    `/achievement-bonus/${achievement.branch_id}`
                                  "
                                  class="dropdown-item"
                                >
                                  <i class="icon-eye"></i>
                                  View Details
                                </router-link>
                              </div>
                            </div>
                          </td>
                        </tr>
                      </template>
                    </template>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Paginate from '../components/Paginate'
import Achievement from '../apis/Achievement'
import moment from 'moment'

export default {
  data() {
    return {
      loading: true,
      pagination: {
        totalPage: 10,
        currentPage: 1,
        totalData: 0,
        displaying: 0
      },
      achievements: [],
      model: {
        year: new Date().getFullYear(),
        bulan: new Date().getMonth() + 1
      }
    }
  },
  computed: {
    years() {
      const year = new Date().getFullYear()
      return Array.from({ length: year - 2010 }, (value, index) => 2011 + index)
    }
  },
  components: {
    Header: () =>
      import(
        /* webpackChunkName: "component-header" */
        /* webpackPrefetch: true */
        /* webpackMode: "eager" */
        '../components/Header'
      ),
    Sidebar: () =>
      import(
        /* webpackChunkName: "component-sidebar" */
        /* webpackPrefetch: true */

        /* webpackMode: "eager" */
        '../components/Sidebar'
      ),
    Paginate
  },
  methods: {
    filter() {
      let last = this.formatDate(
        new Date(this.model.year, this.model.bulan, 0, 23, 59, 59),
        'day'
      )
      let from_date = this.model.year + '-' + this.model.bulan + '-1'
      let to_date = this.model.year + '-' + this.model.bulan + '-' + last
      this.loading = true
      this.achievementBranchBonusByDateRange(from_date, to_date)
    },
    achievementBranchBonusByDateRange(from_date, to_date) {
      Achievement.achievementBranchBonusByDateRange(from_date, to_date)
        .then(res => {
          console.log(res)
          this.achievements = res.data.data
          this.loading = false
        })
        .catch(err => {
          this.loading = false
        })
    },
    beginningOfMonth(myDate) {
      let date = new Date(myDate)
      date.setDate(1)
      date.setHours(0)
      date.setMinutes(0)
      date.setSeconds(0)
      return date
    },
    endOfMonth(myDate) {
      let date = new Date(myDate)
      date.setMonth(date.getMonth() + 1)
      date.setDate(0)
      date.setHours(23)
      date.setMinutes(59)
      date.setSeconds(59)
      return date
    },
    formatDate(date, type) {
      if (type === 'day') {
        return moment(String(date)).format('DD')
      }
      if (type === 'bulan') {
        return moment(String(date)).format('MM')
      }
      if (type === 'year') {
        return moment(String(date)).format('YYYY')
      }
      if (type === 'full') {
        moment(String(value)).format('YYYY-MM-DD')
      }
    }
  },
  mounted() {
    document.title = 'Achievement Bonus'
    let last = this.formatDate(
      new Date(this.model.year, this.model.bulan, 0, 23, 59, 59),
      'day'
    )
    let from_date = this.model.year + '-' + this.model.bulan + '-1'
    let to_date = this.model.year + '-' + this.model.bulan + '-' + last
    this.achievementBranchBonusByDateRange(from_date, to_date)
  },
  filters: {
    formatNum(value) {
      return new Intl.NumberFormat('id-ID').format(value)
    }
  }
}
</script>

<template>
  <div>
    <Header>
      <template v-slot:nav-item-left>
        <li class="nav-item">
          <router-link class="navbar-nav-link" to="/"
            >Report Commission</router-link
          >
        </li>
      </template>
    </Header>
    <div class="page-content">
      <Sidebar />
      <div class="content-wrapper">
        <div class="content">
          <div class="row">
            <div class="col-md-4">
            </div>
            <div class="col-md-2">
                <div class="form-group selectdiv">
                    <select class="form-control" v-model="bulan">
                        <option v-for="(month,index) in months" :value="month.id" :key="index">{{ month.name }}</option>
                    </select>
                </div>
            </div>
            <div class="col-md-2">
                <div class="form-group selectdiv">
                    <select class="form-control" v-model="tahun">
                        <option v-for="(year,index) in years" :value="year" :key="index">{{ year }}</option>
                    </select>
                </div>
            </div>
            <div class="col-md-2">
                <div class="form-group">
                  <button @click="applyFilter" class="btn bg-green-700 btn-block text-white rounded-round btn-labeled btn-labeled-left">
                    <b>
                        <i class="fas fa-plus"></i>
                    </b>
                    Filter
                  </button>
                </div>
            </div>
            <div class="col-md-2">
                <div class="form-group">
                  <button @click="downloadCsv" class="btn bg-green-700 btn-block text-white rounded-round btn-labeled btn-labeled-left">
                    <b>
                        <i class="fas fa-plus"></i>
                    </b>
                    Export
                  </button>
                </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-3">
              <div class="card card-body">
                <div class="media">
                  <div class="mr-3">
                    <a href="#">
                      <i class="icon-vcard icon-3x">

                      </i>
                    </a>
                    </div>
                    <div class="media-body">
                        <span class="text-muted mb-0">
                          Total Rebate Staff
                        </span>
                        <h6 class="mb-0 mt-1">
                          {{ new Intl.NumberFormat('id-ID').format(total_rebate_staff) }}
                        </h6>
                    </div>
                  </div>
                </div>
            </div>
            <div class="col-md-3">
              <div class="card card-body">
                <div class="media">
                  <div class="mr-3">
                    <a href="#">
                      <i class="icon-vcard icon-3x">

                      </i>
                    </a>
                    </div>
                    <div class="media-body">
                        <span class="text-muted mb-0">
                          Total Rebate Non Staff
                        </span>
                        <h6 class="mb-0 mt-1">
                          {{ new Intl.NumberFormat('id-ID').format(total_rebate_non_staff) }}
                        </h6>
                    </div>
                  </div>
                </div>
            </div>
            <div class="col-md-3">
              <div class="card card-body">
                <div class="media">
                  <div class="mr-3">
                    <a href="#">
                      <i class="icon-vcard icon-3x">

                      </i>
                    </a>
                    </div>
                    <div class="media-body">
                        <span class="text-muted mb-0">
                          Total Bounty Staff
                        </span>
                        <h6 class="mb-0 mt-1">
                          {{ new Intl.NumberFormat('id-ID').format(total_bounty_staff) }}
                        </h6>
                    </div>
                  </div>
                </div>
            </div>
            <div class="col-md-3">
              <div class="card card-body">
                <div class="media">
                  <div class="mr-3">
                    <a href="#">
                      <i class="icon-vcard icon-3x">

                      </i>
                    </a>
                    </div>
                    <div class="media-body">
                        <span class="text-muted mb-0">
                          Total Bounty Non Staff
                        </span>
                        <h6 class="mb-0 mt-1">
                          {{ new Intl.NumberFormat('id-ID').format(total_bounty_non_staff) }}
                        </h6>
                    </div>
                  </div>
                </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <div class="card" id="card">
                <div class="card-header">
                  Total : {{ reports.length }} Data
                </div>
                <div class="table-responsive">
                  <table class="table table-striped">
                    <thead>
                      <tr>
                        <th>User ID</th>
                        <th>NIK</th>
                        <th>Name</th>
                        <th>Code</th>
                        <th>Occupation</th>
                        <th>Rebate Staff</th>
                        <th>Rebate Non Staff</th>
                        <th>Bounty Staff</th>
                        <th>Bounty Non Staff</th>
                      </tr>
                    </thead>
                    <tbody>
                      <template v-if="loading">
                      <tr :key="a" v-for="a in 12">
                        <td :key="b" v-for="b in 9">
                          <content-placeholders :rounded="true">
                            <content-placeholders-text :lines="1" />
                          </content-placeholders>
                        </td>
                      </tr>
                    </template>
                      <template v-if="!loading && reports.length > 0">
                        <tr v-for="(report,index) in paginated" :key="index">
                        <td>
                          {{ report.user_id }}
                        </td>
                        <td>
                          {{ report.nik }}
                        </td>
                        <td>
                          {{ report.full_name }}
                        </td>
                        <td>
                          {{ report.code }}
                        </td>
                        <td>
                          {{ report.occupation_name }}
                        </td>
                        <td>
                          {{ new Intl.NumberFormat('id-ID').format(report.rebate_staff) }}
                        </td>
                        <td>
                          {{ new Intl.NumberFormat('id-ID').format(report.rebate_non_staff) }}
                        </td>
                        <td>
                          {{ new Intl.NumberFormat('id-ID').format(report.bounty_staff) }}
                        </td>
                        <td>
                          {{ new Intl.NumberFormat('id-ID').format(report.bounty_non_staff) }}
                        </td>
                      </tr>
                      </template>
                      <template v-if="!loading && reports.length == 0">
                        <tr>
                          <td colspan="7">
                            No Data
                          </td>
                        </tr>
                      </template>
                    </tbody>
                  </table>
                </div>
              </div>
              
                <div class="col-md-12 mt-3">
                  
                    
              <paginate
                :click-handler="clickCallback"
                :container-class="
                  'pagination pagination-separated pagination-rounded align-self-center'
                "
                :margin-pages="5"
                :next-class="'page-item'"
                :next-link-class="'page-link'"
                :next-text="'Next'"
                :page-class="'page-item'"
                :page-count="totalPage"
                :page-link-class="'page-link'"
                :page-range="5"
                :prev-class="'page-item'"
                :prev-link-class="'page-link'"
                :prev-text="'Prev'"
              ></paginate>

                </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Commission from "../apis/Commission"
import Paginate from '../components/Paginate'
export default {
  data() {
    return {
      loading:true,
      reports:[],
      months:[
        {
          id:1,
          name:"Januari"
        },
        {
          id:2,
          name:"Februari"
        },
        {
          id:3,
          name:"Maret"
        },
        {
          id:4,
          name:"April"
        },
        {
          id:5,
          name:"Mei"
        },
        {
          id:6,
          name:"Juni"
        },
        {
          id:7,
          name:"Juli"
        },
        {
          id:8,
          name:"Agustus"
        },
        {
          id:9,
          name:"September"
        },
        {
          id:10,
          name:"Oktober"
        },
        {
          id:11,
          name:"November"
        },
        {
          id:12,
          name:"Desember"
        },
      ],
      current:1,
      pageSize:20,
      totalPage:5,
      bulan:new Date().getMonth()+1,
      total_rebate_staff:0,
      total_rebate_non_staff:0,
      total_bounty_staff:0,
      total_bounty_non_staff:0,
      tahun:new Date().getFullYear()
    }
  },
  components: {
    Header: () =>
      import(
        /* webpackChunkName: "component-header" */
        /* webpackPrefetch: true */
        /* webpackMode: "eager" */
        '../components/Header'
      ),
    Sidebar: () =>
      import(
        /* webpackChunkName: "component-sidebar" */
        /* webpackPrefetch: true */

        /* webpackMode: "eager" */
        '../components/Sidebar'
      ),
      Paginate
  },
  methods: {
    downloadCsv(){
      let filename = 'Report Commission '+this.bulan+'_'+this.tahun+'.csv'
      let reports = this.reports.map((v,i)=>{
        if(typeof v.nik == 'object'){
          v.nik=''
        }
        return v;
      })
      const ordered = _.orderBy(
        this.reports,
          function(item) {
            return item.nik;
          }
      );
      // 1_385 = Rebate Staff
      // 1_374 = Rebate Non Staff
      // 1_388 = Bounty Staff
      // 1_376 = Bounty Non Staff
      let csvFile = 'em_noind;name;code;1_385;1_374;1_388;1_376\n'
      for (let i = 0; i < ordered.length; i++) {
        let row = ordered[i]
        let rebate_staff = parseInt(row.rebate_staff)
        let rebate_non_staff = parseInt(row.rebate_non_staff)
        let bounty_staff = parseInt(row.bounty_staff)
        let bounty_non_staff = parseInt(row.bounty_non_staff)
        let name = row.full_name
        let nik = row.nik

        if(typeof nik == 'object'){
          nik=''
        }

        name = name.replace(',',' ')
        console.log('rebate_staff',rebate_staff)
        console.log('rebate_non_staff',rebate_non_staff)
        console.log('bounty_staff',bounty_staff)
        console.log('bounty_non_staff',bounty_non_staff)
        console.log(
            rebate_staff !== 0 ||
            rebate_non_staff !== 0 ||
            bounty_staff !== 0 ||
            bounty_non_staff !== 0 
          )
        if(
            rebate_staff !== 0 ||
            rebate_non_staff !== 0 ||
            bounty_staff !== 0 ||
            bounty_non_staff !== 0 
          )
          {
            csvFile += nik +';'+name+';'+row.code+';'+rebate_staff+';'+rebate_non_staff+';'+bounty_staff+';'+bounty_non_staff+'\n'
          }
        
      }
      let blob = new Blob([csvFile], { type: 'text/csv;charset=utf-8;' })
      if (navigator.msSaveBlob) {
            // IE 10+
            navigator.msSaveBlob(blob, filename)
          } else {
            let link = document.createElement('a')
            if (link.download !== undefined) {
              // feature detection
              // Browsers that support HTML5 download attribute
              let url = URL.createObjectURL(blob)
              link.setAttribute('href', url)
              link.setAttribute('download', filename)
              link.style.visibility = 'hidden'
              document.body.appendChild(link)
              link.click()
              document.body.removeChild(link)
            }
          }
    },
    applyFilter(){
      this.loading=true
          this.total_rebate_staff = 0
          this.total_rebate_non_staff = 0
          this.total_bounty_staff = 0
          this.total_bounty_non_staff = 0
          this.reports = []
          this.current=1
      this.getReports({month:this.bulan,year:this.tahun})
    },
    clickCallback(e){
      let ttlPage = Math.round( parseInt(this.reports.length)/ parseInt(this.pageSize) )
      if(ttlPage == 0){
        ttlPage = 1
      }
      this.totalPage = ttlPage
      this.current = e
      console.log(e)
      console.log('this.indexStart',this.indexStart)
      console.log('this.indexEnd',this.indexEnd)
      console.log('ttl', ttlPage )
    },
    getReports(payload){
      Commission.reportCommissionHrdByDateRange(payload)
      .then((result) => {
        console.log(result)
        // this.reports = result.data.data
        
      const ordered = _.orderBy(
        result.data.data,
          function(item) {
            return item.nik;
          }
      );
      console.log('ordered',ordered)
      
      this.reports = ordered
      this.reports.map((v,i)=>{
        if(typeof v.nik == 'object'){
          v.nik=''
        }
        return v;
      })
      console.log('reports',this.reports)
        console.log(this.reports.length)
        this.reports.forEach((v,i)=>{
          this.total_rebate_staff+= parseInt(v.rebate_staff)
          this.total_rebate_non_staff+= parseInt(v.rebate_non_staff)
          this.total_bounty_staff+= parseInt(v.bounty_staff)
          this.total_bounty_non_staff+= parseInt(v.bounty_non_staff)
        })


      let ttlPage = Math.round( parseInt(this.reports.length)/ parseInt(this.pageSize) )
      if(ttlPage == 0){
        ttlPage = 1
      }
      this.totalPage = ttlPage

        this.loading=false
      }).catch((err) => {
        console.log(err)
      });
    },
    prev() {
      if(this.current > 1){
        this.current--
      }
    },
    next() {
      let ttl = this.reports.slice(this.indexStart, this.indexEnd)
      if(ttl.length > 0){
        this.current++;
      }
      
    }
  },
  mounted() {
    document.title = 'Report Commission'
    this.getReports({month:this.bulan,year:this.tahun})
  },
  computed : {
    years () {
      const year = new Date().getFullYear()
      return Array.from({length: year - 2018}, (value, index) => 2019 + index)
    },
    indexStart() {
      return (this.current - 1) * this.pageSize;
    },
    indexEnd() {
      return this.indexStart + this.pageSize;
    },
    paginated() {
      const ordered = _.orderBy(
        this.reports,
          function(item) {
            return item.nik;
          }
      );
      return ordered.slice(this.indexStart, this.indexEnd);
    }
  }
}
</script>

import Vue from 'vue'
import Router from 'vue-router'
import HelperApi from '../apis/Helper'
import home from './home'
import wakilPialang from './wakil-pialang'
import employee from './employees'
import LotEarningsReport from './lot-earnings-report'
import achivementTable from './achievement-table'
import achivementBonus from './achievement-bonus'
import login from './login'
import rolePermission from './role-permission'
import department from './departments'
import backofficeAccess from './backoffice-access'
import KpiByPosition from './kpi-by-position'
import kpiScoreByPosition from './kpi-score-by-position'
import kpiByBranch from './kpi-by-branch'
import kpiScoreByBranch from './kpi-score-by-branch'
import kpiChannel from './kpi-channel'
import kpiScoreChannel from './kpi-score-channel'
import ReferralBankAccount from "./referral-bank-account";
import Referral from "./referral-list";
import Reward from "./reward";
import LeagueTable from "./league-table"
import Budget from "./budget"
import ReportCommission from "./report-commission"
Vue.use(Router)

const allRoutes = []
const routes = allRoutes
    .concat(home)
    .concat(wakilPialang)
    .concat(employee)
    .concat(LotEarningsReport)
    .concat(achivementTable)
    .concat(achivementBonus)
    .concat(login)
    .concat(rolePermission)
    .concat(department)
    .concat(backofficeAccess)
    .concat(KpiByPosition)
    .concat(kpiScoreByPosition)
    .concat(kpiByBranch)
    .concat(kpiScoreByBranch)
    .concat(kpiChannel)
    .concat(kpiScoreChannel)
    .concat(ReferralBankAccount)
    .concat(Referral)
    .concat(Reward)
    .concat(LeagueTable)
    .concat(Budget)
    .concat(ReportCommission)

let router = new Router({
    mode: 'history',
    routes
})
router.beforeEach((to, from, next) => {
    if(to.name != 'login' && to.name !== 'verify'){
        
        if(!localStorage.getItem('is_verify')){
            localStorage.setItem('is_verify',false)
            next('/verify')
            return
        }
    }
    if (to.matched.some(record => record.meta.requiresAuth)) {
        let hr_token = localStorage.getItem('hr_token')
        let tokenObj = JSON.parse(hr_token)
        if (tokenObj) {
            let token = tokenObj.access_token
            if (token) {
                HelperApi.RoleByAccessToken()
                    .then(res => {
                        localStorage.setItem(
                            'hr_token_object',
                            JSON.stringify(res.data.data)
                        )
                    })
                    .catch(err => {
                        localStorage.removeItem('hr_token')
                        localStorage.removeItem('hr_employee')
                        localStorage.removeItem('hr_token_object')
                        localStorage.clear();
                        next('/login')
                        return
                    })
                if (!localStorage.getItem('hr_token_object')) {
                    localStorage.removeItem('hr_token')
                    localStorage.removeItem('hr_employee')
                    localStorage.removeItem('hr_token_object')
                    localStorage.clear();
                    next('/login')
                    return
                }
                let tokenObject = JSON.parse(localStorage.getItem('hr_token_object'))
                    .user.roles

                if (tokenObject) {
                    let permissions = ['GET:employee.default.dashboard']
                    tokenObject.forEach((v, i) => {
                        if (typeof v.role.permissions !== 'undefined') {
                            v.role.permissions.forEach((value, index) => {
                                permissions.push(value.permission.name)
                            })
                        }
                    })
                    localStorage.setItem('hr_permission', JSON.stringify(permissions))
                    let find = permissions.find(element => {
                        return to.meta.permission === element
                    })
                    if (find) {
                        next()
                        return
                    } else {
                        // console.log(find)
                        next('/forbiden')
                        return
                    }
                } else {
                    next('/forbiden')
                    return
                }
            }
        }
        next('/login')
    } else {
        next()
    }
})
export default router

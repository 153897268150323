const login = [{
    path: '/login',
    name: 'login',
    component: () =>
        import (
            /* webpackChunkName: "page-login" */
            /* webpackPrefetch: true */
            /* webpackMode: "eager" */
            '../views/Login.vue'
        ),
    meta: {
        isGuest: true
    },
    beforeEnter: (to, from, next) => {
        let hr_token = localStorage.getItem('hr_token')
        let tokenObj = JSON.parse(hr_token)
        if (tokenObj) {
            let token = tokenObj.access_token
            next('/')
            return
        }
        next()
    }
}]
export default login
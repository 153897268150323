<template >
	<li class = "media" >
		<div class = "mr-3" >
			<img
					alt
					class = "rounded-circle align-self-center mt-3"
					height = "50"
					src = "../scss/global_assets/images/placeholders/placeholder.jpg"
					width = "50"
			/>
		</div >
		<div class = "media-body" >
			<div class = "font-size-sm text-muted mb-1" >{{ history.first_name + ' ' + history.last_name }}</div >
			<div :class = "`media-chat-item ${changeBg(history.status_name)}`" >
				<strong class = "d-block" >{{ history.status_name }}</strong >
				{{ history.comment }}
			</div >
			<div class = "font-size-sm text-muted mt-2" >
				{{ history.created_date | formatDate }} |
				{{ history.created_date | formatGetTimeFromDate }}
			</div >
		</div >
	</li >
</template >
<script >
	export default {
		props: {
			history: {
				type: Object,
				require: true
			}
		},
		data() {
			return {
				bg: ''
			}
		},
		methods: {
			changeBg(status) {
				if (status === 'Approved') {
					return 'bg-green'
				}
				if (status === 'Process') {
					return 'bg-orange'
				}
				if (status === 'Rejected') {
					return 'bg-danger-400'
				}
			}
		}
	}
</script >
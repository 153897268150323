import axios from '../services/hrms'

const resource = '/helper'
export default {
	validAccessToken(token) {
		return axios.post(`${ resource }/valid-access-token`, {
			headers: {
				'x-access-token': `${ token }`
			}
		})
	},
	RoleByAccessToken() {
		let token = JSON.parse(localStorage.getItem('hr_token')).access_token
		return axios.get(`${ resource }/role-by-access-token`, {
			headers: {
				'x-access-token': `${ token }`
			}
		})
	}
}

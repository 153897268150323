<template>
    <div>
        <Header>
            <template v-slot:nav-item-left>
                <li class="nav-item">
                    <router-link class="navbar-nav-link" to="/kpi-by-position">Edit KPI</router-link>
                </li>
            </template>
        </Header>
        <div class="page-content">
            <Sidebar/>
            <div class="content-wrapper">
                <div class="content">
                    <breadcrumb-component :links="[{name:'KPI'},{name:'Position'},{name:'Edit KPI '+model.bulan+'-'+model.tahun}]"></breadcrumb-component>
                    <div class="row">
                        <div class="col-md-2">
                            <div class="form-group selectdiv">
                                <select @change="filterDropdown" class="form-control" v-model="model.bulan">
                                    <option value="1">Januari</option>
                                    <option value="2">Februari</option>
                                    <option value="3">Maret</option>
                                    <option value="4">April</option>
                                    <option value="5">Mei</option>
                                    <option value="6">Juni</option>
                                    <option value="7">Juli</option>
                                    <option value="8">Agustus</option>
                                    <option value="9">September</option>
                                    <option value="10">Oktober</option>
                                    <option value="11">November</option>
                                    <option value="12">Desember</option>
                                </select>
                            </div>
                        </div>
                        <div class="col-md-2">
                            <div class="form-group selectdiv">
                                <select @change="filterDropdown" class="form-control" v-model="model.tahun">
                                    <template v-for="year in model.years">
                                        <option :key="year.id">{{ year.tahun }}</option>
                                    </template>
                                </select>
                            </div>
                        </div>
                        <div class="col-md-6">
                        </div>
                        <div class="col-md-2">
                            <div class="input-group mb-3">
                                <input aria-describedby="button-addon2" aria-label="Search Name" class="form-control" placeholder="Search Name" type="text" v-model="model.name">
                                <div class="input-group-append">
                                    <button @click="filterDropdown" class="btn btn-info" id="button-addon2" type="button">Search</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row mt-1">
                        <div class="col-md-12">
                            <div class="card">
                                <table class="table table-striped mb-0">
                                    <thead>
                                    <tr>
                                        <th class="text-muted font-weight-bold">
                                            <a @click="sort($event,'firstname')" href="javascript:void(0)">
                                                Name
                                            </a>
                                        </th>
                                        <th class="text-muted font-weight-bold">
                                            <a @click="sort($event,'occupation_id')" href="javascript:void(0)">
                                                Position
                                            </a>
                                        </th>
                                        <th class="text-muted font-weight-bold">
                                            <a @click="sort($event,'branch_id')" href="javascript:void(0)">
                                                Branch
                                            </a>
                                        </th>
                                        <th class="text-muted font-weight-bold">KPI NR</th>
                                        <th class="text-muted font-weight-bold">KPI AR</th>
                                        <th class="text-muted font-weight-bold">KPI NC</th>
                                        <th class="text-muted font-weight-bold">KPI MA</th>
                                        <th class="text-muted font-weight-bold">KPI NMI</th>
                                        <th class="text-muted font-weight-bold">KPI Lot</th>
                                        <th class="text-muted font-weight-bold">KPI ATA</th>
                                        <th class="text-muted font-weight-bold"></th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <template v-if="loading">
                                        <tr :key="a" v-for="a in filter.limit">
                                            <td :key="b" v-for="b in 11">
                                                <content-placeholders :rounded="true">
                                                    <content-placeholders-text :lines="1"/>
                                                </content-placeholders>
                                            </td>
                                        </tr>
                                    </template>
                                    <template v-else>
                                        <template v-if="kpiDetails.length === 0">
                                            <tr>
                                                <td colspan="11">No Data</td>
                                            </tr>
                                        </template>
                                        <template v-else>
                                            <tr :key="employee.employee.employee_id" v-for="employee in kpiDetails">
                                                <td>
                                                    <router-link
                                                            :to="`/employees/${employee.employee.employee_id}`"
                                                    >
                                                        {{ employee.employee.firstname + ' ' +
                                                        employee.employee.lastname }}
                                                    </router-link>
                                                </td>

                                                <td>{{employee.employee.occupation_name}}</td>
                                                <td>{{employee.employee.branch_name}}</td>
                                                <td>{{employee.kpi.nr | formatNum}}</td>
                                                <td>{{employee.kpi.ar | formatNum}}</td>
                                                <td>{{employee.kpi.nc | formatNum}}</td>
                                                <td>{{employee.kpi.ma_nc | formatNum}}</td>
                                                <td>{{employee.kpi.nmi | formatNum}}</td>
                                                <td>{{employee.kpi.lot | formatNum}}</td>
                                                <td>{{employee.kpi.ata | formatNum}}</td>
                                                <td>
                                                    <div class="list-icons">
                                                        <div class="list-icons-item dropdown">
                                                            <a aria-expanded="false" class="list-icons-item dropdown-toggle caret-0" data-toggle="dropdown"
                                                               href="javascript:void(0)">
                                                                <i class="fas fa-ellipsis-v text-green-700 fa-2x"></i>
                                                            </a>
                                                            <div class="dropdown-menu dropdown-menu-right">
                                                                <a @click="saveOrUpdate($event,employee)" class="dropdown-item" href="javascript:void(0)">
                                                                    <i class="fas fa-pencil-alt"></i>
                                                                    Edit KPI
                                                                </a>
                                                                <a @click="copyModel($event,employee)" class="dropdown-item" href="javascript:void(0)">
                                                                    <i class="fas fa-copy"></i>
                                                                    Copy KPI
                                                                </a>
                                                                <a @click="pasteModel($event,employee)" class="dropdown-item" href="javascript:void(0)">
                                                                    <i class="fas fa-paste"></i>
                                                                    Paste KPI
                                                                </a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                        </template>
                                    </template>
                                    </tbody>
                                </table>
                            </div>
                            <paginate
                                    :click-handler="clickCallback"
                                    :container-class="
				                  'pagination pagination-separated pagination-rounded align-self-center'
				                "
                                    :margin-pages="5"
                                    :next-class="'page-item'"
                                    :next-link-class="'page-link'"
                                    :next-text="'Next'"
                                    :page-class="'page-item'"
                                    :page-count="pagination.totalPage"
                                    :page-link-class="'page-link'"
                                    :page-range="5"
                                    :prev-class="'page-item'"
                                    :prev-link-class="'page-link'"
                                    :prev-text="'Prev'"
                            ></paginate>
                        </div>
                    </div>
                </div>
            </div>
        </div>


        <b-modal
                :hide-footer="true"
                :no-close-on-backdrop="true"
                :no-close-on-esc="true"
                header-class="bg-white"
                id="createOrUpdate"
                ref="createOrUpdate"
                title="Update KPI"
        >
            <form @submit.prevent="submitUpdateForm">
                <div class="row">
                    <div class="col-md-6 mb-2">
                        <label for="nr">NR</label>
                        <input class="form-control" id="nr" type="text" v-model="defaultValue.nr"/>
                    </div>
                    <div class="col-md-6 mb-2">
                        <label for="ar">AR</label>
                        <input class="form-control" id="ar" type="text" v-model="defaultValue.ar"/>
                    </div>
                    <div class="col-md-6 mb-2">
                        <label for="nc">NC</label>
                        <input class="form-control" id="nc" type="text" v-model="defaultValue.nc"/>
                    </div>
                    <div class="col-md-6 mb-2">
                        <label for="ma_nc">MA</label>
                        <input class="form-control" id="ma_nc" type="text" v-model="defaultValue.ma_nc"/>
                    </div>
                    <div class="col-md-6 mb-2">
                        <label for="nmi">NMI</label>
                        <input class="form-control" id="nmi" type="text" v-model="defaultValue.nmi"/>
                    </div>
                    <div class="col-md-6 mb-2">
                        <label for="lot">LOT</label>
                        <input class="form-control" id="lot" type="text" v-model="defaultValue.lot"/>
                    </div>
                    <div class="col-md-6 mb-2">
                        <label for="ata">ATA</label>
                        <input class="form-control" id="ata" type="text" v-model="defaultValue.ata"/>
                    </div>
                </div>
                <div class="form-group">
                </div>
                <button class="btn bg-green-700 btn-block mt-3" type="submit">Submit</button>
            </form>
        </b-modal>

    </div>
</template>
<script>
    import KpiApi from '../apis/Kpi'
    import KpiEmployeeApi from '../apis/KpiEmployee'
    import Hrms from '../services/hrms'
    import Paginate from '../components/Paginate'

    export default {
        data() {
            return {
                loading: true,
                localEmployee: JSON.parse(localStorage.getItem('hr_employee')),
                pagination: {
                    totalPage: 1,
                    currentPage: 1,
                    totalData: 0,
                    displaying: 0
                },
                filter: {
                    employee_no: null,
                    fullname: null,
                    email: null,
                    branch_id: null,
                    department_id: 32,
                    occupation_id: null,
                    limit: 12
                },
                employees: [],
                model: {
                    name: '',
                    years: [],
                    bulan: this.$route.params.month,
                    tahun: this.$route.params.year
                },
                kpiEmployees: [],
                defaultValue: {
                    id: null,
                    kpi_id: null,
                    employee_id: null,
                    nr: 0.00,
                    ar: 0.00,
                    nc: 0.00,
                    ma_nc: 0.00,
                    nmi: 0.00,
                    lot: 0.00,
                    ata: 0.00,
                    created_by: null,
                    created_at: null,
                    updated_by: null,
                    updated_at: null,
                    branch_id: null,
                    occupation_id: null,
                    bulan: null,
                    tahun: null
                },
                kpiDetails: [],
                kpi: {
                    id: null,
                    year: null,
                    created_at: null,
                    updated_at: null,
                    created_by: null,
                    updated_by: null
                },
                sort_filter: {
                    sort: 'asc',
                    order: 'id'
                },
                employeeModel: {},
                copyKpiModel: {}
            }
        },
        components: {
            Header: () =>
                import(
                    /* webpackChunkName: "component-header" */
                    /* webpackPrefetch: true */
                    /* webpackMode: "eager" */
                    '../components/Header'
                    ),
            Sidebar: () =>
                import(
                    /* webpackChunkName: "component-sidebar" */
                    /* webpackPrefetch: true */

                    /* webpackMode: "eager" */
                    '../components/Sidebar'
                    ),
            Paginate
        },
        methods: {
            sort(e, order) {
                this.loading = true
                if (this.sort_filter.sort === 'desc') {
                    this.sort_filter.sort = 'asc'
                } else {
                    this.sort_filter.sort = 'desc'
                }
                this.sort_filter.order = order
                let query = 'year=' + this.model.tahun + '&month=' + this.model.bulan + '&sort=' + this.sort_filter.order + ' ' + this.sort_filter.sort + '&name=' + this.model.name
                this.kpiEmployeeDetail(query)
            },
            removeString(txt) {
                if (typeof txt !== 'undefined' && txt !== null) {
                    return txt.toString().replace(/[^0-9]/g, '')
                }
                return 0;
            },
            submitUpdateForm(e) {
                this.loading = true;
                let now = new Date();
                let dt = now.toISOString().split('T')[0] + ' ' + now.toTimeString().split(' ')[0]
                if (this.defaultValue.id) {
                    let payload = {
                        kpi_id: parseInt(this.removeString(this.defaultValue.kpi_id)),
                        employee_id: parseInt(this.removeString(this.defaultValue.employee_id)),
                        nr: parseFloat(this.defaultValue.nr),
                        ar: parseFloat(this.defaultValue.ar),
                        nc: parseFloat(this.defaultValue.nc),
                        ma_nc: parseFloat(this.defaultValue.ma_nc),
                        nmi: parseFloat(this.defaultValue.nmi),
                        lot: parseFloat(this.defaultValue.lot),
                        ata: parseFloat(this.defaultValue.ata),
                        updated_by: parseInt(this.removeString(this.localEmployee.id)),
                        updated_at: dt,
                        branch_id: parseInt(this.removeString(this.defaultValue.branch_id)),
                        occupation_id: parseInt(this.removeString(this.defaultValue.occupation_id)),
                        bulan: parseInt(this.removeString(this.model.bulan)),
                        tahun: parseInt(this.removeString(this.model.tahun))
                    }
                    console.log('update', payload)
                    this.KpiEmployeeUpdate(payload, this.defaultValue.id)
                } else {
                    let payload = {
                        kpi_id: parseInt(this.removeString(this.kpi.id)),
                        employee_id: parseInt(this.removeString(this.employeeModel.employee_id)),
                        nr: parseFloat(this.defaultValue.nr),
                        ar: parseFloat(this.defaultValue.ar),
                        nc: parseFloat(this.defaultValue.nc),
                        ma_nc: parseFloat(this.defaultValue.ma_nc),
                        nmi: parseFloat(this.defaultValue.nmi),
                        lot: parseFloat(this.defaultValue.lot),
                        ata: parseFloat(this.defaultValue.ata),
                        updated_by: parseInt(this.removeString(this.localEmployee.id)),
                        updated_at: dt,
                        created_by: parseInt(this.removeString(this.localEmployee.id)),
                        created_at: dt,
                        branch_id: parseInt(this.removeString(this.employeeModel.branch_id)),
                        occupation_id: parseInt(this.removeString(this.employeeModel.occupation_id)),
                        bulan: parseInt(this.removeString(this.model.bulan)),
                        tahun: parseInt(this.removeString(this.model.tahun))
                    }
                    console.log('store', payload)
                    this.KpiEmployeeStore(payload)
                }
                this.resetDefaultValue()
                this.loading = true
                this.$refs['createOrUpdate'].hide()
                let query = 'year=' + this.model.tahun + '&month=' + this.model.bulan + '&page=' + this.pagination.currentPage
                this.kpiEmployeeDetail(query)
                this.kpiSearch({limit: -1, sort: 3, order: 'tahun'})
            },
            resetDefaultValue() {
                this.defaultValue = {
                    id: null,
                    kpi_id: null,
                    employee_id: null,
                    nr: 0.00,
                    ar: 0.00,
                    nc: 0.00,
                    ma_nc: 0.00,
                    nmi: 0.00,
                    lot: 0.00,
                    ata: 0.00,
                    created_by: null,
                    created_at: null,
                    updated_by: null,
                    updated_at: null,
                    branch_id: null,
                    occupation_id: null,
                    bulan: null,
                    tahun: null
                }
            },
            saveOrUpdate(event, employee) {
                this.employeeModel = employee.employee
                console.log(this.employeeModel)
                let that = this
                let defaultValue = {
                    id: null,
                    kpi_id: null,
                    employee_id: null,
                    nr: 0.00,
                    ar: 0.00,
                    nc: 0.00,
                    ma_nc: 0.00,
                    nmi: 0.00,
                    lot: 0.00,
                    ata: 0.00,
                    created_by: null,
                    created_at: null,
                    updated_by: null,
                    updated_at: null,
                    branch_id: null,
                    occupation_id: null,
                    bulan: null,
                    tahun: null
                }
                if (!_.isEmpty(employee.kpi)) {
                    this.defaultValue = employee.kpi
                } else {
                    this.defaultValue = defaultValue
                    this.defaultValue.created_by = this.localEmployee.id
                    this.defaultValue.employee_id = this.employeeModel.employee_id
                    this.defaultValue.kpi_id = this.kpi.id
                    this.defaultValue.occupation_id = this.employeeModel.occupation_id
                    this.defaultValue.branch_id = this.employeeModel.branch_id
                    this.defaultValue.bulan = this.model.bulan
                    this.defaultValue.tahun = this.model.tahun
                }

                this.$refs['createOrUpdate'].show()
            },
            filterDropdown(e) {
                document.title = 'KPI Management ' + this.model.tahun + '/' + this.model.bulan
                this.loading = true
                let query = 'year=' + this.model.tahun + '&month=' + this.model.bulan + '&page=' + this.pagination.currentPage + '&name=' + this.model.name
                this.kpiEmployeeDetail(query)
                this.kpiSearch({limit: -1, sort: 3, order: 'tahun'})
            },
            clickCallback(e) {
                this.loading = true
                const search = {}
                let query = 'year=' + this.model.tahun + '&month=' + this.model.bulan + '&page=' + e + '&sort=' + this.sort_filter.order + ' ' + this.sort_filter.sort + '&name=' + this.model.name
                this.kpiEmployeeDetail(query)
                this.kpiSearch({limit: -1, sort: 3, order: 'tahun'})
            },
            kpiSearch(param) {
                Hrms.interceptors.response.use(
                    response => response,
                    error => {
                        if (typeof error.response === 'undefined') {
                            this.$swal(
                                'Ooops!',
                                'A network error occurred. ' +
                                'This could be a CORS issue or a dropped internet connection. ' +
                                'It is not possible for us to know. [CE-KPI-001]',
                                'error'
                            )
                        }
                        return Promise.reject(error)
                    }
                )
                KpiApi.search(param)
                    .then(res => {
                        let that = this
                        this.model.years = res.data.data
                        res.data.data.forEach((v, i) => {
                            if (parseInt(that.model.tahun) === parseInt(v.tahun)) {
                                that.kpi = v
                            }
                        })
                        console.log(this.kpi)
                    })
                    .catch(error => {

                        this.$swal(
                            'Ooops!',
                            'A network error occurred. ' +
                            'It is not possible for us to know. [CE-KPI-002]',
                            'error'
                        )
                    })
            },
            KpiEmployeeUpdate(payload, id) {
                Hrms.interceptors.response.use(
                    response => response,
                    error => {
                        if (typeof error.response === 'undefined') {
                            this.$swal(
                                'Ooops!',
                                'A network error occurred. ' +
                                'This could be a CORS issue or a dropped internet connection. ' +
                                'It is not possible for us to know. [CE-KPIUPDATE-001]',
                                'error'
                            )
                        }
                        return Promise.reject(error)
                    }
                )
                KpiEmployeeApi
                    .update(payload, id)
                    .then(res => {

                    })
                    .catch(err => {

                    })
            },
            KpiEmployeeStore(payload) {
                Hrms.interceptors.response.use(
                    response => response,
                    error => {
                        if (typeof error.response === 'undefined') {
                            this.$swal(
                                'Ooops!',
                                'A network error occurred. ' +
                                'This could be a CORS issue or a dropped internet connection. ' +
                                'It is not possible for us to know. [CE-KPIUPDATE-001]',
                                'error'
                            )
                        }
                        return Promise.reject(error)
                    }
                )
                KpiEmployeeApi
                    .store(payload)
                    .then(res => {

                    })
                    .catch(err => {

                    })
            },
            KpiEmployeeSearch(param) {
                Hrms.interceptors.response.use(
                    response => response,
                    error => {
                        if (typeof error.response === 'undefined') {
                            this.$swal(
                                'Ooops!',
                                'A network error occurred. ' +
                                'This could be a CORS issue or a dropped internet connection. ' +
                                'It is not possible for us to know. [CE-KPI-001]',
                                'error'
                            )
                        }
                        return Promise.reject(error)
                    }
                )
                KpiEmployeeApi.search(param)
                    .then(res => {
                        if (res.data.data.length !== 0) {
                            this.kpiEmployees[param.employee_id] = res.data.data[0]
                        } else {
                            this.kpiEmployees[param.employee_id] = this.defaultValue
                        }
                    })
                    .catch(error => {

                        this.$swal(
                            'Ooops!',
                            'A network error occurred. ' +
                            'It is not possible for us to know. [CE-KPI-002]',
                            'error'
                        )
                    })
            },
            updateHTML(elmId, value) {
                var elem = document.getElementById(elmId);
                if (typeof elem !== 'undefined' && elem !== null) {
                    elem.innerHTML = value;
                }
            },
            copyModel(e, d) {
                this.copyKpiModel = d.kpi
            },
            pasteModel(e, d) {
                let pagination = this.pagination
                console.log(this.copyKpiModel);
                if (!_.isEmpty(this.copyKpiModel)) {
                    let now = new Date();
                    let dt = now.toISOString().split('T')[0] + ' ' + now.toTimeString().split(' ')[0]
                    KpiEmployeeApi.search({
                        employee_id: d.employee.employee_id,
                        tahun: this.model.tahun,
                        bulan: this.model.bulan,
                        limit: 1
                    })
                        .then(res => {
                            console.log(res)
                            let scenario;
                            if (res.data.data.length === 0) {
                                let payload = {
                                    kpi_id: parseInt(this.removeString(this.copyKpiModel.kpi_id)),
                                    employee_id: parseInt(this.removeString(d.employee.employee_id)),
                                    nr: parseFloat(this.copyKpiModel.nr),
                                    ar: parseFloat(this.copyKpiModel.ar),
                                    nc: parseFloat(this.copyKpiModel.nc),
                                    ma_nc: parseFloat(this.copyKpiModel.ma_nc),
                                    nmi: parseFloat(this.copyKpiModel.nmi),
                                    lot: parseFloat(this.copyKpiModel.lot),
                                    ata: parseFloat(this.copyKpiModel.ata),
                                    created_by: parseInt(this.removeString(this.localEmployee.id)),
                                    created_at: dt,
                                    updated_by: parseInt(this.removeString(this.localEmployee.id)),
                                    updated_at: dt,
                                    branch_id: parseInt(this.removeString(d.branch_id)),
                                    occupation_id: parseInt(this.removeString(d.occupation_id)),
                                    bulan: parseInt(this.removeString(this.model.bulan)),
                                    tahun: parseInt(this.removeString(this.model.tahun))
                                }
                                console.log('store', payload)
                                this.KpiEmployeeStore(payload)
                            } else {
                                let payload = {
                                    kpi_id: parseInt(this.removeString(this.copyKpiModel.kpi_id)),
                                    employee_id: parseInt(this.removeString(d.employee.employee_id)),
                                    nr: parseFloat(this.copyKpiModel.nr),
                                    ar: parseFloat(this.copyKpiModel.ar),
                                    nc: parseFloat(this.copyKpiModel.nc),
                                    ma_nc: parseFloat(this.copyKpiModel.ma_nc),
                                    nmi: parseFloat(this.copyKpiModel.nmi),
                                    lot: parseFloat(this.copyKpiModel.lot),
                                    ata: parseFloat(this.copyKpiModel.ata),
                                    updated_by: parseInt(this.removeString(this.localEmployee.id)),
                                    updated_at: dt,
                                    branch_id: parseInt(this.removeString(this.copyKpiModel.branch_id)),
                                    occupation_id: parseInt(this.removeString(this.copyKpiModel.occupation_id)),
                                    bulan: parseInt(this.removeString(this.model.bulan)),
                                    tahun: parseInt(this.removeString(this.model.tahun))
                                }
                                // console.log('update',payload)
                                // console.log('employee',d.kpi.id)
                                this.KpiEmployeeUpdate(payload, d.kpi.id)
                            }
                            this.loading = true
                            let query = 'year=' + this.model.tahun + '&month=' + this.model.bulan + '&page=' + this.pagination.currentPage
                            this.kpiEmployeeDetail(query)
                            this.kpiSearch({limit: -1, sort: 3, order: 'tahun'})
                        })
                        .catch()
                } else {
                    console.log('nothin to copy')
                }
            },
            kpiEmployeeDetail(query) {
                Hrms.interceptors.response.use(
                    response => response,
                    error => {
                        if (typeof error.response === 'undefined') {
                            this.$swal(
                                'Ooops!',
                                'A network error occurred. ' +
                                'This could be a CORS issue or a dropped internet connection. ' +
                                'It is not possible for us to know. [CE-KPIUPDATE-001]',
                                'error'
                            )
                        }
                        return Promise.reject(error)
                    }
                )

                KpiApi
                    .kpiDetail(query)
                    .then(res => {

                        this.kpiDetails = res.data.data
                        let totalPage = _.divide(res.data.pages.totalCount, res.data.pages.defaultPageSize)
                        this.pagination.totalPage = _.ceil(totalPage)
                        this.pagination.currentPage = res.data.meta.current_page
                        this.pagination.totalData = res.data.pages.totalCount
                        this.pagination.displaying = 0
                        this.loading = false;

                    })
                    .catch(err => {
                        console.log(err)
                        this.loading = false;
                        this.pagination.totalPage = _.ceil(1)
                        this.$swal(
                            'Ooops!',
                            'A network error occurred. ' +
                            'This could be a CORS issue or a dropped internet connection. ' +
                            'It is not possible for us to know. [CE-KPIDETAIL-002]',
                            'error'
                        )
                    })
            }
        },
        mounted() {
            document.title = 'KPI Management ' + this.model.tahun + '/' + this.model.bulan
            let query = 'year=' + this.model.tahun + '&month=' + this.model.bulan
            this.kpiEmployeeDetail(query)
            this.kpiSearch({limit: -1, sort: 3, order: 'tahun'})
        },
        beforeRouteUpdate(to, from, next) {
            this.kpiEmployeeDetail('year=' + this.model.tahun + '&month=' + this.model.bulan)
            this.loading = true
            this.kpiSearch({limit: -1, sort: 3, order: 'tahun'})
            next()

        },
        filters: {
            formatNum(value) {
                return new Intl.NumberFormat('id-ID').format(value)
            }
        }
    }
</script>
import axios from '../services/hrms'

const resource = '/kpi'
export default {
    rewardBestByFm(start_date,end_date) {
        let token = JSON.parse(localStorage.getItem('hr_token')).access_token
        let url = `${ resource }/best-fm?start_date=${ start_date }&end_date=${ end_date }`;
        if(!start_date && !end_date){
            url = `${ resource }/best-fm`;
        }
        return axios.get( url, {
            headers: {
                'x-access-token': `${ token }`
            }
        })
    },
    rewardBestByRm(start_date,end_date) {
        let token = JSON.parse(localStorage.getItem('hr_token')).access_token
        let url = `${resource }/best-rm?start_date=${ start_date }&end_date=${ end_date }`;
        if(!start_date && !end_date){
            url = `${resource }/best-rm`;
        }
        return axios.get( url, {
            headers: {
                'x-access-token': `${ token }`
            }
        })
    },
    rewardBestByCfm(start_date,end_date) {
        let token = JSON.parse(localStorage.getItem('hr_token')).access_token
        let url = `${resource }/best-cfm?start_date=${ start_date }&end_date=${ end_date }`;
        if(!start_date && !end_date){
            url = `${resource }/best-cfm`;
        }
        return axios.get( url, {
            headers: {
                'x-access-token': `${ token }`
            }
        })
    },
    rewardBestByCrm(start_date,end_date) {
        let token = JSON.parse(localStorage.getItem('hr_token')).access_token
        let url = `${ resource }/best-crm?start_date=${ start_date }&end_date=${ end_date }`;
        if(!start_date && !end_date){
            url = `${ resource }/best-crm`;
        }
        return axios.get( url, {
            headers: {
                'x-access-token': `${ token }`
            }
        })
    },
    rewardBestByGrowth(start_date,end_date,category,page=1) {
        let token = JSON.parse(localStorage.getItem('hr_token')).access_token
        let url = `${ resource }/best-branch?category=${category}&start_date=${ start_date }&end_date=${ end_date }&page=${page}`;
        if(!start_date && !end_date){
            url = `${ resource }/best-branch`;
        }
        return axios.get( url, {
            headers: {
                'x-access-token': `${ token }`
            }
        })
    }
}
<template >
	<div class = "navbar navbar-expand-md navbar-light fixed-top" >
		<!-- Header with logos -->
		<div class = "navbar-header navbar-dark d-none d-md-flex align-items-md-center" >
			<div class = "navbar-brand navbar-brand-md" >
				<a class = "d-inline-block" href = "javascript:void(0)" >
					<img alt src = "../scss/global_assets/images/logo_light.png" />
				</a >
			</div >
			
			<div class = "navbar-brand navbar-brand-xs" >
				<a class = "d-inline-block" href = "javascript:void(0)" >
					<img alt src = "../scss/global_assets/images/logo_icon_light.png" />
				</a >
			</div >
		</div >
		<!-- /header with logos -->
		
		<!-- Mobile controls -->
		<div class = "d-flex flex-1 d-md-none" >
			<div class = "navbar-brand mr-auto" >
				<a class = "d-inline-block" href = "javascript:void(0)" >
					<img alt src = "../scss/global_assets/images/logo_dark.png" />
				</a >
			</div >
			
			<button
					class = "navbar-toggler"
					data-target = "#navbar-mobile"
					data-toggle = "collapse"
					type = "button"
			>
				<i class = "icon-tree5" ></i >
			</button >
			
			<button class = "navbar-toggler sidebar-mobile-main-toggle" type = "button" >
				<i class = "icon-paragraph-justify3" ></i >
			</button >
		</div >
		<!-- /mobile controls -->
		
		<!-- Navbar content -->
		<div class = "collapse navbar-collapse" id = "navbar-mobile" >
			<ul class = "navbar-nav" >
				<slot name = "nav-item-left" ></slot >
			</ul >
			<ul class = "navbar-nav ml-auto" >
				<slot name = "nav-item-right" ></slot >
				<li v-if="isVerify" class = "nav-item dropdown dropdown-user" >
					<a
							class = "navbar-nav-link d-flex align-items-center dropdown-toggle"
							data-toggle = "dropdown"
							href = "javascript:void(0)"
					>
						<template v-if = "typeof employee.photo !== 'undefined' && employee.photo" >
							<img
									:src = "
                  `${filesUrl[env] + employee.photo.file_path + employee.photo.file_name}`
                "
									class = "rounded-circle mr-2"
									height = "34"
									width = "34"
							/>
						</template >
						<template v-else >
							<img
									class = "rounded-circle mr-2"
									height = "34"
									src = "../scss/global_assets/images/placeholders/placeholder.jpg"
									width = "34"
							/>
						</template >
						<span >{{ employee.firstname + ' ' + employee.lastname }}</span >
					</a >
					
					<div class = "dropdown-menu dropdown-menu-right" >
						<router-link :to = "`/employees/${employee.id}`" class = "dropdown-item" >
							<i class = "icon-user-plus" ></i > My Profile
						</router-link >
						
						<router-link :to = "`/employees/${employee.id}/edit`" class = "dropdown-item" >
							<i class = "icon-user-plus" ></i > Edit Profile
						</router-link >
						<a @click = "logout" class = "dropdown-item" href = "javascript:void(0)" >
							<i class = "icon-switch2" ></i > Logout
						</a >
					</div >
				</li >
			</ul >
		</div >
		<!-- /navbar content -->
	</div >
</template >
<script >
	import router         from '../routes'
	import Authentication from '../apis/Authtentication'
	import employeeObject from '../models/employeeObject'
	import employeeApi    from '../apis/Employee'
	import Hrms           from '../services/hrms'
	import Log            from '../apis/Log'
	import axios          from 'axios'

	export default {
		data() {
			return {
				isVerify:false,
				employee: employeeObject,
				env: process.env.NODE_ENV,
				filesUrl: {
					development: 'https://files.dev.mifx.com/',
					production: 'https://files.mifx.com/'
				}
			}
		},
		methods: {
			logout() {
				Hrms.interceptors.response.use(
					response => response,
					error => {
						if (typeof error.response === 'undefined') {
							this.$swal(
								'Ooops!',
								'A network error occurred. ' +
								'This could be a CORS issue or a dropped internet connection. ' +
								'It is not possible for us to know.',
								'error'
							)
						}
						return Promise.reject(error)
					}
				)
				let token = JSON.parse(localStorage.getItem('hr_token')).access_token
				console.log(token)
				Authentication.logout(token)
				              .then(res => {
					              axios
						              .get('https://www.cloudflare.com/cdn-cgi/trace', {
							              crossdomain: true
						              })
						              .then(result => {
							              console.log(result.data.split('\n'))
							              let ret = result.data.split('\n')
							              let ip = ret[ 2 ].split('ip=')

							              let payload = {
								              user_id: JSON.parse(localStorage.getItem('hr_token')).user.id,
								              user_type: 'user',
								              ip_address: ip[ 1 ],
								              original_source: 'ERP - HRMS',
								              log_type: 'logout',
								              log_datatoken: token
							              }
							              Log.LoginLogoutLogs(payload)
							                 .then(result => {
								                 console.log(result)
								                 localStorage.removeItem('hr_token')
								                 localStorage.removeItem('hr_employee')
								                 localStorage.removeItem('hr_token_object')
                        							localStorage.clear();
								                 router.push('/login')
							                 })
							                 .catch(err => {
								                 localStorage.removeItem('hr_token')
								                 localStorage.removeItem('hr_employee')
								                 localStorage.removeItem('hr_token_object')
                        localStorage.clear();
								                 router.push('/login')
							                 })
						              })
						              .catch(err => {
							              localStorage.removeItem('hr_token')
							              localStorage.removeItem('hr_employee')
							              localStorage.removeItem('hr_token_object')
                        localStorage.clear();
							              router.push('/login')
						              })
				              })
				              .catch(err => {
					              console.log(err)
					              localStorage.removeItem('hr_token')
					              localStorage.removeItem('hr_employee')
					              localStorage.removeItem('hr_token_object')
					              router.push('/login')
				              })
			},
			ViewEmployeeByUserId(id) {
				Hrms.interceptors.response.use(
					response => response,
					error => {
						if (typeof error.response === 'undefined') {
							this.$swal(
								'Ooops!',
								'A network error occurred. ' +
								'This could be a CORS issue or a dropped internet connection. ' +
								'It is not possible for us to know.',
								'error'
							)
						}
						return Promise.reject(error)
					}
				)
				employeeApi
					.ViewByUserId(id)
					.then(result => {
						this.employee = result.data.data
						this.employee.user.password = ""
						localStorage.setItem('hr_employee', JSON.stringify(result.data.data))
					})
					.catch(err => {
						console.log(err.response)
					})
			},
			forceHttps(url) {
				if (typeof url !== 'undefined' && url) {
					url = url.replace(/^http:\/\//i, 'https://')
				} else {
					url = false
				}
				return url
			}
		},
		mounted() {
			
			if(localStorage.getItem('is_verify') == 'true'){
				this.isVerify = true;
				if (localStorage.getItem('hr_employee')) {
					this.employee.user.password = ""
					this.employee = JSON.parse(localStorage.getItem('hr_employee'))
				} else {
					this.ViewEmployeeByUserId(
						JSON.parse(localStorage.getItem('hr_token')).user.id
					)
				}
			}
			document.body.className = "navbar-top";
		}
	}
</script >

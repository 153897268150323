<template>
  <div class="card card-body arrow-right">
    <div
      class="media align-items-center align-items-md-start flex-column flex-md-row"
    >
      <i
        :class="
          `mr-md-3 mb-3 mb-md-0 ${icons[status.toLowerCase()]} rounded-round ${
            text[status.toLowerCase()]
          } icon-3x`
        "
      ></i>
      <div class="media-body text-center text-md-left">
        <h6 class="media-title font-weight-semibold">
          {{ employee | str_limit(13) }}
        </h6>
        <span :class="`${text[status.toLowerCase()]} font-weight-bold`">
          {{ status }}
        </span>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'CardApproval',
  props: {
    status: {
      type: String,
      require: true
    },
    employee: {
      type: String,
      require: true
    }
  },
  data() {
    return {
      icons: {
        pending: 'icon-minus-circle2 ',
        rejected: 'icon-cancel-circle2 ',
        approved: 'icon-checkmark-circle ',
        partial: 'icon-question4'
      },
      text: {
        pending: 'text-warning-400',
        rejected: 'text-danger',
        approved: 'text-green-700',
        partial: 'text-orange-700'
      },
      background: {
        pending: 'bg-warning-400',
        rejected: 'bg-danger',
        approved: 'bg-green-700',
        partial: 'bg-orange-700'
      }
    }
  },
  methods: {
    statusChanger(status) {}
  },
  mounted() {}
}
</script>

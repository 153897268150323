const kpiByPosition = [{
    path: '/kpi-by-position',
    name: 'kpi-by-position-view',
    component: () =>
        import (
            /* webpackChunkName: "kpi-by-position-view" */
            /* webpackPrefetch: true */
            /* webpackMode: "eager" */
            '../views/KpiPosition.vue'
        ),
    meta: {
        requiresAuth: true,
        permission: 'GET:kpi.kpi-employee.search'
    }
}, {
    path: '/kpi-by-position/:year/:month',
    name: 'kpi-by-position-detail-view',
    component: () =>
        import (
            /* webpackChunkName: "kpi-by-position-detail-view" */
            /* webpackPrefetch: true */
            /* webpackMode: "eager" */
            '../views/KpiPositionDetail.vue'
        ),
    meta: {
        requiresAuth: true,
        permission: 'GET:kpi.kpi-employee.search'
    }
}]
export default kpiByPosition
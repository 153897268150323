<template>
  <div>
    <Header></Header>
    <div class="page-content">
      <Sidebar />
      <div class="content-wrapper">
        <div class="content">
          <div class="row">
            <div class="col-md-8">
              <div class="card">
                <div class="card-header bg-white">
                  Create Budget
                </div>
                <div class="card-body">
                  <div class="row">
                    <div class="col-md-12">
                      <div class="form-group">
                        <label for="name">Budget Title</label>
                        <input
                          type="text"
                          class="form-control"
                          id="name"
                          v-model="model.name"
                        />
                      </div>
                    </div>
                    <div class="col-md-12">
                      <div class="form-group">
                        <label for="description">Budget Description </label>
                        <textarea
                          class="form-control"
                          id="description"
                          cols="30"
                          rows="10"
                          v-model="model.description"
                        ></textarea>
                      </div>
                    </div>
                    <div class="col-md-12">
                      <div class="row">
                        <div class="col-md-6">
                          <div class="form-group">
                            <label for="amount">Budget Amount </label>
                            <input
                              type="text"
                              class="form-control"
                              id="amount"
                              v-model="model.amount"
                            />
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group">
                            <label for="due_date">Budget Due Date</label>
                            <datepicker
                              :input-class="'form-control'"
                              id="due_date"
                              placeholder="Due Date"
                              v-model="model.due_date"
                              :format="dateToDatabaseFormat"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-12">
                      <div class="form-group">
                        <label for="budget_title">Business Approval</label>
                        <div class="mt-2">
                          <label>1st Approval</label>
                          <model-select
                            :options="employees"
                            v-model="model.approval_user_id_satu"
                            @searchchange="selectSearch"
                          />
                        </div>
                        <div class="mt-2">
                          <label>2nd Approval</label>
                          <model-select
                            :options="employees"
                            v-model="model.approval_user_id_dua"
                            @searchchange="selectSearch"
                          />
                        </div>
                        <div class="mt-2">
                          <label>3rd Approval</label>
                          <model-select
                            :options="employees"
                            v-model="model.approval_user_id_tiga"
                            @searchchange="selectSearch"
                          />
                        </div>
                        <div class="mt-2">
                          <label>4th Approval</label>
                          <model-select
                            :options="employees"
                            v-model="model.approval_user_id_empat"
                            @searchchange="selectSearch"
                          />
                        </div>
                      </div>
                    </div>
                    <div class="col-md-12">
                      <label
                        >File
                        <input
                          type="file"
                          id="files"
                          ref="files"
                          @change="handleFileUpload"
                          multiple
                        />
                      </label>
                    </div>
                  </div>
                  <div class="row justify-content-end">
                    <div class="col-md-2">
                      <button
                        class="btn btn-success btn-block"
                        @click="submitForm"
                        v-if="!loading"
                      >
                        Submit
                      </button>
                      <button class="btn btn-success btn-block" disabled v-else>
                        Submit
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-4">
              <div class="card">
                <div class="card-header bg-transparent header-elements-inline">
                  <h6 class="card-title font-weight-semibold">
                    <i class="icon-folder6 mr-2"></i>
                    Attachments
                  </h6>

                  <div class="header-elements">
                    <span class="text-muted"
                      >({{
                        details.attachments ? details.attachments.length : '0'
                      }})</span
                    >
                  </div>
                </div>

                <div class="list-group list-group-flush">
                  <template v-for="file in details.attachments">
                    <a
                      href="javascript:void(0);"
                      class="list-group-item list-group-item-action"
                      :key="file.id"
                    >
                      <i class="fa fa-file mr-3"></i>
                      {{ file.file_name }}
                    </a>
                  </template>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import BackofficeApi from '../apis/Backoffice'
import { ModelListSelect, ModelSelect } from 'vue-search-select'
import Datepicker from 'vuejs-datepicker'
import moment from 'moment'
import router from '../routes/index'
export default {
  data() {
    return {
      employees: [],
      loading: false,
      model: {
        id: null,
        name: '',
        amount: '',
        due_date: '',
        approval_user_id_satu: null,
        approval_user_id_dua: null,
        approval_user_id_tiga: null,
        approval_user_id_empat: null,
        file_attachment: [],
        approval_user_id: [],
        date: null,
        description: ''
      },

      details: {
        status_name: 'Pending'
      },
      user_id: JSON.parse(localStorage.getItem('hr_employee')).user_id,
      comments: [],
      approvals: [],
      currentApproval: {},
      icons: {
        pending: 'icon-minus-circle2 ',
        rejected: 'icon-cancel-circle2 ',
        approved: 'icon-checkmark-circle ',
        partial: 'icon-question4'
      },
      text: {
        pending: 'text-warning-400',
        rejected: 'text-danger',
        approved: 'text-green-700',
        partial: 'text-orange-700'
      },
      background: {
        pending: 'bg-warning-400',
        rejected: 'bg-danger',
        approved: 'bg-green-700',
        partial: 'bg-orange-700'
      }
    }
  },
  components: {
    Header: () =>
      import(
        /* webpackChunkName: "component-header" */
        /* webpackPrefetch: true */
        /* webpackMode: "eager" */
        '../components/Header'
      ),
    Sidebar: () =>
      import(
        /* webpackChunkName: "component-sidebar" */
        /* webpackPrefetch: true */

        /* webpackMode: "eager" */
        '../components/Sidebar'
      ),
    Datepicker,
    ModelSelect,
    ModelListSelect
  },
  methods: {
    dateToDatabaseFormat(value) {
      return moment(String(value)).format('YYYY-MM-DD')
    },
    submitForm() {
      this.loading = true
      let formData = new FormData()
      let files = []
      for (var i = 0; i < this.model.file_attachment.length; i++) {
        files.push(URL.createObjectURL(this.model.file_attachment[i]))
        // let file = this.model.file_attachment[i];
        formData.append(
          'file_attachment[' + i + ']',
          this.model.file_attachment[i]
        )
      }
      // console.log(files)
      this.model.file_attachment = files
      if (this.model.approval_user_id_satu) {
        this.model.approval_user_id[0] = this.model.approval_user_id_satu
      }
      if (this.model.approval_user_id_dua) {
        this.model.approval_user_id[1] = this.model.approval_user_id_dua
      }
      if (this.model.approval_user_id_tiga) {
        this.model.approval_user_id[2] = this.model.approval_user_id_tiga
      }
      if (this.model.approval_user_id_empat) {
        this.model.approval_user_id[3] = this.model.approval_user_id_empat
      }
      this.model.date = this.dateToDatabaseFormat(new Date())
      for (let item in this.model) {
        console.log(item)
        if (item != 'file_attachment' && item != 'approval_user_id') {
          formData.append(item, this.model[item])
        }
        if (item == 'due_date') {
          formData.append(item, this.dateToDatabaseFormat(this.model[item]))
        }
      }

      for (var i = 0; i < this.model.approval_user_id.length; i++) {
        formData.append(
          'approval_user_id[' + i + ']',
          this.model.approval_user_id[i]
        )
      }
      let id = this.model.id
      BackofficeApi.budgetUpdate(id, formData)
        .then(res => {
          router.push('/budget-request')
        })
        .catch(res => {
          this.loading = false
          console.log(res.toJSON())
        })
    },
    handleFileUpload() {
      this.model.file_attachment = this.$refs.files.files
    },
    searchApproval(name) {
      BackofficeApi.searchEmployeeAjax(name, 'budget')
        .then(res => {
          this.employees = res.data.data.map(x => {
            return {
              value: x.user_id,
              text: x.firstname + ' ' + x.lastname + ' (' + x.email + ')'
            }
          })
        })
        .catch(res => {
          console.log(res)
        })
    },
    selectSearch(e) {
      if (e.length >= 3) {
        this.searchApproval(e)
      }
    },
    budgetView(query) {
      BackofficeApi.budgetView(query)
        .then(res => {
          console.log('details', res)
          this.details = res.data.data
          this.approval({ id: this.details.id, budget_id: this.details.id })
          this.model.id = this.details.id
          this.model.name = this.details.name
          this.model.amount = this.details.amount
          this.model.due_date = this.details.due_date
          this.model.description = this.details.description
        })
        .catch(err => {
          console.log(err.message)
        })
    },
    approval(query) {
      BackofficeApi.budgetViewApproval(query)
        .then(res => {
          console.log('approvals', res)
          this.approvals = res.data.data

          if (this.approvals.length > 0) {
            let order = {
              1: 'satu',
              2: 'dua',
              3: 'tiga',
              4: 'empat'
            }
            this.approvals.forEach((value, index) => {
              if (value.approval_type == 'business') {
                let name = 'approval_user_id_' + order[value.order_no]
                this.searchApproval(value.first_name)
                this.model[name] = value.user_id
              }
            })
          }

          console.log('model', this.model)
        })
        .catch(err => {
          console.log(err.message)
        })
    }
  },
  mounted() {
    document.title = 'Update Budget Request'
    this.budgetView({ id: this.$route.params.id })
  }
}
</script>

import axios from '../services/hrms'

const resource = '/kpi-score-branch'
export default {
	search(query) {
		let token = JSON.parse(localStorage.getItem('hr_token')).access_token
		const params = new URLSearchParams(query)
		return axios.get(`${ resource }/search?${ params }`, {
			headers: {
				'x-access-token': `${ token }`
			}
		})
	},
	view(id) {
		let token = JSON.parse(localStorage.getItem('hr_token')).access_token
		return axios.get(
			`${ resource }/${ id }`, {
				headers: {
					'x-access-token': `${ token }`
				}
			}
		)
	},
	store(payload) {
		let token = JSON.parse(localStorage.getItem('hr_token')).access_token
		return axios.post(`${ resource }/store`, payload, {
			headers: {
				'x-access-token': `${ token }`
			}
		})
	},
	update(payload, id) {
		let token = JSON.parse(localStorage.getItem('hr_token')).access_token
		return axios.post(`${ resource }/${ id }/update`, payload, {
			headers: {
				'x-access-token': `${ token }`
			}
		})
	},
	delete(id, payload) {
		let token = JSON.parse(localStorage.getItem('hr_token')).access_token
		return axios.post(`${ resource }/${ id }/delete`, payload, {
			headers: {
				'x-access-token': `${ token }`
			}
		})
	},
	kpiScoreBranchDetails(year, month) {
		let token = JSON.parse(localStorage.getItem('hr_token')).access_token
		return axios.get(
			`${ resource }/kpi-score-branch-details?year=${ year }&month=${ month }`, {
				headers: {
					'x-access-token': `${ token }`
				}
			}
		)
	},
	cloneKpiScoreBranchByMonthYear(from_year, from_month, to_year, to_month) {
		let token = JSON.parse(localStorage.getItem('hr_token')).access_token
		return axios.get(
			`${ resource }/clone-kpi-score-branch-by-month-year?from_year=${ from_year }&from_month=${ from_month }&to_year=${ to_year }&to_month=${ to_month }`, {
				headers: {
					'x-access-token': `${ token }`
				}
			}
		)
	},
	groupKpiScoreBranchByMonthYear(year, month) {
		let token = JSON.parse(localStorage.getItem('hr_token')).access_token
		return axios.get(
			`${ resource }/group-kpi-score-branch-by-month-year?year=${ year }&month=${ month }`, {
				headers: {
					'x-access-token': `${ token }`
				}
			}
		)
	}
}
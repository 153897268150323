<template>
    <div>
        <Header>
            <template v-slot:nav-item-left>
                <li class="nav-item">
                    <router-link class="navbar-nav-link" to="/">Reset Password</router-link>
                </li>
            </template>
        </Header>
        <div class="page-content">
            <Sidebar/>
            <div class="content-wrapper">
                <div class="content">
                    <div class="row">
                        <div class="col-md-6">
                            <form @submit.prevent="validateForm" action="javascript:void(0)">
                                <div class="card">
                                    <div class="card-body">
                                        <div class="form-group">
                                            <label for="">Username</label>
                                            <span class="d-block">{{ fullname }}</span>
                                        </div>
                                        <div class="form-group">
                                            <label for="">Password</label>
                                            <input class="form-control" type="password" v-model="user.password">
                                        </div>
                                        <div class="form-group">
                                            <label for="">Password Confirmation</label>
                                            <input class="form-control" type="password" v-model="user.password_confirmation">
                                        </div>
                                    </div>
                                    <div class="card-footer">
                                        <button class="btn btn-info btn-block">Update Password</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import EmployeeApi from '../apis/Employee'

    export default {
        data() {
            return {
                employee: {
                    id: null,
                    firstname: null,
                    lastname: null,
                    dob: null,
                    pob: null,
                    employee_no: null,
                    user_id: null,
                    phone: null,
                    image: null,
                    hire_date: null,
                    resign_date: null,
                    meal_allowance: null,
                    department_id: null,
                    occupation_id: null,
                    job_id: null,
                    type_id: null,
                    branch_id: null,
                    employee_group_id: null,
                    leave_type_id: null,
                    bank_id: null,
                    bank_account_no: null,
                    bank_type_id: null,
                    bank_account_name: null,
                    bpjs_kesehatan: null,
                    bpjs_ketenagakerjaan: null,
                    npwp: null,
                    created_date_user_id: null,
                    created_by_user_id: null,
                    updated_by_user_id: null,
                    updated_date_user_id: null,
                    created_date_client_id: null,
                    created_by_client_id: null,
                    updated_by_client_id: null,
                    updated_date_client_id: null,
                    created_via: null,
                    gender_id: null,
                    email: null,
                    identity_type_id: null,
                    identity_number: null,
                    company_name: null,
                    company_field: null,
                    job_desc: null,
                    bank_branch: null,
                    mobile_number: null,
                    email_signature: null,
                    personal_email: null,
                    alias_code: null,
                    upline_id: null,
                    biological_mother_name: null,
                    email_intro: null,
                    occupation:
                        {
                            id: null,
                            name: null,
                            parent_id: null,
                            department_id: null
                        },
                    department:
                        {
                            id: null,
                            name: null,
                            code: null,
                            manager_id: null,
                            parent_id: null,
                            is_sales: null
                        },
                    user:
                        {
                            id: null,
                            username: null,
                            password: null,
                            active: null,
                            last_login: null,
                            last_login_ip: null,
                            created_by_user_id: null,
                            updated_by_user_id: null,
                            created_date_user_id: null,
                            updated_date_user_id: null,
                            created_by_client_id: null,
                            updated_by_client_id: null,
                            created_date_client_id: null,
                            updated_date_client_id: null,
                            created_via: null
                        },
                    branch:
                        {
                            id: null,
                            code: null,
                            name: null,
                            address: null,
                            country_id: null,
                            province_id: null,
                            kabupaten_id: null,
                            kecamatan_id: null,
                            kelurahan_id: null,
                            zip_code: null,
                            phone: null,
                            fax: null,
                            representative_email: null,
                            digit_account_partial: null,
                            digit_account_regular: null,
                            meta_group_query: null,
                            active: null,
                            group_meta_code: null,
                            created_date: null,
                            updated_date: null,
                            group_demo_default: null,
                            is_headquarter: null,
                            is_partner: null
                        },
                    bank:
                        {
                            id: null,
                            bank_name: null,
                            bank_full_name: null,
                            active: null,
                            is_show_mtp: null,
                            image_file_host: null,
                            image_file_path: null,
                            image_file_name: null,
                            bank_url: null,
                            created_date: null,
                            created_by: null,
                            updated_date: null,
                            updated_by: null
                        },
                    upline:
                        {
                            id: null,
                            firstname: null,
                            lastname: null,
                            dob: null,
                            pob: null,
                            employee_no: null,
                            user_id: null,
                            phone: null,
                            image: null,
                            hire_date: null,
                            resign_date: null,
                            meal_allowance: null,
                            department_id: null,
                            occupation_id: null,
                            job_id: null,
                            type_id: null,
                            branch_id: null,
                            employee_group_id: null,
                            leave_type_id: null,
                            bank_id: null,
                            bank_account_no: null,
                            bank_type_id: null,
                            bank_account_name: null,
                            bpjs_kesehatan: null,
                            bpjs_ketenagakerjaan: null,
                            npwp: null,
                            created_date_user_id: null,
                            created_by_user_id: null,
                            updated_by_user_id: null,
                            updated_date_user_id: null,
                            created_date_client_id: null,
                            created_by_client_id: null,
                            updated_by_client_id: null,
                            updated_date_client_id: null,
                            created_via: null,
                            gender_id: null,
                            email: null,
                            identity_type_id: null,
                            identity_number: null,
                            company_name: null,
                            company_field: null,
                            job_desc: null,
                            bank_branch: null,
                            mobile_number: null,
                            email_signature: null,
                            personal_email: null,
                            alias_code: null,
                            upline_id: null,
                            biological_mother_name: null,
                            email_intro: null
                        }
                },
                user: {
                    password: '',
                    password_confirmation: ''
                }
            }
        },
        components: {
            Header: () =>
                import(
                    /* webpackChunkName: "component-header" */
                    /* webpackPrefetch: true */
                    /* webpackMode: "eager" */
                    '../components/Header'
                    ),
            Sidebar: () =>
                import(
                    /* webpackChunkName: "component-sidebar" */
                    /* webpackPrefetch: true */

                    /* webpackMode: "eager" */
                    '../components/Sidebar'
                    )
        },
        methods: {
            validateForm() {
                EmployeeApi
                    .changePassword({
                        username: this.employee.user.username,
                        password: this.user.password,
                        password_confirmation: this.user.password_confirmation
                    })
                    .then(res => {
                        console.log(res)
                        this.$swal(
                            'Success',
                            'Password Has Changed',
                            'success'
                        )
                    })
                    .catch(err => {
                        this.$swal(
                            'Ooops!',
                            'A network error occurred. ' + 'It is not possible for us to know.',
                            'error'
                        )
                    })
            },
            employeeView(id) {
                EmployeeApi
                    .view(id)
                    .then(res => {
                        this.employee = res.data.data
                    })
                    .catch(error => {
                        console.log(error.response.data.errors)
                    })
            }
        },
        computed: {
            fullname: function () {
                return this.employee.firstname + ' ' + this.employee.lastname + ' / ' + this.employee.email
            }
        },
        mounted() {
            document.title = 'Employee Dashboard'
            this.employeeView(this.$route.params.id)
        },
        beforeRouteUpdate(to, from, next) {
            this.loading = true
            this.employeeView(to)
            next()

        }
    }
</script>

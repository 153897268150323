import axios from '../services/hrms'

const resource = '/auth'
export default {
	authorize(username, password,ip,loc,via,ua) {
		return axios.post(`${ resource }/authorize`, {
			username: username,
			password: password,
			ip: ip,
			loc: loc,
			via: via,
			"user_agent": ua
		})
	},
	accesstoken(authorization_code) {
		return axios.post(`${ resource }/accesstoken`, {
			authorization_code: authorization_code
		})
	},
	logout(token) {
		return axios.get(`${ resource }/logout`, {
			headers: {
				'x-access-token': `${ token }`
			}
		})
	},
	validGoogleAuth(code,user_id) {
		let token = JSON.parse(localStorage.getItem('hr_token')).access_token
		return axios.post(`${ resource }/valid-2fa`, {
			code: code,
			user_id:user_id
		},{
			headers: {
			  'x-access-token': `${token}`
			}
		  })
	},
	validGoogleAuthResend(user_id) {
		let token = JSON.parse(localStorage.getItem('hr_token')).access_token
		return axios.post(`${ resource }/resend-email`, {
			user_id
		},{
			headers: {
			  'x-access-token': `${token}`
			}
		  })
	}
}

export default {
    data() {
        return {
            permissions: []
        }
    },
    methods: {
        setPermissions() {
            this.permissions = JSON.parse(localStorage.getItem('hr_permission'))
        },
        isHavePermission(permission) {
            const acc = permission
            return this.permissions.find(el => {
                return acc === el
            })
        }
    },
    mounted() {
        this.setPermissions()
    }
}
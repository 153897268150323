const apis = {
  production: {
    backoffice: 'https://api-backoffice.mifx.com',
    client: 'https://api-client.mifx.com/v2',
    hrms: 'https://api-hrms.mifx.com/api/v1',
    sales: 'https://api-sales.mifx.com/v1',
    report: 'https://api-report.mifx.com',
    logs: 'https://api-logs.mifx.com/',
    master: 'https://api-master.mifx.com/v1'
  },
  
  // development: {
  //   backoffice: 'https://api-backoffice.mifx.com',
  //   client: 'https://api-client.mifx.com/v2',
  //   hrms: 'https://api-hrms.mifx.com/api/v1',
  //   sales: 'https://api-sales.mifx.com/v1',
  //   report: 'https://api-report.mifx.com',
  //   logs: 'https://api-logs.mifx.com/',
  //   master: 'https://api-master.mifx.com/v1'
  // },
  
  development: {
    backoffice: 'https://backoffice-api.dev.mifx.com',
    client: 'https://api-client.dev.mifx.com/v2',
    hrms: 'https://hrms-api.dev.mifx.com/api/v1',
    sales: 'https://api-sales.dev.mifx.com/v1',
    report: 'https://api-report.dev.mifx.com',
    logs: 'https://api-logs.dev.mifx.com/',
    master: 'https://api-master.dev.mifx.com/v1'
  },
  qa: {
    backoffice: 'https://backoffice-api-qa.mifx.com',
    client: 'https://api-client-qa.mifx.com/v2',
    hrms: 'https://hrms-api-qa.mifx.com/api/v1',
    sales: 'https://api-sales-qa.mifx.com/v1',
    report: 'https://api-report-qa.mifx.com',
    logs: 'https://api-logs-qa.mifx.com/',
    master: 'https://api-master-qa.mifx.com/v1'
  }
}
export default apis

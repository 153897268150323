<template>
  <div>
    <Header>
      <template v-slot:nav-item-left>
        <li class="nav-item">
          <router-link class="navbar-nav-link" to="/achievement-table">
            <i class="fas fa-chevron-left text-green-700"></i>
            Achievement Detail
          </router-link>
        </li>
      </template>
    </Header>
    <div class="page-content">
      <Sidebar />
      <div class="content-wrapper">
        <div class="content">
          <breadcrumb-component
            :links="[
              { name: 'Achievement Table' },
              { name: model.bulan + '-' + model.tahun }
            ]"
          ></breadcrumb-component>
          <div class="row mb-3">
            <div class="col-md-2">
              <div class="card p-1">
                <div class="form-group mb-0">
                  <label for="">Branch</label>
                  <select
                    @change="filterBranch"
                    class="form-control"
                    v-model="model.branch"
                  >
                    <template v-for="branch in branches">
                      <option :key="branch.id" :value="branch.id"
                        >{{ branch.name }}
                      </option>
                    </template>
                  </select>
                </div>
              </div>
            </div>
            <div class="col-md-2">
              <div class="card p-1">
                <div class="form-group mb-0">
                  <label for="">Position</label>
                  <select
                    @change="filterPosition"
                    class="form-control"
                    id=""
                    name=""
                    v-model="model.occupation"
                  >
                    <template v-for="occupatio in occupations">
                      <option :key="occupatio.id" :value="occupatio.id"
                        >{{ occupatio.name }}
                      </option>
                    </template>
                  </select>
                </div>
              </div>
            </div>
            <div class="col-md-2">
              <div class="card">
                <dl class="p-2">
                  <dt>Branch Score</dt>
                  <dd>
                    <content-placeholders :rounded="true" v-if="loading">
                      <content-placeholders-text :lines="1" />
                    </content-placeholders>
                    <template v-if="!loading">
                      {{ branch.score }}
                    </template>
                  </dd>
                </dl>
              </div>
            </div>
          </div>
          <div class="row mb-3 justify-content-end">
            <div class="col-md-2">
              <div class="form-group selectdiv">
                <select
                  @change="filterMonthYear"
                  class="form-control"
                  v-model="model.bulan"
                >
                  <option value="1">Januari</option>
                  <option value="2">Februari</option>
                  <option value="3">Maret</option>
                  <option value="4">April</option>
                  <option value="5">Mei</option>
                  <option value="6">Juni</option>
                  <option value="7">Juli</option>
                  <option value="8">Agustus</option>
                  <option value="9">September</option>
                  <option value="10">Oktober</option>
                  <option value="11">November</option>
                  <option value="12">Desember</option>
                  <option value="13">Quarter 1</option>
                  <option value="14">Quarter 2</option>
                  <option value="15">Quarter 3</option>
                  <option value="16">Quarter 4</option>
                  <option value="17">Year To Date</option>
                </select>
              </div>
            </div>
            <div class="col-md-2">
              <div class="form-group selectdiv">
                <select
                  @change="filterMonthYear"
                  class="form-control"
                  v-model="model.tahun"
                >
                  <option
                    :key="index"
                    :value="year"
                    v-for="(year, index) in years"
                    >{{ year }}
                  </option>
                </select>
              </div>
            </div>
            <div class="col-md-2 mb-1">
              <download-excel
                :data="json_data"
                :fields="json_fields"
                :name="`achievement_table_branch_detail_${json_filename}.xls`"
                class="btn bg-green-600 btn-block rounded-round btn-labeled btn-labeled-left"
                worksheet="Achievement"
              >
                <b>
                  <i class="icon-filter3"></i>
                </b>
                Export
              </download-excel>
            </div>
          </div>
          <div class="row mb-3">
            <div class="col">
              <div class="card">
                <dl class="p-2">
                  <dt>Total NR</dt>
                  <dd>
                    <content-placeholders :rounded="true" v-if="loading">
                      <content-placeholders-text :lines="1" />
                    </content-placeholders>
                    <template v-if="!loading">
                      {{ this.summary.total_nr.toFixed(2) }}
                    </template>
                  </dd>
                </dl>
              </div>
            </div>
            <div class="col">
              <div class="card">
                <dl class="p-2">
                  <dt>Total AR</dt>
                  <dd>
                    <content-placeholders :rounded="true" v-if="loading">
                      <content-placeholders-text :lines="1" />
                    </content-placeholders>
                    <template v-if="!loading">
                      {{ this.summary.total_ar.toFixed(2) }}
                    </template>
                  </dd>
                </dl>
              </div>
            </div>
            <div class="col">
              <div class="card">
                <dl class="p-2">
                  <dt>Total NC</dt>
                  <dd>
                    <content-placeholders :rounded="true" v-if="loading">
                      <content-placeholders-text :lines="1" />
                    </content-placeholders>
                    <template v-if="!loading">
                      {{ this.summary.total_nc.toFixed(2) }}
                    </template>
                  </dd>
                </dl>
              </div>
            </div>
            <div class="col">
              <div class="card">
                <dl class="p-2">
                  <dt>Total MA</dt>
                  <dd>
                    <content-placeholders :rounded="true" v-if="loading">
                      <content-placeholders-text :lines="1" />
                    </content-placeholders>
                    <template v-if="!loading">
                      {{ this.summary.total_ma_nc.toFixed(2) }}
                    </template>
                  </dd>
                </dl>
              </div>
            </div>
            <div class="col">
              <div class="card">
                <dl class="p-2">
                  <dt>Total NMI</dt>
                  <dd>
                    <content-placeholders :rounded="true" v-if="loading">
                      <content-placeholders-text :lines="1" />
                    </content-placeholders>
                    <template v-if="!loading">
                      {{ this.summary.total_nmi.toFixed(2) }}
                    </template>
                  </dd>
                </dl>
              </div>
            </div>
            <div class="col">
              <div class="card">
                <dl class="p-2">
                  <dt>Total Lot</dt>
                  <dd>
                    <content-placeholders :rounded="true" v-if="loading">
                      <content-placeholders-text :lines="1" />
                    </content-placeholders>
                    <template v-if="!loading">
                      {{ this.summary.total_lot.toFixed(2) }}
                    </template>
                  </dd>
                </dl>
              </div>
            </div>
            <div class="col">
              <div class="card">
                <dl class="p-2">
                  <dt>Total ATA</dt>
                  <dd>
                    <content-placeholders :rounded="true" v-if="loading">
                      <content-placeholders-text :lines="1" />
                    </content-placeholders>
                    <template v-if="!loading">
                      {{ this.summary.total_ata.toFixed(2) }}
                    </template>
                  </dd>
                </dl>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col">
              <div class="card">
                <table class="table">
                  <thead>
                    <tr>
                      <th>Position</th>
                      <th>Name</th>
                      <th>NC</th>
                      <th>MA</th>
                      <th>NMI</th>
                      <th>Lot</th>
                      <th>Lot 10/12</th>
                      <th>Lot 14/USD</th>
                      <th>ATA</th>
                      <th>Score</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    <template v-if="loading">
                      <tr :key="a" v-for="a in 10">
                        <td :key="b" v-for="b in 11">
                          <content-placeholders :rounded="true">
                            <content-placeholders-text :lines="1" />
                          </content-placeholders>
                        </td>
                      </tr>
                    </template>
                    <template v-if="!loading && datas.length === 0">
                      <tr>
                        <td colspan="9">
                          No Data
                        </td>
                      </tr>
                    </template>
                    <template v-if="!loading && datas.length > 0">
                      <template v-for="(data, index) in datas">
                        <tr :key="index" v-if="data.score >= 0">
                          <td>{{ data.occupation_name }}</td>
                          <td>{{ data.employee_first_name }}</td>
                          <td>
                            <div class="d-block">
                              <router-link
                                :to="
                                  `/achievement-table/${data.employee_user_id}/${$route.params.bulan}/${$route.params.tahun}/nc`
                                "
                                class
                              >
                                {{ data.total_nc | formatNum }}
                              </router-link>
                            </div>
                            <small class="text-muted">
                              KPI: {{ data.target_nc | formatNum }}
                            </small>
                          </td>
                          <td>
                            <div class="d-block">
                              {{ data.total_ma_nc | formatNum }}
                            </div>
                            <small class="text-muted">
                              KPI: {{ data.target_ma_nc | formatNum }}
                            </small>
                          </td>
                          <td>
                            <div class="d-block">
                              {{ data.total_net_achievement | formatNum }}
                            </div>
                            <small class="text-muted">
                              KPI: {{ data.target_nmi | formatNum }}
                            </small>
                          </td>
                          <td>
                            <div class="d-block">
                              {{ data.total_lot | formatNum }}
                            </div>
                            <small class="text-muted">
                              KPI: {{ data.target_lot | formatNum }}
                            </small>
                          </td>
                          <td>
                            <div class="d-block">
                              0
                            </div>
                          </td>
                          <td>
                            <div class="d-block">
                              0
                            </div>
                          </td>
                          <td>
                            <div class="d-block">
                              {{
                                data.total_active_trading_account | formatNum
                              }}
                            </div>
                            <small class="text-muted">
                              KPI: {{ data.target_ata | formatNum }}
                            </small>
                          </td>
                          <td>
                            {{ data.score }}
                          </td>
                          <td></td>
                        </tr>
                      </template>
                    </template>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import KpiBranch from '../apis/KpiBranch'
import Branch from '../apis/Branch'
import moment from 'moment'
import Occupation from '../apis/Occupation'
import AchievementBranchDetails from '../mixins/AchievementBranchDetails'

export default {
  mixins: [AchievementBranchDetails],
  data() {
    return {
      loading: true,
      datas: [],
      branch: {},
      branches: [],
      occupations: [],
      summary: {
        total_nr: 0,
        total_ar: 0,
        total_nc: 0,
        total_ma_nc: 0,
        total_nmi: 0,
        total_lot: 0,
        total_ata: 0
      },
      sort_filter: {
        sort: 'asc',
        order: 'id'
      },
      model: {
        branch_id: this.$route.params.id,
        occupation: 0,
        years: [],
        bulan: this.$route.params.bulan,
        tahun: this.$route.params.tahun,
        branch: this.$route.params.id
      }
    }
  },
  components: {
    Header: () =>
      import(
        /* webpackChunkName: "component-header" */
        /* webpackPrefetch: true */
        /* webpackMode: "eager" */
        '../components/Header'
      ),
    Sidebar: () =>
      import(
        /* webpackChunkName: "component-sidebar" */
        /* webpackPrefetch: true */

        /* webpackMode: "eager" */
        '../components/Sidebar'
      ),
    DownloadExcel: () =>
      import(
        /* webpackChunkName: "component-download-excel" */
        /* webpackPrefetch: true */
        /* webpackMode: "eager" */
        '../components/JsonExcel'
      )
  },
  methods: {
    filterPosition() {
      console.log('this.model.occupation', this.model.occupation > 0)
      this.loading = true
      this.datas = []
      let akhir = this.model.bulan
      let awal = this.model.bulan

      if (this.model.bulan == 13) {
        akhir = 3
        awal = 1
      }
      if (this.model.bulan == 14) {
        akhir = 6
        awal = 4
      }
      if (this.model.bulan == 15) {
        akhir = 9
        awal = 7
      }
      if (this.model.bulan == 16) {
        akhir = 12
        awal = 10
      }
      if (this.model.bulan == 17) {
        akhir = new Date().getMonth() + 1
        awal = 1
      }

      let last = this.formatDate(
        new Date(this.model.tahun, akhir, 0, 23, 59, 59),
        'day'
      )
      let from_date = this.model.tahun + '-' + awal + '-1'
      let to_date = this.model.tahun + '-' + akhir + '-' + last
      this.achievementByBranchDetail(
        this.model.branch,
        from_date,
        to_date,
        this.model.occupation
      )
      this.achievementByBranch(from_date, to_date, 1, this.model.branch)
      this.branchList()
    },
    Occupation() {
      Occupation.searchSales()
        .then(res => {
          console.log(res)
          this.occupations = res.data.data
          let occupation = [{ id: 0, name: 'All Position' }]
          this.occupations.forEach((x, i) => {
            occupation.push({
              id: x.id,
              name: x.name
            })
          })
          this.occupations = occupation
          console.log(this.occupations)
        })
        .catch(res => {
          console.log(res)
        })
    },
    filterMonthYear() {
      console.log(this.model.branch_id)
      this.loading = true
      let akhir = this.model.bulan
      let awal = this.model.bulan

      if (this.model.bulan == 13) {
        akhir = 3
        awal = 1
      }
      if (this.model.bulan == 14) {
        akhir = 6
        awal = 4
      }
      if (this.model.bulan == 15) {
        akhir = 9
        awal = 7
      }
      if (this.model.bulan == 16) {
        akhir = 12
        awal = 10
      }
      if (this.model.bulan == 17) {
        akhir = new Date().getMonth() + 1
        awal = 1
      }

      let last = this.formatDate(
        new Date(this.model.tahun, akhir, 0, 23, 59, 59),
        'day'
      )
      let from_date = this.model.tahun + '-' + awal + '-1'
      let to_date = this.model.tahun + '-' + akhir + '-' + last
      this.achievementByBranchDetail(this.model.branch, from_date, to_date)
      this.achievementByBranch(from_date, to_date, 1, this.model.branch)
      this.branchList()
    },
    filterBranch(e) {
      this.loading = true
      let akhir = this.model.bulan
      let awal = this.model.bulan

      if (this.model.bulan == 13) {
        akhir = 3
        awal = 1
      }
      if (this.model.bulan == 14) {
        akhir = 6
        awal = 4
      }
      if (this.model.bulan == 15) {
        akhir = 9
        awal = 7
      }
      if (this.model.bulan == 16) {
        akhir = 12
        awal = 10
      }
      if (this.model.bulan == 17) {
        akhir = new Date().getMonth() + 1
        awal = 1
      }

      let last = this.formatDate(
        new Date(this.model.tahun, akhir, 0, 23, 59, 59),
        'day'
      )
      let from_date = this.model.tahun + '-' + awal + '-1'
      let to_date = this.model.tahun + '-' + akhir + '-' + last
      this.achievementByBranchDetail(
        this.model.branch,
        from_date,
        to_date,
        this.model.occupation
      )
      this.achievementByBranch(from_date, to_date, 1, this.model.branch)
      this.branchList()
    },
    branchList() {
      Branch.search({ limit: -1 })
        .then(res => {
          console.log(res)
          this.branches = res.data.data
        })
        .catch(res => {
          console.log(res)
        })
    },
    achievementByBranchDetail(branch_id, from_date, to_date, occupation_id) {
      KpiBranch.achievementByBranchDetail(
        branch_id,
        from_date,
        to_date,
        occupation_id
      )
        .then(res => {
          console.log(res)
          if (_.isObject(res.data.data)) {
            let array = Object.values(res.data.data)
            this.datas = [...new Set(array)]
            this.json_data = this.datas
          } else {
            this.datas = res.data.data
            this.json_data = this.datas
          }
          this.summary = res.data.summary
          this.loading = false
        })
        .catch(res => {
          console.log(res)
        })
    },
    achievementByBranch(from_date, to_date, page, branch_id) {
      KpiBranch.achievementByBranch(from_date, to_date, page, branch_id)
        .then(res => {
          this.branch = res.data.data[0]
        })
        .catch(res => {
          console.log(res)
        })
    },
    formatDate(date, type) {
      if (type === 'day') {
        return moment(String(date)).format('DD')
      }
      if (type === 'bulan') {
        return moment(String(date)).format('MM')
      }
      if (type === 'year') {
        return moment(String(date)).format('YYYY')
      }
      if (type === 'full') {
        moment(String(value)).format('YYYY-MM-DD')
      }
    }
  },
  mounted() {
    document.title = 'Achievement Detail'
    let akhir = this.model.bulan
    let awal = this.model.bulan

    if (this.model.bulan == 13) {
      akhir = 3
      awal = 1
    }
    if (this.model.bulan == 14) {
      akhir = 6
      awal = 4
    }
    if (this.model.bulan == 15) {
      akhir = 9
      awal = 7
    }
    if (this.model.bulan == 16) {
      akhir = 12
      awal = 10
    }
    if (this.model.bulan == 17) {
      akhir = new Date().getMonth() + 1
      awal = 1
    }

    let last = this.formatDate(
      new Date(this.model.tahun, akhir, 0, 23, 59, 59),
      'day'
    )
    let from_date = this.model.tahun + '-' + awal + '-1'
    let to_date = this.model.tahun + '-' + akhir + '-' + last

    this.achievementByBranchDetail(this.model.branch_id, from_date, to_date)
    this.achievementByBranch(from_date, to_date, 1, this.model.branch_id)
    this.branchList()
    this.Occupation()
  },
  computed: {
    years() {
      const year = new Date().getFullYear()
      return Array.from({ length: year - 2018 }, (value, index) => 2019 + index)
    }
  },
  filters: {
    formatNum(value) {
      return new Intl.NumberFormat('id-ID').format(value)
    }
  }
}
</script>

const kpiChannel = [
  {
    path: '/kpi-channel',
    name: 'kpi-channel-view',
    component: () =>
      import(
        /* webpackChunkName: "kpi-channel-view" */
        /* webpackPrefetch: true */
        /* webpackMode: "eager" */
        '../views/KpiChannel'
      ),
    meta: {
      requiresAuth: true,
      permission: 'GET:kpi.kpi-employee.search'
    }
  },
  {
    path: '/kpi-channel/:bulan/:tahun',
    name: 'kpi-channel-detail-view',
    component: () =>
      import(
        /* webpackChunkName: "kpi-channel-detail-view" */
        /* webpackPrefetch: true */
        /* webpackMode: "eager" */
        '../views/KpiChannelDetail'
      ),
    meta: {
      requiresAuth: true,
      permission: 'GET:kpi.kpi-employee.search'
    }
  }
]
export default kpiChannel

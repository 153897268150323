<template>
  <div>
    <Header>
      <template v-slot:nav-item-left>
        <li class="nav-item">
          <router-link class="navbar-nav-link" to="/backoffice-access"
            >Backoffice Access Detail
          </router-link>
        </li>
      </template>
    </Header>
    <div class="page-content">
      <Sidebar />
      <div class="content-wrapper">
        <div class="content">
          <div class="row">
            <div class="col-md-3">
              <div class="card card-body">
                <dl>
                  <dt>Group</dt>
                  <dd>{{ group.group_name }}</dd>
                </dl>
              </div>
            </div>
            <div class="col-md-3">
              <div class="card card-body">
                <dl>
                  <dt>Access</dt>
                  <dd>{{ access.access_name }}</dd>
                </dl>
              </div>
            </div>
          </div>
          <div class="row mt-3">
            <div class="col-md-12">
              <div class="card">
                <div class="card-header">
                  Group User
                </div>
                <table class="table table-striped">
                  <thead>
                    <tr>
                      <th>Name</th>
                      <th class="w-1">
                        <button
                          class="btn bg-green-600 btn-block rounded-round btn-labeled btn-labeled-left"
                          v-b-modal="'addUser'"
                        >
                          <b><i class="fa fa-plus"></i></b>
                          Add User
                        </button>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr :key="a" v-for="a in 5" v-if="loading">
                      <td :key="b" v-for="b in 2">
                        <content-placeholders :rounded="true">
                          <content-placeholders-text :lines="1" />
                        </content-placeholders>
                      </td>
                    </tr>
                    <tr
                      :key="user.user_id"
                      v-for="user in users"
                      v-if="!loading"
                    >
                      <td>
                        <router-link
                          :to="`/employees/${employeeDetailId(user.user_id)}`"
                          >{{ employeeDetails(user.user_id) }}
                        </router-link>
                      </td>
                      <td>
                        <button
                          @click="deleteModel($event, user)"
                          class="btn bg-danger-600 btn-block rounded-round btn-labeled btn-labeled-left"
                        >
                          <b><i class="fa fa-times"></i></b>
                          Revoke
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <paginate
                :click-handler="clickCallback"
                :container-class="
                  'pagination pagination-separated pagination-rounded align-self-center'
                "
                :margin-pages="5"
                :next-class="'page-item'"
                :next-link-class="'page-link'"
                :next-text="'Next'"
                :page-class="'page-item'"
                :page-count="pagination.totalPage"
                :page-link-class="'page-link'"
                :page-range="5"
                :prev-class="'page-item'"
                :prev-link-class="'page-link'"
                :prev-text="'Prev'"
              ></paginate>
            </div>
            <div class="col-md-12 mt-2">
              <div class="card">
                <div class="card-header">
                  Group Menu
                </div>
                <table class="table table-striped">
                  <thead>
                    <tr>
                      <th>Module</th>
                      <th>Name</th>
                      <th>url</th>
                      <th width="100">
                        <button
                          class="btn bg-green-600 btn-block rounded-round btn-labeled btn-labeled-left"
                        >
                          <b><i class="fa fa-plus"></i></b>
                          Add Menu
                        </button>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr :key="a" v-for="a in 5" v-if="groupMenusLoading">
                      <td :key="b" v-for="b in 4">
                        <content-placeholders :rounded="true">
                          <content-placeholders-text :lines="1" />
                        </content-placeholders>
                      </td>
                    </tr>
                    <tr
                      :key="groupMenu.id"
                      v-for="groupMenu in groupMenus"
                      v-if="!groupMenusLoading"
                    >
                      <td>{{ groupMenu.menu.module }}</td>
                      <td>{{ groupMenu.menu.name }}</td>
                      <td>
                        {{
                          groupMenu.menu.controller +
                            '/' +
                            groupMenu.menu.action
                        }}
                      </td>
                      <td></td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <paginate
                :click-handler="clickCallbackGroupMenu"
                :container-class="
                  'pagination pagination-separated pagination-rounded align-self-center'
                "
                :margin-pages="5"
                :next-class="'page-item'"
                :next-link-class="'page-link'"
                :next-text="'Next'"
                :page-class="'page-item'"
                :page-count="paginationGroupMenu.totalPage"
                :page-link-class="'page-link'"
                :page-range="5"
                :prev-class="'page-item'"
                :prev-link-class="'page-link'"
                :prev-text="'Prev'"
              ></paginate>
            </div>
          </div>
        </div>
      </div>
    </div>
    <b-modal
      :hide-footer="true"
      :no-close-on-backdrop="true"
      :no-close-on-esc="true"
      header-class="bg-white"
      id="addUser"
      ref="addUser"
      title="Add User"
    >
      <form @submit.prevent="submitForm">
        <div class="form-group">
          <label for>User</label>
          <model-select
            :options="models"
            @searchchange="selectSearch"
            v-model="model"
          ></model-select>
        </div>
        <button class="btn bg-green-700 btn-block mt-3" type="submit">
          Submit
        </button>
      </form>
    </b-modal>

    <b-modal
      :hide-footer="true"
      :no-close-on-backdrop="true"
      :no-close-on-esc="true"
      header-class="bg-white"
      id="revokeUser"
      ref="revokeUser"
      title="Revoke User Access"
    >
      Are You Sure Want To Revoke this User?
      <button
        @click="revoke"
        class="btn bg-danger-700 btn-block mt-3"
        type="button"
      >
        Yes
      </button>
    </b-modal>
  </div>
</template>
<script>
import BackofficeAccess from '../apis/BackofficeAccess'
import EmployeeApi from '../apis/Employee'
import Hrms from '../services/hrms'
import Paginate from '../components/Paginate'
import { ModelListSelect, ModelSelect } from 'vue-search-select'

export default {
  data() {
    return {
      group: {
        id: 0,
        group_name: 'loading...',
        active: 1,
        created_date: '',
        updated_date: '',
        is_superuser: 0,
        access_id: 0
      },
      pagination: {
        totalPage: 0,
        currentPage: 1,
        totalData: 0,
        displaying: 0
      },
      paginationGroupMenu: {
        totalPage: 0,
        currentPage: 1,
        totalData: 0,
        displaying: 0
      },
      models: [],
      model: '',
      modelToDelete: {},
      loading: true,
      groupMenusLoading: true,
      users: [],
      employees: [],
      access: {},
      groupMenus: []
    }
  },
  components: {
    Paginate,
    ModelSelect,
    ModelListSelect,
    Header: () =>
      import(
        /* webpackChunkName: "component-header" */
        /* webpackPrefetch: true */
        /* webpackMode: "eager" */
        '../components/Header'
      ),
    Sidebar: () =>
      import(
        /* webpackChunkName: "component-sidebar" */
        /* webpackPrefetch: true */

        /* webpackMode: "eager" */
        '../components/Sidebar'
      )
  },
  methods: {
    revoke() {
      this.loading = true
      this.userGroupDelete('id=' + this.modelToDelete.id)
      this.pagination.totalPage = 0
      this.pagination.currentPage = 1
      this.pagination.totalData = 0
      this.pagination.displaying = 0
      this.userGroup('search[group_id]=' + this.$route.params.id)
      this.$refs['revokeUser'].hide()
    },
    deleteModel(e, u) {
      this.modelToDelete = u

      this.$refs['revokeUser'].show()
    },
    submitForm(e) {
      let payload = {
        active: 1,
        user_type: 'user',
        user_id: this.model,
        group_id: this.$route.params.id
      }
      this.loading = true
      this.userGroupCreate(payload)
      this.userGroup('search[group_id]=' + this.$route.params.id)
      this.$refs['addUser'].hide()
    },
    clickCallback(e) {
      this.loading = true
      this.userGroup('page=' + e + '&search[group_id]=' + this.$route.params.id)
    },
    clickCallbackGroupMenu(e) {
      this.groupMenusLoading = true
      this.groupMenu('search[group_id]=' + this.$route.params.id + '&page=' + e)
    },
    groupView(id) {
      BackofficeAccess.groupView(id)
        .then(res => {
          this.group = res.data.data
          this.accessView(res.data.data.access_id)
        })
        .catch()
    },
    accessView(id) {
      BackofficeAccess.accessView(id)
        .then(res => {
          this.access = res.data.data
        })
        .catch()
    },
    userGroup(query) {
      BackofficeAccess.userGroups(query)
        .then(res => {
          this.users = res.data.data
          this.pagination.totalPage = Math.ceil(
            parseInt(res.data.totalCount) / parseInt(res.data.size)
          )
          this.pagination.currentPage = res.data.page
          this.pagination.totalData = parseInt(res.data.totalCount)

          let that = this
          res.data.data.forEach((v, i) => {
            that.viewEmployee(v.user_id)
          })
          this.loading = false
        })
        .catch()
    },
    viewEmployee(id) {
      Hrms.interceptors.response.use(
        response => response,
        error => {
          if (typeof error.response === 'undefined') {
            this.$swal(
              'Ooops!',
              'A network error occurred. ' +
                'This could be a CORS issue or a dropped internet connection. ' +
                'It is not possible for us to know.',
              'error'
            )
          }
          return Promise.reject(error)
        }
      )
      EmployeeApi.ViewByUserId(id)
        .then(res => {
          this.employees.push(res.data.data)
        })
        .catch(error => {
          this.$swal('Ooops!', error.response.data.message, 'error').then(a => {
            router.go(-1)
          })
        })
    },
    employeeDetails(user_id) {
      let name = 'loading...'
      this.employees.forEach((v, i) => {
        if (v.user_id === user_id) {
          name = v.firstname + ' ' + v.lastname + ' (' + v.email + ')'
        }
      })
      return name
    },
    employeeDetailId(user_id) {
      let id = ''
      this.employees.forEach((v, i) => {
        if (v.user_id === user_id) {
          id = v.id
        }
      })
      return id
    },
    searchEmployee(query) {
      Hrms.interceptors.response.use(
        response => response,
        error => {
          if (typeof error.response === 'undefined') {
            this.$swal(
              'Ooops!',
              'A network error occurred. ' +
                'This could be a CORS issue or a dropped internet connection. ' +
                'It is not possible for us to know.',
              'error'
            )
          }
          return Promise.reject(error)
        }
      )
      EmployeeApi.search(query)
        .then(res => {
          this.models = res.data.data.map(x => {
            return {
              value: x.user_id,
              text: x.firstname + ' ' + x.lastname + ' (' + x.email + ')'
            }
          })
        })
        .catch(error => {
          this.$swal('Ooops!', error.response.data.message, 'error').then(a => {
            router.go(-1)
          })
        })
    },
    selectSearch(e) {
      if (e.length >= 3) {
        this.searchEmployee({ fullname: e })
      }
    },
    userGroupCreate(payload) {
      BackofficeAccess.userCreate(payload)
        .then(res => {})
        .catch(err => {})
    },
    userGroupDelete(query) {
      BackofficeAccess.userGroupDelete(query)
        .then(res => {})
        .catch(err => {})
    },
    groupMenu(query) {
      BackofficeAccess.groupMenu(query)
        .then(res => {
          this.groupMenus = res.data.data
          this.paginationGroupMenu.totalPage = Math.ceil(
            parseInt(res.data.totalCount) / parseInt(res.data.size)
          )
          this.paginationGroupMenu.currentPage = res.data.page
          this.paginationGroupMenu.totalData = parseInt(res.data.totalCount)
          this.groupMenusLoading = false
        })
        .catch(err => {})
    }
  },
  mounted() {
    document.title = 'Backoffice Access Dashboard'
    this.groupView(this.$route.params.id)
    this.groupMenu('search[group_id]=' + this.$route.params.id)
    this.userGroup('search[group_id]=' + this.$route.params.id)
  }
}
</script>

const achievementBonus = [{
    path: '/achievement-bonus',
    name: 'achievement-bonus-view',
    component: () =>
        import (
            /* webpackChunkName: "page-achievement-bonus-view" */
            /* webpackPrefetch: true */
            /* webpackMode: "eager" */
            '../views/AchievementBonus.vue'
        ),
    meta: {
        requiresAuth: true,
        permission: 'GET:employee.default.achievement-bonus-view'
    }
}, {
    path: '/achievement-bonus/:id',
    name: 'achievement-bonus-detail-view',
    component: () =>
        import (
            /* webpackChunkName: "achievement-bonus-detail-view" */
            /* webpackPrefetch: true */
            /* webpackMode: "eager" */
            '../views/AchievementBonusDetail.vue'
        ),
    meta: {
        requiresAuth: true,
        permission: 'GET:employee.default.achievement-bonus-view'
    }
}]
export default achievementBonus
<template >
	<div >
		<div class = "row mb-2" >
			<div class = "col-md-6 mb-1" >
				Displaying
				<span class = "font-weight-bold text-green-700" >{{ pagination.displaying }}</span >
				of
				<span class = "font-weight-bold text-green-700" >{{ pagination.totalData }}</span >
				Result
			</div >
		</div >
		<div class = "card" >
			<table class = "table" >
				<thead >
				<tr >
					<th >Request Date</th >
					<th >Due Date</th >
					<th >Title</th >
					<th >Description</th >
					<th v-if = "type !== 'employee-approval-task-list-view'" >Assign to</th >
					<th >Status</th >
					<th ></th >
				</tr >
				</thead >
				<tbody >
				<tr v-if = "loading" >
					<td :key = "n" v-for = "n in 7" >
						<content-placeholders :rounded = "true" >
							<content-placeholders-text :lines = "1" />
						</content-placeholders >
					</td >
				</tr >
				<template v-else >
					<tr v-if = "tasks.length === 0" >
						<td colspan = "7" >No Data</td >
					</tr >
					<tr :key = "task.id" v-for = "task in tasks" >
						<td scope = "row" >
							<div class = "d-flex align-items-center" >
								<div >
                    <span class = "text-default font-weight-semibold mb-2 d-block" >
                      <i class = "icon-calendar mr-1" ></i >
                      {{ task.created_date | formatDate }}
                    </span >
									<div class = "text-muted font-size-sm d-block" >
										<i class = "icon-alarm mr-1" ></i >
										{{ task.created_date | formatGetTimeFromDate }}
									</div >
								</div >
							</div >
						</td >
						<td >
							<div class = "d-flex align-items-center" >
								<div >
                    <span class = "text-default font-weight-semibold" >
                      <i class = "icon-calendar22 mr-1" ></i >
                      {{ task.due_date | formatDate }}
                      {{ task.due_date | formatGetTimeFromDate }}
                    </span >
								</div >
							</div >
						</td >
						<td >{{ task.title }}</td >
						<td >{{ task.description | stripTag }}</td >
						<td v-if = "type !== 'employee-approval-task-list-view'" >
							{{
							task.assigned_to_first_name + ' ' + task.assigned_to_last_name
							}}
						</td >
						<td >
							<template v-if = "task.status_name === 'Pending'" >
                  <span class = "badge bg-grey-400 badge-pill" >
                    {{
                    task.status_name
                    }}
                  </span >
							</template >
							<template v-if = "task.status_name === 'Rejected'" >
                  <span class = "badge bg-danger-700 badge-pill" >
                    {{
                    task.status_name
                    }}
                  </span >
							</template >
							<template v-if = "task.status_name === 'Partial'" >
                  <span class = "badge bg-orange-700 badge-pill" >
                    {{
                    task.status_name
                    }}
                  </span >
							</template >
							<template v-if = "task.status_name === 'Approved'" >
                  <span class = "badge bg-green-700 badge-pill" >
                    {{
                    task.status_name
                    }}
                  </span >
							</template >
						</td >
						
						<td class = "font-weight-bold" >
							<div class = "list-icons" >
								<div class = "list-icons-item dropdown" >
									<a
											class = "list-icons-item dropdown-toggle caret-0"
											data-toggle = "dropdown"
											href = "javascript:void(0)"
									>
										<i class = "fas fa-ellipsis-v text-green-700 fa-2x" ></i >
									</a >
									<div class = "dropdown-menu dropdown-menu-right" >
										<router-link
												:to = "`/employees/${route.params.id}/task/${task.id}`"
												class = "dropdown-item"
										>
											<i class = "icon-file-stats" ></i >
											See Details
										</router-link >
									</div >
								</div >
							</div >
						</td >
					</tr >
				</template >
				</tbody >
			</table >
		</div >
	</div >
</template >
<script >
  import BackofficeApi from '../apis/Backoffice'
  import Hrms          from '../services/hrms'
  import EmployeeApi   from '../apis/Employee'
  import SalesService  from '../services/sales'
  
  export default {
		props: {
			type: {
				type: String,
				require: true
			},
			route: {
				type: Object,
				require: true
			}
		},
		data() {
			return {
				loading: true,
				employee: [],
				tasks: [],
				pagination: {
					totalPage: 0,
					currentPage: 1,
					totalData: 0,
					displaying: 0
				}
			}
		},
		methods: {
			viewEmployee(id) {
				Hrms.interceptors.response.use(
					response => response,
					error => {
						if (typeof error.response === 'undefined') {
							this.$swal(
								'Ooops!',
								'A network error occurred. ' +
								'This could be a CORS issue or a dropped internet connection. ' +
								'It is not possible for us to know.',
								'error'
							)
						}
						return Promise.reject(error)
					}
				)
				EmployeeApi.view(id)
				           .then(res => {
					           this.employee = res.data.data
					           if (this.type === 'employee-request-task-list-view') {
						           if (
							           typeof res.data.data.user !== 'undefined' &&
							           res.data.data.user
						           ) {
							           this.MyRequest(res.data.data.user.id)
						           }
					           }
					           if (this.type === 'employee-task-list-view' && res.data.data.user) {
						           if (typeof res.data.data.user !== 'undefined') {
							           this.MyTask(res.data.data.user.id)
						           }
					           }
					           if (this.type === 'employee-approval-task-list-view') {
						           if (
							           typeof res.data.data.user !== 'undefined' &&
							           res.data.data.user
						           ) {
							           this.MyApproval(res.data.data.user.id)
						           }
					           }
				           })
				           .catch(error => {
					           console.log(error)
					           this.$swal('Ooops!', error.response.data.message, 'error').then(a => {
						           router.go(-1)
					           })
				           })
			},
			MyRequest(id) {
				SalesService.interceptors.response.use(
					response => response,
					error => {
						if (typeof error.response === 'undefined') {
							this.$swal(
								'Ooops!',
								'A network error occurred. ' +
								'This could be a CORS issue or a dropped internet connection. ' +
								'It is not possible for us to know.',
								'error'
							)
						}
						return Promise.reject(error)
					}
				)
				BackofficeApi.MyRequest(id)
				             .then(result => {
					             this.tasks = result.data.data
					             this.loading = false
				             })
				             .catch(err => {
					             console.log(err)
				             })
			},
			MyApproval(id) {
				SalesService.interceptors.response.use(
					response => response,
					error => {
						if (typeof error.response === 'undefined') {
							this.$swal(
								'Ooops!',
								'A network error occurred. ' +
								'This could be a CORS issue or a dropped internet connection. ' +
								'It is not possible for us to know.',
								'error'
							)
						}
						return Promise.reject(error)
					}
				)
				BackofficeApi.MyApproval(id)
				             .then(result => {
					             this.tasks = result.data.data
					             this.loading = false
					             console.log(result.data.data)
				             })
				             .catch(err => {
					             console.log(err)
				             })
			},
			MyTask(id) {
				SalesService.interceptors.response.use(
					response => response,
					error => {
						if (typeof error.response === 'undefined') {
							this.$swal(
								'Ooops!',
								'A network error occurred. ' +
								'This could be a CORS issue or a dropped internet connection. ' +
								'It is not possible for us to know.',
								'error'
							)
						}
						return Promise.reject(error)
					}
				)
				BackofficeApi.MyTask(id)
				             .then(result => {
					             this.tasks = result.data.data
					             this.loading = false
				             })
				             .catch(err => {
					             console.log(err)
				             })
			}
		},
		mounted() {
			this.viewEmployee(this.route.params.id)
		}
	}
</script >

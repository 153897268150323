<template>
    <div>
        <Header>
            <template v-slot:nav-item-left>
                <li class="nav-item">
                    <router-link class="navbar-nav-link" to="/">Employee Dashboard</router-link>
                </li>
            </template>
        </Header>
        <div class="page-content">
            <Sidebar/>
            <div class="content-wrapper">
                <div class="content">

                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import employeeApi from '../apis/Employee'
    import Hrms from '../services/hrms'
    import employeeObject from '../models/employeeObject'
    import router from '../routes'

    export default {
        data() {
            return {
                env: process.env.NODE_ENV,
                filesUrl: {
                    development: 'https://files.dev.mifx.com/',
                    production: 'https://files.mifx.com/'
                },
                employee: employeeObject,
                cardImg: '../scss/global_assets/images/backgrounds/panel_bg.png'
            }
        },
        components: {
            Header: () =>
                import(
                    /* webpackChunkName: "component-header" */
                    /* webpackPrefetch: true */
                    /* webpackMode: "eager" */
                    '../components/Header'
                    ),
            Sidebar: () =>
                import(
                    /* webpackChunkName: "component-sidebar" */
                    /* webpackPrefetch: true */

                    /* webpackMode: "eager" */
                    '../components/Sidebar'
                    )
        },
        methods: {
            ViewEmployeeByUserId(id) {
                Hrms.interceptors.response.use(
                    response => response,
                    error => {
                        if (typeof error.response === 'undefined') {
                            this.$swal(
                                'Ooops!',
                                'A network error occurred. ' +
                                'This could be a CORS issue or a dropped internet connection. ' +
                                'It is not possible for us to know.',
                                'error'
                            )
                        }
                        return Promise.reject(error)
                    }
                )
                employeeApi
                    .ViewByUserId(id)
                    .then(result => {
                        this.employee = result.data.data
                        localStorage.setItem('hr_employee', JSON.stringify(result.data.data))
                    })
                    .catch(err => {
                        console.log(err.response)
                    })
            }
        },
        mounted() {
            document.title = 'Employee Dashboard'
            if (localStorage.getItem('hr_employee')) {
                if(!localStorage.getItem('is_verify')){
                    router.push('/verify')
                }
                this.employee = JSON.parse(localStorage.getItem('hr_employee'))
            } else {
                this.ViewEmployeeByUserId(
                    JSON.parse(localStorage.getItem('hr_token')).user.id
                )
            }
        }
    }
</script>

<template>
  <div>
    <Header>
      <template v-slot:nav-item-left>
        <li class="nav-item">
          <router-link
            :class="{
              active: $route.name === 'page-best-fm'
            }"
            class="navbar-nav-link"
            to="/reward/best-fm"
            >Best FM</router-link
          >
        </li>
        <li class="nav-item">
          <router-link
            :class="{
              active: $route.name === 'page-best-rm'
            }"
            class="navbar-nav-link"
            to="/reward/best-rm"
            >Best RM</router-link
          >
        </li>
        <li class="nav-item">
          <router-link
            :class="{
              active: $route.name === 'page-best-cfm'
            }"
            class="navbar-nav-link"
            to="/reward/best-cfm"
            >Best CFM</router-link
          >
        </li>
        <li class="nav-item">
          <router-link
            :class="{
              active: $route.name === 'page-best-crm'
            }"
            class="navbar-nav-link"
            to="/reward/best-crm"
            >Best CRM</router-link
          >
        </li>
        <li class="nav-item">
          <router-link
            :class="{
              active: $route.name === 'page-best-growth'
            }"
            class="navbar-nav-link"
            to="/reward/best-growth"
            >Best GROWTH</router-link
          >
        </li>
        <li class="nav-item">
          <router-link
            :class="{
              active: $route.name === 'page-best-kpi'
            }"
            class="navbar-nav-link"
            to="/reward/best-kpi"
            >Best KPI</router-link
          >
        </li>
        <li class="nav-item">
          <router-link
            :class="{
              active: $route.name === 'page-best-best-total-performance'
            }"
            class="navbar-nav-link"
            to="/reward/best-total-performance"
            >Best TOTAL PERFORMANCE</router-link
          >
        </li>
      </template>
    </Header>
    <div class="page-content">
      <Sidebar />
      <div class="content-wrapper">
        <div class="content">
          <div class="row mb-3">
            <div class="col-md-12">
              <div class="card card-body">
                <div class="row">
                  <div class="col-md-2">
                    <div class="form-group">
                      <label for="start_date">From</label>
                      <datepicker
                        :input-class="'form-control'"
                        id="start_date"
                        v-model="model.start_date"
                        :format="ymd"
                      />
                    </div>
                  </div>
                  <div class="col-md-2">
                    <div class="form-group">
                      <label for="start_date">To</label>
                      <datepicker
                        :input-class="'form-control'"
                        id="end_date"
                        v-model="model.end_date"
                        :format="ymd"
                      />
                    </div>
                  </div>
                </div>
                <div class="row justify-content-end">
                  <div class="col-md-2">
                    <button
                      @click="applyFilter"
                      class="btn bg-green-700 btn-block rounded-round btn-labeled btn-labeled-left"
                      type="button"
                    >
                      <b>
                        <i class="icon-check"></i>
                      </b>
                      Apply
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <div class="card">
                <div class="table-responsive">
                  <table class="table table-striped">
                    <thead>
                      <tr>
                        <th>Branch</th>
                        <th>NC</th>
                        <th>NC Score</th>
                        <th>MA</th>
                        <th>MA Score</th>
                        <th>NMI</th>
                        <th>NMI Score</th>
                        <th>Lot</th>
                        <th>Lot Score</th>
                        <th>ATA</th>
                        <th>ATA Score</th>
                        <th>Total Score</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      <template v-if="loading">
                        <tr :key="a" v-for="a in 10">
                          <td :key="b" v-for="b in 12">
                            <content-placeholders :rounded="true">
                              <content-placeholders-text :lines="1" />
                            </content-placeholders>
                          </td>
                        </tr>
                      </template>
                      <template v-else>
                        <tr v-for="(data, index) in datas" :key="index">
                          <td>{{ data.branch_name }}</td>
                          <td>
                            <div class="d-block">
                              {{ data.total_nc | formatNum }}
                            </div>
                            <small class="text-muted">
                              KPI: {{ data.target_nc | formatNum }}
                            </small>
                          </td>
                          <td>{{ data.score_nc | formatNum }}</td>
                          <td>
                            <div class="d-block">
                              {{ data.total_ma_nc | formatNum }}
                            </div>
                            <small class="text-muted">
                              KPI: {{ data.target_ma_nc | formatNum }}
                            </small>
                          </td>
                          <td>{{ data.score_ma_nc | formatNum }}</td>
                          <td>
                            <div class="d-block">
                              {{ data.total_net_achievement | formatNum }}
                            </div>
                            <small class="text-muted">
                              KPI: {{ data.target_net_achievement | formatNum }}
                            </small>
                          </td>
                          <td>{{ data.score_net_achievement | formatNum }}</td>
                          <td>
                            <div class="d-block">
                              {{ data.total_lot | formatNum }}
                            </div>
                            <small class="text-muted">
                              KPI: {{ data.target_lot | formatNum }}
                            </small>
                          </td>
                          <td>{{ data.score_lot | formatNum }}</td>
                          <td>
                            <div class="d-block">
                              {{
                                data.total_active_trading_account | formatNum
                              }}
                            </div>
                            <small class="text-muted">
                              KPI:
                              {{
                                data.target_active_trading_account | formatNum
                              }}
                            </small>
                          </td>
                          <td>
                            {{ data.score_active_trading_account | formatNum }}
                          </td>
                          <td>{{ data.score_total | formatNum }}</td>
                          <td>
                            <template v-if="data.rank === 1">
                              <img
                                src="../scss/global_assets/images/ico_Crown_Gold.svg"
                                alt="ico_Crown_Gold"
                              />
                            </template>
                            <template v-if="data.rank === 2">
                              <img
                                src="../scss/global_assets/images/ico_Crown_Silver.svg"
                                alt="ico_Crown_Silver"
                              />
                            </template>
                            <template v-if="data.rank === 3">
                              <img
                                src="../scss/global_assets/images/ico_Crown_Bronze.svg"
                                alt="ico_Crown_Bronze"
                              />
                            </template>
                          </td>
                        </tr>
                      </template>

                      <template v-if="!loading && datas.length === 0">
                        <tr>
                          <td colspan="12">
                            No Data
                          </td>
                        </tr>
                      </template>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Reward from "../apis/HrmsReward";
import Datepicker from 'vuejs-datepicker'
import moment from 'moment'
export default {
  data() {
    return {
      loading: true,
      datas: [],
      model: {
        years: [],
        tanggal: new Date().getDate(),
        bulan: new Date().getMonth() + 1,
        tahun: new Date().getFullYear(),
        start_date: null,
        end_date: null,
        category: 'kpi'
      }
    }
  },
  components: {
    Datepicker,
    Header: () =>
      import(
        /* webpackChunkName: "component-header" */
        /* webpackPrefetch: true */
        /* webpackMode: "eager" */
        '../components/Header'
      ),
    Sidebar: () =>
      import(
        /* webpackChunkName: "component-sidebar" */
        /* webpackPrefetch: true */

        /* webpackMode: "eager" */
        '../components/Sidebar'
      )
  },
  methods: {
    ymd(date) {
      return moment(date).format('YYYY-MM-DD')
    },
    applyFilter() {
      this.loading = true
      this.model.end_date = moment(String(this.model.end_date)).format(
        'YYYY-MM-DD'
      )
      this.model.start_date = moment(String(this.model.start_date)).format(
        'YYYY-MM-DD'
      )
      console.log(this.model)
      this.rewardBestByGrowth(
        this.model.start_date,
        this.model.end_date,
        this.model.category
      )
    },
    rewardBestByGrowth(start_date, end_date, category) {
      Reward.rewardBestByGrowth(start_date, end_date, category)
        .then(res => {
          console.log(res)
          this.datas = res.data.data
          this.loading = false
        })
        .catch(res => {
          console.log(res)
          this.loading = false
        })
    }
  },
  mounted() {
    document.title = 'Annual Reward::Best CFM'

    this.model.start_date = this.model.tahun + '-' + this.model.bulan + '-01'
    this.model.end_date =
      this.model.tahun + '-' + this.model.bulan + '-' + this.model.tanggal
    this.rewardBestByGrowth(
      this.model.start_date,
      this.model.end_date,
      this.model.category
    )
  },
  filters: {
    formatNum(value) {
      return value
    }
  }
}
</script>

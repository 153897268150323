import axios from '../services/sales'

const resource = '/backoffice-sales'
export default {
	SalesCreate(payload) {
		let token = JSON.parse(localStorage.getItem('hr_token')).access_token
		return axios.post(`${ resource }/sales-create`, payload, {
			headers: {
				'x-access-token': `${ token }`
			}
		})
	},
	salesAliasLinkCheck(code) {
		let token = JSON.parse(localStorage.getItem('hr_token')).access_token
		return axios.get(`${ resource }/sales-alias-link-check?alias_link=${ code }`, {
			headers: {
				'x-access-token': `${ token }`
			}
		})
	},
	salesCodeCheck(code) {
		let token = JSON.parse(localStorage.getItem('hr_token')).access_token
		return axios.get(`${ resource }/sales-code-check?sales_code=${ code }`, {
			headers: {
				'x-access-token': `${ token }`
			}
		})
	},
	WpSearchAjaxByCode(code) {
		let token = JSON.parse(localStorage.getItem('hr_token')).access_token
		return axios.get(`${ resource }/wp-search-ajax-by-code?q=${ code }`, {
			headers: {
				'x-access-token': `${ token }`
			}
		})
	},
	SalesCreateAttributes() {
		let token = JSON.parse(localStorage.getItem('hr_token')).access_token
		return axios.get(`${ resource }/sales-create-attributes`, {
			headers: {
				'x-access-token': `${ token }`
			}
		})
	},
	GroupsListByAccess(id) {
		let token = JSON.parse(localStorage.getItem('hr_token')).access_token
		return axios.get(`${ resource }/groups-list-by-access?access_id=${ id }&limit=-1`, {
			headers: {
				'x-access-token': `${ token }`
			}
		})
	},
	InjectAccessGroups(payload) {
		let token = JSON.parse(localStorage.getItem('hr_token')).access_token
		return axios.post(`${ resource }/inject-access-groups`, payload, {
			headers: {
				'x-access-token': `${ token }`
			}
		})
	},
	WakilPialangList(query) {
		let token = JSON.parse(localStorage.getItem('hr_token')).access_token
		const params = new URLSearchParams(query)
		return axios.get(
			`${ resource }/wakil-pialang-list?${ params }&order=updated_date desc`, {
				headers: {
					'x-access-token': `${ token }`
				}
			}
		)
	},
	WakilPialangUpdate(id, payload) {
		let token = JSON.parse(localStorage.getItem('hr_token')).access_token
		return axios.post(`${ resource }/wakil-pialang-update?id=${ id }`, payload, {
			headers: {
				'x-access-token': `${ token }`
			}
		})
	},
	WakilPialangCreate(payload) {
		let token = JSON.parse(localStorage.getItem('hr_token')).access_token
		return axios.post(`${ resource }/wakil-pialang-create`, payload, {
			headers: {
				'x-access-token': `${ token }`
			}
		})
	},
	SalesLiveAccountList(query) {
		let token = JSON.parse(localStorage.getItem('hr_token')).access_token
		const params = new URLSearchParams(query)
		return axios.get(
			`${ resource }/sales-live-account-list?${ params }`, {
				headers: {
					'x-access-token': `${ token }`
				}
			}
		)
	},
	salesAliasLink(query) {
		let token = JSON.parse(localStorage.getItem('hr_token')).access_token
		const params = new URLSearchParams(query)
		return axios.get(
			`${ resource }/sales-alias-link?${ params }`, {
				headers: {
					'x-access-token': `${ token }`
				}
			}
		)
	},
	salesView(query) {
		let token = JSON.parse(localStorage.getItem('hr_token')).access_token
		const params = new URLSearchParams(query)
		return axios.get(
			`${ resource }/sales-view?${ params }`, {
				headers: {
					'x-access-token': `${ token }`
				}
			}
		)
	},
	SalesUpdate(id,payload){
		let token = JSON.parse(localStorage.getItem('hr_token')).access_token
		return axios.post(`${ resource }/sales-update?id=${id}`, payload, {
			headers: {
				'x-access-token': `${ token }`
			}
		})
	},
	SalesCreateValidate(payload) {
		let token = JSON.parse(localStorage.getItem('hr_token')).access_token
		return axios.post(`${ resource }/sales-create-validate`, payload, {
			headers: {
				'x-access-token': `${ token }`
			}
		})
	},
	SalesDowngradeUpgradeSubmit(payload) {
		let token = JSON.parse(localStorage.getItem('hr_token')).access_token
		return axios.post(`${ resource }/sales-downgrade-upgrade-submit`, payload, {
			headers: {
				'x-access-token': `${ token }`
			}
		})
	},
	salesCreateChecking(payload){
		let token = JSON.parse(localStorage.getItem('hr_token')).access_token
		return axios.post(`${ resource }/sales-create-checking`, payload, {
			headers: {
				'x-access-token': `${ token }`
			}
		})
	}

}
<template >
	<dd class = "font-weight-bold" >
		<template v-if = "typeof value !== 'undefined' && value" >{{ value }}</template >
		<template v-else >-</template >
	</dd >
</template >
<script >
	export default {
		props: [ 'value' ]
	}
</script >

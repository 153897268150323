import axios from '../services/master'

const resource = '/bank'
export default {
	view(id) {
		let token = JSON.parse(localStorage.getItem('hr_token')).access_token
		return axios.get(`${ resource }/view?id=${ id }`, {
			headers: {
				'x-access-token': `${ token }`
			}
		})
	},
	list() {
		let token = JSON.parse(localStorage.getItem('hr_token')).access_token
		return axios.get(`${ resource }?limit=-1`, {
			headers: {
				'x-access-token': `${ token }`
			}
		})
	}
}

const achievementTable = [{
    path: '/achievement-table',
    name: 'achievement-table-view',
    component: () =>
        import (
            /* webpackChunkName: "page-achievement-table-view" */
            /* webpackPrefetch: true */
            /* webpackMode: "eager" */
            '../views/AchievementTable.vue'
        ),
    meta: {
        requiresAuth: true,
        permission: 'GET:employee.default.achievement-table-view'
    }
}, {
    path: '/achievement-table/:id/:bulan/:tahun',
    name: 'achievement-table-detail-view',
    component: () =>
        import (
            /* webpackChunkName: "page-achievement-table-vdetail-iew" */
            /* webpackPrefetch: true */
            /* webpackMode: "eager" */
            '../views/AchievementTableView.vue'
        ),
    meta: {
        requiresAuth: true,
        permission: 'GET:employee.default.achievement-table-detail-view'
    }
}, {
    path: '/achievement-table/:id/:bulan/:tahun/nc',
    name: 'achievement-table-break-down-nc',
    component: () =>
        import (
            /* webpackChunkName: "page-achievement-table-break-down-nc" */
            /* webpackPrefetch: true */
            /* webpackMode: "eager" */
            '../views/AchievementTableBreakDownNc.vue'
        ),
    meta: {
        requiresAuth: true,
        permission: 'GET:employee.default.achievement-table-detail-view'
    }
}]
export default achievementTable
const KpiScoreChannel = [
  {
    path: '/kpi-score-channel',
    name: 'kpi-score-channel-view',
    component: () =>
      import(
        /* webpackChunkName: "kpi-score-channel-view" */
        /* webpackPrefetch: true */
        /* webpackMode: "eager" */
        '../views/KpiScoreChannel.vue'
      ),
    meta: {
      requiresAuth: true,
      permission: 'GET:kpi.kpi-employee.search'
    }
  },
  {
    path: '/kpi-score-channel/:bulan/:tahun',
    name: 'kpi-score-channel-detail-view',
    component: () =>
      import(
        /* webpackChunkName: "kpi-channel-detail-view" */
        /* webpackPrefetch: true */
        /* webpackMode: "eager" */
        '../views/KpiScoreChannelDetail'
      ),
    meta: {
      requiresAuth: true,
      permission: 'GET:kpi.kpi-employee.search'
    }
  }
]
export default KpiScoreChannel

const kpiByBranch = [{
    path: '/kpi-by-branch',
    name: 'kpi-by-branch-view',
    component: () =>
        import (
            /* webpackChunkName: "kpi-by-branch-view" */
            /* webpackPrefetch: true */
            /* webpackMode: "eager" */
            '../views/KpiBranch'
        ),
    meta: {
        requiresAuth: true,
        permission: 'GET:kpi.kpi-employee.search'
    }
}, {
    path: '/kpi-by-branch/:year/:month',
    name: 'kpi-by-branch-detail-view',
    component: () =>
        import (
            /* webpackChunkName: "kpi-by-branch-detail-view" */
            /* webpackPrefetch: true */
            /* webpackMode: "eager" */
            '../views/KpiBranchDetail'
        ),
    meta: {
        requiresAuth: true,
        permission: 'GET:kpi.kpi-employee.search'
    }
}]
export default kpiByBranch
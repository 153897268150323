export default {
    alias_code: {
        isError: false,
        type: String,
        required: true,
        default: '',
        value: '',
        label: 'Kode Alias',
        message: 'Kode Alias Tidak Boleh Kosong'
    },
    sales_code: {
        isError: false,
        type: String,
        required: true,
        default: '',
        value: '',
        label: 'Kode Sales',
        message: 'Kode Sales Tidak Boleh Kosong'
    },
    sales_division: {
        isError: false,
        type: String,
        required: true,
        default: '',
        value: '',
        label: 'Divisi Sales',
        message: ''
    },
    wp_code: {
        isError: false,
        type: String,
        required: true,
        default: '',
        value: '',
        label: 'Wakil Pialang',
        message: ''
    }
}

export default{
    data() {
        return{
            json_data:[],json_filename: new Date(Date.now())
            .toLocaleString()
            .slice(0, 3)
            .match(/[0-9]/i)
            ? new Date(Date.now())
                .toLocaleString()
                .split(' ')[0]
                .split(',')[0]
            : new Date(Date.now()).toLocaleString().split(' ')[1] +
              ' ' +
              new Date(Date.now()).toLocaleString().split(' ')[2] +
              ' ' +
              new Date(Date.now()).toLocaleString().split(' ')[3],
            json_fields:{
                'Position':'occupation_name',
                'Name':'employee_first_name',
                'Target NC':'target_nc',
                'NC':'total_nc',
                'Target MA':'target_ma_nc',
                'MA':'total_ma_nc',
                'Target NMI':'target_nmi',
                'NMI':'total_net_achievement',
                'Target Lot':'target_lot',
                'Lot':'total_lot',
                'Target ATA':'target_ata',
                'ATA':'total_active_trading_account',
                'score':'score',
            },
            json_meta:[
                [
                  {
                    key: 'charset',
                    value: 'utf-8'
                  }
                ]
              ]
        }
    }
}
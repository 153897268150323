<template>
    <div>
        <Header>
            <template v-slot:nav-item-left>
                <li class="nav-item">
                    <router-link
                            :class="{ active: $route.name === 'role-permission-role-view' }"
                            :to="`/role-permission/${$route.params.id}`"
                            class="navbar-nav-link"
                    >
                        <i class="fas fa-chevron-left text-green-700"></i>
                        Permissions
                    </router-link>
                </li>
                <li class="nav-item">
                    <router-link
                            :class="{
              active: $route.name === 'role-permission-role-users-view'
            }"
                            :to="`/role-permission/${$route.params.id}/users`"
                            class="navbar-nav-link"
                    >Users
                    </router-link>
                </li>
            </template>
        </Header>
        <div class="page-content">
            <Sidebar/>
            <div class="content-wrapper">
                <div class="content">
                    <div class="row">
                        <div class="col-md-12">
                            <h4 class="mb-2">
                                <template v-if="typeof role.name !== 'undefined'">
                                    {{
                                    role.name.toUpperCase()
                                    }}
                                </template>
                                <template v-else>-</template>

                                <button
                                        class="btn btn-info ml-3 btn-sm btn-labeled btn-labeled-left"
                                        type="button"
                                        v-b-modal.createPermission
                                >
                                    <b>
                                        <i class="fa fa-plus"></i>
                                    </b>
                                    Add Permission
                                </button>
                            </h4>
                            <div class="card">
                                <template v-if="!loading">
                                    <template v-for="(group, index) in groups">
                                        <div
                                                class="card-header bg-gray text-black-50 font-weight-bold"
                                        >{{ group.toUpperCase() }}
                                        </div>
                                        <div class="card-body border-bottom">
                                            <div class="row">
                                                <div class="col-md-4 mb-1" v-show="false">
                                                    <p-check
                                                            class="p-default p-fill p-smooth"
                                                            color="primary"
                                                            name="check[]"
                                                    >Check All {{ group.toUpperCase() }}
                                                    </p-check>
                                                </div>
                                                <template v-for="permission in permissions">
                                                    <template v-if="group === permission.group">
                                                        <div
                                                                :key="permission.id + '-' + index + index"
                                                                class="col-md-6 col-12 col-lg-6 mb-1"
                                                        >
                                                            <p-check
                                                                    @change="
                                  addRemovePermission($event, permission)
                                "
                                                                    class="p-default p-fill p-smooth"
                                                                    color="primary"
                                                                    name="check[]"
                                                                    v-model="rolePermissions[permission.id]"
                                                            >{{ permission.name }}
                                                            </p-check>
                                                        </div>
                                                    </template>
                                                </template>
                                            </div>
                                        </div>
                                    </template>
                                </template>
                                <template v-else>
                                    <div class="card-body">
                                        <content-placeholders>
                                            <content-placeholders-heading :img="true"/>
                                            <content-placeholders-text :lines="3"/>
                                        </content-placeholders>
                                    </div>
                                </template>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <b-modal
                :hide-footer="true"
                :no-close-on-backdrop="true"
                :no-close-on-esc="true"
                header-class="bg-white"
                id="createPermission"
                ref="createPermission"
                title="Create New Permission"
        >
            <form @submit.prevent="submitForm">
                <div class="form-group">
                    <label for="httpMethod">Http Method</label>
                    <model-select :options="httpMethod" v-model="permissionModel.httpMethod"/>
                </div>
                <div class="form-group">
                    <label for="module">Module</label>
                    <model-select :options="modules" v-model="permissionModel.module"/>
                </div>
                <div class="form-group">
                    <label for="controller">Controller</label>
                    <input
                            class="form-control"
                            id="controller"
                            type="text"
                            v-model="permissionModel.controller"
                    />
                </div>
                <div class="form-group">
                    <label for="action">Action</label>
                    <input class="form-control" id="action" type="text" v-model="permissionModel.action"/>
                </div>
                <button class="btn bg-green-700 btn-block mt-3" type="submit">Submit</button>
            </form>
        </b-modal>
    </div>
</template>
<script>
    import RoleAndPermissionApi from '../apis/RoleAndPermission'
    import Hrms from '../services/hrms'
    import {ModelListSelect, ModelSelect} from 'vue-search-select'

    export default {
        data() {
            return {
                loading: true,
                role: {
                    id: 0,
                    name: '-',
                    permissions: [],
                    users: []
                },
                permissions: [],
                groups: [],
                model: {
                    permission_id: 0,
                    role_id: parseInt(this.$route.params.id)
                },
                rolePermissions: [],
                permissionModel: {
                    httpMethod: {
                        value: 'GET',
                        text: 'GET'
                    },
                    module: {
                        value: 'attachment',
                        text: 'attachment'
                    },
                    controller: '',
                    action: ''
                },
                httpMethod: [
                    {
                        value: 'GET',
                        text: 'GET'
                    },
                    {
                        value: 'HEAD',
                        text: 'HEAD'
                    },
                    {
                        value: 'POST',
                        text: 'POST'
                    },
                    {
                        value: 'PUT',
                        text: 'PUT'
                    },
                    {
                        value: 'DELETE',
                        text: 'DELETE'
                    },
                    {
                        value: 'OPTIONS',
                        text: 'OPTIONS'
                    },
                    {
                        value: 'CONNECT',
                        text: 'CONNECT'
                    }
                ],
                modules: [
                    {
                        value: 'attachment',
                        text: 'attachment'
                    },
                    {
                        value: 'attendance',
                        text: 'attendance'
                    },
                    {
                        value: 'benefit',
                        text: 'benefit'
                    },
                    {
                        value: 'employee',
                        text: 'employee'
                    },
                    {
                        value: 'department',
                        text: 'department'
                    },
                    {
                        value: 'occupation',
                        text: 'occupation'
                    },
                    {
                        value: 'permission',
                        text: 'permission'
                    },
                    {
                        value: 'users',
                        text: 'users'
                    },
                    {
                        value: 'helper',
                        text: 'helper'
                    },
                    {
                        value: 'branch',
                        text: 'branch'
                    },
                    {
                        value: 'profile',
                        text: 'profile'
                    }
                ]
            }
        },
        components: {
            ModelSelect,
            ModelListSelect,
            Header: () =>
                import(
                    /* webpackChunkName: "component-header" */
                    /* webpackPrefetch: true */
                    /* webpackMode: "eager" */
                    '../components/Header'
                    ),
            Sidebar: () =>
                import(
                    /* webpackChunkName: "component-sidebar" */
                    /* webpackPrefetch: true */

                    /* webpackMode: "eager" */
                    '../components/Sidebar'
                    )
        },
        methods: {
            submitForm(e) {
                this.loading = true
                this.PermissionStore({
                    name:
                        this.permissionModel.httpMethod.value +
                        ':' +
                        this.permissionModel.module.value +
                        '.' +
                        this.permissionModel.controller +
                        '.' +
                        this.permissionModel.action
                })
            },
            PermissionStore(payload) {
                Hrms.interceptors.response.use(
                    response => response,
                    error => {
                        if (typeof error.response === 'undefined') {
                            this.$swal(
                                'Ooops!',
                                'A network error occurred. ' +
                                'This could be a CORS issue or a dropped internet connection. ' +
                                'It is not possible for us to know. [CE-PERMISSIONSTORE-001]',
                                'error'
                            )
                        }
                        return Promise.reject(error)
                    }
                )
                RoleAndPermissionApi.PermissionStore(payload)
                    .then(result => {
                        console.log(result)

                        this.PermissionList({limit: -1})
                        this.$refs['createPermission'].hide()
                    })
                    .catch(err => {
                        console.log(err)
                        this.$swal(
                            'Ooops!',
                            'A network error occurred. ' +
                            'This could be a CORS issue or a dropped internet connection. ' +
                            'It is not possible for us to know. [CE-PERMISSIONSTORE-002]',
                            'error'
                        )
                    })
            },
            RoleView(query) {
                Hrms.interceptors.response.use(
                    response => response,
                    error => {
                        if (typeof error.response === 'undefined') {
                            this.$swal(
                                'Ooops!',
                                'A network error occurred. ' +
                                'This could be a CORS issue or a dropped internet connection. ' +
                                'It is not possible for us to know. [CE-ROLE-001]',
                                'error'
                            )
                        }
                        return Promise.reject(error)
                    }
                )
                RoleAndPermissionApi.RoleView(query)
                    .then(result => {
                        const that = this
                        this.role = result.data.data
                        result.data.data.permissions.map(x => {
                            return (that.rolePermissions[x.permission_id] = x.permission_id)
                        })
                        console.log(this.rolePermissions)
                    })
                    .catch(err => {
                        console.log(err)
                        this.$swal(
                            'Ooops!',
                            'A network error occurred. ' +
                            'It is not possible for us to know. [CE-ROLE-002]',
                            'error'
                        )
                    })
            },
            PermissionList(query) {
                Hrms.interceptors.response.use(
                    response => response,
                    error => {
                        if (typeof error.response === 'undefined') {
                            this.$swal(
                                'Ooops!',
                                'A network error occurred. ' +
                                'This could be a CORS issue or a dropped internet connection. ' +
                                'It is not possible for us to know. [CE-PERMISSION-001]',
                                'error'
                            )
                        }
                        return Promise.reject(error)
                    }
                )
                RoleAndPermissionApi.PermissionList(query)
                    .then(result => {
                        const that = this
                        this.permissions = result.data.data.map(x => {
                            let str = x.name
                            let method = str.split(':')
                            let group = method[1].split('.')[0]
                            that.groups.push(group)
                            return {
                                id: x.id,
                                name: x.name,
                                group: group
                            }
                        })
                        this.groups = this.groups.filter(
                            (v, i) => this.groups.indexOf(v) == i
                        )
                        this.loading = false
                    })
                    .catch(err => {
                        console.log(err)
                        this.$swal(
                            'Ooops!',
                            'A network error occurred. ' +
                            'It is not possible for us to know. [CE-PERMISSION-002]',
                            'error'
                        )
                    })
            },
            addRemovePermission(e, permission) {
                this.model.permission_id = permission.id
                if (e) {
                    this.PermissionAssignRole(this.model)
                } else {
                    this.PermissionRevokeRole(this.model)
                }
            },
            PermissionAssignRole(payload) {
                Hrms.interceptors.response.use(
                    response => response,
                    error => {
                        if (typeof error.response === 'undefined') {
                            this.$swal(
                                'Ooops!',
                                'A network error occurred. ' +
                                'This could be a CORS issue or a dropped internet connection. ' +
                                'It is not possible for us to know. [CE-PERMISSIONASSIGNROLE-001]',
                                'error'
                            )
                        }
                        return Promise.reject(error)
                    }
                )
                RoleAndPermissionApi.PermissionAssignRole(payload)
                    .then(result => {
                        console.log(result)
                    })
                    .catch(err => {
                        this.$swal(
                            'Ooops!',
                            'A network error occurred. ' +
                            'This could be a CORS issue or a dropped internet connection. ' +
                            'It is not possible for us to know. [CE-PERMISSIONASSIGNROLE-001]',
                            'error'
                        )
                    })
            },
            PermissionRevokeRole(payload) {
                Hrms.interceptors.response.use(
                    response => response,
                    error => {
                        if (typeof error.response === 'undefined') {
                            this.$swal(
                                'Ooops!',
                                'A network error occurred. ' +
                                'This could be a CORS issue or a dropped internet connection. ' +
                                'It is not possible for us to know. [CE-PERMISSIONASSIGNROLE-001]',
                                'error'
                            )
                        }
                        return Promise.reject(error)
                    }
                )
                RoleAndPermissionApi.PermissionRevokeRole(payload)
                    .then(result => {
                        console.log(result)
                    })
                    .catch(err => {
                        this.$swal(
                            'Ooops!',
                            'A network error occurred. ' +
                            'This could be a CORS issue or a dropped internet connection. ' +
                            'It is not possible for us to know. [CE-PERMISSIONASSIGNROLE-001]',
                            'error'
                        )
                    })
            }
        },
        beforeMount() {
            ;(document.title = 'Role Detail'), this.RoleView(this.$route.params.id)
            this.PermissionList({limit: -1})
        }
    }
</script>

import axios from '../services/master'

const resource = ''
export default {
    userGroups(query) {
        let token = JSON.parse(localStorage.getItem('hr_token')).access_token
        return axios.get(`/user-groups?${ query }`, {
            headers: {
                'x-access-token': `${ token }`
            }
        })
    },
    userCreate(payload) {
        let token = JSON.parse(localStorage.getItem('hr_token')).access_token
        return axios.post(`/user-groups/create`, payload, {
            headers: {
                'x-access-token': `${ token }`
            }
        })
    },
    userGroupDelete(query) {
        let token = JSON.parse(localStorage.getItem('hr_token')).access_token
        return axios.post(`/user-groups/delete?${ query }`, {}, {
            headers: {
                'x-access-token': `${ token }`
            }
        })
    },

    groups() {
        let token = JSON.parse(localStorage.getItem('hr_token')).access_token
        return axios.get(`/groups?limit=-1`, {
            headers: {
                'x-access-token': `${ token }`
            }
        })
    },
    groupView(id) {
        let token = JSON.parse(localStorage.getItem('hr_token')).access_token
        return axios.get(`/groups/view?id=${ id }`, {
            headers: {
                'x-access-token': `${ token }`
            }
        })
    },
    accessView(id) {
        let token = JSON.parse(localStorage.getItem('hr_token')).access_token
        return axios.get(`/access/view?id=${ id }`, {
            headers: {
                'x-access-token': `${ token }`
            }
        })
    },
    groupMenu(query) {
        let token = JSON.parse(localStorage.getItem('hr_token')).access_token
        return axios.get(`/groups-menu?${ query }`, {
            headers: {
                'x-access-token': `${ token }`
            }
        })
    }
}
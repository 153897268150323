import axios from '../services/hrms'

const resource = '/bank-account-request'
export default {
    search(query) {
        let token = JSON.parse(localStorage.getItem('hr_token')).access_token
        const params = new URLSearchParams(query)
        return axios.get(`${ resource }/search?${ params }`, {
            headers: {
                'x-access-token': `${ token }`
            }
        })
    },
    update(payload) {
        let token = JSON.parse(localStorage.getItem('hr_token')).access_token
        return axios.post(`${ resource }/${ payload.id }/update`, payload, {
            headers: {
                'x-access-token': `${ token }`
            }
        })
    }
}
<template>
  <div>
    <Header></Header>
    <div class="page-content">
      <Sidebar />
      <div class="content-wrapper">
        <div class="content">
            <div class="row">
                <div class="col-md-8">
                    <div class="card">
                        <div class="card-header bg-white">
                            Create Budget
                        </div>
                        <div class="card-body">
                            <div class="row">
                                <div class="col-md-12">
                                    <div class="form-group">
                                        <label for="name">Budget Title</label>
                                        <input type="text" class="form-control" id="name" v-model="model.name">
                                    </div>
                                </div>
                                <div class="col-md-12">
                                    <div class="form-group">
                                        <label for="description">Budget Description </label>
                                        <textarea class="form-control" id="description" cols="30" rows="10"  v-model="model.description"></textarea>
                                    </div>
                                </div>
                                <div class="col-md-12">
                                    <div class="row">
                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <label for="amount">Budget Amount </label>
                                                <input type="text" class="form-control" id="amount" v-model="model.amount">
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <label for="due_date">Budget Due Date</label>
                                                <datepicker
                                                    :input-class="'form-control'"
                                                    id="due_date"
                                                    placeholder="Due Date"
                                                    v-model="model.due_date"
                                                    :format="dateToDatabaseFormat"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-12">
                                    <div class="form-group">
                                        <label for="budget_title">Business Approval</label>
                                        <div class="mt-2">
                                            <label>1st Approval</label>
                                            <model-select
                                                :options="employees"
                                                v-model="model.approval_user_id_satu"
                                                @searchchange="selectSearch"
                                            />
                                        </div>
                                        <div class="mt-2">
                                            <label>2nd Approval</label>
                                            <model-select
                                                :options="employees"
                                                v-model="model.approval_user_id_dua"
                                                @searchchange="selectSearch"
                                            />
                                        </div>
                                        <div class="mt-2">
                                            <label>3rd Approval</label>
                                            <model-select
                                                :options="employees"
                                                v-model="model.approval_user_id_tiga"
                                                @searchchange="selectSearch"
                                            />
                                        </div>
                                        <div class="mt-2">
                                            <label>4th Approval</label>
                                            <model-select
                                                :options="employees"
                                                v-model="model.approval_user_id_empat"
                                                @searchchange="selectSearch"
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-12">
                                    <label>File
                                        <input type="file" id="files" ref="files" @change="handleFileUpload" multiple/>
                                    </label>
                                </div>
                            </div>
                            <div class="row justify-content-end">
                                <div class="col-md-2">
                                    <button class="btn btn-success btn-block" @click="submitForm" v-if="!loading">Submit</button>
                                    <button class="btn btn-success btn-block" disabled v-else>Submit</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import BackofficeApi from '../apis/Backoffice'
import { ModelListSelect, ModelSelect } from 'vue-search-select'
import Datepicker from 'vuejs-datepicker'
import moment from 'moment'
import router from '../routes/index'
export default {
  data() {
    return {
        employees:[],
        loading:false,
        model:{
            name:'',
            amount:'',
            due_date:'',
            approval_user_id_satu:null,
            approval_user_id_dua:null,
            approval_user_id_tiga:null,
            approval_user_id_empat:null,
            file_attachment:[],
            approval_user_id:[],
            date:null,
        }
    }
  },
  components: {
    Header: () =>
      import(
        /* webpackChunkName: "component-header" */
        /* webpackPrefetch: true */
        /* webpackMode: "eager" */
        '../components/Header'
      ),
    Sidebar: () =>
      import(
        /* webpackChunkName: "component-sidebar" */
        /* webpackPrefetch: true */

        /* webpackMode: "eager" */
        '../components/Sidebar'
      ),
    Datepicker,
    ModelSelect,
    ModelListSelect,
  },
  methods: {
        dateToDatabaseFormat(value) {
        return moment(String(value)).format('YYYY-MM-DD')
        },
      submitForm(){
            this.loading=true
            let formData = new FormData();
            let files = []
            for( var i = 0; i < this.model.file_attachment.length; i++ ){
                files.push(URL.createObjectURL(this.model.file_attachment[i]))
                // let file = this.model.file_attachment[i];
                formData.append('file_attachment[' + i + ']', this.model.file_attachment[i]);
            }
            // console.log(files)
            this.model.file_attachment = files
            if(this.model.approval_user_id_satu){
               this.model.approval_user_id[0]=this.model.approval_user_id_satu 
            }
            if(this.model.approval_user_id_dua){
               this.model.approval_user_id[1]=this.model.approval_user_id_dua  
            }
            if(this.model.approval_user_id_tiga){
               this.model.approval_user_id[2]=this.model.approval_user_id_tiga  
            }
            if(this.model.approval_user_id_empat){
               this.model.approval_user_id[3]=this.model.approval_user_id_empat 
            }
            this.model.date = this.dateToDatabaseFormat(new Date())
            for (let item in this.model){
                console.log(item)
                if(item != 'file_attachment' && item != 'approval_user_id' ){
                    formData.append(item,this.model[item])
                }
                if(item == 'due_date'){
                  formData.append(item,this.dateToDatabaseFormat(this.model[item]))  
                }
            }

            for( var i = 0; i < this.model.approval_user_id.length; i++ ){
                formData.append('approval_user_id[' + i + ']', this.model.approval_user_id[i]);
            }

            BackofficeApi
            .budgetCreate(formData)
            .then(res => {
                router.push('/budget-request')
            })
            .catch(res => {
            this.loading=false
                console.log(res)
            })
      },
      handleFileUpload(){
          this.model.file_attachment = this.$refs.files.files;
      },
      searchApproval(name){
          
              BackofficeApi
              .searchEmployeeAjax(name,'budget')
              .then(res=>{
                this.employees = res.data.data.map(x => {
                    return {
                    value: x.user_id,
                    text: x.firstname + ' ' + x.lastname + ' (' + x.email + ')'
                    }
                })
              })
              .catch(res=>{
                  console.log(res)
              })

      },
    selectSearch(e) {
      if (e.length >= 3) {
        this.searchApproval(e)
      }
    }
  },
  mounted() {
    document.title = 'Create Budget Request'
  }
}
</script>

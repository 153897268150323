var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"sidebar sidebar-dark sidebar-main sidebar-expand-md"},[_vm._m(0),_vm._v(" "),_c('div',{staticClass:"sidebar-content"},[_c('div',{staticClass:"card card-sidebar-mobile"},[_c('ul',{staticClass:"nav nav-sidebar",attrs:{"data-nav-type":"accordion"}},[_vm._m(1),_vm._v(" "),(_vm.isHavePermission('GET:employee.default.dashboard'))?_c('li',{staticClass:"nav-item"},[_c('router-link',{staticClass:"nav-link",class:{ active: _vm.$route.name === 'home' },attrs:{"to":"/"}},[_c('i',{staticClass:"icon-home4"}),_vm._v(" "),_c('span',[_vm._v("Dashboard")])])],1):_vm._e(),_vm._v(" "),(
            _vm.isHavePermission('GET:employee.default.employee-management-menu')
          )?_c('li',{staticClass:"nav-item"},[_c('router-link',{staticClass:"nav-link",class:{
              active:
                _vm.$route.name === 'employee-list' ||
                _vm.$route.name === 'employee-list-disabled' ||
                _vm.$route.name === 'employee-create' ||
                _vm.$route.name === 'employee-view' ||
                _vm.$route.name === 'employee-task-list-view' ||
                _vm.$route.name === 'employee-request-task-list-view' ||
                _vm.$route.name === 'employee-approval-task-list-view' ||
                _vm.$route.name === 'employee-history-task-list-view' ||
                _vm.$route.name === 'employee-task-detail-view'
            },attrs:{"to":"/employees"}},[_c('i',{staticClass:"icon-profile"}),_vm._v(" "),_c('span',[_vm._v("Employee Management")])])],1):_vm._e(),_vm._v(" "),(
            _vm.isHavePermission('GET:employee.default.employee-management-menu')
          )?_c('li',{staticClass:"nav-item"},[_c('router-link',{staticClass:"nav-link",class:{
              active: _vm.$route.name === 'referral-list'
            },attrs:{"to":"/referral-list"}},[_c('i',{staticClass:"icon-profile"}),_vm._v(" "),_c('span',[_vm._v("Referral List")])])],1):_vm._e(),_vm._v(" "),(_vm.isHavePermission('GET:employee.default.budget-request'))?_c('li',{staticClass:"nav-item"},[_c('router-link',{staticClass:"nav-link",class:{
              active:
                _vm.$route.name === 'budget-request' ||
                _vm.$route.name === 'budget-request-my-approval' ||
                _vm.$route.name === 'budget-request-all' ||
                _vm.$route.name === 'budget-request-create' ||
                _vm.$route.name === 'budget-request-detail'
            },attrs:{"to":"/budget-request"}},[_c('i',{staticClass:"icon-profile"}),_vm._v(" "),_c('span',[_vm._v("Budget Request")])])],1):_vm._e(),_vm._v(" "),(
            _vm.isHavePermission('GET:employee.default.employee-management-menu')
          )?_c('li',{staticClass:"nav-item"},[_c('router-link',{staticClass:"nav-link",class:{
              active: _vm.$route.name === 'referral-agreement'
            },attrs:{"to":"/referral-agreement"}},[_c('i',{staticClass:"icon-profile"}),_vm._v(" "),_c('span',[_vm._v("Agreement Referral (Commission)")])])],1):_vm._e(),_vm._v(" "),(_vm.isHavePermission('GET:employee.default.anual.rewards'))?_c('li',{staticClass:"nav-item"},[_c('router-link',{staticClass:"nav-link",class:{
              active:
                _vm.$route.name === 'page-best-fm' ||
                _vm.$route.name === 'page-best-rm' ||
                _vm.$route.name === 'page-best-cfm' ||
                _vm.$route.name === 'page-best-crm' ||
                _vm.$route.name === 'page-best-growth' ||
                _vm.$route.name === 'page-best-kpi' ||
                _vm.$route.name === 'page-best-total-performance'
            },attrs:{"to":"/reward/best-fm"}},[_c('i',{staticClass:"icon-profile"}),_vm._v(" "),_c('span',[_vm._v("Annual Rewards")])])],1):_vm._e(),_vm._v(" "),(
            _vm.isHavePermission('GET:employee.default.report-commission')
          )?_c('li',{staticClass:"nav-item"},[_c('router-link',{staticClass:"nav-link",class:{
              active: _vm.$route.name === 'report-commission-hrd-by-date-range'
            },attrs:{"to":"/report-commission-hrd-by-date-range"}},[_c('i',{staticClass:"icon-profile"}),_vm._v(" "),_c('span',[_vm._v("Report Commission")])])],1):_vm._e(),_vm._v(" "),(
            _vm.isHavePermission('GET:employee.default.employee-management-menu')
          )?_c('li',{staticClass:"nav-item"},[_c('router-link',{staticClass:"nav-link",class:{
              active: _vm.$route.name === 'referral-bank-account'
            },attrs:{"to":"/referral-bank-account"}},[_c('i',{staticClass:"icon-profile"}),_vm._v(" "),_c('span',[_vm._v("Bank Account Request")])])],1):_vm._e(),_vm._v(" "),(_vm.isHavePermission('GET:kpi.kpi-employee.search'))?_c('li',{staticClass:"nav-item"},[_c('router-link',{staticClass:"nav-link",class:{
              active:
                _vm.$route.name === 'kpi-by-position-view' ||
                _vm.$route.name === 'kpi-by-position-detail-view' ||
                _vm.$route.name === 'kpi-by-branch-view' ||
                _vm.$route.name === 'kpi-by-branch-detail-view'
            },attrs:{"to":"/kpi-by-position"}},[_c('i',{staticClass:"fas fa-poll-h"}),_vm._v(" "),_c('span',[_vm._v("KPI Management")])])],1):_vm._e(),_vm._v(" "),(_vm.isHavePermission('GET:kpi.kpi-employee.search'))?_c('li',{staticClass:"nav-item"},[_c('router-link',{staticClass:"nav-link",class:{
              active:
                _vm.$route.name === 'kpi-score-by-position-view' ||
                _vm.$route.name === 'kpi-score-by-position-detail-view' ||
                _vm.$route.name === 'kpi-score-by-branch-view' ||
                _vm.$route.name === 'kpi-score-by-branch-detail-view'
            },attrs:{"to":"/kpi-score-by-position"}},[_c('i',{staticClass:"fas fa-poll-h"}),_vm._v(" "),_c('span',[_vm._v("Score Management")])])],1):_vm._e(),_vm._v(" "),(_vm.isHavePermission('GET:employee.default.departments-view'))?_c('li',{staticClass:"nav-item"},[_c('router-link',{staticClass:"nav-link",class:{
              active: _vm.$route.name === 'kpi-channel-view'
            },attrs:{"to":"/kpi-channel"}},[_c('i',{staticClass:"icon-tree7"}),_vm._v(" "),_c('span',[_vm._v("Kpi Channel")])])],1):_vm._e(),_vm._v(" "),(
            _vm.isHavePermission('GET:employee.default.lot-earnings-report-view')
          )?_c('li',{staticClass:"nav-item"},[_c('router-link',{staticClass:"nav-link",class:{
              active: _vm.$route.name === 'kpi-score-channel-view'
            },attrs:{"to":"/kpi-score-channel"}},[_c('i',{staticClass:"icon-tree7"}),_vm._v(" "),_c('span',[_vm._v("Kpi Score Channel")])])],1):_vm._e(),_vm._v(" "),(
            _vm.isHavePermission('GET:employee.default.lot-earnings-report-view')
          )?_c('li',{staticClass:"nav-item"},[_c('router-link',{staticClass:"nav-link",class:{
              active:
                _vm.$route.name === 'lot-earnings-report-view' ||
                _vm.$route.name === 'lot-earnings-report-detail-view' ||
                _vm.$route.name === 'lot-earnings-report-client-view' ||
                _vm.$route.name === 'lot-earnings-report-client-detail-view'
            },attrs:{"to":"/lot-earnings-report"}},[_c('i',{staticClass:"icon-file-empty2"}),_vm._v(" "),_c('span',[_vm._v("Lot Earnings Report")])])],1):_vm._e(),_vm._v(" "),(
            _vm.isHavePermission('GET:employee.league-table.list-by-occupation')
          )?_c('li',{staticClass:"nav-item"},[_c('router-link',{staticClass:"nav-link",class:{
              active:
                _vm.$route.name === 'league-table' ||
                _vm.$route.name === 'league-table-occupation-view'
            },attrs:{"to":"/league-table/5"}},[_c('i',{staticClass:"icon-profile"}),_vm._v(" "),_c('span',[_vm._v("League Table")])])],1):_vm._e(),_vm._v(" "),(
            _vm.isHavePermission('GET:employee.default.achievement-table-view')
          )?_c('li',{staticClass:"nav-item"},[_c('router-link',{staticClass:"nav-link",class:{
              active:
                _vm.$route.name === 'achievement-table-view' ||
                _vm.$route.name === 'achievement-table-detail-view'
            },attrs:{"to":"/achievement-table"}},[_c('i',{staticClass:"icon-trophy3"}),_vm._v(" "),_c('span',[_vm._v("Achievement Table")])])],1):_vm._e(),_vm._v(" "),(
            _vm.isHavePermission('GET:employee.default.achievement-bonus-view')
          )?_c('li',{staticClass:"nav-item"},[_c('router-link',{staticClass:"nav-link",class:{
              active:
                _vm.$route.name === 'achievement-bonus-view' ||
                _vm.$route.name === 'achievement-bonus-detail-view'
            },attrs:{"to":"/achievement-bonus"}},[_c('i',{staticClass:"icon-coins"}),_vm._v(" "),_c('span',[_vm._v("Achievement Bonus")])])],1):_vm._e(),_vm._v(" "),(_vm.isHavePermission('GET:employee.default.wakil-pialang-menu'))?_c('li',{staticClass:"nav-item"},[_c('router-link',{staticClass:"nav-link",class:{
              active: _vm.$route.name === 'wakil-pialang'
            },attrs:{"to":"/wakil-pialang"}},[_c('i',{staticClass:"icon-city"}),_vm._v(" "),_c('span',[_vm._v("Wakil Pialang")])])],1):_vm._e(),_vm._v(" "),(
            _vm.isHavePermission('GET:employee.default.role-permission-role-view')
          )?_c('li',{staticClass:"nav-item"},[_c('router-link',{staticClass:"nav-link",class:{
              active:
                _vm.$route.name === 'role-permission-view' ||
                _vm.$route.name === 'role-permission-role-view' ||
                _vm.$route.name === 'role-permission-role-users-view'
            },attrs:{"to":"/role-permission"}},[_c('i',{staticClass:"fa fa-user-lock"}),_vm._v(" "),_c('span',[_vm._v("Role and Permission")])])],1):_vm._e(),_vm._v(" "),(
            _vm.isHavePermission('GET:employee.default.backoffice-access-view')
          )?_c('li',{staticClass:"nav-item"},[_c('router-link',{staticClass:"nav-link",class:{
              active:
                _vm.$route.name === 'backoffice-access-view' ||
                _vm.$route.name === 'backoffice-access-detail-view'
            },attrs:{"to":"/backoffice-access"}},[_c('i',{staticClass:"fa fa-user-lock"}),_vm._v(" "),_c('span',[_vm._v("Backoffice Access")])])],1):_vm._e(),_vm._v(" "),(_vm.isHavePermission('GET:employee.default.departments-view'))?_c('li',{staticClass:"nav-item"},[_c('router-link',{staticClass:"nav-link",class:{
              active:
                _vm.$route.name === 'departments-view' ||
                _vm.$route.name === 'departments-detail-view'
            },attrs:{"to":"/departments"}},[_c('i',{staticClass:"icon-tree7"}),_vm._v(" "),_c('span',[_vm._v("Departments")])])],1):_vm._e(),_vm._v(" "),(false)?_c('li',{staticClass:"nav-item nav-item-submenu"},[_vm._m(2),_vm._v(" "),_vm._m(3)]):_vm._e()])])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"sidebar-mobile-toggler text-center"},[_c('a',{staticClass:"sidebar-mobile-main-toggle",attrs:{"href":"#"}},[_c('i',{staticClass:"icon-arrow-left8"})]),_vm._v("\n    Navigation\n    "),_c('a',{staticClass:"sidebar-mobile-expand",attrs:{"href":"#"}},[_c('i',{staticClass:"icon-screen-full"}),_vm._v(" "),_c('i',{staticClass:"icon-screen-normal"})])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('li',{staticClass:"nav-item-header"},[_c('div',{staticClass:"text-uppercase font-size-xs line-height-xs"},[_vm._v("Main")]),_vm._v(" "),_c('i',{staticClass:"icon-menu",attrs:{"title":"Main"}})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('a',{staticClass:"nav-link",attrs:{"href":"#"}},[_c('i',{staticClass:"icon-copy"}),_vm._v(" "),_c('span',[_vm._v("Layouts")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ul',{staticClass:"nav nav-group-sub",attrs:{"data-submenu-title":"Layouts"}},[_c('li',{staticClass:"nav-item"},[_c('a',{staticClass:"nav-link",attrs:{"href":"javascript:void(0)"}},[_vm._v("Default layout")])])])}]

export { render, staticRenderFns }
<template>
  <div>
    <Header>
      <template v-slot:nav-item-left>
        <li class="nav-item">
          <router-link class="navbar-nav-link" to="/"
            >Achievement Detail New Client</router-link
          >
        </li>
      </template>
    </Header>
    <div class="page-content">
      <Sidebar />
      <div class="content-wrapper">
        <div class="content">
          <div class="row">
            <div class="col-md-12">
              <div class="card">
                <table class="table table-striped">
                  <thead>
                    <tr>
                      <th>Live ID</th>
                      <th>Account</th>
                      <th>Funded Date</th>
                      <th>Client Name</th>
                      <th>Client Email</th>
                      <th>Margin</th>
                      <th>Margin Fex</th>
                    </tr>
                  </thead>
                  <tbody>
                    <template v-if="loading">
                      <tr :key="a" v-for="a in 10">
                        <td :key="b" v-for="b in 7">
                          <content-placeholders :rounded="true">
                            <content-placeholders-text :lines="1" />
                          </content-placeholders>
                        </td>
                      </tr>
                    </template>
                    <template v-if="!loading && datas.length === 0">
                      <tr>
                        <td colspan="7">
                          No Data
                        </td>
                      </tr>
                    </template>
                    <template
                      v-for="(data, index) in datas"
                      v-if="!loading && datas.length > 0"
                    >
                      <tr :key="index">
                        <td>{{ data.live_id }}</td>
                        <td>{{ data.account }}</td>
                        <td>{{ data.funded_date }}</td>
                        <td>{{ data.client_first_name }}</td>
                        <td>{{ data.client_email }}</td>
                        <td>{{ data.margin }}</td>
                        <td>{{ data.margin_fex }}</td>
                      </tr>
                    </template>
                  </tbody>
                </table>
              </div>
              <paginate
                :click-handler="clickCallback"
                :container-class="
                  'pagination pagination-separated pagination-rounded align-self-center'
                "
                :margin-pages="5"
                :next-class="'page-item'"
                :next-link-class="'page-link'"
                :next-text="'Next'"
                :page-class="'page-item'"
                :page-count="pagination.totalPage"
                :page-link-class="'page-link'"
                :page-range="5"
                :prev-class="'page-item'"
                :prev-link-class="'page-link'"
                :prev-text="'Prev'"
              ></paginate>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import SalesApi from '../apis/Sales'
import moment from 'moment'
import Paginate from '../components/Paginate'

export default {
  data() {
    return {
      pagination: {
        totalPage: 0,
        currentPage: 1,
        totalData: 0,
        displaying: 0
      },
      loading: true,
      model: {
        user_id: this.$route.params.id,
        bulan: this.$route.params.bulan,
        tahun: this.$route.params.tahun
      },
      datas: []
    }
  },
  components: {
    Header: () =>
      import(
        /* webpackChunkName: "component-header" */
        /* webpackPrefetch: true */
        /* webpackMode: "eager" */
        '../components/Header'
      ),
    Sidebar: () =>
      import(
        /* webpackChunkName: "component-sidebar" */
        /* webpackPrefetch: true */

        /* webpackMode: "eager" */
        '../components/Sidebar'
      ),
    Paginate
  },
  methods: {
    clickCallback(e) {
      this.loading = true
      let akhir = this.model.bulan
      let awal = this.model.bulan
      akhir = akhir.padStart(2, '0')
      awal = awal.padStart(2, '0')
      let last = this.formatDate(
        new Date(this.model.tahun, akhir, 0, 23, 59, 59),
        'day'
      )
      let from_date = this.model.tahun + '-' + awal + '-1'
      let to_date = this.model.tahun + '-' + akhir + '-' + last
      this.myAchievementDetail({
        user_id: this.$route.params.id,
        user_type: 'user',
        achievement_type: 'NC',
        from_date: from_date,
        to_date: to_date,
        page: e
      })
    },
    myAchievementDetail(query) {
      SalesApi.myAchievementDetail(query)
        .then(res => {
          this.datas = res.data.data
          let totalPage =
            parseInt(res.data.totalCount) / parseInt(res.data.size)
          this.pagination.totalPage = Math.round(totalPage)
          this.pagination.currentPage = parseInt(res.data.page)
          this.pagination.totalData = parseInt(res.data.totalCount)
          this.loading = false
          console.log(this.pagination)
        })
        .catch(res => {
          console.log(res)
        })
    },
    formatDate(date, type) {
      if (type === 'day') {
        return moment(String(date)).format('DD')
      }
      if (type === 'bulan') {
        return moment(String(date)).format('MM')
      }
      if (type === 'year') {
        return moment(String(date)).format('YYYY')
      }
      if (type === 'full') {
        moment(String(value)).format('YYYY-MM-DD')
      }
    }
  },
  mounted() {
    let akhir = this.model.bulan
    let awal = this.model.bulan
    akhir = akhir.padStart(2, '0')
    awal = awal.padStart(2, '0')
    let last = this.formatDate(
      new Date(this.model.tahun, akhir, 0, 23, 59, 59),
      'day'
    )
    let from_date = this.model.tahun + '-' + awal + '-1'
    let to_date = this.model.tahun + '-' + akhir + '-' + last
    this.myAchievementDetail({
      user_id: this.$route.params.id,
      user_type: 'user',
      achievement_type: 'NC',
      from_date: from_date,
      to_date: to_date
    })
    document.title = 'Employee Dashboard'
  }
}
</script>

import axios from '../services/hrms'

const resource = '/kpi-branch'
export default {
	search(query) {
		let token = JSON.parse(localStorage.getItem('hr_token')).access_token
		const params = new URLSearchParams(query)
		return axios.get(`${ resource }/search?${ params }`, {
			headers: {
				'x-access-token': `${ token }`
			}
		})
	},
	view(id) {
		let token = JSON.parse(localStorage.getItem('hr_token')).access_token
		return axios.get(
			`${ resource }/${ id }`, {
				headers: {
					'x-access-token': `${ token }`
				}
			}
		)
	},
	store(payload) {
		let token = JSON.parse(localStorage.getItem('hr_token')).access_token
		return axios.post(`${ resource }/store`, payload, {
			headers: {
				'x-access-token': `${ token }`
			}
		})
	},
	update(payload, id) {
		let token = JSON.parse(localStorage.getItem('hr_token')).access_token
		return axios.post(`${ resource }/${ id }/update`, payload, {
			headers: {
				'x-access-token': `${ token }`
			}
		})
	},
	delete(id, payload) {
		let token = JSON.parse(localStorage.getItem('hr_token')).access_token
		return axios.post(`${ resource }/${ id }/delete`, payload, {
			headers: {
				'x-access-token': `${ token }`
			}
		})
	},
	kpiBranchDetails(year, month) {
		let token = JSON.parse(localStorage.getItem('hr_token')).access_token
		return axios.get(
			`${ resource }/kpi-branch-details?year=${ year }&month=${ month }`, {
				headers: {
					'x-access-token': `${ token }`
				}
			}
		)
	},
	cloneKpiBranchByMonthYear(from_year, from_month, to_year, to_month,limit=10) {
		let token = JSON.parse(localStorage.getItem('hr_token')).access_token
		return axios.get(
			`${ resource }/clone-kpi-branch-by-month-year?from_year=${ from_year }&from_month=${ from_month }&to_year=${ to_year }&to_month=${ to_month }&limit=${limit}`, {
				headers: {
					'x-access-token': `${ token }`
				}
			}
		)
	},
	groupKpiBranchByMonthYear(year, month) {
		let token = JSON.parse(localStorage.getItem('hr_token')).access_token
		return axios.get(
			`${ resource }/group-kpi-branch-by-month-year?year=${ year }&month=${ month }`, {
				headers: {
					'x-access-token': `${ token }`
				}
			}
		)
	},
	achievementByBranch(from_date, to_date, page = 1, branch_id = 0,limit=10) {
		let token = JSON.parse(localStorage.getItem('hr_token')).access_token
		let q;
		if (branch_id > 0) {
			q = '&branch_id=' + branch_id
		} else {
			q = ''
		}
		return axios.get(`${ resource }/achievement-by-branch?limit=${limit}&from_date=${ from_date }&to_date=${ to_date }&page=${ page }${ q }`, {
			headers: {
				'x-access-token': `${ token }`
			}
		})
	},
	achievementByBranchDetail(branch_id, from_date, to_date, occupation_id = 0) {
		let token = JSON.parse(localStorage.getItem('hr_token')).access_token
		let q;
		if (occupation_id !== 0) {
			q = '&occupation_id=' + occupation_id
		} else {
			q = ''
		}
		console.log('q', q);
		return axios.get(`${ resource }/achievement-by-branch-detail?from_date=${ from_date }&to_date=${ to_date }&branch_id=${ branch_id }${ q }`, {
			headers: {
				'x-access-token': `${ token }`
			}
		})
	}
}
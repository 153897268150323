<template>
  <div>
    <Header>
      <template v-slot:nav-item-left>
        <li class="nav-item">
          <router-link class="navbar-nav-link" to="/backoffice-access"
            >Backoffice Access</router-link
          >
        </li>
      </template>
    </Header>
    <div class="page-content">
      <Sidebar />
      <div class="content-wrapper">
        <div class="content">
          <div class="row">
            <div class="col-md-12">
              <div class="card">
                <table class="table table-striped">
                  <thead>
                    <tr>
                      <th>Name</th>
                      <th>Active</th>
                      <th>Access</th>
                      <th>Super User</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    <template v-for="group in groups">
                      <tr :key="group.id">
                        <td>
                          {{ group.group_name }}
                        </td>
                        <td>
                          {{ group.active }}
                        </td>
                        <td>
                          {{ group.access_id }}
                        </td>
                        <td>
                          {{ group.is_superuser }}
                        </td>
                        <td>
                          <router-link
                            :to="`/backoffice-access/${group.id}`"
                            class="btn btn-sm bg-green"
                          >
                            <i class="fa fa-eye"></i>
                          </router-link>
                        </td>
                      </tr>
                    </template>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import BackofficeAccess from '../apis/BackofficeAccess'

export default {
  data() {
    return {
      groups: []
    }
  },
  components: {
    Header: () =>
      import(
        /* webpackChunkName: "component-header" */
        /* webpackPrefetch: true */
        /* webpackMode: "eager" */
        '../components/Header'
      ),
    Sidebar: () =>
      import(
        /* webpackChunkName: "component-sidebar" */
        /* webpackPrefetch: true */

        /* webpackMode: "eager" */
        '../components/Sidebar'
      )
  },
  methods: {
    allGroups() {
      BackofficeAccess.groups()
        .then(res => {
          this.groups = res.data.data
          console.log(res)
        })
        .catch()
    }
  },
  mounted() {
    document.title = 'Backoffice Access Dashboard'
    this.allGroups()
  }
}
</script>

<template>
    <div>
        <Header>
            <template v-slot:nav-item-left>
                <li class="nav-item">
                    <router-link class="navbar-nav-link" to="/wakil-pialang">
                        <i class="fas fa-chevron-left text-green-700"></i>
                        Wakil Pialang
                    </router-link>
                </li>
            </template>
        </Header>
        <div class="page-content">
            <Sidebar/>
            <div class="content-wrapper">
                <div class="content">
                    <div class="row mb-2">
                        <div class="col-xl-6 col-md-6">
                            Displaying
                            <span class="font-weight-bold text-green-700">{{ pagination.displaying }}</span>
                            of
                            <span
                                    class="font-weight-bold text-green-700"
                            >{{ pagination.totalData }}</span>
                            Result
                        </div>
                        <div class="col-xl-3 col-md-2"></div>
                        <div class="col-xl-3 col-md-2">
                            <div class="input-group">
                                <input aria-describedby="button-addon2" aria-label="Search Name" class="form-control" placeholder="Search Name" type="text"
                                       v-model="filter.first_name">
                                <div class="input-group-append">
                                    <button @click="searchWpQuery" class="btn btn-success" id="button-addon2" type="button">Filter</button>
                                </div>

                                <div class="input-group-append">
                                    <button @click="createWp" class="btn btn-info" id="button-addon3" type="button">Add WP</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12">
                            <div class="card">
                                <table class="table">
                                    <thead>
                                    <tr>
                                        <th>id</th>
                                        <th>Name</th>
                                        <th>Branch</th>
                                        <th>WP Code</th>
                                        <th>Status</th>
                                        <th></th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <template v-if="loading">
                                        <tr :key="a" v-for="a in 10">
                                            <td :key="b" class="font-weight-bold" v-for="b in 6">
                                                <content-placeholders :rounded="true">
                                                    <content-placeholders-text :lines="1"/>
                                                </content-placeholders>
                                            </td>
                                        </tr>
                                    </template>
                                    <template v-else>
                                        <tr :key="wp.id" v-for="wp in wakilpialang">
                                            <td scope="row">{{ wp.id }}</td>
                                            <td>{{ fullname(wp.first_name, wp.last_name) }}</td>
                                            <td>{{ wp.branch_name }}</td>
                                            <td>{{ wp.wp_code }}</td>
                                            <td>
                                                <template v-if="wp.active">
                            <span
                                    class="badge badge-light badge-striped badge-striped-left border-left-success badge-icon"
                            >
                              <i class="icon-checkmark3"></i>
                              Active
                            </span>
                                                </template>
                                                <template v-else>
                            <span
                                    class="badge badge-light badge-striped badge-striped-left border-left-danger badge-icon"
                            >
                              <i class="icon-cross2"></i>
                              Non-Active
                            </span>
                                                </template>
                                            </td>
                                            <td>
                                                <div class="list-icons">
                                                    <div class="list-icons-item dropdown">
                                                        <a
                                                                class="list-icons-item dropdown-toggle caret-0"
                                                                data-toggle="dropdown"
                                                                href="javascript:void(0)"
                                                        >
                                                            <i class="fas fa-ellipsis-v text-green-700 fa-2x"></i>
                                                        </a>
                                                        <div class="dropdown-menu dropdown-menu-right">
                                                            <a
                                                                    @click="showModal($event, wp)"
                                                                    class="dropdown-item"
                                                                    href="javascript:void(0)"
                                                                    v-b-modal.wpDetail
                                                            >
                                                                <i class="icon-file-stats"></i>
                                                                Details
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                    </template>
                                    </tbody>
                                </table>
                            </div>
                            <paginate
                                    :click-handler="clickCallback"
                                    :container-class="
                  'pagination pagination-separated pagination-rounded align-self-center'
                "
                                    :margin-pages="5"
                                    :next-class="'page-item'"
                                    :next-link-class="'page-link'"
                                    :next-text="'Next'"
                                    :page-class="'page-item'"
                                    :page-count="pagination.totalPage"
                                    :page-link-class="'page-link'"
                                    :page-range="5"
                                    :prev-class="'page-item'"
                                    :prev-link-class="'page-link'"
                                    :prev-text="'Prev'"
                            ></paginate>
                        </div>
                    </div>

                    <b-modal
                            :hide-footer="true"
                            :no-close-on-backdrop="true"
                            :no-close-on-esc="true"
                            header-class="bg-white"
                            id="wpDetail"
                            ref="wpDetail"
                            title="Detail Wakil Pialang"
                    >
                        <form @submit.prevent="updateForm">
                            <div class="form-group">
                                <label for="wp_code">WP Code</label>
                                <input class="form-control" id="wp_code" type="text" v-model="wp.code"/>
                            </div>
                            <div class="form-group">
                                <label for="wp_id">Employee</label>
                                <model-select :options="employees" v-model="wp.id"/>
                            </div>
                            <switches color="info" label="Active" theme="bootstrap"
                                      v-model="wp.active"></switches>
                            <button class="btn bg-green-700 btn-block mt-3" type="submit">Update</button>
                        </form>
                    </b-modal>
                    <b-modal
                            :hide-footer="true"
                            :no-close-on-backdrop="true"
                            :no-close-on-esc="true"
                            header-class="bg-white"
                            id="createWp"
                            ref="createWp"
                            title="Create Wakil Pialang"
                    >
                        <form @submit.prevent="submitForm">
                            <div class="form-group">
                                <label for="wp_code">WP Code</label>
                                <input class="form-control" id="wp_code" type="text" v-model="wps.code"/>
                            </div>
                            <div class="form-group">
                                <label for="wp_id">Employee</label>
                                <model-select :options="employees" v-model="wps.id"/>
                            </div>
                            <switches color="info" label="Active" theme="bootstrap"
                                      v-model="wps.active"></switches>
                            <button class="btn bg-green-700 btn-block mt-3" type="submit">Submit</button>
                        </form>
                    </b-modal>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import BackofficeSales from '../apis/BackofficeSales'
    import Hrms from '../services/hrms'
    import EmployeeApi from '../apis/Employee'
    import SalesService from '../services/sales'
    import Paginate from '../components/Paginate'
    import Switches from 'vue-switches'
    import {ModelListSelect, ModelSelect} from 'vue-search-select'

    export default {
        data() {
            return {
                employees: [],
                loading: true,
                wakilpialang: [],
                pagination: {
                    totalPage: 0,
                    currentPage: 1,
                    totalData: 0,
                    displaying: 0
                },
                wp: {
                    code: '',
                    active: 0,
                    id: 0,
                    value: '',
                    text: '',
                    wp_id: 0
                },
                wps: {
                    code: '',
                    active: 0,
                    id: 0,
                    value: '',
                    text: '',
                    wp_id: 0
                },
                filter: {
                    first_name: ''
                }
            }
        },
        components: {
            ModelSelect,
            ModelListSelect,
            Paginate,
            Switches,
            Header: () =>
                import(
                    /* webpackChunkName: "component-header" */
                    /* webpackPrefetch: true */
                    /* webpackMode: "eager" */
                    '../components/Header'
                    ),
            Sidebar: () =>
                import(
                    /* webpackChunkName: "component-sidebar" */
                    /* webpackPrefetch: true */

                    /* webpackMode: "eager" */
                    '../components/Sidebar'
                    )
        },
        beforeMount() {
            document.title = 'Dashboard'
            this.WakilPialangList()
            this.employeeSearch({limit: -1})
        },
        methods: {
            searchWpQuery() {
                this.loading = true
                this.WakilPialangList('search[first_name]=' + this.filter.first_name)
            },
            employeeSearch(param) {
                Hrms.interceptors.response.use(
                    response => response,
                    error => {
                        if (typeof error.response === 'undefined') {
                            this.$swal(
                                'Ooops!',
                                'A network error occurred. ' +
                                'This could be a CORS issue or a dropped internet connection. ' +
                                'It is not possible for us to know.',
                                'error'
                            )
                        }
                        return Promise.reject(error)
                    }
                )
                EmployeeApi.search(param)
                    .then(res => {
                        this.employees = res.data.data.map(x => {
                            return {
                                value: x.user_id,
                                text: x.firstname + ' ' + x.lastname + ' (' + x.email + ')'
                            }
                        })
                    })
                    .catch(error => {
                        this.$swal(
                            'Ooops!',
                            'A network error occurred. ' + 'It is not possible for us to know.',
                            'error'
                        )
                    })
            },
            updateForm(e) {
                console.log(this.wp)
                let wp = {
                    wp_code: this.wp.code,
                    active: this.wp.active,
                    id: this.wp.id
                }
                BackofficeSales.WakilPialangUpdate(this.wp.wp_id, wp)
                    .then(result => {
                        const that = this
                        this.$swal(
                            'Success',
                            'Wakil Pialang berhasil di ubah',
                            'success'
                        ).then(function (x) {
                            that.loading = true
                            that.WakilPialangList()
                            that.$refs['wpDetail'].hide()
                        })
                    })
                    .catch(err => {
                        const that = this
                        let error = ''
                        Object.values(err.response.data.errors).forEach((v, i) => {
                            error = error + ' ' + v[0]
                        })
                        this.$swal('Oooops!', error, 'error').then(function (x) {
                            that.loading = true
                            that.WakilPialangList()
                            that.$refs['wpDetail'].hide()
                        })
                    })
            },
            submitForm(e) {
                console.log(this.wp)
                let wp = {
                    wp_code: this.wps.code,
                    active: this.wps.active,
                    id: this.wps.id
                }
                BackofficeSales.WakilPialangCreate(wp)
                    .then(result => {
                        const that = this
                        this.$swal(
                            'Success',
                            'Wakil Pialang berhasil di simpan',
                            'success'
                        ).then(function (x) {
                            that.loading = true
                            that.WakilPialangList()
                            that.$refs['createWp'].hide()
                        })
                    })
                    .catch(err => {
                        const that = this
                        let error = ''
                        Object.values(err.response.data.errors).forEach((v, i) => {
                            error = error + ' ' + v[0]
                        })
                        this.$swal('Oooops!', error, 'error')
                    })
            },
            showModal(e, wp) {
                this.wp.id = wp.id
                this.wp.code = wp.wp_code
                this.wp.active = wp.active
                this.wp.value = wp.id
                this.wp.wp_id = wp.id
            },
            clickCallback(e) {
                this.loading = true
                const search = {}
                search.page = e
                const fil = 'search[first_name]=' + this.filter.first_name + '&page=' + e
                this.WakilPialangList(fil)
            },
            fullname(firstname, lastname) {
                if (
                    typeof firstname !== 'undefined' &&
                    typeof lastname !== 'undefined' &&
                    firstname
                ) {
                    return firstname + ' ' + lastname
                } else {
                    return '-'
                }
            },
            WakilPialangList(query) {
                SalesService.interceptors.response.use(
                    response => response,
                    error => {
                        if (typeof error.response === 'undefined') {
                            this.$swal(
                                'Ooops!',
                                'A network error occurred. ' +
                                'This could be a CORS issue or a dropped internet connection. ' +
                                'It is not possible for us to know.',
                                'error'
                            )
                        }
                        return Promise.reject(error)
                    }
                )
                BackofficeSales.WakilPialangList(query)
                    .then(result => {
                        this.wakilpialang = result.data.data
                        let total = result.data.totalCount / result.data.size
                        this.pagination.totalPage = parseInt(total)
                        this.pagination.currentPage = result.data.page
                        this.pagination.totalData = result.data.totalCount
                        let display = result.data.page * result.data.size
                        if (display >= this.pagination.totalData) {
                            this.pagination.displaying = this.pagination.totalData
                        } else {
                            this.pagination.displaying = display
                        }
                        this.loading = false
                    })
                    .catch(err => {
                    })
            },
            createWp(){
                this.$refs['createWp'].show()
            }
        }
    }
</script>

<template>
  <div class="sidebar sidebar-dark sidebar-main sidebar-expand-md">
    <!-- Sidebar mobile toggler -->
    <div class="sidebar-mobile-toggler text-center">
      <a class="sidebar-mobile-main-toggle" href="#">
        <i class="icon-arrow-left8"></i>
      </a>
      Navigation
      <a class="sidebar-mobile-expand" href="#">
        <i class="icon-screen-full"></i>
        <i class="icon-screen-normal"></i>
      </a>
    </div>
    <!-- /sidebar mobile toggler -->

    <!-- Sidebar content -->
    <div class="sidebar-content">
      <!-- Main navigation -->
      <div class="card card-sidebar-mobile">
        <ul class="nav nav-sidebar" data-nav-type="accordion">
          <!-- Main -->
          <li class="nav-item-header">
            <div class="text-uppercase font-size-xs line-height-xs">Main</div>
            <i class="icon-menu" title="Main"></i>
          </li>
          <li
            class="nav-item"
            v-if="isHavePermission('GET:employee.default.dashboard')"
          >
            <router-link
              :class="{ active: $route.name === 'home' }"
              class="nav-link"
              to="/"
            >
              <i class="icon-home4"></i>
              <span>Dashboard</span>
            </router-link>
          </li>
          <li
            class="nav-item"
            v-if="
              isHavePermission('GET:employee.default.employee-management-menu')
            "
          >
            <router-link
              :class="{
                active:
                  $route.name === 'employee-list' ||
                  $route.name === 'employee-list-disabled' ||
                  $route.name === 'employee-create' ||
                  $route.name === 'employee-view' ||
                  $route.name === 'employee-task-list-view' ||
                  $route.name === 'employee-request-task-list-view' ||
                  $route.name === 'employee-approval-task-list-view' ||
                  $route.name === 'employee-history-task-list-view' ||
                  $route.name === 'employee-task-detail-view'
              }"
              class="nav-link"
              to="/employees"
            >
              <i class="icon-profile"></i>
              <span>Employee Management</span>
            </router-link>
          </li>
          <li
            class="nav-item"
            v-if="
              isHavePermission('GET:employee.default.employee-management-menu')
            "
          >
            <router-link
              :class="{
                active: $route.name === 'referral-list'
              }"
              class="nav-link"
              to="/referral-list"
            >
              <i class="icon-profile"></i>
              <span>Referral List</span>
            </router-link>
          </li>
          <li
            class="nav-item"
            v-if="isHavePermission('GET:employee.default.budget-request')"
          >
            <router-link
              :class="{
                active:
                  $route.name === 'budget-request' ||
                  $route.name === 'budget-request-my-approval' ||
                  $route.name === 'budget-request-all' ||
                  $route.name === 'budget-request-create' ||
                  $route.name === 'budget-request-detail'
              }"
              class="nav-link"
              to="/budget-request"
            >
              <i class="icon-profile"></i>
              <span>Budget Request</span>
            </router-link>
          </li>
          <li
            class="nav-item"
            v-if="
              isHavePermission('GET:employee.default.employee-management-menu')
            "
          >
            <router-link
              :class="{
                active: $route.name === 'referral-agreement'
              }"
              class="nav-link"
              to="/referral-agreement"
            >
              <i class="icon-profile"></i>
              <span>Agreement Referral (Commission)</span>
            </router-link>
          </li>
          <li
            class="nav-item"
            v-if="isHavePermission('GET:employee.default.anual.rewards')"
          >
            <router-link
              :class="{
                active:
                  $route.name === 'page-best-fm' ||
                  $route.name === 'page-best-rm' ||
                  $route.name === 'page-best-cfm' ||
                  $route.name === 'page-best-crm' ||
                  $route.name === 'page-best-growth' ||
                  $route.name === 'page-best-kpi' ||
                  $route.name === 'page-best-total-performance'
              }"
              class="nav-link"
              to="/reward/best-fm"
            >
              <i class="icon-profile"></i>
              <span>Annual Rewards</span>
            </router-link>
          </li>
          <li
            class="nav-item"
            v-if="
              isHavePermission('GET:employee.default.report-commission')
            "
          >
            <router-link
              :class="{
                active: $route.name === 'report-commission-hrd-by-date-range'
              }"
              class="nav-link"
              to="/report-commission-hrd-by-date-range"
            >
              <i class="icon-profile"></i>
              <span>Report Commission</span>
            </router-link>
          </li>
          <li
            class="nav-item"
            v-if="
              isHavePermission('GET:employee.default.employee-management-menu')
            "
          >
            <router-link
              :class="{
                active: $route.name === 'referral-bank-account'
              }"
              class="nav-link"
              to="/referral-bank-account"
            >
              <i class="icon-profile"></i>
              <span>Bank Account Request</span>
            </router-link>
          </li>
          <li
            class="nav-item"
            v-if="isHavePermission('GET:kpi.kpi-employee.search')"
          >
            <router-link
              :class="{
                active:
                  $route.name === 'kpi-by-position-view' ||
                  $route.name === 'kpi-by-position-detail-view' ||
                  $route.name === 'kpi-by-branch-view' ||
                  $route.name === 'kpi-by-branch-detail-view'
              }"
              class="nav-link"
              to="/kpi-by-position"
            >
              <i class="fas fa-poll-h"></i>
              <span>KPI Management</span>
            </router-link>
          </li>
          <li
            class="nav-item"
            v-if="isHavePermission('GET:kpi.kpi-employee.search')"
          >
            <router-link
              :class="{
                active:
                  $route.name === 'kpi-score-by-position-view' ||
                  $route.name === 'kpi-score-by-position-detail-view' ||
                  $route.name === 'kpi-score-by-branch-view' ||
                  $route.name === 'kpi-score-by-branch-detail-view'
              }"
              class="nav-link"
              to="/kpi-score-by-position"
            >
              <i class="fas fa-poll-h"></i>
              <span>Score Management</span>
            </router-link>
          </li>
          <li
            class="nav-item"
            v-if="isHavePermission('GET:employee.default.departments-view')"
          >
            <router-link
              :class="{
                active: $route.name === 'kpi-channel-view'
              }"
              class="nav-link"
              to="/kpi-channel"
            >
              <i class="icon-tree7"></i>
              <span>Kpi Channel</span>
            </router-link>
          </li>
          <li
            class="nav-item"
            v-if="
              isHavePermission('GET:employee.default.lot-earnings-report-view')
            "
          >
            <router-link
              :class="{
                active: $route.name === 'kpi-score-channel-view'
              }"
              class="nav-link"
              to="/kpi-score-channel"
            >
              <i class="icon-tree7"></i>
              <span>Kpi Score Channel</span>
            </router-link>
          </li>
          <li
            class="nav-item"
            v-if="
              isHavePermission('GET:employee.default.lot-earnings-report-view')
            "
          >
            <router-link
              :class="{
                active:
                  $route.name === 'lot-earnings-report-view' ||
                  $route.name === 'lot-earnings-report-detail-view' ||
                  $route.name === 'lot-earnings-report-client-view' ||
                  $route.name === 'lot-earnings-report-client-detail-view'
              }"
              class="nav-link"
              to="/lot-earnings-report"
            >
              <i class="icon-file-empty2"></i>
              <span>Lot Earnings Report</span>
            </router-link>
          </li>

          <li
            class="nav-item"
            v-if="
              isHavePermission('GET:employee.league-table.list-by-occupation')
            "
          >
            <router-link
              :class="{
                active:
                  $route.name === 'league-table' ||
                  $route.name === 'league-table-occupation-view'
              }"
              class="nav-link"
              to="/league-table/5"
            >
              <i class="icon-profile"></i>
              <span>League Table</span>
            </router-link>
          </li>
          <li
            class="nav-item"
            v-if="
              isHavePermission('GET:employee.default.achievement-table-view')
            "
          >
            <router-link
              :class="{
                active:
                  $route.name === 'achievement-table-view' ||
                  $route.name === 'achievement-table-detail-view'
              }"
              class="nav-link"
              to="/achievement-table"
            >
              <i class="icon-trophy3"></i>
              <span>Achievement Table</span>
            </router-link>
          </li>

          <li
            class="nav-item"
            v-if="
              isHavePermission('GET:employee.default.achievement-bonus-view')
            "
          >
            <router-link
              :class="{
                active:
                  $route.name === 'achievement-bonus-view' ||
                  $route.name === 'achievement-bonus-detail-view'
              }"
              class="nav-link"
              to="/achievement-bonus"
            >
              <i class="icon-coins"></i>
              <span>Achievement Bonus</span>
            </router-link>
          </li>

          <li
            class="nav-item"
            v-if="isHavePermission('GET:employee.default.wakil-pialang-menu')"
          >
            <router-link
              :class="{
                active: $route.name === 'wakil-pialang'
              }"
              class="nav-link"
              to="/wakil-pialang"
            >
              <i class="icon-city"></i>
              <span>Wakil Pialang</span>
            </router-link>
          </li>

          <li
            class="nav-item"
            v-if="
              isHavePermission('GET:employee.default.role-permission-role-view')
            "
          >
            <router-link
              :class="{
                active:
                  $route.name === 'role-permission-view' ||
                  $route.name === 'role-permission-role-view' ||
                  $route.name === 'role-permission-role-users-view'
              }"
              class="nav-link"
              to="/role-permission"
            >
              <i class="fa fa-user-lock"></i>
              <span>Role and Permission</span>
            </router-link>
          </li>

          <li
            class="nav-item"
            v-if="
              isHavePermission('GET:employee.default.backoffice-access-view')
            "
          >
            <router-link
              :class="{
                active:
                  $route.name === 'backoffice-access-view' ||
                  $route.name === 'backoffice-access-detail-view'
              }"
              class="nav-link"
              to="/backoffice-access"
            >
              <i class="fa fa-user-lock"></i>
              <span>Backoffice Access</span>
            </router-link>
          </li>
          <li
            class="nav-item"
            v-if="isHavePermission('GET:employee.default.departments-view')"
          >
            <router-link
              :class="{
                active:
                  $route.name === 'departments-view' ||
                  $route.name === 'departments-detail-view'
              }"
              class="nav-link"
              to="/departments"
            >
              <i class="icon-tree7"></i>
              <span>Departments</span>
            </router-link>
          </li>
          <li class="nav-item nav-item-submenu" v-if="false">
            <a class="nav-link" href="#">
              <i class="icon-copy"></i>
              <span>Layouts</span>
            </a>

            <ul class="nav nav-group-sub" data-submenu-title="Layouts">
              <li class="nav-item">
                <a class="nav-link" href="javascript:void(0)">Default layout</a>
              </li>
            </ul>
          </li>
        </ul>
      </div>
      <!-- /main navigation -->
    </div>
    <!-- /sidebar content -->
  </div>
</template>
<script>
import PermissionMixin from '../mixins/PermissionMixin'

export default {
  mixins: [PermissionMixin]
}
</script>

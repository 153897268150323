const leagueTable = [{
    path: '/league-table',
    name: 'league-table-view',
    component: () =>
        import (
            /* webpackChunkName: "page-league-table-view" */
            /* webpackPrefetch: true */
            /* webpackMode: "eager" */
            '../views/LeagueTable.vue'
        ),
    meta: {
        requiresAuth: true,
        permission: 'GET:employee.league-table.list-by-occupation'
    }
},{
    path: '/league-table/:id',
    name: 'league-table-occupation-view',
    component: () =>
        import (
            /* webpackChunkName: "page-league-table-view" */
            /* webpackPrefetch: true */
            /* webpackMode: "eager" */
            '../views/LeagueTable.vue'
        ),
    meta: {
        requiresAuth: true,
        permission: 'GET:employee.league-table.list-by-occupation'
    }
}]
export default leagueTable
<template>
    <div>
        <Header>
            <template v-slot:nav-item-left>
                <li class="nav-item">
                    <router-link class="navbar-nav-link" to="/lot-earnings-report-client">
                        <i class="fas fa-chevron-left text-green-700"></i>
                        Referral Earnings Detail
                    </router-link>
                </li>
            </template>
        </Header>
        <div class="page-content">
            <Sidebar/>
            <div class="content-wrapper">
                <div class="content">
                    <breadcrumb-component :links="[{name:'Lot Earing Report'},{name:'Client'},{name:datas.personal_data.first_name}]"></breadcrumb-component>
                    <div class="row mb-3">
                        <div class="col-md-12">
                            <div class="card">
                                <div class="row">
                                    <div class="col-3 border-right">
                                        <div class="media p-1">
                                            <div class="mr-3">
                                                <a href="#">
                                                    <avatar :username="datas.personal_data.first_name" height="42" width="42"></avatar>
                                                </a>
                                            </div>
                                            <div class="media-body">
                                                <small class="text-muted d-block">Referral Name</small>
                                                <span class="font-weight-semibold">{{datas.personal_data.first_name}}</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-1 border-right">
                                        <div class="media p-1">
                                            <div class="media-body">
                                                <small class="text-muted d-block">Referral ID</small>
                                                <span class="font-weight-semibold">{{$route.params.user_id}}</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-3 border-right">
                                        <div class="media p-1">
                                            <div class="media-body">
                                                <small class="text-muted d-block">Email</small>
                                                <span class="font-weight-semibold">{{datas.personal_data.email}}</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-1 border-right">
                                        <div class="media p-1">
                                            <div class="media-body">
                                                <small class="text-muted d-block">Branch</small>
                                                <span class="font-weight-semibold">{{datas.personal_data.branch_name}}</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-2 border-right">
                                        <div class="media p-1">
                                            <div class="media-body">
                                                <small class="text-muted d-block">Position</small>
                                                <span class="font-weight-semibold">{{datas.personal_data.occupation_name}}</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-2">
                                        <div class="media p-1">
                                            <div class="media-body">
                                                <small class="text-muted d-block">Upline</small>
                                                <span class="font-weight-semibold">{{datas.personal_data.upline_first_name+' '+datas.personal_data.upline_last_name}}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row mb-3">
                        <div class="col-md-6 mb-1">
                            Displaying
                            <span class="font-weight-bold text-green-700">{{ pagination.displaying }}</span>
                            of
                            <span
                                    class="font-weight-bold text-green-700"
                            >{{ pagination.totalData }}</span>
                            Result
                        </div>
                        <div class="col-md-3">
                            <div class="form-group selectdiv">
                                <select @change="filterDropdown" class="form-control" v-model="model.bulan">
                                    <option value="1">Januari</option>
                                    <option value="2">Februari</option>
                                    <option value="3">Maret</option>
                                    <option value="4">April</option>
                                    <option value="5">Mei</option>
                                    <option value="6">Juni</option>
                                    <option value="7">Juli</option>
                                    <option value="8">Agustus</option>
                                    <option value="9">September</option>
                                    <option value="10">Oktober</option>
                                    <option value="11">November</option>
                                    <option value="12">Desember</option>
                                </select>
                            </div>
                        </div>
                        <div class="col-md-3">
                            <div class="form-group selectdiv">
                                <select @change="filterDropdown" class="form-control" v-model="model.year">
                                    <option :value="year" v-for="year in years">{{ year }}</option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-3">
                            <div class="card">
                                <div class="media p-2">
                                    <div class="media-body">
                                        <small class="text-muted d-block">Total Lots</small>
                                        <span class="font-weight-semibold">{{(datas.summary.total_lot).toFixed(2)}}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-3">
                            <div class="card">
                                <div class="media p-2">
                                    <div class="media-body">
                                        <small class="text-muted d-block">Total Earnings</small>
                                        <span class="font-weight-semibold">{{(datas.summary.total_earnings_commission_amount).toFixed(2)}}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-6">
                            <div class="card">
                                <table class="table">
                                    <thead>
                                    <tr>
                                        <td>Account Number</td>
                                        <td>Lots</td>
                                        <td>Earnings</td>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <template v-if="loading">
                                        <tr :key="a" v-for="a in 5">
                                            <td :key="b" v-for="b in 3">
                                                <content-placeholders :rounded="true">
                                                    <content-placeholders-text :lines="1"/>
                                                </content-placeholders>
                                            </td>
                                        </tr>
                                    </template>
                                    <template v-if="!loading && datas.data.length === 0">
                                        <tr>
                                            <td colspan="7">
                                                No Data
                                            </td>
                                        </tr>
                                    </template>
                                    <template v-for="(client,index) in datas.data" v-if="!loading && datas.data.length > 0">
                                        <tr :key="index" v-if="index<5">
                                            <td>{{client.account}}</td>
                                            <td>{{client.total_lot}}</td>
                                            <td>{{client.earnings_commission_amount}}</td>
                                        </tr>
                                    </template>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="card">
                                <table class="table">
                                    <thead>
                                    <tr>
                                        <td>Account Number</td>
                                        <td>Lots</td>
                                        <td>Earnings</td>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <template v-if="loading">
                                        <tr :key="a" v-for="a in 5">
                                            <td :key="b" v-for="b in 3">
                                                <content-placeholders :rounded="true">
                                                    <content-placeholders-text :lines="1"/>
                                                </content-placeholders>
                                            </td>
                                        </tr>
                                    </template>
                                    <template v-if="!loading && datas.data.length === 0">
                                        <tr>
                                            <td colspan="7">
                                                No Data
                                            </td>
                                        </tr>
                                    </template>
                                    <template v-for="(client,index) in datas.data" v-if="!loading && datas.data.length > 0">
                                        <tr :key="index" v-if="index>=5">
                                            <td>{{client.account}}</td>
                                            <td>{{client.total_lot}}</td>
                                            <td>{{client.earnings_commission_amount}}</td>
                                        </tr>
                                    </template>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div class="col-md-12">
                            <paginate
                                    :click-handler="clickCallback"
                                    :container-class="
                  'pagination pagination-separated pagination-rounded align-self-center'
                "
                                    :margin-pages="5"
                                    :next-class="'page-item'"
                                    :next-link-class="'page-link'"
                                    :next-text="'Next'"
                                    :page-class="'page-item'"
                                    :page-count="pagination.totalPage"
                                    :page-link-class="'page-link'"
                                    :page-range="5"
                                    :prev-class="'page-item'"
                                    :prev-link-class="'page-link'"
                                    :prev-text="'Prev'"
                            ></paginate>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import BackofficeApi from '../apis/Backoffice'
    import moment from "moment";
    import Paginate from '../components/Paginate'
    import Avatar from 'vue-avatar'

    export default {
        data() {
            return {
                loading: true,
                pagination: {
                    totalPage: 0,
                    currentPage: 1,
                    totalData: 0,
                    displaying: 0
                },
                datas: {
                    page: 1,
                    size: 10,
                    totalCount: 0,
                    summary: {
                        "total_or_commission_amount": 0,
                        "total_recom_commission_amount": 0,
                        "total_reduksi_commission_amount": 0,
                        "total_earnings_commission_amount": 0,
                        "total_subsidi_commission_amount": 0,
                        "total_lot": 0
                    },
                    personal_data: {
                        "first_name": "-",
                        "last_name": "",
                        "email": "-",
                        "branch_name": "-",
                        "occupation_name": "-",
                        "upline_first_name": "-",
                        "upline_last_name": "",
                        "upline_email": "-"
                    }
                },
                model: {
                    years: [],
                    user_id: this.$route.params.user_id,
                    user_type: this.$route.params.type,
                    year: new Date().getFullYear(),
                    bulan: new Date().getMonth() + 1,
                    tahun: new Date().getFullYear()
                }
            }
        },
        components: {
            Header: () =>
                import(
                    /* webpackChunkName: "component-header" */
                    /* webpackPrefetch: true */
                    /* webpackMode: "eager" */
                    '../components/Header'
                    ),
            Sidebar: () =>
                import(
                    /* webpackChunkName: "component-sidebar" */
                    /* webpackPrefetch: true */

                    /* webpackMode: "eager" */
                    '../components/Sidebar'
                    ),
            Paginate,
            Avatar
        },
        methods: {
            formatDate(date, type) {
                if (type === 'day') {
                    return moment(String(date)).format('DD')
                }
                if (type === 'bulan') {
                    return moment(String(date)).format('MM')
                }
                if (type === 'year') {
                    return moment(String(date)).format('YYYY')
                }
                if (type === 'full') {
                    moment(String(value)).format('YYYY-MM-DD')
                }
            },
            commissionEarningsReportByUserDetail(start_date, end_date, user_type, user_id) {
                BackofficeApi
                    .commissionEarningsReportByUserDetail(start_date, end_date, user_type, user_id)
                    .then(res => {
                        this.datas = res.data
                        this.pagination.totalData = res.data.totalCount
                        this.pagination.currentPage = res.data.page
                        this.pagination.totalPage = _.ceil(res.data.totalCount / res.data.size)
                        this.loading = false
                    })
                    .catch(err => {
                        this.loading = false
                    })
            },
            clickCallback(e) {
                this.loading = true
                let last = this.formatDate(new Date(this.model.year, this.model.bulan, 0, 23, 59, 59), 'day')
                let from_date = this.model.year + '-' + this.model.bulan + '-1'
                let to_date = this.model.year + '-' + this.model.bulan + '-' + last
                this.commissionEarningsReportByUserDetail(from_date, to_date, this.model.user_type, this.model.user_id, e)
            },
            filterDropdown(e) {
                this.loading = true
                let last = this.formatDate(new Date(this.model.year, this.model.bulan, 0, 23, 59, 59), 'day')
                let from_date = this.model.year + '-' + this.model.bulan + '-1'
                let to_date = this.model.year + '-' + this.model.bulan + '-' + last
                this.commissionEarningsReportByUserDetail(from_date, to_date, this.model.user_type, this.model.user_id)
            }
        },
        mounted() {
            document.title = 'Employee Earnings Detail'
            let last = this.formatDate(new Date(this.model.year, this.model.bulan, 0, 23, 59, 59), 'day')
            let from_date = this.model.year + '-' + this.model.bulan + '-1'
            let to_date = this.model.year + '-' + this.model.bulan + '-' + last
            this.commissionEarningsReportByUserDetail(from_date, to_date, this.model.user_type, this.model.user_id)
        },
        computed: {
            years() {
                const year = new Date().getFullYear()
                return Array.from({length: year - 2018}, (value, index) => 2019 + index)
            }
        }
    }
</script>

<template>
    <div>
        <Header>
            <template v-slot:nav-item-left>
                <li class="nav-item">
                    <router-link class="navbar-nav-link" to="/">Referral List</router-link>
                </li>
            </template>
        </Header>
        <div class="page-content">
            <Sidebar/>
            <div class="content-wrapper">
              <breadcrumb-component
                  :links="[{ name: 'Referral List' }]"
              ></breadcrumb-component>
                <div class="content">

                  <div class="row">

                    <div class="col-md-12">
                      <div class="card card-body">
                        <div class="row">
                          <div class="col-md-2">
                            <div class="form-group">
                              <label for="employee_no">Referral NIK</label>
                              <input
                                  class="form-control"
                                  id="filter_nik"
                                  name="filter_nik"
                                  placeholder="Referral NIK"
                                  type="text"
                                  v-model="filter.filter_nik"
                              />
                            </div>
                          </div>
                          <div class="col-md-2">
                            <div class="form-group">
                              <label for="employee_no">Referral Name</label>
                              <input
                                  class="form-control"
                                  id="filter_first_name"
                                  name="filter_first_name"
                                  placeholder="Referral Name"
                                  type="text"
                                  v-model="filter.filter_first_name"
                              />
                            </div>
                          </div>
                          <div class="col-md-2">
                            <div class="form-group">
                              <label for="employee_no">Referral Email</label>
                              <input
                                  class="form-control"
                                  id="filter_email"
                                  name="filter_email"
                                  placeholder="Referral Email"
                                  type="text"
                                  v-model="filter.filter_email"
                              />
                            </div>
                          </div>
                        </div>

                        <div class="row justify-content-end">
                          <div class="col-md-3">
                            <button
                                @click="showModal"
                                class="btn bg-green-700 btn-block rounded-round btn-labeled btn-labeled-left"
                                type="button"
                            >
                              <b>
                                <i class="icon-check"></i>
                              </b>
                              Export New Referral
                            </button>
                          </div>
                          <div class="col-md-2">
                            <button
                                @click="applyFilter"
                                class="btn bg-green-700 btn-block rounded-round btn-labeled btn-labeled-left"
                                type="button"
                            >
                              <b>
                                <i class="icon-check"></i>
                              </b>
                              Apply
                            </button>
                          </div>
                        </div>
                      </div>
                      <div class="card">
                        <table class="table table-striped">
                          <thead>
                          <tr>
                            <th>Referral Email</th>
                            <th>Referral Name</th>
                            <th>NIK</th>
                            <th>Referral Code</th>
                            <th>NPWP</th>
                            <th>Branch</th>
                            <th>Referral Type</th>
                            <th>Active</th>
                            <th>Status</th>
                          </tr>
                          </thead>
                          <tbody>
                          <tr v-for="referral in referrals" :key="referral.id">
                            <td>{{ referral.email }}</td>
                            <td>{{ referral.first_name }}</td>
                            <td>{{ referral.nik }}</td>
                            <td>{{ referral.referral_code }}</td>
                            <td>{{ referral.npwp_no }}</td>
                            <td>{{ referral.branch_name }}</td>
                            <td>{{ referral.referral_type_name }}</td>
                            <td>
                              <template v-if="referral.active">
                                <i class="fa fa-check-circle text-success"></i>
                              </template>
                              <template v-else>
                                <i class="fa fa-times-circle text-danger"></i>
                              </template>
                            </td>
                            <td>{{ referral.status_name }}</td>
                          </tr>
                          </tbody>
                        </table>
                      </div>
                      <paginate
                          :click-handler="clickCallback"
                          :container-class="
                  'pagination pagination-separated pagination-rounded align-self-center'
                "
                          :margin-pages="5"
                          :next-class="'page-item'"
                          :next-link-class="'page-link'"
                          :next-text="'Next'"
                          :page-class="'page-item'"
                          :page-count="pagination.totalPage"
                          :page-link-class="'page-link'"
                          :page-range="5"
                          :prev-class="'page-item'"
                          :prev-link-class="'page-link'"
                          :prev-text="'Prev'"
                          v-if="!$root.mobile"
                      ></paginate>
                    </div>
                  </div>

                </div>
            </div>
        </div>

    <b-modal
      :hide-footer="true"
      :no-close-on-backdrop="true"
      :no-close-on-esc="true"
      header-class="bg-white"
      id="submitFormExportReferral"
      ref="submitFormExportReferral"
      size="sm"
      title="Info"
    >
      <form @submit.prevent="submitFormExportReferral">
        <div class="row mb-2 mt-2">
          <div class="col-md-6">
            <div class="form-group">
              <label for="">Bulan</label>
              <model-select
                :options="bulan"
                id="bulan"
                placeholder="select item"
                v-model="model.month"
              ></model-select>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label for="">Tahun</label>
              <model-select
                :options="years"
                id="tahun"
                placeholder="select item"
                v-model="model.year"
              ></model-select>
            </div>
          </div>
        </div>
        <div class="row justify-content-between">
          <div class="col-md-3">
            <button class="btn bg-green-700 btn-block mt-3" type="submit">
              Submit
            </button>
          </div>
        </div>
      </form>
    </b-modal>


    </div>
</template>
<script>
import Backoffice from "../apis/Backoffice";
import Paginate from '../components/Paginate'
import { ModelSelect } from 'vue-search-select'
import _ from 'lodash'
    export default {
        data() {
            return {
              loading:true,
              referrals:[],
              pagination: {
                totalPage: 0,
                currentPage: 1,
                totalData: 0,
                displaying: 0
              },
              loc:[
                {
                  value:'A',
                  req:[11]
                },
                {
                  value:'B',
                  req:[14]
                },
                {
                  value:'C',
                  req:[5,4]
                },
                {
                  value:'D',
                  req:[25,2,3,10,7,17,20],
                },
                {
                  value:'E',
                  req:[15]
                },
                {
                  value:'G',
                  req:[13]
                },
                {
                  value:'H',
                  req:[1]
                },
                {
                  value:'I',
                  req:[8]
                },
                {
                  value:'J',
                  req:[16]
                },
                {
                  value:'K',
                  req:[6]
                }
              ],
              wloc:[
                {
                  value:"1",
                  req:[25]
                },
                {
                  value:"01A",
                  req:[26]
                },
                {
                  value:"2",
                  req:[1]
                },
                {
                  value:"3",
                  req:[2]
                },
                {
                  value:"4",
                  req:[3]
                },
                {
                  value:"05C",
                  req:[6]
                },
                {
                  value:"6",
                  req:[14]
                },
                {
                  value:"7",
                  req:[13]
                },
                {
                  value:"8",
                  req:[15]
                },
                {
                  value:"9",
                  req:[4,5]
                },
                {
                  value:"10",
                  req:[10]
                },
                {
                  value:"11",
                  req:[17]
                },
                {
                  value:"12",
                  req:[20]
                },
                {
                  value:"14",
                  req:[11]
                },
                {
                  value:"15",
                  req:[7]
                },
                {
                  value:"16",
                  req:[8]
                },
              ],
              bulan:[
                {
                  value: 1,
                  text: 'Januari'
                },
                {
                  value: 2,
                  text: 'Februari'
                },
                {
                  value: 3,
                  text: 'Maret'
                },
                {
                  value: 4,
                  text: 'April'
                },
                {
                  value: 5,
                  text: 'Mei'
                },
                {
                  value: 6,
                  text: 'Juni'
                },
                {
                  value: 7,
                  text: 'Juli'
                },
                {
                  value: 8,
                  text: 'Agustus'
                },
                {
                  value: 9,
                  text: 'September'
                },
                {
                  value: 10,
                  text: 'Oktober'
                },
                {
                  value: 11,
                  text: 'November'
                },
                {
                  value: 12,
                  text: 'Desember'
                }
              ],
              filter: {
                filter_nik:'',
                filter_first_name:'',
                filter_email:'',
                limit: 10
              },
              model:{
                month: new Date().getMonth() + 1,
                year:new Date().getFullYear()
              }
            }
        },
        components: {
            Header: () =>
                import(
                    /* webpackChunkName: "component-header" */
                    /* webpackPrefetch: true */
                    /* webpackMode: "eager" */
                    '../components/Header'
                    ),
            Sidebar: () =>
                import(
                    /* webpackChunkName: "component-sidebar" */
                    /* webpackPrefetch: true */

                    /* webpackMode: "eager" */
                    '../components/Sidebar'
                    ),
          Paginate,
          ModelSelect
        },
        methods: {
          referralListWithNikHistory(){
            let date = this.model.year+'-'+this.model.month+'-01'
            Backoffice.referralListWithNikHistory({"period_history_date":date}).then((result) => {
              let rows = result.data.data
              let filename = 'New Refferral '+this.model.month+'-'+this.model.year+'.csv'
              let csvFile ='em_noind;'
              +'em_tittle;'
              +'em_name;'
              +'em_loc;'
              +'em_wloc;'
              +'em_dept;'
              +'em_costcd;'
              +'em_jobcd;'
              +'em_level;'
              +'em_grade;'
              +'em_doh;'
              +'em_dor;'
              +'em_dob;'
              +'em_sex;'
              +'em_msts;'
              +'em_anak;'
              +'em_psts;'
              +'em_panak;'
              +'em_educ;'
              +'em_religcd;'
              +'em_adr1;'
              +'em_adr2;'
              +'em_adr3;'
              +'em_adr4;'
              +'em_npwp;'
              +'em_ktpno;'
              +'em_passport;'
              +'em_nation;'
              +'em_badgeid;'
              +'em_phone;'
              +'em_astek;'
              +'em_bpjs_ks;'
              +'em_eMail;'
              +'em_pin;'
              +'em_bankCd1;'
              +'em_bankCd2;'
              +'em_bankAC1;'
              +'em_bankAC2;'
              +'em_golkar;'
              +'em_fundtyp;'
              +'em_pertyp;'
              +'em_pmttyp;'
              +'em_status'
              +'\n'

              for (let i = 0; i < rows.length; i++) {
                let row = rows[i]
                // console.log(this.locValue(row.branch_id))
                let em_noind = row.nik
                let em_tittle = ''
                let em_name = row.referral_first_name+ (typeof row.referral_last_name == 'string' ? ' '+row.referral_last_name : '')
                let em_loc = this.locValue(row.branch_id)
                let em_wloc = this.wlocValue(row.branch_id)
                let em_dept = ''
                let em_costcd = ''
                let em_jobcd = ''
                let em_level = ''
                let em_grade = ''
                let em_doh = ''
                let em_dor = ''
                let em_dob = ''
                let em_sex = ''
                let em_msts = ''
                let em_anak = ''
                let em_psts = ''
                let em_panak = ''
                let em_educ = ''
                let em_religcd = ''
                let em_adr1 = row.domisili_address
                let em_adr2 = ''
                let em_adr3 = ''
                let em_adr4 = ''
                let em_npwp = ''
                let em_ktpno = ''
                let em_passport = ''
                let em_nation = ''
                let em_badgeid = ''
                let em_phone = ''
                let em_astek = ''
                let em_bpjs_ks = ''
                let em_eMail = ''
                let em_pin = ''
                let em_bankCd1 = row.bank_name
                let em_bankCd2 = ''
                let em_bankAC1 = row.bank_account_no
                let em_bankAC2 = ''
                let em_golkar = '9'
                let em_fundtyp = (row.bank_name == 'BCA' ? 'A' : 'B')
                let em_pertyp = 'M'
                let em_pmttyp = 'B'
                let em_status = row.referral_type_name
                csvFile +=
                    em_noind+';'+
                    em_tittle+';'+
                    em_name+';'+
                    em_loc+';'+
                    em_wloc+';'+
                    em_dept+';'+
                    em_costcd+';'+
                    em_jobcd+';'+
                    em_level+';'+
                    em_grade+';'+
                    em_doh+';'+
                    em_dor+';'+
                    em_dob+';'+
                    em_sex+';'+
                    em_msts+';'+
                    em_anak+';'+
                    em_psts+';'+
                    em_panak+';'+
                    em_educ+';'+
                    em_religcd+';'+
                    em_adr1+';'+
                    em_adr2+';'+
                    em_adr3+';'+
                    em_adr4+';'+
                    em_npwp+';'+
                    em_ktpno+';'+
                    em_passport+';'+
                    em_nation+';'+
                    em_badgeid+';'+
                    em_phone+';'+
                    em_astek+';'+
                    em_bpjs_ks+';'+
                    em_eMail+';'+
                    em_pin+';'+
                    em_bankCd1+';'+
                    em_bankCd2+';'+
                    em_bankAC1+';'+
                    em_bankAC2+';'+
                    em_golkar+';'+
                    em_fundtyp+';'+
                    em_pertyp+';'+
                    em_pmttyp+';'+
                    em_status+''+
                    '\n'
              }
              let blob = new Blob([csvFile], { type: 'text/csv;charset=utf-8;' })
              if (navigator.msSaveBlob) {
                // IE 10+
                navigator.msSaveBlob(blob, filename)
              } else {
                let link = document.createElement('a')
                if (link.download !== undefined) {
                  // feature detection
                  // Browsers that support HTML5 download attribute
                  let url = URL.createObjectURL(blob)
                  link.setAttribute('href', url)
                  link.setAttribute('download', filename)
                  link.style.visibility = 'hidden'
                  document.body.appendChild(link)
                  link.click()
                  document.body.removeChild(link)
                }
              }
              this.loading = false
            }).catch((err) => {
              console.log(err)
            });
          },
          showModal(){
            this.$refs['submitFormExportReferral'].show()
          },
          locValue(req){
            let a = '';
            this.loc.map((v,i)=>{
              return _.find(v.req,(o)=>{
               if(req == o) {
                  a = v.value
                  return v.value
               }
              })
            })
            return a
          },
          wlocValue(req){
            let a = '';
            this.wloc.map((v,i)=>{
              return _.find(v.req,(o)=>{
               if(req == o) {
                  a = v.value
                  return v.value
               }
              })
            })
            return a
          },
          submitFormExportReferral(){
            this.loading = true
            this.referralListWithNikHistory()
          },
          applyFilter(e){
            let search=''
            if(this.filter.filter_email){
              search=search+'&search[filter_email]='+this.filter.filter_email
            }
            if(this.filter.filter_nik){
              search=search+'&search[filter_nik]='+this.filter.filter_nik
            }
            if(this.filter.filter_first_name){
              search=search+'&search[filter_first_name]='+this.filter.filter_first_name
            }

            search = search+'&page='+this.pagination.currentPage

            this.getReferralList(search)
          },
          clickCallback(e){

            let search=''
            if(this.filter.filter_email){
              search=search+'&search[filter_email]='+this.filter.filter_email
            }
            if(this.filter.filter_nik){
              search=search+'&search[filter_nik]='+this.filter.filter_nik
            }
            if(this.filter.filter_first_name){
              search=search+'&search[filter_first_name]='+this.filter.filter_first_name
            }

            search = search+'&page='+e

            this.getReferralList(search)
          },
          getReferralList(query){
            Backoffice.referralList(query)
            .then(res=>{
              this.referrals = res.data.data

              this.pagination.currentPage = parseInt(
                  res.data.page
              )
              this.pagination.totalData = parseInt(
                  res.data.totalCount
              )

              let totalPage = parseInt(res.data.totalCount) / parseInt(res.data.size)
              totalPage = Math.round(totalPage)


              this.pagination.totalPage = totalPage


            })
            .catch(res=>{
              console.log(res)
            })
          }
        },
        computed: {
          years() {
            const year = new Date().getFullYear()
            let years = Array.from({ length: year - 2019 }, (value, index) => {
              return {
                value: 2020 + index,
                text: 2020 + index,
              }
            })
            // console.log(years)
            return years
          }
        },
        mounted() {
            document.title = 'Referral List'
            this.getReferralList({})
            console.log('locValue',this.locValue(5))
            console.log('wlocValue',this.wlocValue(5))
        }
    }
</script>

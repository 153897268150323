<template >
	<ul :class = "containerClass" v-if = "!noLiSurround" >
		<li
				:class = "[pageClass, firstPageSelected() ? disabledClass : '']"
				v-if = "firstLastButton"
		>
			<a
					:class = "pageLinkClass"
					:tabindex = "firstPageSelected() ? -1 : 0"
					@click = "selectFirstPage()"
					@keyup.enter = "selectFirstPage()"
					v-html = "firstButtonText"
			></a >
		</li >
		
		<li
				:class = "[prevClass, firstPageSelected() ? disabledClass : '']"
				v-if = "!(firstPageSelected() && hidePrevNext)"
		>
			<a
					:class = "prevLinkClass"
					:tabindex = "firstPageSelected() ? -1 : 0"
					@click = "prevPage()"
					@keyup.enter = "prevPage()"
					v-html = "prevText"
			></a >
		</li >
		
		<li
				:class = "[
        pageClass,
        page.selected ? activeClass : '',
        page.disabled ? disabledClass : '',
        page.breakView ? breakViewClass : ''
      ]"
				v-for = "page in pages"
		>
			<a
					:class = "[pageLinkClass, breakViewLinkClass]"
					tabindex = "0"
					v-if = "page.breakView"
			>
				<slot name = "breakViewContent" >{{ breakViewText }}</slot >
			</a
			>
			<a :class = "pageLinkClass" tabindex = "0" v-else-if = "page.disabled" >{{
			                                                                        page.content
			                                                                        }}</a >
			<a
					:class = "pageLinkClass"
					@click = "handlePageSelected(page.index + 1)"
					@keyup.enter = "handlePageSelected(page.index + 1)"
					tabindex = "0"
					v-else
			>{{ page.content }}</a
			>
		</li >
		
		<li
				:class = "[nextClass, lastPageSelected() ? disabledClass : '']"
				v-if = "!(lastPageSelected() && hidePrevNext)"
		>
			<a
					:class = "nextLinkClass"
					:tabindex = "lastPageSelected() ? -1 : 0"
					@click = "nextPage()"
					@keyup.enter = "nextPage()"
					v-html = "nextText"
			></a >
		</li >
		
		<li
				:class = "[pageClass, lastPageSelected() ? disabledClass : '']"
				v-if = "firstLastButton"
		>
			<a
					:class = "pageLinkClass"
					:tabindex = "lastPageSelected() ? -1 : 0"
					@click = "selectLastPage()"
					@keyup.enter = "selectLastPage()"
					v-html = "lastButtonText"
			></a >
		</li >
	</ul >
	
	<div :class = "containerClass" v-else >
		<a
				:class = "[pageLinkClass, firstPageSelected() ? disabledClass : '']"
				@click = "selectFirstPage()"
				@keyup.enter = "selectFirstPage()"
				tabindex = "0"
				v-html = "firstButtonText"
				v-if = "firstLastButton"
		></a >
		<a
				:class = "[prevLinkClass, firstPageSelected() ? disabledClass : '']"
				@click = "prevPage()"
				@keyup.enter = "prevPage()"
				tabindex = "0"
				v-html = "prevText"
				v-if = "!(firstPageSelected() && hidePrevNext)"
		></a >
		<template v-for = "page in pages" >
			<a
					:class = "[
          pageLinkClass,
          breakViewLinkClass,
          page.disabled ? disabledClass : ''
        ]"
					tabindex = "0"
					v-if = "page.breakView"
			>
				<slot name = "breakViewContent" >{{ breakViewText }}</slot >
			</a
			>
			<a
					:class = "[
          pageLinkClass,
          page.selected ? activeClass : '',
          disabledClass
        ]"
					tabindex = "0"
					v-else-if = "page.disabled"
			>{{ page.content }}</a
			>
			<a
					:class = "[pageLinkClass, page.selected ? activeClass : '']"
					@click = "handlePageSelected(page.index + 1)"
					@keyup.enter = "handlePageSelected(page.index + 1)"
					tabindex = "0"
					v-else
			>{{ page.content }}</a
			>
		</template >
		<a
				:class = "[nextLinkClass, lastPageSelected() ? disabledClass : '']"
				@click = "nextPage()"
				@keyup.enter = "nextPage()"
				tabindex = "0"
				v-html = "nextText"
				v-if = "!(lastPageSelected() && hidePrevNext)"
		></a >
		<a
				:class = "[pageLinkClass, lastPageSelected() ? disabledClass : '']"
				@click = "selectLastPage()"
				@keyup.enter = "selectLastPage()"
				tabindex = "0"
				v-html = "lastButtonText"
				v-if = "firstLastButton"
		></a >
	</div >
</template >

<script >
	export default {
		props: {
			value: {
				type: Number
			},
			pageCount: {
				type: Number,
				required: true
			},
			forcePage: {
				type: Number
			},
			clickHandler: {
				type: Function,
				default: () => {
				}
			},
			pageRange: {
				type: Number,
				default: 3
			},
			marginPages: {
				type: Number,
				default: 1
			},
			prevText: {
				type: String,
				default: 'Prev'
			},
			nextText: {
				type: String,
				default: 'Next'
			},
			breakViewText: {
				type: String,
				default: '…'
			},
			containerClass: {
				type: String
			},
			pageClass: {
				type: String
			},
			pageLinkClass: {
				type: String
			},
			prevClass: {
				type: String
			},
			prevLinkClass: {
				type: String
			},
			nextClass: {
				type: String
			},
			nextLinkClass: {
				type: String
			},
			breakViewClass: {
				type: String
			},
			breakViewLinkClass: {
				type: String
			},
			activeClass: {
				type: String,
				default: 'active'
			},
			disabledClass: {
				type: String,
				default: 'disabled'
			},
			noLiSurround: {
				type: Boolean,
				default: false
			},
			firstLastButton: {
				type: Boolean,
				default: false
			},
			firstButtonText: {
				type: String,
				default: 'First'
			},
			lastButtonText: {
				type: String,
				default: 'Last'
			},
			hidePrevNext: {
				type: Boolean,
				default: false
			}
		},
		beforeUpdate() {
			if (this.forcePage === undefined) return
			if (this.forcePage !== this.selected) {
				this.selected = this.forcePage
			}
		},
		computed: {
			selected: {
				get: function () {
					return this.value || this.innerValue
				},
				set: function (newValue) {
					this.innerValue = newValue
				}
			},
			pages: function () {
				let items = {}
				if (this.pageCount <= this.pageRange) {
					for (let index = 0; index < this.pageCount; index++) {
						let page = {
							index: index,
							content: index + 1,
							selected: index === this.selected - 1
						}
						items[ index ] = page
					}
				} else {
					const halfPageRange = Math.floor(this.pageRange / 2)
					let setPageItem = index => {
						let page = {
							index: index,
							content: index + 1,
							selected: index === this.selected - 1
						}
						items[ index ] = page
					}
					let setBreakView = index => {
						let breakView = {
							disabled: true,
							breakView: true
						}
						items[ index ] = breakView
					}
					// 1st - loop thru low end of margin pages
					for (let i = 0; i < this.marginPages; i++) {
						setPageItem(i)
					}
					// 2nd - loop thru selected range
					let selectedRangeLow = 0
					if (this.selected - halfPageRange > 0) {
						selectedRangeLow = this.selected - 1 - halfPageRange
					}
					let selectedRangeHigh = selectedRangeLow + this.pageRange - 1
					if (selectedRangeHigh >= this.pageCount) {
						selectedRangeHigh = this.pageCount - 1
						selectedRangeLow = selectedRangeHigh - this.pageRange + 1
					}
					for (
						let i = selectedRangeLow;
						i <= selectedRangeHigh && i <= this.pageCount - 1;
						i++
					) {
						setPageItem(i)
					}
					// Check if there is breakView in the left of selected range
					if (selectedRangeLow > this.marginPages) {
						setBreakView(selectedRangeLow - 1)
					}
					// Check if there is breakView in the right of selected range
					if (selectedRangeHigh + 1 < this.pageCount - this.marginPages) {
						setBreakView(selectedRangeHigh + 1)
					}
					// 3rd - loop thru high end of margin pages
					for (
						let i = this.pageCount - 1;
						i >= this.pageCount - this.marginPages;
						i--
					) {
						setPageItem(i)
					}
				}
				return items
			}
		},
		data() {
			return {
				innerValue: 1
			}
		},
		methods: {
			handlePageSelected(selected) {
				if (this.selected === selected) return
				this.innerValue = selected
				this.$emit('input', selected)
				this.clickHandler(selected)
			},
			prevPage() {
				if (this.selected <= 1) return
				this.handlePageSelected(this.selected - 1)
			},
			nextPage() {
				if (this.selected >= this.pageCount) return
				this.handlePageSelected(this.selected + 1)
			},
			firstPageSelected() {
				return this.selected === 1
			},
			lastPageSelected() {
				return this.selected === this.pageCount || this.pageCount === 0
			},
			selectFirstPage() {
				if (this.selected <= 1) return
				this.handlePageSelected(1)
			},
			selectLastPage() {
				if (this.selected >= this.pageCount) return
				this.handlePageSelected(this.pageCount)
			}
		}
	}
</script >

<style lang = "css" scoped >
	a {
		cursor: pointer;
	}
</style >

<template>
  <div>
    <Header>
      <template v-slot:nav-item-left>
        <li class="nav-item">
          <router-link class="navbar-nav-link" to="/departments">
            <i class="fas fa-chevron-left text-green-700"></i>
            Department Detail
          </router-link>
        </li>
      </template>
    </Header>
    <div class="page-content">
      <Sidebar />
      <div class="content-wrapper">
        <div class="content">
          <div class="row">
            <div class="col-md-4">
              <div class="card card-body">
                <div class="media">
                  <div class="mr-3">
                    <a href="#">
                      <i class="fas fa-bezier-curve fa-3x"></i>
                    </a>
                  </div>
                  <div class="media-body">
                    <span class="text-muted mb-0">Department</span>
                    <h6 class="mb-0 mt-1">
                      <template v-if="loading">
                        <content-placeholders :rounded="true">
                          <content-placeholders-text :lines="1" />
                        </content-placeholders>
                      </template>
                      <template v-else>{{ department.name }}</template>
                    </h6>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-4">
              <div class="card card-body">
                <div class="media">
                  <div class="mr-3">
                    <a href="#">
                      <i class="fas fa-user-tie fa-3x"></i>
                    </a>
                  </div>
                  <div class="media-body">
                    <span class="text-muted mb-0">Manager</span>
                    <h6 class="mb-0 mt-1">
                      <template v-if="loading">
                        <content-placeholders :rounded="true">
                          <content-placeholders-text :lines="1" />
                        </content-placeholders>
                      </template>
                      <template v-else>
                        <template
                          v-if="
                            typeof department.manager !== 'undefined' &&
                              department.manager
                          "
                        >
                          {{
                            department.manager.firstname +
                              ' ' +
                              department.manager.lastname
                          }}
                        </template>
                        <template v-else>-</template>
                      </template>
                    </h6>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <b-tabs content-class="mt-3">
                <b-tab active title="Occupation">
                  <div class="row mb-2">
                    <div class="col-md-10 mb-1">
                      Displaying
                      <span class="font-weight-bold text-green-700">{{
                        occupationPagination.displaying
                      }}</span>
                      of
                      <span class="font-weight-bold text-green-700">{{
                        occupationPagination.totalData
                      }}</span>
                      Result
                    </div>
                    <div class="col-md-2">
                      <b-button
                        block
                        pill
                        v-b-modal="'addOccupation'"
                        variant="info"
                        >Create Occupation
                      </b-button>
                    </div>
                  </div>
                  <b-modal
                    :hide-footer="true"
                    :no-close-on-backdrop="true"
                    :no-close-on-esc="true"
                    header-class="bg-white"
                    id="addOccupation"
                    ref="addOccupation"
                    title="Create Occupation"
                  >
                    <form @submit.prevent="submitForm">
                      <div class="form-group">
                        <label for="wp_code">Occupation Name</label>
                        <input
                          class="form-control"
                          id="wp_code"
                          type="text"
                          v-model="occupation.name"
                        />
                      </div>
                      <button
                        class="btn bg-green-700 btn-block mt-3"
                        type="submit"
                      >
                        Submit
                      </button>
                    </form>
                  </b-modal>
                  <div class="card">
                    <table class="table">
                      <thead>
                        <tr>
                          <th class="text-muted font-weight-bold col-md-10">
                            Name
                          </th>
                          <th class="col-md-2"></th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          :key="occupation.id"
                          v-for="occupation in occupations"
                        >
                          <td scope="row">{{ occupation.name }}</td>
                          <td>
                            <b-button
                              @click="changeModel($event, occupation)"
                              pill
                              size="sm"
                              v-b-modal="'updateOccupation'"
                              variant="info"
                            >
                              <i class="fa fa-eye"></i>
                            </b-button>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </b-tab>
                <b-tab lazy title="Employee">
                  <div class="row mb-2">
                    <div class="col-md-12 mb-1">
                      Displaying
                      <span class="font-weight-bold text-green-700">{{
                        employeePagination.displaying
                      }}</span>
                      of
                      <span class="font-weight-bold text-green-700">{{
                        employeePagination.totalData
                      }}</span>
                      Result
                    </div>
                  </div>
                  <div class="card">
                    <table class="table">
                      <thead>
                        <tr>
                          <th class="text-muted font-weight-bold">
                            Employee ID
                          </th>
                          <th class="text-muted font-weight-bold">Name</th>
                          <th class="text-muted font-weight-bold">Email</th>
                          <th class="text-muted font-weight-bold">Position</th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody>
                        <template v-if="employeeLoading">
                          <tr :key="a" v-for="a in employeeFilter.limit">
                            <td :key="b" v-for="b in 5">
                              <content-placeholders :rounded="true">
                                <content-placeholders-text :lines="1" />
                              </content-placeholders>
                            </td>
                          </tr>
                        </template>
                        <template v-else>
                          <template v-if="employees.length == 0">
                            <tr>
                              <td colspan="5">No Data</td>
                            </tr>
                          </template>
                          <tr :key="employee.id" v-for="employee in employees">
                            <td scope="row">{{ employee.employee_no }}</td>
                            <td>
                              {{ employee.firstname + ' ' + employee.lastname }}
                            </td>
                            <td>{{ employee.email }}</td>
                            <td>
                              <template
                                v-if="
                                  typeof employee.occupation !== 'undefined' &&
                                    employee.occupation
                                "
                                >{{ employee.occupation.name }}
                              </template>
                              <template v-else>-</template>
                            </td>

                            <td>
                              <div class="list-icons">
                                <div class="list-icons-item dropdown">
                                  <a
                                    class="list-icons-item dropdown-toggle caret-0"
                                    data-toggle="dropdown"
                                    href="javascript:void(0)"
                                  >
                                    <i
                                      class="fas fa-ellipsis-v text-green-700 fa-2x"
                                    ></i>
                                  </a>
                                  <div
                                    class="dropdown-menu dropdown-menu-right"
                                  >
                                    <router-link
                                      :to="`/employees/${employee.id}`"
                                      class="dropdown-item"
                                    >
                                      <i class="icon-file-stats"></i>
                                      See Details
                                    </router-link>
                                  </div>
                                </div>
                              </div>
                            </td>
                          </tr>
                        </template>
                      </tbody>
                    </table>
                  </div>

                  <paginate
                    :click-handler="employeeChangePage"
                    :container-class="
                      'pagination pagination-separated pagination-rounded align-self-center'
                    "
                    :margin-pages="5"
                    :next-class="'page-item'"
                    :next-link-class="'page-link'"
                    :next-text="'Next'"
                    :page-class="'page-item'"
                    :page-count="employeePagination.totalPage"
                    :page-link-class="'page-link'"
                    :page-range="5"
                    :prev-class="'page-item'"
                    :prev-link-class="'page-link'"
                    :prev-text="'Prev'"
                  ></paginate>
                </b-tab>
              </b-tabs>
            </div>
          </div>
        </div>
      </div>
    </div>

    <b-modal
      :hide-footer="true"
      :no-close-on-backdrop="true"
      :no-close-on-esc="true"
      header-class="bg-white"
      id="updateOccupation"
      ref="updateOccupation"
      title="Update Occupation"
    >
      <form @submit.prevent="submitUpdateForm">
        <div class="form-group">
          <label for="name">Occupation Name</label>
          <input
            class="form-control"
            id="name"
            type="text"
            v-model="occupationModel.name"
          />
        </div>
        <button class="btn bg-green-700 btn-block mt-3" type="submit">
          Submit
        </button>
      </form>
    </b-modal>
  </div>
</template>
<script>
import EmployeeApi from '../apis/Employee'
import DepartmentApi from '../apis/Department'
import OccupationApi from '../apis/Occupation'
import Hrms from '../services/hrms'
import Paginate from '../components/Paginate'

export default {
  data() {
    return {
      loading: true,
      employeeLoading: true,
      department: {},
      employees: [],
      employeePagination: {
        totalPage: 0,
        currentPage: 1,
        totalData: 0,
        displaying: 0
      },
      occupations: [],
      occupationPagination: {
        totalPage: 0,
        currentPage: 1,
        totalData: 0,
        displaying: 0
      },
      employeeFilter: {
        employee_no: null,
        fullname: null,
        email: null,
        cabang: null,
        department: null,
        position: null,
        limit: 12
      },
      occupation: {
        name: '',
        department_id: this.$route.params.id
      },
      occupationModel: {
        id: 0,
        name: ''
      }
    }
  },
  components: {
    Paginate,
    Header: () =>
      import(
        /* webpackChunkName: "component-header" */
        /* webpackPrefetch: true */
        /* webpackMode: "eager" */
        '../components/Header'
      ),
    Sidebar: () =>
      import(
        /* webpackChunkName: "component-sidebar" */
        /* webpackPrefetch: true */

        /* webpackMode: "eager" */
        '../components/Sidebar'
      )
  },
  methods: {
    changeModel(e, o) {
      this.occupationModel = {
        id: o.id,
        name: o.name
      }
    },
    submitForm(e) {
      Hrms.interceptors.response.use(
        response => response,
        error => {
          if (typeof error.response === 'undefined') {
            this.$swal(
              'Ooops!',
              'A network error occurred. ' +
                'This could be a CORS issue or a dropped internet connection. ' +
                'It is not possible for us to know.  [CE-OCCUPATIONSTORE-001]',
              'error'
            )
          }
          return Promise.reject(error)
        }
      )
      let payload = this.occupation
      OccupationApi.store(payload)
        .then(result => {
          this.OccupationSearch({ department_id: this.$route.params.id })
          this.$refs['addOccupation'].hide()
        })
        .catch(err => {
          console.log(err)
          this.$swal(
            'Ooops!',
            'A network error occurred. ' +
              'This could be a CORS issue or a dropped internet connection. ' +
              'It is not possible for us to know.  [CE-OCCUPATIONSTORE-002]',
            'error'
          )
        })
    },
    submitUpdateForm(e) {
      Hrms.interceptors.response.use(
        response => response,
        error => {
          if (typeof error.response === 'undefined') {
            this.$swal(
              'Ooops!',
              'A network error occurred. ' +
                'This could be a CORS issue or a dropped internet connection. ' +
                'It is not possible for us to know.  [CE-OCCUPATIONUPDATE-001]',
              'error'
            )
          }
          return Promise.reject(error)
        }
      )
      let payload = this.occupationModel
      OccupationApi.update(this.occupationModel.id, payload)
        .then(result => {
          this.OccupationSearch({ department_id: this.$route.params.id })
          this.$refs['updateOccupation'].hide()
        })
        .catch(err => {
          console.log(err)
          this.$swal(
            'Ooops!',
            'A network error occurred. ' +
              'This could be a CORS issue or a dropped internet connection. ' +
              'It is not possible for us to know.  [CE-OCCUPATIONUPDATE-002]',
            'error'
          )
        })
    },
    employeeChangePage(e) {
      this.employeeLoading = true
      const search = {}
      if (
        typeof this.employeeFilter.employee_no !== 'undefined' &&
        this.employeeFilter.employee_no
      ) {
        search.employee_no = this.employeeFilter.employee_no
      }
      if (
        typeof this.employeeFilter.fullname !== 'undefined' &&
        this.employeeFilter.fullname
      ) {
        search.fullname = this.employeeFilter.fullname
      }
      if (
        typeof this.employeeFilter.email !== 'undefined' &&
        this.employeeFilter.email
      ) {
        search.email = this.employeeFilter.email
      }
      if (
        typeof this.employeeFilter.cabang !== 'undefined' &&
        this.employeeFilter.cabang
      ) {
        search.branch_id = this.employeeFilter.cabang
      }
      if (
        typeof this.employeeFilter.position !== 'undefined' &&
        this.employeeFilter.position
      ) {
        search.occupation_id = this.employeeFilter.position
      }
      search.department_id = this.$route.params.id
      search.expand = 'department,occupation,branch,user'
      search.page = e
      search.limit = this.employeeFilter.limit
      this.EmployeeSearch(search)
    },
    EmployeeSearch(query) {
      Hrms.interceptors.response.use(
        response => response,
        error => {
          if (typeof error.response === 'undefined') {
            this.$swal(
              'Ooops!',
              'A network error occurred. ' +
                'This could be a CORS issue or a dropped internet connection. ' +
                'It is not possible for us to know.  [CE-EMPLOYEE-001]',
              'error'
            )
          }
          return Promise.reject(error)
        }
      )
      EmployeeApi.search(query)
        .then(result => {
          console.log(result)
          this.employees = result.data.data

          this.employeePagination.totalPage = parseInt(
            result.headers['x-pagination-page-count']
          )
          this.employeePagination.currentPage = parseInt(
            result.headers['x-pagination-current-page']
          )
          this.employeePagination.totalData = parseInt(
            result.headers['x-pagination-total-count']
          )
          let perPage = parseInt(result.headers['x-pagination-per-page'])
          let display = perPage * this.employeePagination.currentPage
          if (display >= this.employeePagination.totalData) {
            this.employeePagination.displaying = this.employeePagination.totalData
          } else {
            this.employeePagination.displaying = display
          }
          this.employeeLoading = false
        })
        .catch(err => {
          console.log(err)
          this.$swal(
            'Ooops!',
            'A network error occurred. ' +
              'This could be a CORS issue or a dropped internet connection. ' +
              'It is not possible for us to know.  [CE-EMPLOYEE-002]',
            'error'
          )
        })
    },
    DepartmentView(query) {
      Hrms.interceptors.response.use(
        response => response,
        error => {
          if (typeof error.response === 'undefined') {
            this.$swal(
              'Ooops!',
              'A network error occurred. ' +
                'This could be a CORS issue or a dropped internet connection. ' +
                'It is not possible for us to know.  [CE-DEPARTMENT-001]',
              'error'
            )
          }
          return Promise.reject(error)
        }
      )
      DepartmentApi.view(query)
        .then(result => {
          console.log(result)
          this.department = result.data.data
          this.loading = false
        })
        .catch(err => {
          console.log(err)
          this.$swal(
            'Ooops!',
            'A network error occurred. ' +
              'This could be a CORS issue or a dropped internet connection. ' +
              'It is not possible for us to know.  [CE-DEPARTMENT-002]',
            'error'
          )
        })
    },
    OccupationSearch(query) {
      Hrms.interceptors.response.use(
        response => response,
        error => {
          if (typeof error.response === 'undefined') {
            this.$swal(
              'Ooops!',
              'A network error occurred. ' +
                'This could be a CORS issue or a dropped internet connection. ' +
                'It is not possible for us to know.  [CE-OCCUPATION-001]',
              'error'
            )
          }
          return Promise.reject(error)
        }
      )
      OccupationApi.search(query)
        .then(result => {
          console.log(result)
          this.occupations = result.data.data
          this.occupationPagination.totalPage = parseInt(
            result.headers['x-pagination-page-count']
          )
          this.occupationPagination.currentPage = parseInt(
            result.headers['x-pagination-current-page']
          )
          this.occupationPagination.totalData = parseInt(
            result.headers['x-pagination-total-count']
          )
          let perPage = parseInt(result.headers['x-pagination-per-page'])
          let display = perPage * this.occupationPagination.currentPage
          if (display >= this.occupationPagination.totalData) {
            this.occupationPagination.displaying = this.occupationPagination.totalData
          } else {
            this.occupationPagination.displaying = display
          }
        })
        .catch(err => {
          console.log(err)
          this.$swal(
            'Ooops!',
            'A network error occurred. ' +
              'This could be a CORS issue or a dropped internet connection. ' +
              'It is not possible for us to know.  [CE-OCCUPATION-002]',
            'error'
          )
        })
    }
  },
  mounted() {
    document.title = 'Department Detail'
    this.DepartmentView(this.$route.params.id)
    this.EmployeeSearch({
      department_id: this.$route.params.id,
      expand: 'department,occupation,branch,user',
      limit: this.employeeFilter.limit
    }),
      this.OccupationSearch({ department_id: this.$route.params.id })
  }
}
</script>

import Vue from 'vue'
import App from './App.vue'
import router from './routes'
import VueSwal from 'vue-swal'
import {
    BootstrapVue,
    IconsPlugin
} from 'bootstrap-vue'
import VueContentPlaceholders from 'vue-content-placeholders'
import moment from 'moment'
import Vue2Filters from 'vue2-filters'
Vue.use(Vue2Filters)
Vue.config.productionTip = false
import PrettyCheckbox from 'pretty-checkbox-vue'
Vue.use(PrettyCheckbox)
Vue.filter('formatDate', function(value) {
    if (value) {
        return moment(String(value)).format('DD MMMM YYYY')
    }
})
Vue.filter('str_limit', function(value, size) {
    if (!value) return ''
    value = value.toString()

    if (value.length <= size) {
        return value
    }
    return value.substr(0, size) + '...'
})

Vue.filter('formatGetTimeFromDate', function(value) {
    if (value) {
        return moment(String(value)).format('HH:mm')
    }
})
Vue.filter('stripTag', function(string) {
    return string.replace(/<\/?[^>]+>/gi, ' ')
})
Vue.filter('database', function(value) {
    if (value) {
        return moment(String(value)).format('YYYY-MM-DD')
    }
})
Vue.component('breadcrumb-component', require('./components/Breadcrumb').default);

Vue.use(VueContentPlaceholders)
    // Install BootstrapVue
Vue.use(BootstrapVue)
    // Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin)
import _ from 'lodash'
Vue.prototype._ = _
require('bootstrap')

Vue.use(VueSwal)
new Vue({
    router,
    data() {
        return {
            mobile: window.innerWidth <= 700
        }
    },
    created() {
        addEventListener('resize', () => {
            this.mobile = innerWidth <= 700
            console.log(this.mobile + ':' + innerWidth)
        })
        console.log(window.innerWidth)
    },
    // store,
    el: '#app',
    render: h => h(App)
})
<template>
    <div>
        <Header>
            <template v-slot:nav-item-left>
                <li class="nav-item">
                    <router-link
                            :class="{
              active: $route.name === 'employee-request-task-list-view'
            }"
                            :to="`/employees/${$route.params.id}/request-tasks`"
                            class="navbar-nav-link"
                    >
                        <i class="fas fa-chevron-left text-green-700"></i>
                        My Request
                    </router-link>
                </li>
                <li class="nav-item">
                    <router-link
                            :class="{ active: $route.name === 'employee-task-list-view' }"
                            :to="`/employees/${$route.params.id}/tasks`"
                            class="navbar-nav-link"
                    >My Task
                    </router-link>
                </li>
                <li class="nav-item">
                    <router-link
                            :class="{
              active: $route.name === 'employee-approval-task-list-view'
            }"
                            :to="`/employees/${$route.params.id}/approval-tasks`"
                            class="navbar-nav-link"
                    >My Approval
                    </router-link>
                </li>
                <li class="nav-item">
                    <router-link
                            :class="{
              active: $route.name === 'employee-history-task-list-view'
            }"
                            :to="`/employees/${$route.params.id}/history-tasks`"
                            class="navbar-nav-link"
                    >History
                    </router-link>
                </li>
            </template>
        </Header>
        <div class="page-content">
            <Sidebar/>
            <div class="content-wrapper">
                <div class="content">
                    <div class="row">
                        <div class="col-md-12">
                            <task-table :route="$route" :type="'employee-task-list-view'"/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import PermissionMixin from '../mixins/PermissionMixin'
    import router from '../routes/index'

    export default {
        mixins: [PermissionMixin],
        data() {
            return {
                localEmployee: JSON.parse(localStorage.getItem('hr_employee'))
            }
        },
        components: {
            TaskTable: () =>
                import(
                    /* webpackChunkName: "component-task-table" */
                    /* webpackPrefetch: true */
                    /* webpackMode: "eager" */
                    '../components/TableTask'
                    ),
            Header: () =>
                import(
                    /* webpackChunkName: "component-header" */
                    /* webpackPrefetch: true */
                    /* webpackMode: "eager" */
                    '../components/Header'
                    ),
            Sidebar: () =>
                import(
                    /* webpackChunkName: "component-sidebar" */
                    /* webpackPrefetch: true */

                    /* webpackMode: "eager" */
                    '../components/Sidebar'
                    )
        },
        methods: {},
        mounted() {
            document.title = 'Task List My Task'
            if (this.localEmployee.id != this.$route.params.id) {
                if (
                    !this.isHavePermission('GET:employee.default.can-see-other-profile')
                ) {
                    router.push('/forbiden')
                }
            }
        }
    }
</script>

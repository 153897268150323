<template>
  <div>
    <Header>
      <template v-slot:nav-item-left>
        <li class="nav-item">
          <router-link class="navbar-nav-link" to="/referral-bank-account"
            >Referral Bank Account Request</router-link
          >
        </li>
        <li class="nav-item">
          <router-link class="navbar-nav-link" to="/sales-bank-account"
            >Employee Bank Account Request</router-link
          >
        </li>
      </template>
    </Header>
    <div class="page-content">
      <Sidebar />
      <div class="content-wrapper">
        <div class="content">
          <breadcrumb-component
            :links="[{ name: 'Referral Bank Account Request' }]"
          ></breadcrumb-component>

          <div class="row">
            <div class="col-md-12">
              <div class="card card-body">
                <div class="row">
                  <div class="col-md-2">
                    <div class="form-group">
                      <label for="referral_first_name">Referral Name</label>
                      <input
                        class="form-control"
                        id="referral_first_name"
                        name="referral_first_name"
                        placeholder="Referral Name"
                        type="text"
                        v-model="filter.referral_first_name"
                      />
                    </div>
                  </div>
                  <div class="col-md-2">
                    <div class="form-group">
                      <label for="referral_email">Referral Email</label>
                      <input
                        class="form-control"
                        id="referral_email"
                        name="referral_email"
                        placeholder="Referral Email"
                        type="text"
                        v-model="filter.referral_email"
                      />
                    </div>
                  </div>
                  <div class="col-md-2">
                    <div class="form-group">
                      <label for="status_id">Status</label>
                      <model-select
                        :options="status"
                        id="status_id"
                        placeholder="select item"
                        v-model="filter.status_id"
                      ></model-select>
                    </div>
                  </div>
                </div>
                <div class="row justify-content-end">
                  <div class="col-md-2">
                    <button
                      @click="applyFilter"
                      class="btn bg-green-700 btn-block rounded-round btn-labeled btn-labeled-left"
                      type="button"
                    >
                      <b>
                        <i class="icon-check"></i>
                      </b>
                      Apply
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-12">
              <div class="card">
                <div class="table-responsive">
                  <table class="table table-striped">
                    <thead>
                      <tr>
                        <th>
                          <a
                            href="Javascript:void(0);"
                            @click="sortFilter('created_date')"
                          >
                            Request Time
                          </a>
                        </th>
                        <th>Referral Email</th>
                        <th>Referral Name</th>
                        <th>Referral NIK</th>
                        <th>Branch</th>
                        <th>Referral Type</th>
                        <th>Exisiting Bank Account</th>
                        <th>New Bank Account</th>
                        <th>Status</th>
                        <th>is FM ?</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      <template v-if="loading">
                        <tr v-for="a in filter.limit" :key="a">
                          <td v-for="b in 10" :key="b">
                            <content-placeholders :rounded="true">
                              <content-placeholders-text :lines="1" />
                            </content-placeholders>
                          </td>
                        </tr>
                      </template>
                      <template v-else>
                        <tr v-for="data in datas" :key="data.id">
                          <td>
                            {{ data.created_date }}
                          </td>
                          <td>
                            {{ data.referral_email }}
                          </td>
                          <td>
                            {{ data.referral_first_name }}
                          </td>
                          <td>
                            {{ data.referral_nik }}
                          </td>
                          <td>
                            {{ data.branch_name }}
                          </td>
                          <td>
                            {{ data.referral_type }}
                          </td>
                          <td>
                            {{
                              data.current_bank_name &&
                              data.current_bank_name.length > 1
                                ? data.current_bank_name
                                : '-'
                            }},
                            {{
                              data.current_bank_account_no &&
                              data.current_bank_account_no.length > 1
                                ? data.current_bank_account_no
                                : '-'
                            }},
                            {{
                              data.current_bank_account_name &&
                              data.current_bank_account_name.length > 1
                                ? data.current_bank_account_name
                                : '-'
                            }},
                            {{
                              data.current_bank_branch &&
                              data.current_bank_branch.length > 1
                                ? data.current_bank_branch
                                : '-'
                            }}
                          </td>
                          <td>
                            {{
                              data.new_bank_name &&
                              data.new_bank_name.length > 1
                                ? data.new_bank_name
                                : '-'
                            }},
                            {{
                              data.new_bank_account_no &&
                              data.new_bank_account_no.length > 1
                                ? data.new_bank_account_no
                                : '-'
                            }},
                            {{
                              data.new_bank_account_name &&
                              data.new_bank_account_name.length > 1
                                ? data.new_bank_account_name
                                : '-'
                            }},
                            {{
                              data.new_bank_branch &&
                              data.new_bank_branch.length > 1
                                ? data.new_bank_branch
                                : '-'
                            }}
                          </td>
                          <td>
                          <template v-if="data.status_id === 1"
                            ><span class="text-info">Pending</span></template
                          >
                          <template v-if="data.status_id === 2"
                            ><span class="text-success">Approved</span></template
                          >
                          <template v-if="data.status_id === 3"
                            ><span class="text-danger">Rejected</span></template
                          >
                          </td>
                          <td>
                            <template v-if="data.fm_firstname">
                                <i class="fa fa-check-circle text-success"></i>
                              </template>
                            <template v-else>
                              <i class="fa fa-times-circle text-danger"></i>
                            </template>
                          </td>
                          <td>
                            <div class="list-icons">
                              <div class="list-icons-item dropdown">
                                <a
                                  class="list-icons-item dropdown-toggle caret-0"
                                  data-toggle="dropdown"
                                  href="javascript:void(0)"
                                >
                                  <i
                                    class="fas fa-ellipsis-v text-green-700 fa-2x"
                                  ></i>
                                </a>
                                <div class="dropdown-menu dropdown-menu-right">
                                  <a
                                    class="dropdown-item text-success"
                                    href="javascript:void(0)"
                                    @click="approveAction(data)"
                                  >
                                    <i class="fa fa-check-circle"></i>
                                    Approve
                                  </a>
                                <a
                                  class="dropdown-item text-danger"
                                  href="javascript:void(0)"
                                  @click="rejectAction(data)"
                                >
                                  <i class="fa fa-times-circle"></i>
                                  Reject
                                </a>
                                </div>
                              </div>
                            </div>
                          </td>
                        </tr>
                      </template>
                    </tbody>
                  </table>
                </div>
              </div>

              <paginate
                :click-handler="clickCallback"
                :container-class="
                  'pagination pagination-separated pagination-rounded align-self-center'
                "
                :margin-pages="5"
                :next-class="'page-item'"
                :next-link-class="'page-link'"
                :next-text="'Next'"
                :page-class="'page-item'"
                :page-count="pagination.totalPage"
                :page-link-class="'page-link'"
                :page-range="5"
                :prev-class="'page-item'"
                :prev-link-class="'page-link'"
                :prev-text="'Prev'"
                v-if="!$root.mobile"
              ></paginate>
            </div>
          </div>
        </div>
      </div>
    </div>
    
    <b-modal
      :hide-footer="true"
      :no-close-on-backdrop="true"
      :no-close-on-esc="true"
      header-class="bg-white"
      id="EmployeeReject"
      ref="EmployeeReject"
      title="Reject"
    >
      <form @submit.prevent="submitFormReject">
        <div class="form-group">
          <label>Reason</label>
          <textarea name="reject_none" class="form-control" id="reject_none" cols="30" rows="10" v-model="rejectModel.note"></textarea>
        </div>
        <button class="btn bg-green-700 btn-block mt-3" type="submit">
          Submit
        </button>
      </form>
    </b-modal>
  </div>
</template>
<script>
import ReferralBankAccountApi from '../apis/ReferralBankAccount'
import BackofficeReferralApi from '../apis/BackofficeReferral'
import Paginate from '../components/Paginate'
import { ModelSelect } from 'vue-search-select'

export default {
  data() {
    return {
      status: [
        {
          value: 1,
          text: 'Pending'
        },
        {
          value: 2,
          text: 'Approved'
        },
        {
          value: 3,
          text: 'Rejected'
        }
      ],
      loading: true,
      datas: [],
      pagination: {
        totalPage: 0,
        currentPage: 1,
        totalData: 0,
        displaying: 0
      },
      filter: {
        limit: 10,
        user_type: 'client',
        referral_first_name: '',
        referral_email: '',
        sort: 'id',
        page: 1,
        status_id: 1
      },
      sort_filter: {
        sort: ''
      },
      rejectModel:{
        note:'',
        id:'',
        status_id:''
      },
      modelData:{}
    }
  },
  components: {
    Header: () =>
      import(
        /* webpackChunkName: "component-header" */
        /* webpackPrefetch: true */
        /* webpackMode: "eager" */
        '../components/Header'
      ),
    Sidebar: () =>
      import(
        /* webpackChunkName: "component-sidebar" */
        /* webpackPrefetch: true */

        /* webpackMode: "eager" */
        '../components/Sidebar'
      ),
    Paginate,
    ModelSelect
  },
  methods: {
    applyFilter() {
      this.loading = true
      this.referralBankAccountList(this.filter)
    },
    sortFilter(column) {
      console.log(this.sort_filter.sort)
      if (this.sort_filter.sort == '') {
        this.sort_filter.sort = '-'
      } else {
        this.sort_filter.sort = ''
      }
      this.filter.sort = this.sort_filter.sort + column
      this.loading = true
      this.referralBankAccountList(this.filter)
    },
    clickCallback(e) {
      this.loading = true
      const search = {}
      search.page = e
      search.user_type = this.filter.user_type
      this.referralBankAccountList(search)
    },
    referralBankAccountList(query) {
      console.log(this.loading)
      ReferralBankAccountApi.search(query)
        .then(res => {
          this.datas = res.data.data
          this.pagination.totalPage = Math.round(
            parseInt(res.data.totalCount) / parseInt(res.data.size)
          )
          this.pagination.currentPage = parseInt(res.data.page)
          this.pagination.totalData = parseInt(res.data.totalCount)
          this.pagination.displaying = parseInt(res.data.totalCount)
          this.loading = false
          console.log(this.loading)
          console.log(res)
        })
        .catch(error => {
          console.log(error)
        })
    },
    approveAction(data) {
      this.loading = true
      let payload = {
        user_type: data.user_type,
        user_id: data.user_id,
        foreign_model_id: data.foreign_model_id,
        foreign_table_name: data.foreign_table_name,
        process_type: 'approved'
      }
      BackofficeReferralApi.referralBankAccountCommissionProcess(payload)
        .then(res => {
          console.log(res)
          this.referralBankAccountList({
            user_type: 'client'
          })
          this.updateData({
            id: data.id,
            status_id: 2
          })
        })
        .catch(res => {
          console.log(res)
          this.referralBankAccountList({
            user_type: 'client'
          })
          this.updateData({
            id: data.id,
            status_id: 2
          })
        })
    },
    rejectAction(data) {
      this.loading = true
      this.modelData = data
      this.rejectModel.id=data.id
      this.rejectModel.status_id=3
      this.$refs['EmployeeReject'].show()
    },
    submitFormReject(){      
      BackofficeReferralApi.referralBankAccountCommissionProcess({
          user_type: this.modelData.user_type,
          user_id: this.modelData.user_id,
          foreign_model_id: this.modelData.foreign_model_id,
          foreign_table_name: this.modelData.foreign_table_name,
          process_type: 'rejected'
        })
        .then(res => {
          console.log(res)
          this.referralBankAccountList({
            user_type: 'client'
          })
          this.updateData({
            id: this.rejectModel.id,
            status_id: this.rejectModel.status_id,
            note: this.rejectModel.note
          })
        })
        .catch(res => {
          console.log(res)
          this.referralBankAccountList({
            user_type: 'client'
          })
          this.updateData({
            id: data.id,
            status_id: 3
          })
        })
        this.$refs['EmployeeReject'].hide()
    },
    updateData(payload) {
      this.loading = true
      ReferralBankAccountApi.update(payload)
        .then(res => {
          this.referralBankAccountList({
            user_type: 'client'
          })
          console.log(res)
        })
        .catch(res => {
          this.referralBankAccountList({
            user_type: 'client'
          })
          console.log(res)
        })
    }
  },
  mounted() {
    document.title = 'Referral Bank Account'
    this.referralBankAccountList(this.filter)
  }
}
</script>

import axios from '../services/sales'

const resource = '/sales'
export default {
    myAchievementDetail(query) {
        let token = JSON.parse(localStorage.getItem('hr_token')).access_token
        const params = new URLSearchParams(query)
        return axios.get(`${ resource }/my-achievement-detail?${ params }`, {
            headers: {
                'x-access-token': `${ token }`
            }
        })
    }
}
import axios from '../services/hrms'

const resource = '/occupation'
export default {
	search(query) {
		let token = JSON.parse(localStorage.getItem('hr_token')).access_token
		const params = new URLSearchParams(query)
		return axios.get(`${ resource }/search?${ params }`, {
			headers: {
				'x-access-token': `${ token }`
			}
		})
	},
	view(id) {
		let token = JSON.parse(localStorage.getItem('hr_token')).access_token
		return axios.get(`${ resource }/${ id }`, {
			headers: {
				'x-access-token': `${ token }`
			}
		})
	},
	store(payload) {
		let token = JSON.parse(localStorage.getItem('hr_token')).access_token
		return axios.post(`${ resource }/store`, payload, {
			headers: {
				'x-access-token': `${ token }`
			}
		})
	},
	update(id, payload) {
		let token = JSON.parse(localStorage.getItem('hr_token')).access_token
		return axios.post(`${ resource }/${ id }/update`, payload, {
			headers: {
				'x-access-token': `${ token }`
			}
		})
	},
	searchSales() {
		let token = JSON.parse(localStorage.getItem('hr_token')).access_token
		return axios.get(`${ resource }/search-sales`, {
			headers: {
				'x-access-token': `${ token }`
			}
		})
	}
}

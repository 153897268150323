const budgetRequest = [
  {
    path: '/budget-request',
    name: 'budget-request',
    component: () =>
      import(
        /* webpackChunkName: "page-budget-request" */
        /* webpackPrefetch: true */
        /* webpackMode: "eager" */
        '../views/BudgetRequest.vue'
      ),
    meta: {
      requiresAuth: true,
      permission: 'GET:employee.default.index'
    }
  },
  {
    path: '/budget-request-my-approval',
    name: 'budget-request-my-approval',
    component: () =>
      import(
        /* webpackChunkName: "page-budget-request-my-approval" */
        /* webpackPrefetch: true */
        /* webpackMode: "eager" */
        '../views/BudgetRequestMyApproval.vue'
      ),
    meta: {
      requiresAuth: true,
      permission: 'GET:employee.default.index'
    }
  },
  {
    path: '/budget-request-all',
    name: 'budget-request-all',
    component: () =>
      import(
        /* webpackChunkName: "page-budget-request-all" */
        /* webpackPrefetch: true */
        /* webpackMode: "eager" */
        '../views/BudgetRequestAll.vue'
      ),
    meta: {
      requiresAuth: true,
      permission: 'GET:employee.default.index'
    }
  },
  {
    path: '/budget-request-create',
    name: 'budget-request-create',
    component: () =>
      import(
        /* webpackChunkName: "page-budget-request-create" */
        /* webpackPrefetch: true */
        /* webpackMode: "eager" */
        '../views/BudgetRequestCreate.vue'
      ),
    meta: {
      requiresAuth: true,
      permission: 'GET:employee.default.index'
    }
  },
  {
    path: '/budget-request-detail/:id',
    name: 'budget-request-detail',
    component: () =>
      import(
        /* webpackChunkName: "page-budget-request-detail" */
        /* webpackPrefetch: true */
        /* webpackMode: "eager" */
        '../views/BudgetDetail.vue'
      ),
    meta: {
      requiresAuth: true,
      permission: 'GET:employee.default.index'
    }
  },
  {
    path: '/budget-request-update/:id',
    name: 'budget-request-update',
    component: () =>
      import(
        /* webpackChunkName: "page-budget-request-update" */
        /* webpackPrefetch: true */
        /* webpackMode: "eager" */
        '../views/BudgetRequestUpdate.vue'
      ),
    meta: {
      requiresAuth: true,
      permission: 'GET:employee.default.index'
    }
  }
]
export default budgetRequest

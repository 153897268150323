export default{
    data() {
        return{
            json_data:[],json_filename: new Date(Date.now())
            .toLocaleString()
            .slice(0, 3)
            .match(/[0-9]/i)
            ? new Date(Date.now())
                .toLocaleString()
                .split(' ')[0]
                .split(',')[0]
            : new Date(Date.now()).toLocaleString().split(' ')[1] +
              ' ' +
              new Date(Date.now()).toLocaleString().split(' ')[2] +
              ' ' +
              new Date(Date.now()).toLocaleString().split(' ')[3],
            json_fields:{
                'Branch':'branch.name',
                'Target NR':'target.nr',
                'NR':'achiev.total_new_referral',
                'Target AR':'target.ar',
                'AR':'achiev.total_new_referral',
                'Target NC':'target.nc',
                'NC':'achiev.total_nc',
                'Target MA':'target.ma_nc',
                'MA':'achiev.total_ma_nc',
                'Target NMI':'target.nmi',
                'NMI':'achiev.total_net_achievement',
                'Target Lot':'target.lot',
                'Lot':'achiev.total_lot',
                'Target ATA':'target.ata',
                'ATA':'achiev.total_active_trading_account',
                'Score':'score',
            },
            json_meta:[
                [
                  {
                    key: 'charset',
                    value: 'utf-8'
                  }
                ]
              ]
        }
    }
}
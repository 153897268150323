export default {
    firstname: {
        isError: false,
        type: String,
        required: true,
        default: '',
        value: '',
        label: 'Nama Depan'
    },
    lastname: {
        isError: false,
        type: String,
        required: false,
        default: '',
        value: '',
        label: 'Nama Belakang'
    },
    dob: {
        isError: false,
        type: String,
        required: true,
        default: '',
        value: '',
        label: 'Tanggal Lahir'
    },
    pob: {
        isError: false,
        type: String,
        required: true,
        default: '',
        value: '',
        label: 'Kota Kelahiran'
    },
    employee_no: {
        isError: false,
        type: String,
        required: true,
        default: '',
        value: '',
        label: 'NIK'
    },
    user_id: {
        isError: false,
        type: Number,
        required: false,
        default: '',
        value: '',
        label: 'user_id'
    },
    phone: {
        isError: false,
        type: String,
        required: false,
        default: '',
        value: '',
        label: 'Nomor Telp'
    },
    image: {
        isError: false,
        type: String,
        required: false,
        default: '',
        value: '',
        label: 'image'
    },
    hire_date: {
        isError: false,
        type: String,
        required: true,
        default: '',
        value: '',
        label: 'Tanggal Bergabung'
    },
    resign_date: {
        isError: false,
        type: String,
        required: false,
        default: '',
        value: '',
        label: 'resign_date'
    },
    meal_allowance: {
        isError: false,
        type: String,
        required: false,
        default: '',
        value: '',
        label: 'meal_allowance'
    },
    department_id: {
        isError: false,
        type: Number,
        required: true,
        default: '',
        value: '',
        label: 'Divisi'
    },
    occupation_id: {
        isError: false,
        type: Number,
        required: true,
        default: '',
        value: '',
        label: 'Jabatan'
    },
    job_id: {
        isError: false,
        type: Number,
        required: false,
        default: '',
        value: '',
        label: 'job_id'
    },
    type_id: {
        isError: false,
        type: Number,
        required: false,
        default: '',
        value: '',
        label: 'type_id'
    },
    branch_id: {
        isError: false,
        type: Number,
        required: true,
        default: '',
        value: '',
        label: 'Cabang'
    },
    employee_group_id: {
        isError: false,
        type: Number,
        required: false,
        default: '',
        value: '',
        label: 'employee_group_id'
    },
    leave_type_id: {
        isError: false,
        type: Number,
        required: false,
        default: '',
        value: '',
        label: 'leave_type_id'
    },
    bank_id: {
        isError: false,
        type: Number,
        required: true,
        default: '',
        value: '',
        label: 'Bank'
    },
    bank_account_no: {
        isError: false,
        type: String,
        required: true,
        default: '',
        value: '',
        label: 'Nomor Rekening'
    },
    bank_type_id: {
        isError: false,
        type: Number,
        required: false,
        default: '',
        value: '',
        label: 'bank_type_id'
    },
    bank_account_name: {
        isError: false,
        type: String,
        required: true,
        default: '',
        value: '',
        label: 'Nama Pemilik Rekening'
    },
    bpjs_kesehatan: {
        isError: false,
        type: String,
        required: false,
        default: '',
        value: '',
        label: 'Nomor BPJS Kesehatan'
    },
    bpjs_ketenagakerjaan: {
        isError: false,
        type: String,
        required: false,
        default: '',
        value: '',
        label: 'Nomor BPJS Ketenagakerjaan'
    },
    npwp: {
        isError: false,
        type: String,
        required: false,
        default: '',
        value: '',
        label: 'Nomor NPWP'
    },
    gender_id: {
        isError: false,
        type: Number,
        required: true,
        default: '',
        value: '',
        label: 'Jenis Kelamin'
    },
    email: {
        isError: false,
        type: String,
        required: true,
        default: '',
        value: '',
        label: 'Email MIFX'
    },
    identity_type_id: {
        isError: false,
        type: Number,
        required: true,
        default: '',
        value: 1,
        label: 'Tipe Identitas'
    },
    identity_number: {
        isError: false,
        type: String,
        required: true,
        default: '',
        value: '',
        label: 'Nomor Identitas'
    },
    company_name: {
        isError: false,
        type: String,
        required: false,
        default: '',
        value: '',
        label: 'company_name'
    },
    company_field: {
        isError: false,
        type: String,
        required: false,
        default: '',
        value: '',
        label: 'company_field'
    },
    job_desc: {
        isError: false,
        type: String,
        required: false,
        default: '',
        value: '',
        label: 'job_desc'
    },
    bank_branch: {
        isError: false,
        type: String,
        required: true,
        default: '',
        value: '',
        label: 'Cabang Bank'
    },
    mobile_number: {
        isError: false,
        type: String,
        required: true,
        default: '',
        value: '',
        label: 'Nomor HP'
    },
    email_signature: {
        isError: false,
        type: String,
        required: false,
        default: '',
        value: '',
        label: 'email_signature'
    },
    personal_email: {
        isError: false,
        type: String,
        required: true,
        default: '',
        value: '',
        label: 'Email Pribadi'
    },
    upline_id: {
        isError: false,
        type: Number,
        required: true,
        default: '',
        value: '',
        label: 'Atasan Langsung'
    },
    biological_mother_name: {
        isError: false,
        type: String,
        required: false,
        default: '',
        value: '',
        label: 'Nama Gadis Ibu Kandung'
    },
    address: {
        isError: false,
        type: String,
        required: true,
        default: '',
        value: '',
        label: 'Alamat'
    },
    username: {
        isError: false,
        type: String,
        required: true,
        default: '',
        value: '',
        label: 'Nama Pengguna'
    },
    password: {
        isError: false,
        type: String,
        required: true,
        default: '',
        value: '',
        label: 'Password',
        message: 'Kata Sandi tidak sama dan Kata sandi harus mengandung huruf kecil, huruf besar,angka dan minimal 8'
    },
    password_confirmation: {
        isError: false,
        type: String,
        required: true,
        default: '',
        value: '',
        label: 'Password',
        message: 'Kata Sandi tidak sama dan Kata sandi harus mengandung huruf kecil, huruf besar,angka dan minimal 8'
    },
    is_active: {
        isError: false,
        type: Boolean,
        required: false,
        default: '',
        value: true,
        label: 'Active'
    },
    commission_scheme: {
        isError: false,
        type: String,
        required: false,
        default: '',
        value: '',
        label: 'Skema Komisi'
    },
    ktp_image: {
        isError: false,
        type: String,
        required: false,
        default: '',
        value: '/images/placeholder.jpg',
        label: 'Foto KTP'
    },
    npwp_image: {
        isError: false,
        type: String,
        required: false,
        default: '',
        value: '/images/placeholder.jpg',
        label: 'Foto NPWP'
    }
}
<template>
  <div>
    <do-loading
      :active.sync="isLoading"
      :can-cancel="false"
      :is-full-page="true"
    />

    <Header>
      <template v-slot:nav-item-left>
        <li class="nav-item">
          <router-link class="navbar-nav-link" to="/employees">
            <i class="fas fa-chevron-left text-green-700"></i>
            Add New Recruit
          </router-link>
        </li>
      </template>
    </Header>
    <div class="page-content">
      <Sidebar />
      <div class="content-wrapper">
        <div class="content">
          <breadcrumb-component
            :links="[
              { name: 'Employee Management' },
              { name: 'Add New Recruit' }
            ]"
          ></breadcrumb-component>
          <form @submit.prevent="validateForm" action="javascript:void(0)">
            <div class="row">
              <div class="col-md-8">
                <div class="card">
                  <div class="card-header bg-white">
                    <h6 class="card-title mb-0 text-green-700 font-weight-bold">
                      Data Perusahaan
                    </h6>
                  </div>
                  <div class="card-body">
                    <div class="row">
                      <div class="col-md-6">
                        <input-text
                          :is-error="employee.employee_no.isError"
                          :is-required="employee.employee_no.required"
                          :label="employee.employee_no.label"
                          error-message="NIK Tidak Boleh Kosong"
                          id="employee_no"
                          v-model="employee.employee_no.value"
                        />
                      </div>
                      <div class="col-md-6">
                        <div class="form-group">
                          <label for="hire_date">
                            {{ employee.hire_date.label }}
                            <sup
                              class="text-danger"
                              v-if="employee.hire_date.required"
                              >*</sup
                            >
                          </label>
                          <datepicker
                            :class="{
                              'is-invalid': employee.hire_date.isError
                            }"
                            :input-class="'form-control'"
                            id="hire_date"
                            placeholder="Pilih tanggal bergabung"
                            v-model="employee.hire_date.value"
                          />
                          <div
                            class="text-danger d-block mt-1"
                            v-if="employee.hire_date.isError"
                          >
                            {{ employee.hire_date.label }} Tidak Boleh Kosong
                          </div>
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="form-group">
                          <label for="branch_id">
                            {{ employee.branch_id.label }}
                            <sup
                              class="text-danger"
                              v-if="employee.branch_id.required"
                              >*</sup
                            >
                          </label>
                          <model-select
                            :class="{
                              'is-invalid': employee.branch_id.isError
                            }"
                            :is-disabled="branchesIsDisabled"
                            :is-error="employee.branch_id.isError"
                            :options="branches"
                            :placeholder="`Pilih ${employee.branch_id.label}`"
                            v-model="employee.branch_id.value"
                          ></model-select>

                          <div
                            class="text-danger d-block mt-1"
                            v-if="employee.branch_id.isError"
                          >
                            Cabang Tidak Boleh Kosong
                          </div>
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="form-group">
                          <label for="department_id">
                            {{ employee.department_id.label }}
                            <sup
                              class="text-danger"
                              v-if="employee.department_id.required"
                              >*</sup
                            >
                          </label>
                          <model-select
                            :class="{
                              'is-invalid': employee.employee_no.isError
                            }"
                            :is-disabled="departmentsIsDisabled"
                            :is-error="employee.department_id.isError"
                            :options="departments"
                            :placeholder="
                              `Pilih ${employee.department_id.label}`
                            "
                            @input="occupationByDepartment"
                            v-model="employee.department_id.value"
                          />
                          <div
                            class="text-danger d-block mt-1"
                            v-if="employee.department_id.isError"
                          >
                            Divisi Tidak Boleh Kosong
                          </div>
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="form-group">
                          <label for="occupation_id">
                            {{ employee.occupation_id.label }}
                            <sup
                              class="text-danger"
                              v-if="employee.occupation_id.required"
                              >*</sup
                            >
                          </label>
                          <model-select
                            :class="{
                              'is-invalid': employee.employee_no.isError
                            }"
                            :is-disabled="occupationsIsDisabled"
                            :is-error="employee.occupation_id.isError"
                            :options="occupations"
                            :placeholder="
                              `Pilih ${employee.occupation_id.label}`
                            "
                            v-model="employee.occupation_id.value"
                          />
                          <div
                            class="text-danger d-block mt-1"
                            v-if="employee.occupation_id.isError"
                          >
                            Jabatan Tidak Boleh Kosong
                          </div>
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="form-group">
                          <label for="upline_id">
                            {{ employee.upline_id.label }}
                            <sup
                              class="text-danger"
                              v-if="employee.upline_id.required"
                              >*</sup
                            >
                          </label>
                          <model-select
                            :class="{
                              'is-invalid': employee.employee_no.isError
                            }"
                            :is-disabled="employeesIsDisabled"
                            :is-error="employee.upline_id.isError"
                            :options="employees"
                            :placeholder="`Pilih ${employee.upline_id.label}`"
                            @searchchange="selectSearch"
                            v-model="employee.upline_id.value"
                          />
                          <div
                            class="text-danger d-block mt-1"
                            v-if="employee.upline_id.isError"
                          >
                            Atasan Langsung Tidak Boleh Kosong
                          </div>
                        </div>
                      </div>
                      <template v-if="isDepartmentSales">
                        <div class="col-md-6">
                          <div class="form-group">
                            <label for="sales_division">
                              {{ sales.sales_division.label }}
                              <sup
                                class="text-danger"
                                v-if="sales.sales_division.required"
                                >*</sup
                              >
                            </label>
                            <model-select
                              :class="{
                                'is-invalid': sales.sales_division.isError
                              }"
                              :is-error="sales.sales_division.isError"
                              :options="sales_division"
                              :placeholder="
                                `Pilih ${sales.sales_division.label}`
                              "
                              v-model="sales.sales_division.value"
                            ></model-select>
                            <div
                              class="text-danger d-block mt-1"
                              v-if="sales.sales_division.isError"
                            >
                              {{ sales.sales_division.label }} Tidak Boleh
                              Kosong
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group">
                            <label for="alias_code">
                              {{ sales.alias_code.label }}
                              <sup
                                class="text-danger"
                                v-if="sales.alias_code.required"
                                >*</sup
                              >
                            </label>
                            <input
                              :class="{
                                'is-invalid': sales.alias_code.isError
                              }"
                              :placeholder="`${sales.alias_code.label}`"
                              @input="aliasCodeCheck"
                              class="form-control"
                              id="alias_code"
                              type="text"
                              v-mask="
                                'XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX'
                              "
                              v-model="sales.alias_code.value"
                            />
                            <div
                              class="text-danger d-block mt-1"
                              v-if="sales.alias_code.isError"
                            >
                              {{ sales.alias_code.message }}
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group">
                            <label for="commission_scheme">
                              {{ employee.commission_scheme.label }}
                              <sup
                                class="text-danger"
                                v-if="employee.commission_scheme.required"
                                >*</sup
                              >
                            </label>
                            <input
                              :class="{
                                'is-invalid': employee.commission_scheme.isError
                              }"
                              :placeholder="
                                `${employee.commission_scheme.label}`
                              "
                              class="form-control"
                              id="commission_scheme"
                              type="text"
                              v-model="employee.commission_scheme.value"
                            />
                            <div
                              class="text-danger d-block mt-1"
                              v-if="employee.commission_scheme.isError"
                            >
                              {{ employee.commission_scheme.label }} Tidak Boleh
                              Kosong
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group">
                            <label for="sales_code">
                              {{ sales.sales_code.label }}
                              <sup
                                class="text-danger"
                                v-if="sales.sales_code.required"
                                >*</sup
                              >
                            </label>
                            <input
                              :class="{
                                'is-invalid': sales.sales_code.isError
                              }"
                              :placeholder="`${sales.sales_code.label}`"
                              @input="salesCodeCheck"
                              class="form-control"
                              id="sales_code"
                              type="text"
                              v-mask="
                                'XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX'
                              "
                              v-model="sales.sales_code.value"
                            />
                            <div
                              class="text-danger d-block mt-1"
                              v-if="sales.sales_code.isError"
                            >
                              {{ sales.sales_code.message }}
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group">
                            <label for="wp_code">
                              {{ sales.wp_code.label }}
                              <sup
                                class="text-danger"
                                v-if="sales.wp_code.required"
                                >*</sup
                              >
                            </label>
                            <model-select
                              :class="{
                                'is-invalid': sales.wp_code.isError
                              }"
                              :is-disabled="salesWPIsDisabled"
                              :is-error="sales.wp_code.isError"
                              :options="wakil_pialang"
                              :placeholder="`Pilih ${sales.wp_code.label}`"
                              @searchchange="searchWakilPialang"
                              v-model="sales.wp_code.value"
                            />
                            <div
                              class="text-danger d-block mt-1"
                              v-if="sales.wp_code.isError"
                            >
                              {{ sales.wp_code.label }} Tidak Boleh Kosong
                            </div>
                          </div>
                        </div>
                      </template>
                    </div>
                  </div>
                </div>

                <div class="card">
                  <div class="card-header bg-white">
                    <h6 class="card-title mb-0 text-green-700 font-weight-bold">
                      Access Module
                    </h6>
                  </div>
                  <content-placeholders v-if="moduleLoading">
                    <content-placeholders-heading :img="true" />
                    <content-placeholders-text :lines="3" />
                  </content-placeholders>
                  <template v-else>
                    <template v-for="(access, index) in access_modules">
                      <template
                        v-if="
                          access.access_name != 'Client' &&
                            access.access_name != 'Referral'
                        "
                      >
                        <div :key="access.id" class="card-header bg-white">
                          <p-check
                            @change="addModule($event, index, access)"
                            class="p-round p-fill"
                            color="success"
                            name="check[]"
                            >{{ access.access_name }}
                          </p-check>
                        </div>
                        <div
                          :id="`access_${access.id}`"
                          class="card-body d-none border-bottom"
                          :key="`access_${access.id}`"
                        >
                          <div class="row">
                            <template v-for="group in modules">
                              <template v-if="group.access_id === access.id">
                                <div class="col-md-4 mb-2" :key="group.id">
                                  <p-check
                                    @change="addGroup($event, group, access)"
                                    class="p-curve p-thick"
                                    color="info"
                                    name="check[]"
                                    >{{ group.group_name }}
                                  </p-check>
                                </div>
                              </template>
                            </template>
                          </div>
                        </div>
                      </template>
                    </template>
                  </template>
                  <div class="card-footer bg-white"></div>
                </div>
                <div class="card">
                  <div class="card-header bg-white">
                    <h6 class="card-title mb-0 text-green-700 font-weight-bold">
                      Data Pribadi
                    </h6>
                  </div>
                  <div class="card-body">
                    <div class="row">
                      <div class="col-md-6">
                        <div class="form-group">
                          <label for="firstname">
                            {{ employee.firstname.label }}
                            <sup
                              class="text-danger"
                              v-if="employee.firstname.required"
                              >*</sup
                            >
                          </label>
                          <input
                            :class="{
                              'is-invalid': employee.firstname.isError
                            }"
                            :placeholder="`${employee.firstname.label}`"
                            class="form-control"
                            id="firstname"
                            type="text"
                            v-model="employee.firstname.value"
                          />
                          <div
                            class="text-danger d-block mt-1"
                            v-if="employee.firstname.isError"
                          >
                            {{ employee.firstname.label }} Tidak Boleh Kosong
                          </div>
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="form-group">
                          <label for="lastname">
                            {{ employee.lastname.label }}
                            <sup
                              class="text-danger"
                              v-if="employee.lastname.required"
                              >*</sup
                            >
                          </label>
                          <input
                            :class="{
                              'is-invalid': employee.lastname.isError
                            }"
                            :placeholder="`${employee.lastname.label}`"
                            class="form-control"
                            id="lastname"
                            type="text"
                            v-model="employee.lastname.value"
                          />
                          <div
                            class="text-danger d-block mt-1"
                            v-if="employee.lastname.isError"
                          >
                            {{ employee.lastname.label }} Tidak Boleh Kosong
                          </div>
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="form-group">
                          <label for="gender_id">
                            {{ employee.gender_id.label }}
                            <sup
                              class="text-danger"
                              v-if="employee.gender_id.required"
                              >*</sup
                            >
                          </label>
                          <model-select
                            :class="{
                              'is-invalid': employee.gender_id.isError
                            }"
                            :is-disabled="gendersIsDisabled"
                            :is-error="employee.gender_id.isError"
                            :options="genders"
                            :placeholder="`Pilih ${employee.gender_id.label}`"
                            v-model="employee.gender_id.value"
                          ></model-select>
                          <div
                            class="text-danger d-block mt-1"
                            v-if="employee.gender_id.isError"
                          >
                            {{ employee.gender_id.label }} Tidak Boleh Kosong
                          </div>
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="form-group">
                          <label for="mobile_number">
                            {{ employee.mobile_number.label }}
                            <sup
                              class="text-danger"
                              v-if="employee.mobile_number.required"
                              >*</sup
                            >
                          </label>
                          <input
                            :class="{
                              'is-invalid': employee.mobile_number.isError
                            }"
                            :placeholder="`${employee.mobile_number.label}`"
                            class="form-control"
                            id="mobile_number"
                            type="text"
                            v-model="employee.mobile_number.value"
                          />
                          <div
                            class="text-danger d-block mt-1"
                            v-if="employee.mobile_number.isError"
                          >
                            {{ employee.mobile_number.label }} Tidak Boleh
                            Kosong
                          </div>
                        </div>
                      </div>
                      <div class="col-md-12">
                        <div class="form-group">
                          <label for="address">
                            {{ employee.address.label }}
                            <sup
                              class="text-danger"
                              v-if="employee.address.required"
                              >*</sup
                            >
                          </label>
                          <textarea
                            :class="{
                              'is-invalid': employee.address.isError
                            }"
                            :placeholder="`${employee.address.label}`"
                            class="form-control"
                            id="address"
                            rows="10"
                            v-model="employee.address.value"
                          ></textarea>
                          <div
                            class="text-danger d-block mt-1"
                            v-if="employee.address.isError"
                          >
                            {{ employee.address.label }} Tidak Boleh Kosong
                          </div>
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="form-group">
                          <label for="email">
                            {{ employee.email.label }}
                            <sup
                              class="text-danger"
                              v-if="employee.email.required"
                              >*</sup
                            >
                          </label>
                          <input
                            :class="{
                              'is-invalid': employee.email.isError
                            }"
                            :placeholder="`${employee.email.label}`"
                            class="form-control"
                            id="email"
                            type="email"
                            v-model="employee.email.value"
                          />
                          <div
                            class="text-danger d-block mt-1"
                            v-if="employee.email.isError"
                          >
                            {{ employee.email.label }} Tidak Boleh Kosong
                          </div>
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="form-group">
                          <label for="personal_email">
                            {{ employee.personal_email.label }}
                            <sup
                              class="text-danger"
                              v-if="employee.personal_email.required"
                              >*</sup
                            >
                          </label>
                          <input
                            :class="{
                              'is-invalid': employee.personal_email.isError
                            }"
                            :placeholder="`${employee.personal_email.label}`"
                            class="form-control"
                            id="personal_email"
                            type="personal_email"
                            @input="searchFC"
                            v-model="employee.personal_email.value"
                          />
                          <div
                            class="text-danger d-block mt-1"
                            v-if="employee.personal_email.isError"
                          >
                            {{ employee.personal_email.label }} Tidak Boleh
                            Kosong
                          </div>
                          <div
                            class="text-success d-block mt-1"
                            v-if="is_fc_notif"
                          >
                            <template v-if="is_fc">
                              <p>email sudah terdaftar sebagai FC</p>
                              <p></p>
                            </template>
                            <template v-else
                              >email belum terdaftar sebagai FC</template
                            >
                          </div>
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="form-group">
                          <label for="pob">
                            {{ employee.pob.label }}
                            <sup
                              class="text-danger"
                              v-if="employee.pob.required"
                              >*</sup
                            >
                          </label>
                          <input
                            :class="{
                              'is-invalid': employee.pob.isError
                            }"
                            :placeholder="`${employee.pob.label}`"
                            class="form-control"
                            id="pob"
                            type="pob"
                            v-model="employee.pob.value"
                          />
                          <div
                            class="text-danger d-block mt-1"
                            v-if="employee.pob.isError"
                          >
                            {{ employee.pob.label }} Tidak Boleh Kosong
                          </div>
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="form-group">
                          <label for="dob">
                            {{ employee.dob.label }}
                            <sup
                              class="text-danger"
                              v-if="employee.dob.required"
                              >*</sup
                            >
                          </label>

                          <datepicker
                            :class="{
                              'is-invalid': employee.dob.isError
                            }"
                            :input-class="'form-control'"
                            id="dob"
                            placeholder="Pilih Tanggal lahir"
                            v-model="employee.dob.value"
                          />
                          <div
                            class="text-danger d-block mt-1"
                            v-if="employee.dob.isError"
                          >
                            {{ employee.dob.label }} Tidak Boleh Kosong
                          </div>
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="form-group">
                          <label for="identity_type_id">
                            {{ employee.identity_type_id.label }}
                            <sup
                              class="text-danger"
                              v-if="employee.identity_type_id.required"
                              >*</sup
                            >
                          </label>
                          <model-select
                            :class="{
                              'is-invalid': employee.identity_type_id.isError
                            }"
                            :is-disabled="idTypeIsDisabled"
                            :is-error="employee.identity_type_id.isError"
                            :options="idType"
                            :placeholder="
                              `Pilih ${employee.identity_type_id.label}`
                            "
                            v-model="employee.identity_type_id.value"
                          />
                          <div
                            class="text-danger d-block mt-1"
                            v-if="employee.identity_type_id.isError"
                          >
                            {{ employee.identity_type_id.label }} Tidak Boleh
                            Kosong
                          </div>
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="form-group">
                          <label for="identity_number">
                            {{ employee.identity_number.label }}
                            <sup
                              class="text-danger"
                              v-if="employee.identity_number.required"
                              >*</sup
                            >
                          </label>
                          <input
                            :class="{
                              'is-invalid': employee.identity_number.isError
                            }"
                            :placeholder="`${employee.identity_number.label}`"
                            class="form-control"
                            id="identity_number"
                            type="identity_number"
                            v-model="employee.identity_number.value"
                          />
                          <div
                            class="text-danger d-block mt-1"
                            v-if="employee.identity_number.isError"
                          >
                            {{ employee.identity_number.label }} Tidak Boleh
                            Kosong
                          </div>
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="form-group">
                          <label for="bpjs_kesehatan">
                            {{ employee.bpjs_kesehatan.label }}
                            <sup
                              class="text-danger"
                              v-if="employee.bpjs_kesehatan.required"
                              >*</sup
                            >
                          </label>
                          <input
                            :class="{
                              'is-invalid': employee.bpjs_kesehatan.isError
                            }"
                            :placeholder="`${employee.bpjs_kesehatan.label}`"
                            class="form-control"
                            id="bpjs_kesehatan"
                            type="bpjs_kesehatan"
                            v-model="employee.bpjs_kesehatan.value"
                          />
                          <div
                            class="text-danger d-block mt-1"
                            v-if="employee.bpjs_kesehatan.isError"
                          >
                            {{ employee.bpjs_kesehatan.label }} Tidak Boleh
                            Kosong
                          </div>
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="form-group">
                          <label for="bpjs_ketenagakerjaan">
                            {{ employee.bpjs_ketenagakerjaan.label }}
                            <sup
                              class="text-danger"
                              v-if="employee.bpjs_ketenagakerjaan.required"
                              >*</sup
                            >
                          </label>
                          <input
                            :class="{
                              'is-invalid':
                                employee.bpjs_ketenagakerjaan.isError
                            }"
                            :placeholder="
                              `${employee.bpjs_ketenagakerjaan.label}`
                            "
                            class="form-control"
                            id="bpjs_ketenagakerjaan"
                            type="bpjs_ketenagakerjaan"
                            v-model="employee.bpjs_ketenagakerjaan.value"
                          />
                          <div
                            class="text-danger d-block mt-1"
                            v-if="employee.bpjs_ketenagakerjaan.isError"
                          >
                            {{ employee.bpjs_ketenagakerjaan.label }} Tidak
                            Boleh Kosong
                          </div>
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="form-group">
                          <label for="npwp">
                            {{ employee.npwp.label }}
                            <sup
                              class="text-danger"
                              v-if="employee.npwp.required"
                              >*</sup
                            >
                          </label>
                          <input
                            :class="{
                              'is-invalid': employee.npwp.isError
                            }"
                            :placeholder="`${employee.npwp.label}`"
                            class="form-control"
                            id="npwp"
                            type="npwp"
                            v-mask="'##.###.###.#-###.###'"
                            v-model="employee.npwp.value"
                          />
                          <div
                            class="text-danger d-block mt-1"
                            v-if="employee.npwp.isError"
                          >
                            {{ employee.npwp.label }} Tidak Boleh Kosong
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="card">
                  <div class="card-header bg-white">
                    <h6 class="card-title mb-0 text-green-700 font-weight-bold">
                      Data Bank
                    </h6>
                  </div>
                  <div class="card-body">
                    <div class="row">
                      <div class="col-md-6">
                        <div class="form-group">
                          <label for="bank_id">
                            {{ employee.bank_id.label }}
                            <sup
                              class="text-danger"
                              v-if="employee.bank_id.required"
                              >*</sup
                            >
                          </label>
                          <model-select
                            :class="{
                              'is-invalid': employee.bank_id.isError
                            }"
                            :is-disabled="bankIsDisabled"
                            :is-error="employee.bank_id.isError"
                            :options="bank"
                            :placeholder="`Pilih ${employee.bank_id.label}`"
                            v-model="employee.bank_id.value"
                          ></model-select>
                          <div
                            class="text-danger d-block mt-1"
                            v-if="employee.bank_id.isError"
                          >
                            {{ employee.bank_id.label }} Tidak Boleh Kosong
                          </div>
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="form-group">
                          <label for="bank_account_name">
                            {{ employee.bank_account_name.label }}
                            <sup
                              class="text-danger"
                              v-if="employee.bank_account_name.required"
                              >*</sup
                            >
                          </label>
                          <input
                            :class="{
                              'is-invalid': employee.bank_account_name.isError
                            }"
                            :placeholder="`${employee.bank_account_name.label}`"
                            class="form-control"
                            id="bank_account_name"
                            type="text"
                            v-model="employee.bank_account_name.value"
                          />
                          <div
                            class="text-danger d-block mt-1"
                            v-if="employee.bank_account_name.isError"
                          >
                            {{ employee.bank_account_name.label }} Tidak Boleh
                            Kosong
                          </div>
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="form-group">
                          <label for="bank_account_no">
                            {{ employee.bank_account_no.label }}
                            <sup
                              class="text-danger"
                              v-if="employee.bank_account_no.required"
                              >*</sup
                            >
                          </label>
                          <input
                            :class="{
                              'is-invalid': employee.bank_account_no.isError
                            }"
                            :placeholder="`${employee.bank_account_no.label}`"
                            class="form-control"
                            id="bank_account_no"
                            type="text"
                            v-model="employee.bank_account_no.value"
                          />
                          <div
                            class="text-danger d-block mt-1"
                            v-if="employee.bank_account_no.isError"
                          >
                            {{ employee.bank_account_no.label }} Tidak Boleh
                            Kosong
                          </div>
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="form-group">
                          <label for="bank_branch">
                            {{ employee.bank_branch.label }}
                            <sup
                              class="text-danger"
                              v-if="employee.bank_branch.required"
                              >*</sup
                            >
                          </label>
                          <input
                            :class="{
                              'is-invalid': employee.bank_branch.isError
                            }"
                            :placeholder="`${employee.bank_branch.label}`"
                            class="form-control"
                            id="bank_branch"
                            type="text"
                            v-model="employee.bank_branch.value"
                          />
                          <div
                            class="text-danger d-block mt-1"
                            v-if="employee.bank_branch.isError"
                          >
                            {{ employee.bank_branch.label }} Tidak Boleh Kosong
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="card">
                  <div class="card-header bg-white">
                    <h6 class="card-title mb-0 text-green-700 font-weight-bold">
                      Upload Document
                    </h6>
                  </div>
                  <div class="card-body">
                    <ul class="list-unstyled">
                      <b-media class="mb-3" tag="li">
                        <template v-slot:aside>
                          <img
                            :src="employee.ktp_image.value"
                            class="img-fluid d-block"
                            v-if="employee.ktp_image.value"
                            width="150"
                          />
                          <br />
                          <a
                            @click="clearImage($event, 'ktp_image')"
                            class="btn btn-danger btn-sm"
                            href="javascript:void(0)"
                            >Clear</a
                          >
                        </template>
                        <h4 class="mt-0 mb-0 pt-0 pb-0">
                          {{ employee.ktp_image.label }}
                        </h4>
                        <input
                          @change="onFileChange($event, 'ktp_image')"
                          id="ktp_image"
                          type="file"
                        />
                        <p class="mb-0 mt-2">
                          Ukuran gambar minimum 300x300 pixel, File gambar harus
                          berupa format JPEG, PNG atau GIF. Gambar tidak boleh
                          melebihi 32MB
                        </p>
                      </b-media>

                      <b-media class="mt-3" tag="li">
                        <template v-slot:aside>
                          <img
                            :src="employee.npwp_image.value"
                            class="img-fluid d-block"
                            v-if="employee.npwp_image.value"
                            width="150"
                          />
                          <br />
                          <a
                            @click="clearImage($event, 'npwp_image')"
                            class="btn btn-danger btn-sm"
                            href="javascript:void(0)"
                            >Clear</a
                          >
                        </template>
                        <h4 class="mt-0 mb-0 pt-0 pb-0">
                          {{ employee.npwp_image.label }}
                        </h4>
                        <input
                          @change="onFileChange($event, 'npwp_image')"
                          id="npwp_image"
                          type="file"
                        />
                        <p class="mb-0 mt-2">
                          Ukuran gambar minimum 300x300 pixel, File gambar harus
                          berupa format JPEG, PNG atau GIF. Gambar tidak boleh
                          melebihi 32MB
                        </p>
                      </b-media>
                    </ul>
                  </div>
                </div>

                <div class="card">
                  <div class="card-header bg-white">
                    <h6 class="card-title mb-0 text-green-700 font-weight-bold">
                      Data Login
                    </h6>
                  </div>
                  <div class="card-body">
                    <div class="row">
                      <div class="col-md-6">
                        <div class="form-group">
                          <label for="username">
                            {{ employee.username.label }}
                            <sup
                              class="text-danger"
                              v-if="employee.username.required"
                              >*</sup
                            >
                          </label>
                          <input
                            :class="{
                              'is-invalid': employee.username.isError
                            }"
                            :placeholder="`${employee.username.label}`"
                            class="form-control"
                            id="username"
                            type="text"
                            v-model="employee.username.value"
                          />
                          <div
                            class="text-danger d-block mt-1"
                            v-if="employee.username.isError"
                          >
                            {{ employee.username.label }} Tidak Boleh Kosong
                          </div>
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="form-group">
                          <label for="password">
                            {{ employee.password.label }}
                            <sup
                              class="text-danger"
                              v-if="employee.password.required"
                              >*</sup
                            >
                          </label>
                          <input
                            :class="{
                              'is-invalid': employee.password.isError
                            }"
                            :placeholder="`${employee.password.label}`"
                            @input="validationPassword"
                            class="form-control"
                            id="password"
                            type="password"
                            v-model="employee.password.value"
                          />
                          <div
                            class="text-danger d-block mt-1"
                            v-if="employee.password.isError"
                          >
                            {{ employee.password.message }}
                          </div>
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="form-group">
                          <label for="password_confirmation">
                            {{ employee.password_confirmation.label }}
                            <sup
                              class="text-danger"
                              v-if="employee.password_confirmation.required"
                              >*</sup
                            >
                          </label>
                          <input
                            :class="{
                              'is-invalid':
                                employee.password_confirmation.isError
                            }"
                            :placeholder="
                              `${employee.password_confirmation.label}`
                            "
                            @input="validationPasswordConfirmation"
                            class="form-control"
                            id="password_confirmation"
                            type="password"
                            v-model="employee.password_confirmation.value"
                          />
                          <div
                            class="text-danger d-block mt-1"
                            v-if="employee.password_confirmation.isError"
                          >
                            {{ employee.password_confirmation.message }}
                          </div>
                        </div>
                      </div>
                      <div class="col-md-6">
                        <switches
                          :label="`${employee.is_active.label}`"
                          color="info"
                          theme="bootstrap"
                          v-model="employee.is_active.value"
                        ></switches>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="row justify-content-end">
                  <div class="col-md-4">
                    <button class="btn bg-green-700 btn-block" type="submit">
                      Submit
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>

    <b-modal
      :hide-footer="true"
      :no-close-on-backdrop="true"
      :no-close-on-esc="true"
      header-class="bg-white"
      id="ModalSalesCheck"
      ref="ModalSalesCheck"
      size="xl"
      title="Info"
    >
      <form @submit.prevent="submitFormCheckCreateSales">
        <div class="row">
          <div class="col-md-12">
            <div class="alert alert-warning">
              {{ sales_check_create_message }}
            </div>
          </div>
        </div>
        <div class="row mb-2 mt-2">
          <div class="col-md-12">
            <table class="table table-striped table-bordered">
              <thead>
                <tr>
                  <th>first name</th>
                  <th>last name</th>
                  <th>identity no</th>
                  <th>bank account no</th>
                  <th>date of birth</th>
                  <th>email</th>
                  <th>npwp</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td colspan="7">Employee</td>
                </tr>
                <tr :key="employee.user_id" v-for="employee in employee_data">
                  <td>{{ employee.first_name }}</td>
                  <td>{{ employee.last_name }}</td>
                  <td>{{ employee.identity_no }}</td>
                  <td>{{ employee.bank_account_no }}</td>
                  <td>{{ employee.date_of_birth }}</td>
                  <td>{{ employee.email }}</td>
                  <td>{{ employee.npwp_no }}</td>
                </tr>
                <tr>
                  <td colspan="7">Referral</td>
                </tr>
                <tr :key="referral.user_id" v-for="referral in referral_data">
                  <td>{{ referral.first_name }}</td>
                  <td>{{ referral.last_name }}</td>
                  <td>{{ referral.identity_no }}</td>
                  <td>{{ referral.bank_account_no }}</td>
                  <td>{{ referral.date_of_birth }}</td>
                  <td>{{ referral.email }}</td>
                  <td>{{ referral.npwp_no }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="row justify-content-between">
          <div class="col-md-3">
            <button
              @click="dismisModal"
              class="btn bg-warning-700 btn-block mt-3"
              type="button"
            >
              Cancle
            </button>
          </div>
          <div class="col-md-3">
            <button class="btn bg-green-700 btn-block mt-3" type="submit">
              Submit
            </button>
          </div>
        </div>
      </form>
    </b-modal>
  </div>
</template>
<script>
import BackofficeApi from '../apis/Backoffice'
import EmployeeApi from '../apis/Employee'
import DepartmentApi from '../apis/Department'
import OccupationApi from '../apis/Occupation'
import BackOfficeSales from '../apis/BackofficeSales'
import BackofficeSales from '../apis/BackofficeSales'
import Hrms from '../services/hrms'
import Master from '../services/master'
import SalesService from '../services/sales'
import { ModelListSelect, ModelSelect } from 'vue-search-select'
import BranchApi from '../apis/Branch'
import EnumDetail from '../apis/EnumDetail'
import BankApi from '../apis/Bank'
import Switches from 'vue-switches'
import DoLoading from 'vue-loading-overlay'
import Datepicker from 'vuejs-datepicker'
import { mask } from 'vue-the-mask'
import employeeModel from '../models/employee'
import salesModel from '../models/sales'
import moment from 'moment'
import router from '../routes/index'

export default {
  directives: { mask },
  components: {
    Header: () =>
      import(
        /* webpackChunkName: "component-header" */
        /* webpackPrefetch: true */
        /* webpackMode: "eager" */
        '../components/Header'
      ),
    Sidebar: () =>
      import(
        /* webpackChunkName: "component-sidebar" */
        /* webpackPrefetch: true */

        /* webpackMode: "eager" */
        '../components/Sidebar'
      ),
    ModelSelect,
    ModelListSelect,
    Switches,
    DoLoading,
    Datepicker,
    InputText: () =>
      import(
        /* webpackChunkName: "employee-create-input-text" */

        /* webpackPrefetch: true */

        '../components/input/Text.vue'
      )
  },
  data() {
    return {
      moduleLoading: true,
      isLoading: false,
      employeesIsDisabled: true,
      bankIsDisabled: true,
      branchesIsDisabled: true,
      departmentsIsDisabled: true,
      occupationsIsDisabled: true,
      salesWPIsDisabled: false,
      gendersIsDisabled: true,
      idTypeIsDisabled: true,
      isDepartmentSales: false,
      access_modules: [],
      wakil_pialang: [],
      sales_division: [],
      bank: [],
      employees: [],
      branches: [],
      departments: [],
      occupations: [],
      genders: [],
      accessSelected: [],
      groupSelected: [],
      idType: [],
      modules: [],
      employee: employeeModel,
      accessModule: {
        access: {
          isError: false,
          type: Array,
          required: true,
          default: '',
          value: '',
          label: 'Akses Module'
        }
      },
      sales: salesModel,
      employee_data: [],
      referral_data: [],
      sales_check_create_message: '',
      sales_create_approve: false,
      is_fc_notif: false,
      is_fc: false
    }
  },
  methods: {
    searchFC() {
      console.log(this.employee.personal_email.value)
      let personal_email = this.employee.personal_email.value
      let filter = /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/
      if (filter.test(personal_email)) {
        console.log('email')
        //document.getElementById("personal_email").disabled = true;
        this.clientSearchAjaxByEmailName({
          q: personal_email,
          functional: 'REFERRAL'
        })
      } else {
        console.log('bukan email')
        this.is_fc_notif = false
      }
    },
    clientSearchAjaxByEmailName(query) {
      BackofficeApi.clientSearchAjaxByEmailName(query)
        .then(res => {
          console.log(res)
          if (res.data.data.length > 0) {
            let personal_email = this.employee.personal_email.value
            let data = res.data.data
            data.forEach((v, i) => {
              console.log(v.email)
              console.log(personal_email)
              if (v.email == personal_email) {
                this.is_fc_notif = true
                this.is_fc = true
                console.log('ini reperal')
              }
            })
          } else {
            console.log('ini bukan reperal')
            this.is_fc = false
          }
        })
        .catch(err => {
          console.log(err)
        })
    },
    employeeHired() {
      let payload = {
        nik: this.employee.employee_no.value,
        personal_email: this.employee.personal_email.value,
        bank_id: this.employee.bank_id.value,
        bank_account_no: this.employee.bank_account_no.value,
        bank_account_name: this.employee.bank_account_name.value,
        bank_branch: this.employee.bank_branch.value
      }
      console.log(payload)
      if (this.is_fc) {
        BackofficeApi.employeeHired(payload)
          .then(res => {
            console.log(res)
          })
          .catch(res => {
            console.log(res)
          })
      } else {
        console.log('bukan fc skiped')
      }
    },
    submitFormCheckCreateSales() {
      this.sales_create_approve = true
      this.isLoading = true
      this.validateForm()
      this.$refs['ModalSalesCheck'].hide()
    },
    validationPassword() {
      let lowerCaseLetters = /[a-z]/g
      let upperCaseLetters = /[A-Z]/g
      let numbers = /[0-9]/g
      let panjang = 8
      if (
        this.employee.password.value != null &&
        this.employee.password.value.match(lowerCaseLetters) &&
        this.employee.password.value.match(upperCaseLetters) &&
        this.employee.password.value.match(numbers) &&
        this.employee.password.value.length >= parseInt(panjang)
      ) {
        this.employee.password.isError = false
        this.employee.password.message = ''
      } else {
        this.employee.password.isError = true
        this.employee.password.message =
          'Kata Sandi tidak sama dan Kata sandi harus mengandung huruf kecil, huruf besar,angka dan minimal 8'
      }
    },
    validationPasswordConfirmation() {
      let lowerCaseLetters = /[a-z]/g
      let upperCaseLetters = /[A-Z]/g
      let numbers = /[0-9]/g
      let panjang = 8
      if (
        this.employee.password_confirmation.value != null &&
        this.employee.password_confirmation.value.match(lowerCaseLetters) &&
        this.employee.password_confirmation.value.match(upperCaseLetters) &&
        this.employee.password_confirmation.value.match(numbers) &&
        this.employee.password_confirmation.value.length >= parseInt(panjang)
      ) {
        if (
          this.employee.password_confirmation.value !==
          this.employee.password.value
        ) {
          this.employee.password_confirmation.isError = true
          this.employee.password_confirmation.message = 'Kata Sandi tidak sama'
        } else {
          this.employee.password_confirmation.isError = false
          this.employee.password_confirmation.message = ''
        }
      } else {
        this.employee.password_confirmation.isError = true
        this.employee.password_confirmation.message =
          'Kata Sandi tidak sama dan Kata sandi harus mengandung huruf kecil, huruf besar,angka dan minimal 8'
      }
    },
    dateToDatabaseFormat(value) {
      return moment(String(value)).format('YYYY-MM-DD')
    },
    addModule(e, index, access) {
      let element = document.getElementById('access_' + access.id)
      if (e) {
        this.accessSelected[index] = access.id
        element.classList.toggle('d-none')
      } else {
        element.classList.toggle('d-none')
        this.accessSelected[index] = 0
      }
      console.log(this.accessSelected)
    },
    addGroup(event, group, access) {
      if (event) {
        this.groupSelected[group.id] = group.id
      } else {
        this.groupSelected[group.id] = 0
      }
      console.log(this.groupSelected)
    },
    dismisModal() {
      this.$refs['ModalSalesCheck'].hide()
    },
    validateForm(e) {
      let employeeError = 0
      let salesError = 0
      this.validationPassword()
      this.validationPasswordConfirmation()
      Object.keys(this.employee).forEach((v, i) => {
        if (this.employee[v].required) {
          if (!this.employee[v].value) {
            this.employee[v].isError = true
            employeeError += 1
          } else {
            this.employee[v].isError = false
          }
        }
      })
      if (this.isDepartmentSales) {
        Object.keys(this.sales).forEach((v, i) => {
          if (this.sales[v].required) {
            if (!this.sales[v].value) {
              this.sales[v].isError = true
              salesError += 1
            } else {
              this.sales[v].isError = false
            }
          }
        })
      }
      if (salesError === 0 && employeeError === 0) {
        this.isLoading = true
        let upline = this.employee.upline_id.value
        let upline_employee = upline.split(':')[0]
        let upline_sales = upline.split(':')[1]
        let payload = {
          user: {
            username: this.employee.username.value,
            password: this.employee.password.value,
            active: this.employee.is_active.value,
            password_confirmation: this.employee.password_confirmation.value
          },
          employee: {
            firstname: this.employee.firstname.value,
            lastname: this.employee.lastname.value,
            dob: this.dateToDatabaseFormat(this.employee.dob.value),
            pob: this.employee.pob.value,
            phone: this.employee.phone.value,
            image: this.employee.image.value,
            hire_date: this.dateToDatabaseFormat(this.employee.hire_date.value),
            resign_date: this.employee.resign_date.value,
            meal_allowance: this.employee.meal_allowance.value,
            department_id: this.employee.department_id.value,
            occupation_id: this.employee.occupation_id.value,
            job_id: this.employee.job_id.value,
            type_id: this.employee.type_id.value,
            branch_id: this.employee.branch_id.value,
            employee_group_id: this.employee.employee_group_id.value,
            employee_no: this.employee.employee_no.value,
            leave_type_id: this.employee.leave_type_id.value,
            bank_id: this.employee.bank_id.value,
            bank_account_no: this.employee.bank_account_no.value,
            bank_type_id: this.employee.bank_type_id.value,
            bank_account_name: this.employee.bank_account_name.value,
            bpjs_kesehatan: this.employee.bpjs_kesehatan.value,
            bpjs_ketenagakerjaan: this.employee.bpjs_ketenagakerjaan.value,
            npwp: this.employee.npwp.value,
            created_via: 'HRMS::FRONTEND',
            gender_id: this.employee.gender_id.value,
            email: this.employee.email.value,
            alias_code: this.sales.alias_code.value,
            personal_email: this.employee.personal_email.value,
            upline_id: upline_employee,
            mobile_number: this.employee.mobile_number.value,
            identity_number: this.employee.identity_number.value,
            identity_type_id: this.employee.identity_type_id.value,
            bank_branch: this.employee.bank_branch.value
          }
        }
        if (this.isDepartmentSales) {
          let salesCreateValidatePayload = {
            sales_code: this.sales.sales_code.value,
            wp_id: this.sales.wp_code.value,
            alias_link: this.sales.alias_code.value,
            sales_division: this.sales.sales_division.value,
            first_name: this.employee.firstname.value,
            date_of_birth: this.employee.dob.value,
            last_name: this.employee.lastname.value,
            identity_no: this.employee.identity_number.value,
            npwp_no: this.employee.npwp.value,
            bank_account_no: this.employee.bank_account_no.value
          }

          BackOfficeSales.salesCreateChecking(salesCreateValidatePayload)
            .then(sales_create_checking_response => {
              if (!this.sales_create_approve) {
                console.log(
                  'sales_create_checking_response',
                  sales_create_checking_response
                )
                this.employee_data =
                  sales_create_checking_response.data.data.employee_data
                this.referral_data =
                  sales_create_checking_response.data.data.referral_data
                this.sales_check_create_message =
                  sales_create_checking_response.data.data.message
                this.$refs['ModalSalesCheck'].show()
                this.loading = false
                this.isLoading = false
              } else {
                BackofficeSales.SalesCreateValidate(salesCreateValidatePayload)
                  .then(sres => {
                    console.log(sres)
                    EmployeeApi.store(payload)
                      .then(result => {
                        console.log(result)
                        this.employeeHired()
                        let savedEmployee = result.data.data
                        console.log(savedEmployee)
                        const accessSelected = this.accessSelected.filter(
                          Number
                        )
                        const groupSelected = this.groupSelected.filter(Number)
                        let payload = {
                          user_id: savedEmployee.user_id,
                          access: accessSelected,
                          groups: groupSelected
                        }
                        if (this.isDepartmentSales) {
                          let salesPayload = {
                            id: savedEmployee.user_id,
                            sales_code: this.sales.sales_code.value,
                            wp_id: this.sales.wp_code.value,
                            alias_link: this.sales.alias_code.value,
                            sales_division: this.sales.sales_division.value,
                            upline_id: upline_sales
                          }
                          BackofficeSales.SalesCreate(salesPayload)
                            .then(sres => {
                              console.log(sres)
                            })
                            .catch(serr => {
                              console.log(serr.response)
                              if (serr.response.status === 400) {
                                this.$swal(
                                  serr.response.data.name,
                                  'Error when registering sales ' +
                                    JSON.stringify(serr.response.data.errors),
                                  'error'
                                )
                              }
                            })
                        }
                        BackOfficeSales.InjectAccessGroups(payload)
                          .then(res => {
                            console.log('InjectAccessGroups')
                            console.log(res)
                            this.isLoading = false
                            router.push('/employees')
                          })
                          .catch(error => {
                            console.log(error.response)
                            this.isLoading = false
                          })
                      })
                      .catch(err => {
                        console.log(err)
                        if (typeof err.response !== 'undefined') {
                          if (err.response.status === 409) {
                            this.$swal(
                              err.response.data.name,
                              err.response.data.message,
                              'error'
                            )
                          }
                          if (err.response.status === 500) {
                            this.$swal(
                              err.response.data.name,
                              'Error when registering Employee ' +
                                JSON.stringify(err.response.data.message),
                              'error'
                            )
                          }
                          if (err.response.status === 422) {
                            this.$swal(
                              err.response.data.name,
                              err.response.data.message,
                              'error'
                            )
                          }
                        }
                        this.isLoading = false
                      })
                  })
                  .catch(serr => {
                    console.log(serr.response)
                    this.isLoading = false
                    this.$swal(
                      'Ooops!',
                      serr.response.data.errors.message,
                      'error'
                    )
                    this.loading = false
                  })
              }
            })
            .catch(serr => {
              console.log(serr.response)
              this.isLoading = false
              this.$swal('Ooops!', serr.response.data.errors.message, 'error')
              this.loading = false
            })
        } else {
          EmployeeApi.store(payload)
            .then(result => {
              this.employeeHired()
              console.log(result)
              let savedEmployee = result.data.data
              console.log(savedEmployee)
              const accessSelected = this.accessSelected.filter(Number)
              const groupSelected = this.groupSelected.filter(Number)
              let payload = {
                user_id: savedEmployee.user_id,
                access: accessSelected,
                groups: groupSelected
              }
              if (this.isDepartmentSales) {
                let salesPayload = {
                  id: savedEmployee.user_id,
                  sales_code: this.sales.sales_code.value,
                  wp_id: this.sales.wp_code.value,
                  alias_link: this.sales.alias_code.value,
                  sales_division: this.sales.sales_division.value,
                  upline_id: upline_sales
                }
                BackofficeSales.SalesCreate(salesPayload)
                  .then(sres => {
                    console.log(sres)
                  })
                  .catch(serr => {
                    console.log(serr.response)
                    if (serr.response.status === 400) {
                      this.$swal(
                        serr.response.data.name,
                        'Error when registering sales ' +
                          JSON.stringify(serr.response.data.errors),
                        'error'
                      )
                    }
                  })
              }
              BackOfficeSales.InjectAccessGroups(payload)
                .then(res => {
                  console.log('InjectAccessGroups')
                  console.log(res)
                  this.isLoading = false
                  router.push('/employees')
                })
                .catch(error => {
                  console.log(error.response)
                  this.isLoading = false
                })
            })
            .catch(err => {
              console.log(err)
              if (typeof err.response !== 'undefined') {
                if (err.response.status === 409) {
                  this.$swal(
                    err.response.data.name,
                    err.response.data.message,
                    'error'
                  )
                }
                if (err.response.status === 500) {
                  this.$swal(
                    err.response.data.name,
                    'Error when registering Employee ' +
                      JSON.stringify(err.response.data.message),
                    'error'
                  )
                }
                if (err.response.status === 422) {
                  this.$swal(
                    err.response.data.name,
                    err.response.data.message,
                    'error'
                  )
                }
              }
              this.isLoading = false
            })
        }
      } else {
        this.$swal('Ooops!', 'Silakan isi inputasi yang wajib', 'error')
      }
    },
    SalesCreateAttributes() {
      SalesService.interceptors.response.use(
        response => response,
        error => {
          if (typeof error.response === 'undefined') {
            this.$swal(
              'Ooops!',
              'A network error occurred. ' +
                'This could be a CORS issue or a dropped internet connection. ' +
                'It is not possible for us to know.',
              'error'
            )
          }
          return Promise.reject(error)
        }
      )
      BackOfficeSales.SalesCreateAttributes()
        .then(result => {
          this.access_modules = result.data.data.access.list
          this.sales_division = result.data.data.sales_division.list.map(x => {
            return {
              value: x.rule_description,
              text: x.rule_description
            }
          })
          const that = this
          Object.values(this.access_modules).forEach((value, index) => {
            BackOfficeSales.GroupsListByAccess(this.access_modules[index].id)
              .then(res => {
                this.access_modules[index].groups = res.data.data
                this.modules = [...res.data.data, ...this.modules]
                console.log(this.access_modules)
                console.log(this.modules)
              })
              .catch(error => {})
          })
          console.log(this.modules)
          this.moduleLoading = false
        })
        .catch(err => {
          console.log(err.response)
        })
    },
    searchWakilPialang(e) {
      SalesService.interceptors.response.use(
        response => response,
        error => {
          if (typeof error.response === 'undefined') {
            this.$swal(
              'Ooops!',
              'A network error occurred. ' +
                'This could be a CORS issue or a dropped internet connection. ' +
                'It is not possible for us to know.',
              'error'
            )
          }
          return Promise.reject(error)
        }
      )
      if (e.length >= 2) {
        this.isLoading = true
        this.sales.wp_code.value = e
        BackOfficeSales.WpSearchAjaxByCode(e)
          .then(result => {
            this.wakil_pialang = result.data.data.map(x => {
              return {
                value: x.id,
                text:
                  x.wp_code +
                  ' (' +
                  x.first_name +
                  ' ' +
                  x.last_name +
                  '/ ' +
                  x.email +
                  ')'
              }
            })
            console.log(result)
            this.isLoading = false
          })
          .catch(err => {
            console.log(err.response)
            this.isLoading = false
          })
      }
    },
    aliasCodeCheck(e) {
      SalesService.interceptors.response.use(
        response => response,
        error => {
          if (typeof error.response === 'undefined') {
            this.$swal(
              'Ooops!',
              'A network error occurred. ' +
                'This could be a CORS issue or a dropped internet connection. ' +
                'It is not possible for us to know.',
              'error'
            )
          }
          return Promise.reject(error)
        }
      )
      if (this.sales.alias_code.value.length >= 5) {
        BackOfficeSales.salesAliasLinkCheck(this.sales.alias_code.value)
          .then(result => {
            if (result.status === 200) {
              this.sales.alias_code.isError = true
              this.sales.alias_code.message =
                this.sales.alias_code.value +
                ' Sudah Digunakan, Silakan Pilih Kode Lain'
            }
          })
          .catch(err => {
            if (err.response.status === 400) {
              this.sales.alias_code.isError = false
              this.sales.alias_code.message = ''
            }
          })
      } else {
        this.sales.alias_code.isError = true
        this.sales.alias_code.message = 'Minimal 5 karakter'
      }
    },
    salesCodeCheck(e) {
      SalesService.interceptors.response.use(
        response => response,
        error => {
          if (typeof error.response === 'undefined') {
            this.$swal(
              'Ooops!',
              'A network error occurred. ' +
                'This could be a CORS issue or a dropped internet connection. ' +
                'It is not possible for us to know.',
              'error'
            )
          }
          return Promise.reject(error)
        }
      )
      if (this.sales.sales_code.value.length >= 5) {
        BackOfficeSales.salesCodeCheck(this.sales.sales_code.value)
          .then(result => {
            if (result.status === 200) {
              this.sales.sales_code.isError = true
              this.sales.sales_code.message =
                this.sales.sales_code.value +
                ' Sudah Digunakan, Silakan Pilih Kode Lain'
            }
          })
          .catch(err => {
            if (err.response.status === 400) {
              this.sales.sales_code.isError = false
              this.sales.sales_code.message = ''
            }
          })
      } else {
        this.sales.sales_code.isError = true
        this.sales.sales_code.message = 'Minimal 5 karakter'
      }
    },
    clearImage(e, model) {
      document.getElementById(model).value = null
      this.employee[model].value = '/images/placeholder.jpg'
    },
    onFileChange(e, model) {
      const file = e.target.files[0]
      this.employee[model].value = URL.createObjectURL(file)
    },
    listGender() {
      Master.interceptors.response.use(
        response => response,
        error => {
          if (typeof error.response === 'undefined') {
            this.$swal(
              'Ooops!',
              'A network error occurred. ' +
                'This could be a CORS issue or a dropped internet connection. ' +
                'It is not possible for us to know.',
              'error'
            )
          }
          return Promise.reject(error)
        }
      )
      EnumDetail.findByRuleName('GENDER_TYPE')
        .then(result => {
          this.genders = result.data.data.map(x => {
            return {
              value: x.rule_value,
              text: x.rule_description
            }
          })
          this.gendersIsDisabled = false
        })
        .catch(err => {})
    },
    listIdType() {
      Master.interceptors.response.use(
        response => response,
        error => {
          if (typeof error.response === 'undefined') {
            this.$swal(
              'Ooops!',
              'A network error occurred. ' +
                'This could be a CORS issue or a dropped internet connection. ' +
                'It is not possible for us to know.',
              'error'
            )
          }
          return Promise.reject(error)
        }
      )
      EnumDetail.findByRuleName('PROFILE_IDENTITY_TYPE')
        .then(result => {
          this.idType = result.data.data.map(x => {
            return {
              value: x.rule_value,
              text: x.rule_description
            }
          })
          this.idTypeIsDisabled = false
        })
        .catch(err => {})
    },
    listBranch() {
      Master.interceptors.response.use(
        response => response,
        error => {
          if (typeof error.response === 'undefined') {
            this.$swal(
              'Ooops!',
              'A network error occurred. ' +
                'This could be a CORS issue or a dropped internet connection. ' +
                'It is not possible for us to know.',
              'error'
            )
          }
          return Promise.reject(error)
        }
      )
      BranchApi.list()
        .then(result => {
          this.branches = result.data.data.map(x => {
            return {
              value: x.id,
              text: x.name
            }
          })
          this.branchesIsDisabled = false
        })
        .catch(err => {
          this.$swal(
            'Ooops!',
            'A network error occurred. ' + 'It is not possible for us to know.',
            'error'
          )
        })
    },
    listBank() {
      Master.interceptors.response.use(
        response => response,
        error => {
          if (typeof error.response === 'undefined') {
            this.$swal(
              'Ooops!',
              'A network error occurred. ' +
                'This could be a CORS issue or a dropped internet connection. ' +
                'It is not possible for us to know.',
              'error'
            )
          }
          return Promise.reject(error)
        }
      )
      BankApi.list()
        .then(result => {
          this.bank = result.data.data.map(x => {
            return {
              value: x.id,
              text: x.bank_name + ' (' + x.bank_full_name + ')'
            }
          })
          this.bankIsDisabled = false
        })
        .catch(err => {
          this.$swal(
            'Ooops!',
            'A network error occurred. ' + 'It is not possible for us to know.',
            'error'
          )
        })
    },
    occupationByDepartment(e) {
      this.employee.occupation_id.value = 0
      this.departmentView(e)
      this.listOccupation({ department_id: e })
    },
    listDepartment(params) {
      Hrms.interceptors.response.use(
        response => response,
        error => {
          if (typeof error.response === 'undefined') {
            this.$swal(
              'Ooops!',
              'A network error occurred. ' +
                'This could be a CORS issue or a dropped internet connection. ' +
                'It is not possible for us to know.',
              'error'
            )
          }
          return Promise.reject(error)
        }
      )
      DepartmentApi.search(params)
        .then(result => {
          this.departments = result.data.data.map(x => {
            return {
              value: x.id,
              text: x.name
            }
          })
          this.departmentsIsDisabled = false
        })
        .catch(err => {
          this.$swal(
            'Ooops!',
            'A network error occurred. ' + 'It is not possible for us to know.',
            'error'
          )
        })
    },
    listOccupation(params) {
      Hrms.interceptors.response.use(
        response => response,
        error => {
          if (typeof error.response === 'undefined') {
            this.$swal(
              'Ooops!',
              'A network error occurred. ' +
                'This could be a CORS issue or a dropped internet connection. ' +
                'It is not possible for us to know.',
              'error'
            )
          }
          return Promise.reject(error)
        }
      )
      OccupationApi.search(params)
        .then(result => {
          this.occupations = result.data.data.map(x => {
            return {
              value: x.id,
              text: x.name
            }
          })
          this.occupationsIsDisabled = false
        })
        .catch(err => {
          this.$swal(
            'Ooops!',
            'A network error occurred. ' + 'It is not possible for us to know.',
            'error'
          )
        })
    },
    employeeSearch(param) {
      Hrms.interceptors.response.use(
        response => response,
        error => {
          if (typeof error.response === 'undefined') {
            this.$swal(
              'Ooops!',
              'A network error occurred. ' +
                'This could be a CORS issue or a dropped internet connection. ' +
                'It is not possible for us to know.',
              'error'
            )
          }
          return Promise.reject(error)
        }
      )
      EmployeeApi.search(param)
        .then(res => {
          this.employees = res.data.data.map(x => {
            return {
              value: x.id + ':' + x.user_id,
              text: x.firstname + ' ' + x.lastname + ' (' + x.email + ')'
            }
          })
          this.employeesIsDisabled = false
        })
        .catch(error => {
          this.$swal(
            'Ooops!',
            'A network error occurred. ' + 'It is not possible for us to know.',
            'error'
          )
        })
    },
    departmentView(id) {
      Hrms.interceptors.response.use(
        response => response,
        error => {
          if (typeof error.response === 'undefined') {
            this.$swal(
              'Ooops!',
              'A network error occurred. ' +
                'This could be a CORS issue or a dropped internet connection. ' +
                'It is not possible for us to know.',
              'error'
            )
          }
          return Promise.reject(error)
        }
      )
      DepartmentApi.view(id)
        .then(result => {
          this.isDepartmentSales = !!result.data.data.is_sales
        })
        .catch(error => {
          this.$swal(
            'Ooops!',
            'A network error occurred. ' + 'It is not possible for us to know.',
            'error'
          )
        })
    },
    selectSearch(e) {
      if (e.length >= 3) {
        this.employeeSearch({ fullname: e })
      }
    }
  },
  mounted() {
    document.title = 'Add New Recruit'
  },
  beforeMount() {
    this.listDepartment({ limit: -1 })
    this.employeeSearch({})
    this.listBranch()
    this.listGender()
    this.listIdType()
    this.listBank()
    this.SalesCreateAttributes()
  }
}
</script>

<template>
    <div class="page-content page-content-login">
        <!-- Main content -->
        <div class="content-wrapper">
            <!-- Content area -->
            <div class="content d-flex flex-column justify-content-center align-items-center">
                <div class="d-block">
                    <center>
                        <img class=""
                             src="../scss/global_assets/images/mifx-brand-identity-mini-brand-guideline-rgb-1.png"
                             srcset="../scss/global_assets/images/mifx-brand-identity-mini-brand-guideline-rgb-1@2x.png 2x,
					 ../scss/global_assets/images/mifx-brand-identity-mini-brand-guideline-rgb-1@3x.png 3x">
                    </center>
                </div>
                <div class="login-bg">
                    <form @submit.prevent="validateForm" action="#" class="login-form">
                        <div class="card mb-0">
                            <div class="card-body">
                                <div
                                        class="form-group form-group-feedback form-group-feedback-left"
                                >

                                    <center>
                                        <label class="text-white align-center text-center" for="">Email
                                            Address</label>
                                    </center>
                                    <input
                                            :class="{ 'is-invalid': isError }"
                                            class="form-control text-white"
                                            placeholder="Email / Username"
                                            type="text"
                                            v-model="user.username"
                                    />
                                </div>
                                <div class="invalid-feedback">
                                    <div class="validation-invalid-label">{{ errorMessage }}</div>
                                </div>

                                <div
                                        class="form-group form-group-feedback form-group-feedback-left"
                                >
                                    <center>
                                        <label class="text-white align-center text-center" for="">Password</label>
                                    </center>
                                    <input
                                            :class="{ 'is-invalid': isError }"
                                            class="form-control text-white"
                                            placeholder="Password"
                                            type="password"
                                            v-model="user.password"
                                    />
                                </div>
                                <div class="invalid-feedback">
                                    <div class="validation-invalid-label">{{ errorMessage }}</div>
                                </div>

                                <div class="form-group d-flex align-items-center">
                                    <div class="form-check mb-0">
                                        <label class="form-check-label text-white">
                                            <input
                                                    checked
                                                    class="form-input-styled"
                                                    data-fouc
                                                    name="remember"
                                                    type="checkbox"
                                            />
                                            Remember
                                        </label>
                                    </div>

                                    <a class="ml-auto text-green" href="javascript:void(0)"
                                    >Forgot password?</a
                                    >
                                </div>

                                <div class="form-group">
                                    <button class="btn bg-green-700 btn-block radius-5" type="submit">
                                        Login
                                        <i class="icon-circle-right2 ml-2" v-if="!loading"></i>
                                        <i
                                                class="fad fa-spinner-third fa-spin ml-2"
                                                v-if="loading"
                                        ></i>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
            <!-- /content area -->
        </div>
        <!-- /main content -->
    </div>
</template>
<script>
    import Authtentication from '../apis/Authtentication'
    import HelperApi from '../apis/Helper'
    import Log from '../apis/Log'
    import Hrms from '../services/hrms'
    import router from '../routes'
    import axios from 'axios'
    import TokenObject from '../models/roleByAccessTokenObject'

    export default {
        data() {
            return {
                user: {
                    username: '',
                    password: '',
                    ip: '127.0.0.1',
                    loc: 'hrms-default',
                    via: 'hrms-frontend',
                    ua: 'hrms-frontend',
                },
                ip_address: '',
                loading: false,
                errorMessage: '',
                isError: false,
                TokenObject: TokenObject
            }
        },
        methods: {
            validateForm(e) {
                Hrms.interceptors.response.use(
                    response => response,
                    error => {
                        if (typeof error.response === 'undefined') {
                            this.$swal(
                                'Ooops!',
                                'A network error occurred. ' +
                                'This could be a CORS issue or a dropped internet connection. ' +
                                'It is not possible for us to know.',
                                'error'
                            )
                        }
                        return Promise.reject(error)
                    }
                )
                this.loading = true

                
                Authtentication
                .authorize(this.user.username, this.user.password,null,null,this.user.via,null)
                    .then(res => {
                        console.log('Authtentication',this.user)
                        if (res.data.code !== 422) {
                            localStorage.setItem('attempts', 0)
                            localStorage.setItem('is_login', false)
                            localStorage.setItem('hr_token','{"access_token":"","user_id":"'+res.data.data.user_id+'","code":"'+res.data.data.authorization_code+'","user_ip":"'+this.user.ip+'","user_loc":"'+this.user.loc+'","user_ua":"'+this.user.ua+'"}')
                            router.push('/verify')
                        } else {
                            let attempts = localStorage.getItem('attempts')
                            if(!attempts){
                                attempts = localStorage.setItem('attempts',0)
                            }else{
                                attempts = parseInt(attempts) + 1
                                localStorage.setItem('attempts',attempts)
                            }
                            if(attempts > 3){
                                res.data.message = res.data.message+'!!' 
                            }
                            this.$swal('Ooops!', res.data.message, 'error')
                        }
                    })
                    .catch(error => {
                        this.loading = false
                        if (error.response.status === 422) {
                            this.isError = true
                            this.errorMessage = error.response.data.message
                            this.$swal('Ooops!', error.response.data.message, 'error')
                        }
                        console.log(error.response)
                    })
                    
            }
        },
        mounted() {
            document.title = 'Login to your account'
            document.body.className = document.body.className.replace("navbar-top", "");
            if(!localStorage.getItem('attempts')){
                localStorage.setItem('attempts',0)
            }
            if(!localStorage.getItem('is_verify')){
                localStorage.setItem('is_verify',false)
            }
        }
    }
</script>
